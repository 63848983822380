import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../Auth/AuthContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { showToast } from '../components/common/toast';
import countryLanguage from 'country-language';
import { useModal } from "../components/common/Modal/ModalContext";
import { useTranslation } from '../components/common/LanguageProvider';

import Editor from './Sharing/Editor/Editor';
import SeoContent from './Sharing/seo-content';
//import Image from 'next/image';


function SharingComponent(props) {
  const { t, setLanguage } = useTranslation();
  console.log(t("Convert Audio to Text"));
  const { login, signInWithToken, sendResetPasswordMail, googleLogin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // To retrieve a specific query parameter:
  const uid = queryParams.get('uid');
  const redirect = queryParams.get('redirect');
  const lang = queryParams.get('lang');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmail, setIsEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isRegister, setIsRegister] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^.{6,}$/;
  const [country, setCountry] = useState('');
  const rememberRef = useRef();
  const [isRemember, setIsRemember] = useState(true);
  const { showModal } = useModal();

  const isDesktop = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    return width >= 1024 && height >= 600 && width / height >= 1.3;
  };

  async function handleLogin() {
    try {
      setIsLoading(true);
      setError('');
      if (!emailRegex.test(email)) {
        setIsLoading(false)
        setError("invalid-email")
        showToast(t("Invalid email type. Please check your email."), { type: "error", position: "top-center" });
        return;
      }
      if (!passwordRegex.test(password)) {
        setIsLoading(false)
        showToast(t("Short password. The password must be 6 characters long or more."), { type: "error", position: "top-center" });
        return;
      }
      const response = await login(email, password);

      sessionStorage.setItem("remember", isRemember);
      localStorage.setItem("remember", isRemember);
      localStorage.setItem("signedin-flag", true);
      navigate('/dashboard');

      // Do something after successful login, like redirecting to another page
    } catch (error) {
      setIsLoading(false)
      console.error("Failed to login:", error);
      console.log(error.code)
      if (error.code === "auth/user-not-found") {
        showToast(t("User not found. Please create an account."), { type: "error", position: "top-center" });
      } else if (error.code === "auth/invalid-email") {
        showToast(t("Invalid email type. Please check your email."), { type: "error", position: "top-center" });
      } else if (error.code === "auth/wrong-password") {
        showToast(t("Wrong password. Please check your password."), { type: "error", position: "top-center" });
      } else {
        showToast(t("An error occured. Please try again."), { type: "error", position: "top-center" });
      }
      // Handle login errors, for example, show a message to the user
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  }

  useEffect(() => {
    async function fetchTranslations() {
      if (lang && lang.length === 2) {
        localStorage.setItem("dashboard-language", lang);
        localStorage.setItem("transcript-language", lang);
        setLanguage(lang);
        const countryDetails = countryLanguage.getCountry(lang);
        if (countryDetails) {
          setCountry(countryDetails.iso639_1);
        }

        try {

          navigate('/signIn');

        } catch (error) {
          console.error("Failed to load translations:", error);
          // Handle error (e.g., show notification, use fallback language, etc.)
        }
      }
    }

    fetchTranslations();
  }, [lang]);

  const findLanguage = async () => {
    try {
      const browserLanguage = navigator.language || navigator.userLanguage || 'en-US';
      const [iso639_1, countryCode] = browserLanguage.split("-");

      setLanguage(iso639_1);
      localStorage.setItem("dashboard-language", iso639_1);
      localStorage.setItem("dashboard-country", countryCode || 'US');
      localStorage.setItem("transcript-language", browserLanguage);
      try {
        if (window.chrome && window.chrome.runtime) {
          let widgetData = localStorage.getItem("gleap-widget-session-cj7rzrc0mklHXp7Ux1hISbI6hcVIIPJp");
          let language = localStorage.getItem("transcript-language");
          window.chrome.runtime.sendMessage(localStorage.getItem("extId"), { action: "loginUser", widgetData, language });
        }
      } catch (error) {
        console.log("Error accessing chrome runtime:", error);
      }

      window.location.reload();

    } catch (error) {
      console.error('Error detecting browser language:', error);
    }
  };

  useEffect(() => {
    if (uid) {
      setIsLoading(true);
      automateLogin(uid);
    } else {
      setIsLoading(false);
    }
  }, [uid]);
  const automateLogin = async (uid) => {
    setIsLoading(true);
    const response = await fetch("https://hg0761tbmf.execute-api.eu-central-1.amazonaws.com/default/automateLogin?hashedId=" + uid);

    if (response.status === 200) {
      const data = await response.json();
      console.log(data);
      console.log(data["customToken"]); // Here you should see your token or any other data sent from Lambda
      const token = data["customToken"];
      await signInWithToken(token);
      setIsLoading(false);
      console.log("keke")
      if (redirect) {

        console.log(redirect)
        navigate(redirect);

      } else {
        navigate('/dashboard');
      }

    } else {
      console.log('Error:', response.status, response.statusText);
    }

    console.log("kek");
  };


  useEffect(() => {
    /* const isMobileDevice = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    if (isMobileDevice()) {
      let isMobile = window.matchMedia("only screen and (max-width: 960px)").matches;
      if (isMobile) {
        window.location.href = "https://transkriptormobile.page.link/sign";
        setTimeout(() => {
          window.location.href = "https://transkriptor.com";
        }, 3000);
      }
    } */
  }, []);

  const [isCompleted, setIsCompleted] = useState(false);
  return (
    <>
      <div className="seo-container">

        {isDesktop() && <SeoContent />}
      </div>

      <div
        className="main-transcript-content"
        style={{ width: isDesktop() ? '' : '100%' }}
      >
        <Editor language={"en"} setIsCompleted={props.setIsCompleted}/>
      </div>

    </>

  );
}

export default SharingComponent;
