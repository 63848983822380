import React, {useEffect, useRef, useState} from "react";

import SpeakerDropdown from "./SpeakerDropdown";
const Transcription = 
    React.forwardRef((props, ref) => {
        const {item, index, goToBlock, handleTranscriptContent, handleKeyDown, onAddSpeaker, speakerList, changeSpeaker, onRenameSpeaker, fontSize, focus, slideFocus} = props;
        var text = item.text;
        var voiceStart = item.VoiceStart;
        var voiceEnd = item.VoiceEnd;
        var speaker = item.Speaker;
        var contentRef = ref;
        const listRef = useRef(null);
        const [isFocus, setIsFocus] = useState(false);
        useEffect(() => {
            console.log("fontSize", fontSize)
        }, [fontSize]);
        function msToTime(duration) {
           
            var milliseconds = (duration % 1000);
            var seconds = Math.floor(duration / 1000) % 60;
            var minutes = Math.floor(duration / (1000 * 60)) % 60;
            var hours = Math.floor(duration / (1000 * 60 * 60)) % 24;
            if (hours < 10) {
                hours = "0" + hours.toString()
            }
            if (minutes < 10) {
                minutes = "0" + minutes.toString()
            }
            if (seconds < 10) {
                seconds = "0" + seconds.toString()
            }
            return hours.toString() + ":" + minutes.toString() + ":" + seconds.toString()
        }
    
        useEffect(() => {
            if(focus  > voiceStart && focus < voiceEnd) {
                setIsFocus(true);
                console.log("focus", focus)
              
    
            }else{
                setIsFocus(false);
            }
           
        }, [focus]);
        const handlePaste = (event) => {
            event.preventDefault();
            const text = event.clipboardData.getData('text/plain');
            document.execCommand("insertText", false, text);
        };
    
    
        useEffect(() => {
            if(slideFocus  > voiceStart && slideFocus < voiceEnd) {
                setTimeout(() => {
                    console.log(listRef.current)
                    listRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 0);
              
            }
           
        }, [slideFocus]);
        
        
        return (
            <>
                {text && text.length > 0 ? (
                    <>
                        <div key={index} className={`transkript-list ${isFocus  ? "highlighted": ""}`} ref={listRef} onClick={()=>{
                                    goToBlock(voiceStart);
                                    
                                }}>
                           
                            <div className="time-speaker-container">
                                <div class="transcript-time notranslate" >
                                    <span class="transcript-time-icon " style={{fontSize: fontSize + "px"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16.875 16.875"><g id="clock" transform="translate(-1.125 -1.125)"><path id="Path_151" data-name="Path 151" d="M17.966,6.715H16.841v6.261l4.064,1.932.483-1.016-3.423-1.627Z" transform="translate(-7.858 -2.795)" fill="#6b6c6d"/><path id="Path_152" data-name="Path 152" d="M9.563,1.125A8.438,8.438,0,1,0,18,9.563,8.437,8.437,0,0,0,9.563,1.125Zm0,15.75a7.313,7.313,0,1,1,7.313-7.312A7.313,7.313,0,0,1,9.563,16.875Z" fill="#6b6c6d"/></g></svg>
                                    </span>{msToTime(voiceStart)}
                                </div>
                                <SpeakerDropdown index={index} speaker={speaker} onAddSpeaker={onAddSpeaker} speakerList={speakerList} changeSpeaker={changeSpeaker} onRenameSpeaker={onRenameSpeaker} fontSize={fontSize}/>
                            
                            </div>
                            <div class="transcript-content" ref={contentRef} style={{fontSize: fontSize + 'px'}} onPaste={handlePaste}>
                                {text}
                            </div>
                        </div>                
                    </>
                ): (
                    <>
                    </>
                )}
    
            </>
        );
    })


export default Transcription;

function arePropsEqual(prevProps, nextProps) {
    // If the focus is within the voiceStart and voiceEnd range of the component, then consider props unequal and allow re-render
    const isRelevantFocusChange =
      (nextProps.focus > prevProps.item.VoiceStart &&
        nextProps.focus < prevProps.item.VoiceEnd) ||
      (prevProps.focus > prevProps.item.VoiceStart &&
        prevProps.focus < prevProps.item.VoiceEnd);
  
    // If the focus is not relevant for this component instance, disregard focus changes when determining whether to re-render
    const didFocusChange = isRelevantFocusChange && prevProps.focus !== nextProps.focus;
    
    // Similarly, consider other props, and be specific if needed
    const didSlideFocusChange = prevProps.slideFocus !== nextProps.slideFocus;
    const didFontSizeChange = prevProps.fontSize !== nextProps.fontSize;
    
    // Continue with other relevant prop comparisons
    // ...
  
    return !(
      didFocusChange ||
      didSlideFocusChange ||
      didFontSizeChange
      // ... other prop comparisons
    );
  }
  