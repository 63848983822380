// DirectionContext.js
import React, { createContext, useContext, useState } from 'react';

const DirectionContext = createContext();

export const useDirection = () => useContext(DirectionContext);

export const DirectionProvider = ({ children }) => {
    var lang = localStorage.getItem("dashboard-language")
    var directionLang = "ltr"
    if(lang === "ar" || lang === "fa" || lang === "he" || lang ==="ur"){
        directionLang = "rtl"
    }
  const [direction, setDirection] = useState(directionLang); // default to LTR

  return (
    <DirectionContext.Provider value={{ direction, setDirection }}>
      {children}
    </DirectionContext.Provider>
  );
};
