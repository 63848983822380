import React, { useEffect } from "react";
import { useRef } from "react";
import axios from "axios";

import { useAuth } from "../Auth/AuthContext";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useModal } from "../components/common/Modal/ModalContext";
import filesBlue from "../assets/img/icon/files-blue.svg";
import dropbox from "../assets/img/icon/dropbox.svg";
import googleDrive from "../assets/img/icon/google-drive.svg";
import oneDrive from "../assets/img/icon/onedrive.svg";
import youtube from "../assets/img/icon/youtube.svg";
import { fetchDataPost } from "../hooks/apiUtils";
import { showToast } from "../components/common/toast";
import { useTranslation } from "../components/common/LanguageProvider";
import uploadPng from "../assets/img/icon/upload-btn-icon.svg";
import FAQModal from "../components/common/Modal/FaqModal";

import { v4 as uuidv4 } from 'uuid';
import { sendAmplitudeData } from "../Auth/amplitude";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
var upload_arr = [];
var upload_status = [];
function Converter({ header, hideText, userData, segment, linkParam, languageParam }) {
  const { t } = useTranslation();
  const { hashedId, currentUser } = useAuth();
  const fileInput = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [urlUpload, setUrlUpload] = useState(false);
  const [stillProcessing, setStillProcessing] = useState(false);
  const [urlStatus, setUrlStatus] = useState(null);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const { showModal, hideModal, specialHideModal } = useModal();
  const [showFaqModal, setShowFaqModal] = useState(false);

  var urlIndex = 0;
  const [attachListener, setAttachListener] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    if (attachListener) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [attachListener]);
  function identifyLinkType(url) {
    if (url.includes("youtube.com") || url.includes("youtu.be")) {
      return "youtube_transcription";
    } else if (url.includes("onedrive.live.com")) {
      return "onedrive_transcription";
    } else if (url.includes("drive.google.com")) {
      return "googledrive_transcription";
    } else if (url.includes("dropbox.com")) {
      return "dropbox_transcription";
    }
    else {
      return "url_transcription"; // Link does not match known patterns
    }
  }

  const startClock = () => {
    setTimeout(() => {
      specialHideModal()
      navigate("/files")
    }, 60 * 1000)
  }
  useEffect(() => {
    if(linkParam && languageParam && hashedId && userData){
      showModal("TRANSCRIBE-URL", {linkParam, languageParam}, { onTranscribeURL });
    }
  }, [linkParam, languageParam, hashedId, userData])
  const onTranscribeURL = async (language, service, url) => {

    startClock()
    if (userData.Minutes < 0) {
      setTimeout(() => {
        showModal("UPGRADE-MINUTES", {type: "url_transcription"});
      }, 1000);
      return;
    }
    if (!language) {
      showToast(t("Please select a language"), {
        type: "error",
        position: "top-center",
      });
      return;
    }
    showModal("TRANSCRIBE-PROCESSING")
    setUrlUpload(true);
    setUrlStatus("checking");
    var payload = {
      feature_category: "transcription",
      feature_subcategory: "cloud_transcription",
      feature_name: identifyLinkType(url),
      feature_input_type: "url",
      feature_content: url,
      feature_language: language,
      feature_service: service,
      feature_id: uuidv4(),
      feature_product: "transkriptor",
      feature_status: "production",
      feature_platform: "web",
      feature_start_timestamp: new Date().getTime(),
      needs_credit: true,
      is_premium: false,
      data_source: "frontend"
    }
    sendAmplitudeData("feature_init", userData, currentUser, "", "", "", payload)
    const postPayload = { url: url, user_id: hashedId, service: service, language: language, feature_id: payload.feature_id, feature_start_timestamp: payload.feature_start_timestamp }
    var temp_folder_id = localStorage.getItem("destinationId");
    if(localStorage.getItem("destinationId") === hashedId){
      temp_folder_id = "";
    }
    if(temp_folder_id && temp_folder_id.length > 0){
      postPayload.folder_id = localStorage.getItem('destinationId')
    }
    var speaker_count = localStorage.getItem("speakerCount");
    if(speaker_count && speaker_count.length > 0){
      postPayload.speaker_count = speaker_count;
    }
    fetchDataPost(
      "https://3j3l2hnktb.execute-api.eu-central-1.amazonaws.com/default/Transcribe-From-Url-Initiation", postPayload
      
    ).then(async(data) => {
      if (data.status === 200) {

        showToast(
          t(
            "Your file is being transcribed. You will be notified when it is ready.",
            { type: "success" }
          )
        );
        setAttachListener(false);
        setTimeout(() => {
          specialHideModal();
          navigate("/files")
        }, 5000);


      } else if (data.status === 500) {
        const response = await data.json();
        if(response.message && response.message === "Folder URL"){
          specialHideModal();
          setAttachListener(false)
          setUrlUpload(false);
          showToast("Please make sure that the url is file not folder, share file link from Youtube, Google Drive, Dropbox, OneDrive or raw media files...")
          return;
        }
        specialHideModal();
        setAttachListener(false)
        setUrlUpload(false);
        showToast("Please make sure that the url is valid, share file link from Youtube, Google Drive, Dropbox, OneDrive or raw media files...")

      } else if (data.status === 403) {
        specialHideModal();
        setAttachListener(false)
        setUrlUpload(false);

        setUrlStatus("privateUrl")
        showToast("Please make sure that the file in url is public.")

      } else if (data.status === 503) {
        checkProcessing(url)
      } else {
        specialHideModal();
        setAttachListener(false)
        setUrlUpload(false);
        showToast(t("Something went wrong, please try again..."), { type: "error" });
      }
    });
  };

  const checkProcessing = async (url) => {
    setStillProcessing(true);
    fetchDataPost(
      "https://inwv3q0922.execute-api.eu-central-1.amazonaws.com/default/URL-Transcription-Check",
      { url: url }
    ).then((data) => {
      if (data === "processing" && urlIndex < 90) {
        urlIndex++;
        setTimeout(checkProcessing(url), 10000);
      } else if (data === "processing" && urlIndex >= 90) {
        showToast(t("Upload failed, please try again..."), { type: "error" });
        setTimeout(navigate("/dashboard"), 3000);
      } else if (data === "ready") {
        setAttachListener(false);
        setTimeout(function () {
          setUrlStatus("uploaded");
          navigate("/files");
        }, 40000);
      }
    });
  };

  const checkStatus = () => {
    if (userData && userData.Minutes > 0) {
      showModal("TRANSCRIBE-URL", { onTranscribeURL });
    } else if (userData.Minutes <= 0) {
      showModal("UPGRADE-MINUTES")
    } else {
      showToast(t("Please Wait", { type: "info" }))
    }


  }

  useEffect(() => {
    if (segment % 2 === 0 || segment % 2 === 1) {
      setIsLoading(false)
    }
    localStorage.removeItem("destinationId");
    localStorage.removeItem('speakerCount');

  }, [segment])

  const faqData = [
    {
      question: "How can I convert YouTube videos to text?",
      answer: (
        <>
          <ol>
            <li>{t("Click the 'Convert Audio from YouTube or Cloud' button.")}</li>
            <li>{t("Paste the URL of the YouTube video.")}</li>
            <li>{t("Ensure that the video link is public.")}</li>
            <li>{t("Select the language spoken in the video.")}</li>
            <li>{t("Choose the transcription service.")}</li>
          </ol>
        </>
      ),
    },
    {
      question: "How can I convert my audio or video files in my Google Drive, OneDrive, or Dropbox accounts to text?",
      answer: (
        <>
          <ol>
            <li>{t("Click the 'Convert Audio from YouTube or Cloud' button.")}</li>
            <li>{t("Paste the URL of the video or audio file.")}</li>
            <li>{t("Ensure that the file link is public.")}</li>
            <li>{t("Select the language spoken in the audio or video.")}</li>
            <li>{t("Choose the transcription service.")}</li>
          </ol>
        </>
      ),
    },
    {
      question: "How to make a Google Drive file public?",
      answer: (
        <>
          <p>{t("Follow these steps to make your Google Drive file public:")}</p>
          <ol>
            <li>{t("Navigate to Google Drive and open the file you wish to share.")}</li>
            <li>{t("Click the ‘Share’ button in the upper right corner.")}</li>
            <li>{t("Click 'Change to anyone with the link' and choose 'Viewer' or 'Editor' to set the access level.")}</li>
          </ol>
        </>
      ),
    },
    {
      question: "Is there a difference in transcription quality between sharing the URL and uploading the file from my device?",
      answer: "No, the transcription quality is consistent regardless of the upload method used."
    },
    {
      question: "How can I improve transcription quality?",
      answer: (
        <>
          <p>{t("Here are several tips to enhance the quality of your transcriptions:")}</p>
          <ul>
            <li><strong>{t("Minimize Background Noise:")}</strong> {t("Use Audacity to reduce noise with these detailed steps:")}
              <ol>
                <li>{t("Visit Audacityteam.org/download, download and open Audacity.")}</li>
                <li>{t("Click 'File' and 'Open' to locate the audio file you wish to improve.")}</li>
                <li>{t("Select your “Room Tone” by dragging your mouse over a section where there is minimal or no audio sound.")}</li>
                <li>{t("Select the “Effect” menu and then choose 'Noise Reduction.'")}</li>
                <li>{t("Click 'Get Noise Profile' and wait for the modal to disappear.")}</li>
                <li>{t("Return to your timeline of audio to clear your selection.")}</li>
                <li>{t("Go back to the 'Effect' menu and select 'Noise Reduction' again.")}</li>
                <li>{t("Click 'Preview' to listen to the default noise removal settings. Adjust the sliders if necessary and preview again to check the results.")}</li>
              </ol>
            </li>
            <li><strong>{t("Optimize Microphone Placement:")}</strong>{t("Keep the microphone close to the speaker to ensure clear audio capture.")}</li>
            <li><strong>{t("Consider Recording Quality:")}</strong> {t("Avoid using low-quality recording settings and platforms that compress audio, such as phone calls or Skype.")}</li>
            <li><strong>{t("Manage Accents:")}</strong>{t("Be mindful of heavy accents, as they can impact transcription accuracy. Clear and slow speech can improve results.")}</li>
          </ul>
        </>
      ),
    }
  ];


  return (
    <>
      {isLoading ? (<>
        <div className="col-lg-4 card-margin">
          <div className="card card-padding card-100 text-center card-hover">
            <Skeleton count={5} style={{ borderRadius: '10px', marginTop: "21.07px" }} />
          </div>
        </div>
      </>) : (
        header
          ? <div onClick={() => {
            setUrlStatus("checking");
            checkStatus();
          }} className='btn header-btn rounded-btn'>
            <span className="round-color-icon " style={{ padding: '6px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z" />
                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z" />
              </svg>
            </span> {!hideText && t("Import URL")}
          </div>
          :

          <>

            <FAQModal isOpen={showFaqModal} faqData={faqData} onClose={() => setShowFaqModal(false)} />
            <div
              class="col-lg-4 card-margin"
            >
              <div
                class="card card-padding card-100 text-center card-hover action-box"
                style={{ position: "relative" }}
                onClick={() => {
                  setUrlStatus("checking");
                  checkStatus();
                }}
              >
                <div className="top-right-button" onClick={
                  (e) => {
                    e.stopPropagation();
                    setShowFaqModal(true)
                  }
                }>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-info-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                  </svg>
                </div>
                <div class="img-icon-list">
                  <span
                    class="pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Dropbox"
                  >
                    <img src={dropbox} class="img-fluid" alt="img" />
                  </span>
                  <span
                    class="pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Google Drive"
                  >
                    <img src={googleDrive} class="img-fluid" alt="img" />
                  </span>
                  <span
                    class="pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="OneDrive"
                  >
                    <img src={oneDrive} class="img-fluid" alt="img" />
                  </span>
                  <span
                    class="pointer"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Youtube"
                  >
                    <img src={youtube} class="img-fluid" alt="img" />
                  </span>
                </div>
                <p class="ft-18-title">
                  {t("Convert Audio from Youtube or Cloud")}
                </p>
                <p>
                  {t(
                    "Transkriptor will transcribe the audio or video from youtube link or cloud storage (Google Drive, One Drive, Dropbox)."
                  )}
                </p>
              </div>
            </div>

          </>
      )}
    </>
  );
}

export default Converter;

// s3 upload function for files smaller than 5 mb
export const uploadToS3 = async (presignedUrl, file, onUploadProgress) => {
  try {
    const result = await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onUploadProgress(percentCompleted);
      },
    });
    return result;
  } catch (error) {
    throw error;
  }
};
