import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { showToast } from '../../components/common/toast';
import { useModal } from '../../components/common/Modal/ModalContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../Auth/AuthContext';
import { useTranslation } from '../../components/common/LanguageProvider';
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { sendAmplitudeData } from '../../Auth/amplitude';
import { v4 as uuidv4 } from 'uuid';
import { fetchData } from '../../hooks/apiUtils';
const multipartUrl =
  "https://u8vqorw2rc.execute-api.eu-central-1.amazonaws.com/default/AA-API-multipartupload?";
const uploaderFinisherUrl =
  "https://7z7j0bn899.execute-api.eu-central-1.amazonaws.com/default/AA-API-TranskriptorUploaderFinisher?";
  var upload_arr = [];
var upload_status = [];

const UploaderContext = createContext();

export const useUploader = () => useContext(UploaderContext);

export const UploadProvider = ({ children }) => {
	const { t } = useTranslation();
	const [uploadProgress, setUploadProgress] = useState(0);
	const { showModal } = useModal();
	const [selectedFiles, setSelectedFiles] = useState(null);
	const navigate = useNavigate();
	const [attachListener, setAttachListener] = useState(false);
	const [showUploadBox, setShowUploadBox] = useState(false);
	const [showUploadedState, setShowUploadedState] = useState(false);
	const { hashedId, userData, currentUser } = useAuth();
	const [uploadQueue, setUploadQueue] = useState([]);
	const navigateFile = useRef(true);
	const ffmpegRef = useRef(new FFmpeg());
	const [loaded, setLoaded] = useState(false);
	const [language, setLanguage] = useState(null);
	const [service, setService] = useState(null);
	const [conversionFlag, setConversionFlag] = useState(false);
	const [filesRouteLoading, setFilesRouteLoading] = useState(false);
	var attempt = 0;

	const closeBoxTimeout = useRef(null);


	const startCloseTimeout = () => {
		if (closeBoxTimeout.current !== null) {
			clearTimeout(closeBoxTimeout.current);
		}
		setShowUploadedState(true);
		closeBoxTimeout.current = setTimeout(() => {
			closeUploader();
		}, 2000);
	};
	useEffect(() => {
		const loadFFmpeg = async () => {
			const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
			const ffmpeg = ffmpegRef.current;
			try {
				await ffmpeg.load({
					coreURL: await toBlobURL(
						`${baseURL}/ffmpeg-core.js`,
						"text/javascript"
					),
					wasmURL: await toBlobURL(
						`${baseURL}/ffmpeg-core.wasm`,
						"application/wasm"
					),
				});
				setLoaded(true);
			} catch (error) {
				console.error("Failed to load FFmpeg:", error);
			}
		};

		loadFFmpeg();
	}, []);
	const cancelCloseTimeout = () => {
		if (closeBoxTimeout.current !== null) {
			clearTimeout(closeBoxTimeout.current);
			closeBoxTimeout.current = null; // Reset the ref after clearing the timeout
		}
	};


	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (uploadQueue.some(file => file.status !== 'completed')) {
				event.preventDefault();
				event.returnValue = '';
			}
		};

		if (attachListener) {
			window.addEventListener('beforeunload', handleBeforeUnload);
			return () => {
				window.removeEventListener('beforeunload', handleBeforeUnload);
			};
		}
	}, [attachListener]);
	const addMoreFiles = (files) => {
        const isTrial = userData?.SStatus === "Trial";
        if (isTrial) {
			showModal("TRIAL-FILE-LIMIT", { files: files}, {onTranscribeModal});
        }

    };
	const onTranscribeModal = (files) => {
		showModal("TRANSCRIBE-MULTIPLE-FILES", { files: files }, { onTranscribeMultipleFiles });
	};
	
	const onUploadMultipleFiles = async (files) => {
		const isTrial = userData.SStatus === "Trial";
		var temp_files = files;
		if(isTrial && files.length > 1){
			temp_files = files.slice(0, 1);
			showModal("TRIAL-FILE-LIMIT", { files: temp_files }, { onTranscribeModal});
		}else{
			showModal("TRANSCRIBE-MULTIPLE-FILES", { files: temp_files }, { onTranscribeMultipleFiles });
	
		}
		};

	const checkUploader = () => {
		showModal("CHECK-UPLOADER", {closeUploaderBox});
	};
	const closeUploaderBox = () => {
		setAttachListener(false);
		setShowUploadBox(false);
		setUploadQueue([]);
		setUploadProgress(0);
		setTimeout(() => {
			window.location.reload();	
		}, 1000);

	}

	const closeUploader = () => {
		setAttachListener(false);
		setShowUploadBox(false);
		setUploadQueue([]);
		setUploadProgress(0);

	}


	const checkAndUploadNext = (service, language, files, pk, sk) => {
		let file
		setUploadQueue(prevQueue => {
			let newQueue = [...prevQueue];
			// Find the first file that is waiting to be uploaded.
			let index = newQueue.findIndex(file => file.status === 'waiting');
			if (index !== -1) {
				if(file){
					return newQueue;
				}
				// Assume `files` is an array of files to be uploaded and is accessible in this scope.
				newQueue[index].status = 'uploading';
				file = files.find(file => compareFiles(file.name) === compareFiles(newQueue[index].name));
				setUploadProgress(0); // Reset upload progress.
				// No else clause needed here as we only modify the status if a file to upload is found.
			} else if (!newQueue.some(file => file.status === 'waiting' || file.status === 'uploading')) {
				// Navigate only if there are no files waiting or currently uploading.
				if(sk && pk && !window.location.href.includes('editor') )
				  	navigate(`/editor/${sk}/${pk}`)
				
			}
			return newQueue;
		});
		
		if (file) {
			onTranscribeFile(language, service, file, true, files);

		} else {
			startCloseTimeout();
			navigateFile.current = true;
		}
	}


	const onTranscribeMultipleFiles = async (language, service, files) => {
		if (!userData) {
			showToast(t("Unknown Error"), { type: "error" });
			window.location.reload();
		}
		if (userData.Minutes < 0) {
			showModal("UPGRADE-MINUTES", {type: "local_transcription"});
			return;
		}
		cancelCloseTimeout()
		if (uploadQueue.some(file => file.status !== 'completed')) {
			const queueFiles = files.map(file => ({ 'name': file.name, 'status': 'waiting' }));
			setUploadQueue(prevQueue => [...prevQueue, ...queueFiles]);
		}
		else {
			// add first file as uploading and rest as waiting
			const queueFiles = files.map((file, index) => ({ 'name': file.name, 'status': index === 0 ? 'uploading' : 'waiting' }));
			setUploadQueue(prevQueue => [...prevQueue, ...queueFiles]);
		}

		onTranscribeFile(language, service, files[0], true, files);
	};


	const onTranscribeFile = async (language, service, file, flag, files) => {
		if (userData.Minutes < 0) {
			showModal("UPGRADE-MINUTES" , {type: "local_transcription"});
			return;
		}
		if (!language) {
			showToast(t("Please select a language"), {
				type: "error",
				position: "top-center",
			});
			return;
		}
		setLanguage(language);
		setService(service);
		if (!flag) flag = false;
		handleUpload(file, language, service, flag, files);
	};

	const showUploadModal = () => {
		showModal("MULTIPLE-UPLOAD", { onUploadMultipleFiles })
	}
	async function convertVideoToMP3(file, ffmpegRef) {
		if (!file) {
			return null;
		}

		const fileNameWithoutExt = file.name
			.replace(/\.[^/.]+$/, "")
			.replace(/\s+/g, "_");
		const outputFileName = `${fileNameWithoutExt}.mp3`;
		const ffmpeg = ffmpegRef.current;
		try {
			const fileData = await fetchFile(file);
			await ffmpeg.writeFile(file.name, fileData);
			await ffmpeg.exec(["-i", file.name, outputFileName]);
			const data = await ffmpeg.readFile(outputFileName);
			const mp3File = new File([data.buffer], outputFileName, {
				type: "audio/mp3",
			});
			return mp3File;
		} catch (err) {
			console.error("Error during the FFmpeg operation:", err);
			return null;
		}
	}

	const handleUpload = async (file, language, service, flag, files) => {

		if (!flag && selectedFiles === 0) {
			setAttachListener(false);
			navigate('/files');
			return;
		}
		if (!loaded) {
			console.error("FFmpeg is not loaded yet.");
			// Don't return here to allow the upload process to continue without FFmpeg conversion.
		}
		if (!file) return;
		setShowUploadBox(true);
		setAttachListener(true);

		try {
			let originalFile = file;
			if (
				file.type.startsWith("video/") &&
				loaded
			) {
				try {
					setConversionFlag(true);
					const convertedFile = await convertVideoToMP3(file, ffmpegRef);
					file = convertedFile ? convertedFile : file;
				} catch (conversionError) {
					console.error("Error during FFmpeg conversion:", conversionError);
					file = originalFile; // Fallback to original file on conversion error
				}
			}
			setConversionFlag(false);
			setAttachListener(true);
			let file_size = file.size / (1024 * 1024);
			if (file_size > 5) {
				multipartUpload(
					file,
					hashedId,
					navigate,
					setUploadProgress,
					language,
					service,
					flag,
					files
				);
				return;
			} else {
				var payload = {
					feature_category: "transcription",
					feature_subcategory: "local_transcription",
					feature_name: "local_transcription",
					feature_input_type: file.type,
					feature_content: file.name,
					feature_language: language,
					feature_service: service,
					feature_id: uuidv4(),
					feature_product: "transkriptor",
					feature_status: "production",
					feature_platform: "web",
					feature_start_timestamp: new Date().getTime(),
					needs_credit: true,
					is_premium: false,
					data_source: "frontend"
					
				  }
				  sendAmplitudeData("feature_init", userData, currentUser, "", "", "", payload)
				// flow for files smaller than 5 mb
				const generalUploadUrl = `https://cqhldp14tk.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreatePresignedURLGeneralUpload?cid=${hashedId}&filename=${encodeURIComponent(
					removeSpecialCharsAndTrim(file.name)
				)}&feature_id=${payload.feature_id}&feature_start_timestamp=${payload.feature_start_timestamp}`;

				// Step 1: Get the pre-signed URL from your server
				const response = await fetch(generalUploadUrl);
				const data = await response.json();
				const presignedUrl = data["the_url"];
				
				// Step 2: Upload the file to S3
				const uploadResult = await uploadToS3(
					presignedUrl,
					file,
					(progress) => {
						setUploadProgress(progress);
					}
				);

				var temp_folder_id = localStorage.getItem("destinationId");
				if(localStorage.getItem("destinationId") === hashedId){
					temp_folder_id = "";
				}
				var speaker_count = localStorage.getItem("speakerCount");
				// Step 3: Notify your server about the successful upload
				var endpointUrl = `https://7z7j0bn899.execute-api.eu-central-1.amazonaws.com/default/AA-API-TranskriptorUploaderFinisher?cid=${hashedId}&filename=${encodeURIComponent(
					removeSpecialCharsAndTrim(file.name)
				)}&language=${language}&service=${service}&feature_id=${payload.feature_id}&feature_start_timestamp=${payload.feature_start_timestamp}`;
				if(temp_folder_id && temp_folder_id.length > 0){
					endpointUrl += `&folder_id=${temp_folder_id}`;
				}
				if(speaker_count && speaker_count.length > 0){
					endpointUrl += `&speaker_count=${speaker_count}`;
				}

				const endpointResponse = await fetch(endpointUrl);
				const endpointData = await endpointResponse.json();
				setUploadQueue(prevQueue => {
					let newQueue = prevQueue.map(iterator => {
						if (compareFiles(iterator.name) === compareFiles(file.name)) {
							iterator.status = "completed";
						}
						return iterator;
					});
					return newQueue;
				});
				let pk = endpointData.PK.replace("User#", "");
				let sk = endpointData.SK.replace("#Transkription#", "");
		
				checkAndUploadNext(service, language, files, pk, sk);
			}


		} catch (err) {
			console.error("Error during upload or conversion:", err);
			showToast(t("Failed to process file."), { type: "error" });
		} finally {
		}


	};

	const multipartUpload = async (file, hashedId, navigate, setUploadProgress, language, service, flag, files) => {
		let partSize = 5 * 1024 * 1024; // 5 MB
		let fileSize = file.size;
		let filename = file.name;
		var payload = {
			feature_category: "transcription",
			feature_subcategory: "local_transcription",
			feature_name: "local_transcription",
			feature_input_type: file.type,
			feature_content: file.name,
			feature_language: language,
			feature_service: service,
			feature_id: uuidv4(),
			feature_product: "transkriptor",
			feature_status: "production",
			feature_platform: "web",
			feature_start_timestamp: new Date().getTime(),
			needs_credit: true,
			is_premium: false,
			data_source: "frontend"
			
		  }
		sendAmplitudeData("feature_init", userData, currentUser, "", "", "", payload)
		if (fileSize > 1024 * 1024 * 1024 * 3)
			partSize = 20 * 1024 * 1024; // 10 MB
		else if (fileSize > 1024 * 1024 * 1024) {
			partSize = 10 * 1024 * 1024; // 20 MB
		}
		const partCount = Math.ceil(fileSize / partSize);
		let multipartInitiateUrl =
			multipartUrl +
			`fileSize=${fileSize}&cid=${hashedId}&filename=${encodeURIComponent(
				removeSpecialCharsAndTrim(filename)
			)}&partCount=${partCount}&partSize=${partSize}&flag=true&feature_id=${payload.feature_id}&feature_start_timestamp=${payload.feature_start_timestamp}`;

		fetch(multipartInitiateUrl).then(response => response.json()).then(data => {
			const presignedUrls = data.presignedUrls;
			const uploadId = data.uploadId;
			var partList = [];
			for (let i = 0; i < presignedUrls.length; i++) {
				const partNumber = presignedUrls[i].part_number;
				const presignedUrl = presignedUrls[i].presigned_url;
				const start = i * partSize;

				const end = Math.min((i + 1) * partSize, fileSize);
				const filePart = file.slice(start, end);
				upload_arr.push(0);
				let temp_arr = {
					url: presignedUrls[i],
					status: "started"
				}
				upload_status[i + 1] = temp_arr

				// Assuming 'presignedUrl' and 'fileChunk' are already defined
				partList.push({
					ETag: '',
					PartNumber: i + 1
				});

				let content_arr = {
					uploadId: uploadId,
					filename: filename,
					partCount: partCount,
					partList: partList,
					upload_arr: upload_arr,
					file_size: fileSize,
					language: language,
					service: service
				}
				upload_status[0] = content_arr;
				upload_part(presignedUrl, i, uploadId, partList, filename, multipartUrl, filePart, partCount, file, navigate, hashedId, setUploadProgress, language, service, flag, files, payload.feature_id, payload.feature_start_timestamp)
			}
		})

	};
	const filenameWithoutExtension = (filename) => {
		const parts = filename.split('.');
		parts.pop(); // Remove the extension
		let temp_filename = parts.join('.');
		return removeSpecialCharsAndTrim(temp_filename);

	  };

	  const compareFiles = (file)=>{
		if(file.length ){
			const fileNameWithoutExt = file
			.replace(/\.[^/.]+$/, "")
			.replace(/\s+/g, "_");
			return removeSpecialCharsAndTrim(fileNameWithoutExt);
		}
		return file;
	  }
	const upload_part = async (
		presignedUrl, i, uploadId, partList, filename,
		apiUrl, filePart, partCount, file, navigate, hashedId, setUploadProgress, language, service, multiFlag, files, feature_id, feature_start_timestamp
	) => {
		try {
			// Upload a part of the file using the presigned URL
			let uploadCid = hashedId
			const partResponse = await axios.put(presignedUrl, filePart, {
				headers: {
					'Content-Type': 'application/octet-stream'
				},
				onUploadProgress: (e) => {

					upload_arr = upload_status[0].upload_arr;
					upload_arr[i] = e.loaded;
					const sum = upload_arr.reduce((acc, val) => acc + val, 0);
					upload_status[0].loaded = upload_arr;

					// Calculate the percentage and ensure it does not exceed 100%
					const percentComplete = Math.min((sum / upload_status[0].file_size) * 100, 100);

					setUploadProgress(percentComplete);

				},
			});

			if (partResponse.status >= 200 && partResponse.status < 300) {
				const etag = partResponse.headers.etag;

				if (partList.length > 0) {
					partList[i].ETag = etag;
				}

				upload_status[i + 1].status = "completed";

				let flag = true;
				for (let j = 1; j < upload_status.length; j++) {
					if (upload_status[j].status !== "completed") {
						flag = false;
						break;
					}
				}


				if (flag) {
					// All parts have been uploaded, complete the multipart upload
					try {
						const response = await axios.get(apiUrl, {
							params: {
								uploadId,
								filename: removeSpecialCharsAndTrim(filename),
								cid: uploadCid,
							},
						});
						// Handle the response here if needed.
					} catch (error) {
						console.error("Error during Axios request:", error);

						// Display a toast notification with the error message.
						showToast(
							t(
								"Upload failed error: Your upload failed please try again. Please use Google Chrome, do not have any special characters (+,&,:...) in your file name",
								{ type: "error" }
							)
						);
						setAttachListener(false);
					}


					let canceled = false

					// if file is not in queue, it means it is cancelled so return
					setUploadQueue(prevQueue => {
						if (!prevQueue.some(f => compareFiles(f.name)=== compareFiles(filename))) {
							canceled = true;
							return prevQueue;
						}
						return prevQueue;
					}
					);
					if (canceled) {
						checkAndUploadNext(service, language, files);
						return;
					}

					// All parts have been uploaded, complete the multipart upload
					var temp_folder_id = localStorage.getItem("destinationId");
					if(localStorage.getItem("destinationId") === hashedId){
						temp_folder_id = "";
					}
					var speaker_count = localStorage.getItem("speakerCount");

					// Step 3: Notify your server about the successful upload
					var endpointUrl = `https://7z7j0bn899.execute-api.eu-central-1.amazonaws.com/default/AA-API-TranskriptorUploaderFinisher?cid=${uploadCid}&filename=${encodeURIComponent(
						removeSpecialCharsAndTrim(file.name)
					)}&language=${language}&service=${service}&feature_id=${feature_id}&feature_start_timestamp=${feature_start_timestamp}`;
					if(temp_folder_id && temp_folder_id.length > 0){
						endpointUrl += `&folder_id=${temp_folder_id}`;
					}
					if(speaker_count && speaker_count.length > 0){
						endpointUrl += `&speaker_count=${speaker_count}`;
					}
					const endpointResponse = await fetch(endpointUrl);
					const endpointData = await endpointResponse.json();
						// make its status as completed
					setUploadQueue(prevQueue => {
						let newQueue = prevQueue.map(file => {
							if (compareFiles(file.name) === compareFiles(filename)) {
								file.status = "completed";
							}
							return file;
						});
						return newQueue;
					});

					let pk = endpointData.PK.replace("User#", "");
					let sk = endpointData.SK.replace("#Transkription#", "");
					
					checkAndUploadNext(service, language, files, pk, sk);
				}
			} else {
				console.error(`Failed to upload part ${i + 1}: ${partResponse.status}`);
				// Your retry logic and snackbar call goes here
			}

		} catch (error) {
			console.error("Error during upload:", error);
			// Your error logic goes here
		}
	}

	const removeSpecialCharsAndTrim = (filename) => {
		// Remove special characters (#, &, *) and trim whitespace
		let temp = filename.replace(/[&#*]/g, '').trim();
		return temp;
	};


	return (
		<UploaderContext.Provider value={{ uploadProgress, showUploadBox, setUploadQueue, setShowUploadBox, uploadQueue, closeUploader, showUploadModal, filesRouteLoading, setFilesRouteLoading, showUploadedState, setShowUploadedState, conversionFlag, checkUploader}}>
			{children}
		</UploaderContext.Provider>
	);
};

export default UploaderContext;


// s3 upload function for files smaller than 5 mb
export const uploadToS3 = async (presignedUrl, file, onUploadProgress) => {
	try {
		const result = await axios.put(presignedUrl, file, {
			headers: {
				"Content-Type": file.type,
			},
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				onUploadProgress(percentCompleted);
			},
		});
		return result;
	} catch (error) {
		throw error;
	}
};
