import React, { createContext, useState, useContext, useRef } from 'react';
import { showToast } from '../../components/common/toast';
import { useModal } from '../../components/common/Modal/ModalContext';
import { useTranslation } from '../../components/common/LanguageProvider';

const RecorderContext = createContext();

export const useRecorder = () => useContext(RecorderContext);

export const RecorderProvider = ({ children }) => {
  const { t } = useTranslation();
  const [showRecordingModal, setshowRecordingModal] = useState(false);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState(null);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const [blockRecording, setBlockRecording] = useState(false);
  const { showModal } = useModal();


  const showRecordModal = async () => {
    if (blockRecording) {
      return
    }
    await takePermission();
    setshowRecordingModal(true);
  }

  const takePermission = async () => {
    const permissionsGranted = await requestPermissionsAndFetchDevices();
    
  }


  const requestPermissionsAndFetchDevices = async () => {
    try {
      // Request permissions by trying to access the camera and microphone
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

      // If successful, fetch the devices
      await getVideoDevices();
      await getAudioDevices();

      return true; 
    } catch (error) {
      console.error('Error requesting permissions or fetching devices:', error);
      getDevicesSeperately();
    }
  };

  const getDevicesSeperately = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      await getVideoDevices();
    } catch (error) {
      console.error('Error requesting permissions or fetching devices:', error);
    }

    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await getAudioDevices();
    } catch (error) {
      console.error('Error fetching audio devices:', error);
    }
  };

  const getVideoDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    setVideoDevices(videoDevices);
    if (videoDevices.length > 0) {
      setSelectedVideoDevice(videoDevices[0].deviceId);
    }
  };
  const getAudioDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevices = devices.filter(device => device.kind === 'audioinput');
    setAudioDevices(audioDevices);
    if (audioDevices.length > 0) {
      setSelectedAudioDevice(audioDevices[0].deviceId);
    }

  };


  return (
    <RecorderContext.Provider value={{ showRecordModal, showRecordingModal, setshowRecordingModal, selectedVideoDevice, setSelectedVideoDevice, selectedAudioDevice, setSelectedAudioDevice, videoDevices, audioDevices, setBlockRecording }}>
      {children}
    </RecorderContext.Provider>
  );
};

export default RecorderContext;