let cachedTranslations = {};
let toTranslate= {};
export async function loadLanguageTranslations(languageCode) {
    if (cachedTranslations[languageCode]) {
        return cachedTranslations[languageCode];
    }

    try {
        const translations = await import(`../../assets/translations/${languageCode}.json`);
        cachedTranslations[languageCode] = translations;
        return translations;
    } catch (error) {
        console.error("Error loading language file:", error);
        return {};
    }
}
export function t(word) {
    const languageCode = localStorage.getItem("dashboard-language") || 'en';

    const translations = cachedTranslations[languageCode] || {};
    const translatedWord = translations[word];

    if (!translatedWord) {
        addToDictJson(word);
        return word;
    }
    
    return translatedWord;
}
function addToDictJson(word) {
   toTranslate[word] = "";
}

