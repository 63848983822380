import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../Auth/AuthContext';

import recordSvg from '../assets/img/icon/record.svg';
import googleMeet from '../assets/img/icon/google-meet.svg';
import zoom from '../assets/img/icon/zoom.svg';
import upgrade from '../assets/img/icon/upgrade.svg';
import folders from '../assets/img/icon/folders.svg';
import teamProfiles1 from '../assets/img/icon/team-profile1.png';
import teamProfiles2 from '../assets/img/icon/team-profile2.png';
import teamProfiles3 from '../assets/img/icon/team-profile3.png';
import arrowRight from '../assets/img/icon/arrow-right.svg';
import logoIcon from '../assets/img/icon/logo-icon.png';
import microsoftTeams from '../assets/img/icon/microsoft-teams.svg';
import recordPng from "../assets/img/icon/record-btn-icon.svg"
import countryLanguage from 'country-language';
import logoMeetingtor from '../assets/img/logo_meetingtor.png';
import logoRecorditor from '../assets/img/logo_recorditor.png';
import logoEskritor from '../assets/img/logo-eskritor-nobuffer.png';
import RecorderComponent from './Recorditor/RecorderComponent';
import UploaderComponent from './Uploader/UploaderComponent';

import { useNavigate, useLocation } from "react-router-dom";
import Files from './Files/Files';
import { useTranslation } from '../components/common/LanguageProvider';
import { useModal } from '../components/common/Modal/ModalContext';
import { showToast } from '../components/common/toast';
import Converter from './Converter';
import LanguageDropdown from '../components/common/transcribeLanguageDropdown';
import { Modal, Button } from 'react-bootstrap';
function Dashboard() {

  const { t, setLanguage } = useTranslation();
  const navigate = useNavigate();
  const { token, userData, hashedId } = useAuth();
  const { showModal } = useModal();
  const [autoTranscribeManualMeeting, setAutoTanscribeManualMeeting] = useState(true);
  const [transcriptionLanguage, setTranscriptionLanguage] = useState(localStorage.getItem("transcript-language") || "EN");
  const [addAssistantInProgrees, setAddAssistantInProgress] = useState(false);
  const addAssistantBtnRef = useRef(null);
  const meetingUrlRef = useRef(null);
  const [planInfo, setPlanInfo] = useState();
  const [credits, setCredits] = useState(0);
  const [showTrialModal, setShowTrialModal] = useState(false);
  const handleCloseTrialModal = () => setShowTrialModal(false);
  const [languageParam, setLanguageParam] = useState(null)
  const [linkParam, setLinkParam] = useState(null);
  useEffect(() => {
    if (!localStorage.getItem("transcript-language"))
      findLanguage();
    if (!localStorage.getItem("isLanguageSet")) {
      localStorage.setItem("isLanguageSet", true);
      showModal("LANGUAGE-MODAL");

    }
    addAssistantBtnRef.current.disabled = true
    var baseUrl = window.location.href.split('?')[0];
    //window.history.pushState({}, document.title, baseUrl);

  }, []);
  const findLanguage = async () => {
    try {
      const browserLanguage = navigator.language || navigator.userLanguage || 'en-US';
      const [iso639_1, countryCode] = browserLanguage.split("-");

      localStorage.setItem("dashboard-language", iso639_1);
      localStorage.setItem("dashboard-country", countryCode || 'US');
      localStorage.setItem("transcript-language", browserLanguage);
      setLanguage(iso639_1)

    } catch (error) {
      console.error('Error detecting browser language:', error);
    }
  };
  useEffect(() => {
    if (hashedId) {
      getPlanInfo();
          // Get the current URL
      const currentUrl = window.location.href;
    
    // Create a URL object with the current URL
      const parsedUrl = new URL(currentUrl);
      console.log(parsedUrl, "parsedUrl")
    // Create a URLSearchParams object from the search part of the URL
      const params = new URLSearchParams(parsedUrl.search)
       // Extract the 'link' and 'language' parameters
    const temp_linkParam = params.get('link');
    const temp_languageParam = params.get('language');
    if(temp_linkParam && temp_languageParam){
      localStorage.setItem("transcript-language", temp_languageParam);
      setLinkParam(temp_linkParam);
      setLanguageParam(temp_languageParam);
    }
    }

  }, [hashedId]);
  const generalRedirect = (app) => {

    if (token) {
      const pathSegments = window.location.pathname.split('/');

      // Get the first string after the first "/" (which will be at index 1)
      const firstPathSegment = pathSegments[1] || ''; // Ensure it defaults to an empty string if not found
      var path = firstPathSegment;
      var url = `https://app.${app}.com/${path}?t=${token}&lang=${localStorage.getItem("dashboard-language")}&option=transkriptor`;

      window.open(url, '_blank');
    } else {
      showToast("Please refresh the page and try again.", { type: "error" });
    }

  };
  const addAssistantManually = async () => {
    var url = meetingUrlRef.current.value;

    if (!url || url.length === 0) {
      return;
    }

    setAddAssistantInProgress(true);
    addAssistantBtnRef.current.disabled = true;
    meetingUrlRef.current.value = "";
    showToast(t("Assistant is being added to the meeting"), { type: "info" });
    try {
      const response = await fetch('https://05kevs6xq8.execute-api.eu-central-1.amazonaws.com/default/Meetingtor-InsertAssistantManually', {
        method: 'POST',
        headers: {
        },
        body: JSON.stringify({
          "meetingUrl": url, "uid": hashedId, "auto_transcribe": autoTranscribeManualMeeting
        })
      });

      if (!response.ok) {
        const data = await response.json();
        if (response.status === 406) {

          if (data.limitSurpassed) {
            showModal("UPGRADE-RECORD");
          } else {
            showToast(t(data.message), { type: "error" });
          }
        } else {
          showToast(t(data.message), { type: "error" });
        }
      } else {
        showToast((t("Assistant will join the meeting soon!")), { type: "success" });
      }

      setAddAssistantInProgress(false);
      document.getElementById('urlInput').value = "";
      setAutoTanscribeManualMeeting(false);

    } catch (error) {
      console.log(error);
    }
  }

  const onAutoTranscribeChange = () => {
    const val = !autoTranscribeManualMeeting;
    if (userData.SStatus === "Trial") {
      showModal("UPGRADE-TRANSCRIBE");

    } else {
      setAutoTanscribeManualMeeting(val);
    }
  }
  const hideCheck = () => {
    return true;
  }

  const checkInputMeetingURL = (e) => {
    if (e.target.value.length > 0) {

      addAssistantBtnRef.current.disabled = false;
    } else {
      addAssistantBtnRef.current.disabled = true;
    }
  }
  const getPlanInfo = async () => {
    if (hashedId) {
      fetch("https://bfpu1bhqsa.execute-api.eu-central-1.amazonaws.com/default/Recorditor-GetPlanInfo?uid=" + hashedId).then(res => res.json()).then(data => {
        setPlanInfo(data);
      })
    }
  };

  useEffect(() => {
    if (planInfo) {
      setCredits(planInfo.allowed - planInfo.total_orders);
    }
  }, [planInfo]);
  return (
    <>

      <div className='row justify-content-center' >
        <UploaderComponent userData={userData} segment={userData?.UserSegment} />

        <RecorderComponent credits={credits} userData={userData} segment={userData?.UserSegment} />

        <Converter userData={userData} segment={userData?.UserSegment} languageParam={languageParam} linkParam={linkParam}/>

        <div className='col-lg-8 card-margin'>
          <div className='card card-padding card-100 '>
            <Files isFile={false} />
          </div>
        </div>
        <div className='col-lg-4 card-margin'>
          <div className='card card-padding card-100 text-center card-hover' onClick={() => generalRedirect("meetingtor")}>
            <div className='img-icon-list'>
              <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Google Meet"><img src={googleMeet} class="img-fluid" alt="img" /></span>
              <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Zoom"><img src={zoom} class="img-fluid" alt="img" /></span>
              <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Microsoft Teams"><img src={microsoftTeams} class="img-fluid" alt="img" /></span>
            </div>
            <p class="ft-18-title">{t("Smart Meeting Recorder")}</p>
            <p>{t("With Meetingtor automatically record all meetings from your calendar. Edit, share or transcribe your meeting recordings with just a click.")}</p>

          </div>
        </div>
        <div class="" style={{ display: "none" }}>
          <div class="card card-padding overflow-visible card-100">
            <p style={{ "lineHeight": 1, "marginBottom": 10 }} class="ft-18-title text-center">{t('Record and Transcribe Live Meeting')} </p>
            <p style={{ "lineHeight": 1.2, "marginBottom": 10 }} class='text-center' >{t('Enter the URL of live meeting to add meeting recorder now!')}</p>
            <div class="img-icon-list" style={{ "height": 44 }}>
              <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Google Meet"><img src={googleMeet} class="img-fluid" alt="img" /></span>
              <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Microsoft Teams"><img src={microsoftTeams} class="img-fluid" alt="img" /></span>
              <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Zoom"><img src={zoom} class="img-fluid" alt="img" /></span>
            </div>
            <div class="mb-2">
              <input type="text" id="urlInput" class="form-control round-input border-input" ref={meetingUrlRef} onChange={checkInputMeetingURL} placeholder={t("Enter URL")} />
            </div>
            <div className=" d-flex flex-row justify-content-between">
              <div className='col'>
                <div class="form-group selectBox">
                  <LanguageDropdown onLanguageChange={setTranscriptionLanguage} hideCheck={hideCheck} />

                </div>
              </div>

            </div>
            <div class="text-center">

              <button class="btn theme-btn rounded-btn" ref={addAssistantBtnRef}
                onClick={addAssistantManually}
              >
                <span class="me-2"><img src={recordPng} class="img-fluid" width="15px" alt="img" /></span>{t("Add Meeting Assistant")} </button>

            </div>
          </div>

        </div>

        <div className='text-center row' style={{ display: "none" }}>

          <div class="col-lg-4 card-margin" >

            <div class="card card-padding card-100  text-center card-hover" onClick={() => generalRedirect("meetingtor")}>
              <div className='img-icon-list'>
                <img src={logoMeetingtor} className='img-fluid recorder-img' alt='img' style={{ height: '50px' }}></img>
              </div>
              <p class="ft-18-title">{t("Smart Meeting Recorder")}</p>

              <p>{t("Connect your Google calendar or Outlook calendar. Record all your Google Meet, Zoom and Microsoft Teams meetings automatically.")}</p>
            </div>
          </div>
          <div class="col-lg-4 card-margin" >

            <div class="card card-padding card-100  text-center card-hover" onClick={() => generalRedirect("recorditor")}>
              <div className='img-icon-list'>
                <img src={logoRecorditor} className='img-fluid recorder-img' alt='img' style={{ height: '50px' }}></img>
              </div>
              <p class="ft-18-title">{t("Online Screen Recording")}</p>

              <p>{t("Record your screen and camera with a single click. Create and share video messages with an online screen recorder.")}</p>
            </div>
          </div>
          <div class="col-lg-4 card-margin" >

            <div class="card card-padding card-100  text-center card-hover" onClick={() => generalRedirect("eskritor")}>
              <div className='img-icon-list'>
                <img src={logoEskritor} className='img-fluid recorder-img' alt='img' style={{ height: '50px' }}></img>
              </div>
              <p class="ft-18-title">{t("AI Content Writer")}</p>

              <p>{t("Automatically generate different formats of text with Eskritor’s artificial intelligence (AI) content writer.")}</p>
            </div>
          </div>
        </div>


      </div>
    </>
  );
}

export default Dashboard;



