import Uploader from "./UploadBox";

import React, { createContext, useState, useContext, useRef, useEffect } from 'react';
import { useModal } from '../../components/common/Modal/ModalContext';

import { useUploader } from './UploaderContext';
import { useNavigate } from 'react-router-dom';
import uploadPng from "../../assets/img/icon/upload-btn-icon.svg"
import { useTranslation } from "../../components/common/LanguageProvider";

import FAQModal from "../../components/common/Modal/FaqModal";
import { useAuth } from '../../Auth/AuthContext';
import { showToast } from "../../components/common/toast";
import audioIcon from '../../assets/img/icon/audio-icon.svg';
import videoIcon from '../../assets/img/icon/video-icon.svg';
import googleDriveIcon from '../../assets/img/icon/google-drive.svg';
import youtubeIcon from '../../assets/img/icon/youtube.svg';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const UploaderComponent = (props) => {
    const { t } = useTranslation();
    const { userData, segment } = props;
    const [showFaqModal, setShowFaqModal] = useState(false);
    const { showUploadModal, uploadQueue } = useUploader();
    const { showModal } = useModal();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate()
    const checkStatus = () => {

        if (uploadQueue.length <= 0) {
            if (userData && userData.Minutes > 0) {
                showUploadModal();

            }else if(userData.Minutes <= 0){
                showModal("UPGRADE-MINUTES", {type: "local_transcription"})
            }else{
                showToast(t("Please Wait", {type:"info"}))
            }

        } else {
            showToast(t("Please wait, files are currently uploading.", { type: "info" }))
        }
    }

    useEffect(() => {
        if (segment % 2 === 0 || segment % 2 === 1) {
            setIsLoading(false)
        }
        localStorage.removeItem("destinationId");
        localStorage.removeItem('speakerCount');

    }, [segment])


    const faqData = [
        {
            question: "Is there a file size limit, and how can I upload my files faster?",
            answer: (
                <>
                    <p>{t("Yes, there is a 5 GB file size limit. Uploading large video files can be time-consuming. To speed up the upload process, we recommend converting your video files to MP3 or WAV formats.")}</p>
                    <p>{t("Additionally, you can upload files directly from YouTube or cloud storage platforms such as Google Drive, OneDrive, or Dropbox. Simply share a public link of your file or video, which allows you to bypass the standard upload wait times.")}</p>
                </>
            )
        },        
        {
            question: "Is there a transcription duration limit?",
            answer: [
                <p>
                    {t("Trial Accounts: Trial users can transcribe up to 80% of files with a maximum duration of 7 minutes. This means that files longer than 7 minutes will only have the first 5.36 minutes transcribed during the trial period.")}
                </p>,
                <p>
                    {t("Upgraded Accounts: Users with upgraded accounts can transcribe files up to 6 hours long. Files exceeding 3 hours in duration will be processed in two separate parts.")}
                </p>
            ]
        },
        {
            question: "What is the difference between Standard, Subtitle, and Speaker Separated transcription services?",
            answer: (
                <>
                    <ul>
                        <li>
                            <strong>{t("Standard:")}</strong>
                            <p>{t("This is our most sought-after transcription service due to its high accuracy and consistent results across various transcription tasks. It's especially effective for business meetings and personal recordings.")}</p>
                        </li>
                        <li>
                            <strong>{t("Subtitle:")}</strong>
                            <p>{t("Designed specifically for creating subtitles, this service segments text into concise, manageable chunks suitable for SRT format. Access to SRT downloads is exclusive to paid members.")}</p>
                        </li>
                        <li>
                            <strong>{t("Speaker Separated (Beta):")}</strong>
                            <p>{t("Ideal for recordings involving multiple speakers, this advanced service distinguishes between speakers to clarify who said what. It performs best with a small number of speakers who have distinct voice tones, such as different genders. Performance may diminish if speakers have similar vocal characteristics, potentially leading to less clarity than the Standard service.")}</p>
                        </li>
                    </ul>
                </>
            )
        },
        {
            question: "Which file formats do you support?",
            answer: (
                <>
                    <p>{t("We support several audio and video formats. Supported file formats are MP3, MP4, WAV, AAC, M4A, WEBM, FLAC, OPUS, AVI, M4V, MPEG, MOV, OGV, MPG, WMV, OGM, OGG, AU, WMA, AIFF, OGA.")}</p>
                    <p>{t("If you have a different format, please use any media converter tool to convert your file to a supported format. Please ensure that you keep high audio quality.")}</p>
                    <p>{t("Here are several online media conversion tools you may use:")}</p>
                    <ul>
                        <li><a href="https://convertio.co" target="_blank" rel="noopener noreferrer" style={{ color: 'var(--theme-color)', textDecoration: 'underline' }}>https://convertio.co</a></li>
                        <li><a href="https://cloudconvert.com" target="_blank" rel="noopener noreferrer" style={{ color: 'var(--theme-color)', textDecoration: 'underline' }}>https://cloudconvert.com</a></li>
                        <li><a href="https://online-audio-converter.com" target="_blank" rel="noopener noreferrer" style={{ color: 'var(--theme-color)', textDecoration: 'underline' }}>https://online-audio-converter.com</a></li>
                    </ul>
                </>
            )
        },
        {
            question: "Which languages do you support?",
            answer:
                (
                    <>
                        <p>{t("Transkriptor supports transcription in a variety of languages, including:")}</p>
                        <p>{t("Afrikaans, Amharic, Arabic, Bengali, Bulgarian, Burmese, Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Filipino, Finnish, French, German, Greek, Gujarati, Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Irish, Italian, Japanese, Kannada, Khmer, Korean, Lao, Latvian, Lithuanian, Macedonian, Malay, Maltese, Marathi, Norwegian, Persian, Polish, Portuguese, Romanian, Russian, Serbian, Sinhala, Slovak, Slovenian, Spanish, Swahili, Swedish, Tamil, Telugu, Thai, Turkish, Ukrainian, Urdu, Uzbek, Vietnamese, Zulu.")}</p>
                    </>
                )
        },
        {
            question: "How can I translate my transcription into another language?",
            answer: "After uploading your file, please select the language spoken in the recording. Upon completion of the transcription, you can use the Google Translate extension at the top of your editor page to translate your transcript into the desired language."
        }
    ];


    return (
        <>
            {isLoading ? (<>
                <div className="col-lg-4 card-margin">
                    <div className="card card-padding card-100 text-center card-hover">
                        <Skeleton count={5} style={{ borderRadius: '10px', marginTop: "23px" }} />
                    </div>
                </div>

            </>) : (
                <>
                    <FAQModal isOpen={showFaqModal} onClose={() => setShowFaqModal(false)} faqData={faqData} />
                    <div class="col-lg-4 card-margin "  >

                        <div class="card card-padding card-100  text-center card-hover action-box" style={{ position: 'relative' }} onClick={() => checkStatus()}>
                            <div className="top-right-button" onClick={
                                (e) => {
                                    e.stopPropagation();
                                    setShowFaqModal(true)
                                }
                            }>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-info-circle" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                </svg>
                            </div>
                            <div className='img-icon-list'>
                                <span className="round-color-icon green-bg recorder-span">
                                    <img src={uploadPng} className='img-fluid recorder-img' alt='img' style={{ width: '150px', height: '150px' }}></img>
                                </span>
                            </div>
                            <p class="ft-18-title">{t("Upload Audio or Video File")}</p>
                            <p>{t("Convert any audio file (mp3, mp4, wav, aac, m4a, webm,...) or video file to text")}</p>
                        </div>
                    </div>
                </>
            )}



        </>
    );

}

export default UploaderComponent;