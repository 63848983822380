import React, {useEffect, useState} from "react";
import blueExport from "../../assets/img/files/blue-export.svg";
import blueYoutube from "../../assets/img/files/blue-youtube.svg";
import blueGoogleDrive from "../../assets/img/files/blue-google-drive.svg";
import blueDropbox from "../../assets/img/files/blue-dropbox.svg";
import blueOneDrive from "../../assets/img/files/blue-onedrive.svg";
import blueEdit from "../../assets/img/files/blue-edit.svg";
import blueCaption from "../../assets/img/files/blue-caption.svg";
import blueRelationship from "../../assets/img/files/blue-relationship.svg";
import blueTranslate from "../../assets/img/files/blue-translate.svg";
import yellowTime from "../../assets/img/files/yellow-time.svg";
import yellowCase from "../../assets/img/files/yellow-case.svg";
import yellowAi from "../../assets/img/files/yellow-ai.svg";
import yellowOpenBox from "../../assets/img/files/yellow-openbox.svg";
import yellowRelationship from "../../assets/img/files/yellow-relationship.svg";
import yellowTranslate from "../../assets/img/files/yellow-translate.svg";
import pinkDownload from "../../assets/img/files/pink-download.svg";
import pinkTranslate from "../../assets/img/files/pink-translate.svg";
import pinkSpeaker from "../../assets/img/files/pink-speaker.svg";
import pinkLink from "../../assets/img/files/pink-link.svg";
import pinkConvert from "../../assets/img/files/pink-convert.svg";
import pinkRelationship from "../../assets/img/files/pink-relationship.svg";
import { useTranslation } from '../../components/common/LanguageProvider';
import { useNavigate} from 'react-router-dom';
import { useAuth } from "../../Auth/AuthContext";
import logoIcon from "../../assets/img/icon/logo-icon.png";
import { showToast } from "../../components/common/toast";
import Gleap from "gleap";
import { fetchData } from "../../hooks/apiUtils";
function Failed({ failCode, duration, orderID }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {userData, hashedId} = useAuth();
  const isChrome = window.navigator.userAgent.includes("Chrome")
  const [isLoading, setIsLoading] = useState(false);
  const [isReInit, setIsReInit] = useState(false);  
  const errorMessages = {
    UF: t("Upload failed error: Your upload failed please try again. Please use Google Chrome, do not have any special characters (+,&,:...) in your file name"),
    IFT: t("Invalid file error: Please check your audio file make sure it has sound. Please convert your audio to wav or mp3 and use Google Chrome, do not have any special characters (+,&,:...) in your file name"),
    ISB: t("Insufficient minutes error: Please purchase minutes or renew your subscription early to transcribe"),
    LT3: t("Long audio error: Your audio is longer than 3 hours we split your audio and created 2 transcription tasks please check them"),
    LT6: t("Long audio error: Your audio is longer than 6 hours. Please use media splitter tools and upload files shorter than 6 hours."),
    TMP: t("Trial users can only transcribe maximum 2 files at a time. Please upgrade your account to transcribe more files."),
    LT4: t("Long audio error: Your audio is longer than 4 hours. We split your audio and created 2 transcription tasks please check them."),
    LT10: t("Long audio error: Your audio is longer than 10 hours. We split your audio and created 2 transcription tasks please check them."),
  };

  function getMessageByCode(failCode) {
    return errorMessages[failCode] || t("Unknown error occurred. Please contact support.");
  }
  const redirectCustomerPortal = async () => {
    setIsLoading(true);

    fetch("https://gf5c4w9xq7.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-Purchase-CreateCustomerPortal-v2?cid=" + hashedId).then(res => res.json()).then(data => {
        const url = data["redirectUrl"];
        if(isChrome){
            window.open(url, "_blank");
            setIsLoading(false);

          }else{
            window.location.href = url;
            setIsLoading(false);

          }

    })
    
}
  const handleClick = () => {
    if(userData?.Subscription?.Source === "Stripe"){
      redirectCustomerPortal();
    }else{
      navigate("/payment")
    }

  };
  useEffect(() => {
    console.log("duration", duration, failCode, orderID, userData, isReInit)
    if(userData && orderID && orderID.length > 0 && failCode === "ISB" && duration !== null){
      if(duration < userData.Minutes){
        setIsReInit(true);
      }
    }
  }, [duration, failCode, userData, orderID]);
  const reInitate = async () => {
    setIsLoading(true);
    showToast(t("Transcription is reinitiated. Please wait..."), { type: "info" });
    const response = await fetchData("https://95dqqor8sd.execute-api.eu-central-1.amazonaws.com/default/reInitiator?orderid=" + orderID);
    window.location.reload();
    setIsLoading(false);
  };

  return (
    <>
      <div className="card card-padding overflow-visible">
        <div class="card">
          <div class="card-header theme-bg py-3 px-4">
            <h2 class="mini-title white-color semibold text-center mb-0">
              TRANSKRIPTOR
            </h2>
          </div>
          <div class="card-padding mx-auto">
            <div class="d-md-flex d-block align-items-center justify-content-center text-center text-md-start">
              <div>
                {(failCode === "ISB" && isReInit) ? (
                  <>
                  <div class="d-md-flex d-block align-items-center text-center text-md-start">
                                    <img
                                      src={logoIcon}
                                      class="img-fluid me-3 mb-2"
                                      alt="img"
                                    />
                                    <div>
                                      
                                      <p>
                                        {t("You now have enough minutes to transcribe this file. Click here to transcribe!")}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="text-center mt-3">
                                    <button onClick={() => reInitate()} class="btn theme-btn" style={{ backgroundColor: "#1f9739", border: "none" }}>
                                      {t("Now, click to get the full transcription!")}
                                      <span class="ms-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="18"
                                          fill="currentColor"
                                          class="bi bi-arrow-right-circle"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                                          />
                                        </svg>
                                      </span>
                                    </button>
                                  </div>
                  </>
                ):(
                  <p className="text-center">{getMessageByCode(failCode)}</p>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
          {isLoading ? (
                <div className='row'>
                  <div className='d-flex justify-content-center'>
                    <div className="spinner-border text-primary" role="status">

                    </div>
                  </div>


                </div>
            ): null}
          </div>
            {(failCode === "ISB" && !isReInit) && (
                 <div className="d-flex justify-content-center" >
         
                 <button onClick={handleClick} className="btn theme-btn m-2">
                       <span className="btn-icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                               <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                           </svg>
                       </span>
                       {t("Upgrade")}
                   </button>
                 </div>
            )}
              
              {failCode !== "ISB" && (
                 <div className="d-flex justify-content-center" >
         
                 <button onClick={()=> Gleap.openHelpCenter()} className="btn theme-btn m-2">
                       {t("Contact us")}
                   </button>
                 </div>
            )}
                {(failCode === "TMP") && (
                 <div className="d-flex justify-content-center" >
         
                 <button onClick={handleClick} className="btn theme-btn m-2">
                       <span className="btn-icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                               <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                           </svg>
                       </span>
                       {t("Upgrade")}
                   </button>
                 </div>
            )}
              

        </div>
        <hr></hr>
        <div class="col-xxl-12 mx-auto">
          <div
            id="carouselExampleIndicators"
            class="carousel slide file-slider"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row gx-0 text-center">
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={blueExport}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Export as TXT, SRT, Word or Plain Text")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t("Download TXT, SRT, Word or Plain Text versions of your transcriptions in the editor page")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon slider-theme-icon-small">
                          <img
                            src={blueYoutube}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                        <div class="slider-theme-icon slider-theme-icon-small">
                          <img
                            src={blueGoogleDrive}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                        <div class="slider-theme-icon slider-theme-icon-small">
                          <img
                            src={blueDropbox}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                        <div class="slider-theme-icon slider-theme-icon-small">
                          <img
                            src={blueOneDrive}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Transcribe from Youtube or Cloud Storage link")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Paste a Youtube or Cloud link in Uploader page and convert it to text in any language")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={blueEdit}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                       {t("Edit with slow motion and change speakers")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Paste a Youtube or cloud link in uploader page and convert it to text in any language")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={blueCaption}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Create Caption Files with a Button")}</p>
                      <div class="slider-hover-box">
                        <p>
                          {t("Create subtitles as SRT while uploading your audio")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={blueRelationship}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Colloborate on your transcripts")}</p>
                      <div class="slider-hover-box">
                        <p>
                          {t("Share transcripts with your teammates, create workspace to work on same transcripts and folders and invite your teammates")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={blueTranslate}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Transcribe and translate in 100+ languages")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t("Transcribe the audio for any language and then, translate it to another within seconds")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row gx-0 text-center yellow-theme-slider">
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={yellowTime}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                       {t("Write and edit articles within seconds")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Use writing use cases to improve your essays or to make them better")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={yellowCase}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("30+ available use cases")}</p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Find the best use case for your needs and finish your works faster")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={yellowAi}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Edit outputs manually and AI help you to improve")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Edit outputs in the text editor, and shorten, expand or rephrase text again")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={yellowOpenBox}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Find ideas for your business")}</p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Give some keywords and get AI generated marketing ideas")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={yellowRelationship}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Colloborate on your results")}</p>
                      <div class="slider-hover-box">
                        <p>
                        {t("Share results with your teammates, create workspace to work on same files, and folders and invite your teammates")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={yellowTranslate}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Find your answers in over 100 languages")}
                      </p>
                      <div class="slider-hover-box">
                        <p>{t("Find best results for your target language")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row gx-0 text-center pink-theme-slider">
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={pinkDownload}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Download Mp3 version of texts")}</p>
                      <div class="slider-hover-box">
                        <p>
                          {t("Download Mp3 version of converted text files, images or links")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={pinkLink}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                       {t("Convert text to audio from any web link")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                        {t("Paste a web link and convert it to audio within seconds")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={pinkSpeaker}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Edit with slow motion and change speakers")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Change play speed and edit the text in detail, or change the speakers, then save it to download it")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={pinkConvert}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Type anything and convert it to audio")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t("Type anything and convert it to audio with over 150+ voices")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={pinkRelationship}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Colloborate on your audio files")}</p>
                      <div class="slider-hover-box">
                        <p>
                        {t("Share audio files with your teammates, create workspace to work on same files and folders and invite your teammates")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src={pinkTranslate}
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Create audio and translate in 100+ languages")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Convert text to audio for any language and then, translate it to another within seconds")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Failed;
