import recordPng from "../../assets/img/icon/record-btn-icon.svg";
import { useRecorder } from "./RecorderContext";
import { useModal } from "../../components/common/Modal/ModalContext";
import { useTranslation } from "../../components/common/LanguageProvider";
import { useAuth } from "../../Auth/AuthContext";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FAQModal from "../../components/common/Modal/FaqModal";

const RecorderComponent = (props) => {
  const { t } = useTranslation();
  const { showRecordModal } = useRecorder();
  const { credits, userData, segment } = props;
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const isChrome =
    !!window.navigator.userAgent.match(/Chrome/i) &&
    !window.navigator.userAgent.match(/Edge/i);
  const { showModal } = useModal();
  const navigate = useNavigate();
  const [showFaqModal, setShowFaqModal] = useState(false);
  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const checkStatus = async() => {
    try{
      await navigator.mediaDevices.getUserMedia({audio: true });
      if(userData && userData.Minutes > 0){
        if(userData && (credits> 0 || userData.SStatus !== "Trial")){
            showRecordModal();
        }else{
          showModal("LIMIT-EXCEEDED")
        }
      }else if(userData.Minutes <= 0){
        showModal("UPGRADE-MINUTES", {type: "record_transcription"})
      }
    }catch(error){
      showModal("RECORD-NO-AUDIO-DEVICES")
      console.error('Error requesting permissions or fetching devices:', error);
    }
  };
  useEffect(() => {
    if (segment % 2 === 0 || segment % 2 === 1) {
      setIsLoading(false);
    }
    localStorage.removeItem("destinationId");
    localStorage.removeItem('speakerCount');

  }, [segment]);

  const faqData = [
    {
      question: "How can I record my audio or screen?",
      answer: (
        <>
          <ol>
            <li>
              {t(
                "Tap the record button and grant the necessary camera and microphone permissions."
              )}
            </li>
            <li>
              {t(
                "Select your desired recording settings and start the recording."
              )}
            </li>
            <li>
              {t(
                "Choose what you wish to share and don't forget to activate the 'Share system/tab audio' option located in the lower right corner if needed."
              )}
            </li>
            <li>{t("After clicking 'Share' your recording will begin.")}</li>
          </ol>
        </>
      ),
    },
    {
      question: "How can I transcribe my recording?",
      answer: (
        <>
          <ol>
            <li>
              {t(
                "Once you stop recording, a new screen will appear where you can select the spoken language and the transcription service."
              )}
            </li>
            <li>{t("Click the 'Transcribe' button.")}</li>
            <li>
              {t(
                "You will be directed to the editor page, where Transkriptor will automatically convert your speech into text."
              )}
            </li>
          </ol>
        </>
      ),
    },
    {
      question: "How can I transcribe my previous recordings?",
      answer: (
        <>
          <p>
            {t(
              "Your recordings are automatically saved in the Recorditor app. Access them by signing into with your Transkriptor login credentials <a href='http://app.recorditor.com'>Recorditor</a>, or by clicking the app logo at the top right of your screen to navigate to the Recorditor dashboard."
            )}
          </p>
          <ol>
            <li>
              {t("Click the 'Files' tab on the left side of your dashboard.")}
            </li>
            <li>{t("Select the recording you wish to transcribe.")}</li>
            <li>
              {t(
                "Choose the spoken language and service, then click the 'Transcribe' button."
              )}
            </li>
            <li>
              {t(
                "Alternatively, use the 'Transkriptor' logo to start transcription."
              )}
            </li>
            <li>
              {t(
                "You will be redirected to the editor page to see your text conversion."
              )}
            </li>
          </ol>
        </>
      ),
    },
    {
      question: "How can I download or share my audio or screen recording?",
      answer: (
        <>
          <p>
            {t(
              "Visit the Recorditor website to access your audio and screen recordings. Upon opening your file, you will find the download and share buttons in the upper right corner of the screen."
            )}
          </p>
          <p>
            {t(
              "Note: The download feature is only available for upgraded accounts."
            )}
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <>
          <div className="col-lg-4 card-margin">
            <div className="card card-padding card-100 text-center card-hover">
              <Skeleton
                count={5}
                style={{ borderRadius: "10px", marginTop: "21.07px" }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {isMobileDevice() ? (
            <div class="col-lg-4 card-margin">
              <div class="card card-padding flex-center card-100 text-center">
                <div class="img-icon-list">
                  <span className="pointer round-color-icon red-bg recorder-span">
                    <img
                      src={recordPng}
                      class="img-fluid recorder-img"
                      alt="img"
                    />
                  </span>
                </div>

                <div className="w-100 d-sm-flex d-block align-items-center justify-content-center">
                  <p class="ft-18-title">
                    {" "}
                    {t("Please use a computer to record your screen!")}
                  </p>
                </div>
              </div>
            </div>
          ) : isChrome ? (
            <>
              <FAQModal
                isOpen={showFaqModal}
                onClose={() => setShowFaqModal(false)}
                faqData={faqData}
              />

              <div className="col-lg-4 card-margin">
                <div
                  className="card card-padding card-100 text-center card-hover action-box"
                  style={{ position: "relative" }}
                  onClick={() => checkStatus()}
                >
                  <div
                    className="top-right-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowFaqModal(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="black"
                      class="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                  </div>
                  <div class="img-icon-list">
                    <span className="pointer round-color-icon red-bg recorder-span">
                      <img
                        src={recordPng}
                        class="img-fluid recorder-img"
                        alt="img"
                      />
                    </span>
                  </div>
                  <p class="ft-18-title">{t("Record")}</p>
                  <p>
                    {t(
                      "Record your voice or meetings directly using Transkriptor, then convert it to text."
                    )}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className=" col-lg-4 card-margin ">
                <div className="card card-padding card-100 flex-center card-hover text-center">
                  <div className="w-100 d-sm-flex d-block align-items-center justify-content-center">
                    <h4>
                      {t("Please use Google Chrome to record your screen!")}
                    </h4>
                  </div>
                  <img
                    src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg"
                    className="img-fluid m-2"
                  ></img>
                  <button
                    class="btn theme-btn mb-1"
                    onClick={() =>
                      window.open("https://www.google.com/chrome/", "_blank")
                    }
                  >
                    {t("Get Google Chrome")}
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default RecorderComponent;
