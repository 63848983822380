// featureFlags.js
// config.js
import { Experiment } from "@amplitude/experiment-js-client";

const apiKey = "a4563a2706e1e084439f19815d3b36f8";
const getFlagVariant = async (flagKey, user) => {
  const experiment = Experiment.initialize(apiKey);
  console.log("Experiment:", experiment);
  const variants = await experiment.fetch(user);
  console.log("Fetched variants:", variants);
  const variant = experiment.variant("paddle-integration");
  console.log("Variant:", variant);
  return variant.value;
};

export { getFlagVariant };
