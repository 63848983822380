import React, { useState, useEffect, useRef } from 'react';
import { showToast } from './toast';
import { useTranslation } from './LanguageProvider';
import { useDirection } from '../common/DirectionContext';
import { Link } from 'react-router-dom';
function DashboardLanguageDropdown(props) {
    const [isOpen, setIsOpen] = useState(false);
    const { setDirection } = useDirection();
    var temp_lang = localStorage.getItem("dashboard-language") || "EN";
    const dropdownRef = useRef(null);
    // New state variable for the filtered languages
    const [filteredLanguages, setFilteredLanguages] = useState(languages);


    const { t, setLanguage } = useTranslation();
    const handleLanguageClick = (event) => {
        const language = event.target;
        if (language) {

        setSelectedLanguage(language.innerHTML);
        setIsOpen(false);
        props.onLanguageChange(language.getAttribute('value'))
        if(language.getAttribute('value') !== localStorage.getItem("dashboard-language")){
            showToast(t("Dashboard language changed to ") + language.innerHTML, { type: "success" })
            if(language.getAttribute('value') === "ar" || language.getAttribute('value') === "fa" || language.getAttribute('value') === "he"){
                setDirection(dir => dir !== 'rtl' ? 'rtl' : 'ltr')
            }else{
                setDirection(dir => dir !== 'ltr' ? 'ltr' : 'ltr')
            }
        }
        localStorage.setItem("dashboard-language", language.getAttribute('value'));
        const languageCode = localStorage.getItem("dashboard-language") || 'en';
        setLanguage(languageCode); // Load translations once when the app starts
        setInputValue(""); // Clear the input value when a language is selected
        props.hideCheck(true);

       
    }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }else{
          setIsOpen(true)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const findLanguage = (langKey) => {

        // 1. Try the full key (e.g., en-US)
        
        // Split and get just the language part
        const langOnly = langKey.split('-')[0];
    
        // 2. Check the uppercase (e.g., TR)
        if (languages[langOnly.toUpperCase()]) {
            return languages[langOnly.toUpperCase()];
        }
        // 3. Try lowercase (e.g., en)
        if (languages[langOnly.toLowerCase()]) {
            return languages[langOnly.toLowerCase()];
        }
    
        // 4. Check for the full langKey
        if (languages[langKey]) {
            return languages[langKey];
        }
                
        // 5. Default to English (EN)
        return languages["EN"];
    };
    const [selectedLanguage, setSelectedLanguage] = useState( findLanguage(temp_lang));
    const [inputValue, setInputValue] = useState(""); // To store the characters entered by the user
    

    
    const filterLanguages = () => {
        const filtered = Object.entries(languages)
          .filter(([code, label]) => label.toLowerCase().includes(inputValue.toLowerCase()))
          .reduce((obj, [code, label]) => {
            obj[code] = label;
            return obj;
          }, {});
      
        if (Object.keys(filtered).length === 0) {
          setInputValue("");
          showToast(t("No language found", {type: "error"}))

          setFilteredLanguages(languages); // Set to all languages if none match the filter
        } else {
          setFilteredLanguages(filtered);
        }
      };
      
    
      const handleKeyDown = (e) => {
        setInputValue(e.target.value)
      };
    useEffect(() => {
        filterLanguages()
      }, [inputValue]);
    return (
        <>
                <div ref={dropdownRef} className={`selectBox ${isOpen ? 'show' : ''}`} >
                <div 
                    className="form-control round-input selectBox__value"
                    tabIndex="0" // Makes the div focusable
                >
                    {selectedLanguage}
                </div>

            {isOpen && (
                   <div class="dropdown-menu">                   
                    <div className="language-group">
                    <div class="mb-2 table-search input-icon-div d-lg-inline-block d-block ms-auto mt-lg-0 mt-3">
                          <input type="text" class="form-control round-input" name="" placeholder={t("Search...")} value={inputValue} onChange={handleKeyDown}/>
                          <span class="input-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg>
                          </span>
                        </div>
                        {
                            Object.entries(filteredLanguages).map(([code, label]) => (
                            <Link 
                                className={`dropdown-item ${selectedLanguage === label ? 'active' : ''}`} 
                                value={code} 
                                onClick={handleLanguageClick}
                            >
                                {label}
                            </Link>
                            ))
                        }
                        </div>


                   </div>
            )}
        </div>
          
        </>
    );
}

export default DashboardLanguageDropdown;



const languages = 
    {"af":"Afrikaans (Afrikaans)",
    "am":"Amharic (አማርኛ)",
    "ar":"Arabic (عربي)",
    "bn":"Bengali (বুটে)",
    "bg":"Bulgarian (Български)",
    "my":"Burmese (မြန်မာနိုင်ငံ)",
    "ca":"Catalan (Calalana)",
    "zh":"Chinese (中国人)",
    "hr":"Croatian (Hrvatski)",
    "cs":"Czech (Čeština)",
    "da":"Danish (Dansk)",
    "nl":"Dutch (Nederlands)",
    "en":"English (English)",
    "et":"Estonian (Eesti)",
    "fil":"Filipino (Pilipino)",
    "fi":"Finnish (Suomalainen)",
    "fr":"French (Français)",
    "de":"German (Deutsch)",
    "el":"Greek (Ελληνικά)",
    "gu":"Gujarati (ગુજરાત)",
    "he":"Hebrew (עִברִית)",
    "hi":"Hindi (हिन्दी)",
    "hu":"Hungarian (Magyar)",
    "is":"Icelandic (Íslenskt)",
    "id":"Indonesian ",
    "ga":"Irish (Gaeilge)",
    "it":"Italian (Italiano)",
    "ja":"Japanese (日本)",
    "jv":"Javanese (Basa Jawa)",
    "kn":"Kannada (ಕನ್ನಡ)",
    "km":"Khmer (ខ្មែរ)",
    "ko":"Korean (한국인)",
    "lo":"Lao (ພາສາລາວ)",
    "lv":"Latvian (Latviski)",
    "lt":"Lithuanian (Lietuvių)",
    "mk":"Macedonian ",
    "ms":"Malay (Melayu)",
    "mt":"Maltese (Maltaca)",
    "mr":"Marathi (मराठी)",
    "nb":"Norwegian (Norsk)",
    "fa":"Persian (فارسی)",
    "pl":"Polish (Dialekt)",
    "pt":"Portuguese (Português)",
    "ro":"Romanian (Română)",
    "ru":"Russian (Русский)",
    "sr":"Serbian (Српски)",
    "si":"Sinhala (සිංහල)",
    "sk":"Slovak (Slovenský)",
    "sl":"Slovenian (Slovenščina)",
    "es":"Spanish (Español)",
    "sw":"Swahili (Svahili)",
    "sv":"Swedish (Svenska)",
    "ta":"Tamil (தமிழ்)",
    "te":"Telugu (తెలుగు)",
    "th":"Thai (ไทย)",
    "tr":"Turkish (Türkçe)",
    "uk":"Ukrainian ",
    "ur":"Urdu (پیشاب)",
    "uz":"Uzbek (O'Zbek Tili)",
    "vi":"Vietnamese ",
    "zu":"Zulu (Isizulu)"}