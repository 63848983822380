import React, { createContext, useState, useContext } from 'react';

const ModalContext = createContext();

export function ModalProvider({ children }) {
    const [modalType, setModalType] = useState(null);
    const [modalData, setModalData] = useState(null);

    function showModal(type, data, callbacks = {}) {
        setModalType(type);
        setModalData({ ...data, ...callbacks });
    }

    const hideModal = () => {
        if(modalType === "RECORD-UPLOADING" || modalType === "TRANSCRIBE-PROCESSING" || modalType === "TRANSCRIBE-RECORD" || modalType === "TRIAL-FILE-LIMIT"){
            
        }else{
            setModalType(null);
            setModalData(null);
        }
        
    };
    const specialHideModal = () => {
            setModalType(null);
            setModalData(null);   
    };
    return (
        <ModalContext.Provider value={{ modalType, modalData, showModal, hideModal, specialHideModal }}>
            {children}
        </ModalContext.Provider>
    );
}

export function useModal() {
    return useContext(ModalContext);
}
