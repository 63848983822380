import React from "react";

import amplitude from "amplitude-js";
import { useAuth } from "../../Auth/AuthContext";
import logo_nobuffer from '../../assets/img/logo_nobuffer.png';
import { useTranslation } from "../../components/common/LanguageProvider";
import checkCircle from '../../assets/svg/sidebar/check-circle.svg';
function SeoContent() {
    const { t } = useTranslation();
    var amplitudeInstance = amplitude.getInstance();
    amplitudeInstance.init("a4563a2706e1e084439f19815d3b36f8", null, {
        defaultTracking: {
            attribution: false,
            pageViews: false,
            sessions: false,
            formInteractions: false,
            fileDownloads: false,
        },
        includeReferrer: true,
        includeUtm: true,
        includeGclid: true,
    });

    const register = () => {
        var deviceId = amplitude.getInstance().getDeviceId();
        window.location.href =
            "https://app.transkriptor.com/register?lang=en&deviceId=" + deviceId;
    };

    const { googleLogin } = useAuth();

    const user_language = "English";
    return (
        <>
            <div className='main-seo-content'>
                <div className="d-flex justify-content-center custom-margin">
                    <img src={logo_nobuffer} alt='Transkriptor' className='img-fluid mt-5 mb-4'
                        width={350} height={150} />
                </div>
                <div >
                    <h1 className="text-center slogan">{t("Convert Audio to Text")}</h1>
                    <p className="text-center phrase m-4">{t("Transcribe your meetings, interviews, lectures, and other conversations.")}</p>
                </div>
                <div class="d-flex justify-content-center login-btn-sec mb-4">
                    <button class="btn-tryit btn nav-link theme-btn nav-link-btn d-flex justify-content-center conversion-button-text"
                        onClick={register}>{t("Get Started for Free")}<span class="ms-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z">
                                </path>
                            </svg></span>
                    </button>
                </div>

                <div class="container m-3 list-group-cont">
                    <ol class="list-group">
                        <li class="list-group-item">
                            <img src={checkCircle} alt='check-circle' className='img-fluid me-2' width={20} height={20} />

                            <span className="content-text">
                                <strong>{t("Fast & Accurate:")}</strong> {t("Convert any audio or video file to text within seconds and up to 99% accuracy.")}</span>
                        </li>
                        <li class="list-group-item">
                            <img src={checkCircle} alt='check-circle' className='img-fluid me-2' width={20} height={20} /> <span className="content-text">
                                <strong>{t("Language Support:")}</strong> {t("Transcribe in 50+ languages and dialects.")}</span>
                        </li>
                        <li class="list-group-item">
                            <img src={checkCircle} alt='check-circle' className='img-fluid me-2' width={20} height={20} /> <span className="content-text">
                                <strong>{t("Mobile App:")}</strong> {t("Access your transcripts anytime from Transkriptor mobile app available on iOS and Android.")}</span>
                        </li>
                        <li class="list-group-item">
                            <img src={checkCircle} alt='check-circle' className='img-fluid me-2' width={20} height={20} /> <span className="content-text">
                                <strong>{t("AI Assistant:")}</strong> {t("Get insights or quickly write any content from your transcript with AI.")}</span>
                        </li>
                        <li class="list-group-item">
                            <img src={checkCircle} alt='check-circle' className='img-fluid me-2' width={20} height={20} /> <span className="content-text">
                                <strong class="notranslate">Meetingtor:</strong> {t("Record and transcribe all your Microsoft Teams, Zoom or Google Meet meetings on your calendar.")}</span>
                        </li>
                        <li class="list-group-item">
                            <img src={checkCircle} alt='check-circle' className='img-fluid me-2' width={20} height={20} /> <span className="content-text">
                                <strong class="notranslate">Recorditor:</strong> {t("Record and transcribe your screen or microphone with a single click.")}</span>
                        </li>
                        <li class="list-group-item">
                            <img src={checkCircle} alt='check-circle' className='img-fluid me-2' width={20} height={20} /> <span className="content-text">
                                <strong>{t("Future Updates:")}</strong> {t("Continuous development to add new AI and productivity tools to save your time.")}</span>
                        </li>
                        <li class="list-group-item">
                            <img src={checkCircle} alt='check-circle' className='img-fluid me-2' width={20} height={20} /> <span className="content-text">
                                <strong>{t("Rewards:")}</strong> {t("Share on social media and get access to premium features for free.")}</span>
                        </li>
                        <li class="list-group-item">
                            <img src={checkCircle} alt='check-circle' className='img-fluid me-2' width={20} height={20} /> <span className="content-text">
                                <strong>{t("Customer Reviews:")}</strong> {t("Transkriptor rated as 4.8 stars by over 500 users on major review platforms: Trustpilot, G2, Capterra…")}</span>
                        </li>
                        <li class="list-group-item">
                            <img src={checkCircle} alt='check-circle' className='img-fluid me-2' width={20} height={20} /> <span className="content-text">
                                <strong>{t("On Press:")}</strong> {t("Transkriptor highlighted as the top Chrome extension of 2023 by Google.")}<a href="https://blog.google/products/chrome/favorite-google-chrome-extensions-2023/">{' '}
                                    {t("Favorite Google Chrome Extensions")}</a></span>
                        </li>
                    </ol>
                </div>
            </div>
        </>
    );
}
export default SeoContent;