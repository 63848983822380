import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useModal } from "../../components/common/Modal/ModalContext";
import { showToast, dismissToast } from "../../components/common/toast";
import { fetchData } from "../../hooks/apiUtils";
import { useAuth } from "../../Auth/AuthContext";
import { useTranslation } from '../../components/common/LanguageProvider';
import downloadSvg from '../../assets/img/icon/download.svg';

import jsPDF from 'jspdf';
import font from "../../assets/font.json";
function Download({ content, filename, audio, contentRefs, orderId, setChangeDetect, isTranslated, isFiles, isDisabled }) {
    const { t } = useTranslation();
    const { showModal } = useModal();
    const { hashedId } = useAuth();

    const getText = () => {
        return content.map((item, index) => {
            const innerHTMLValue = contentRefs.current && contentRefs.current[index]
                ? contentRefs.current[index].innerText.replaceAll("#", "")
                : null;
            return {
                ...item,
                text: innerHTMLValue || item.text
            };
        }).filter(item => item.text && item.text.trim() !== "");
    };

    var items = []

    const handleDownloadTranscript = async (exportFormat, paragraphSize, includeTimestamps, mergeSegments, includeSpeakerNames, singleParagraph, transcription, mp3) => {
        if (!isFiles) {
            setChangeDetect(false);

        }
        var response;
        if (hashedId)
            response = await fetchData("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId);
        if (response && response.SStatus === "Trial") {
            showModal("UPGRADE-DOWNLOAD");
            return;
        } else {
            console.log(exportFormat, paragraphSize, includeTimestamps, mergeSegments, includeSpeakerNames, singleParagraph, transcription, mp3)
            if (mp3) {
                const link = document.createElement('a');
                link.href = audio;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }


            if(!isTranslated){
                  
                if (exportFormat === "word") {
                    exportFormat = "docx";
                }

                if (exportFormat === "clipboard") {
                    try {
                        const textContent = await createText(exportFormat, includeSpeakerNames, includeTimestamps, singleParagraph);
                        if (textContent) {
                            await navigator.clipboard.writeText(textContent);
                            return;
                        } else {
                            showToast("No text to copy", 'error');
                        }
                    } catch (error) {
                        console.error('Failed to copy text: ', error);
                        showToast("Failed to copy text to clipboard", 'error');
                    }
                } else {
                    // Show downloading toast and get a reference or ID
                    let toastId = showToast(t("File is being downloaded..."), {type:"info"});
                    if(!exportFormat)
                        exportFormat = "txt";
                    if(!paragraphSize)
                        paragraphSize = 1;
                    if(!includeTimestamps)
                        includeTimestamps = false;
                    if(!mergeSegments)
                        mergeSegments = false;
                    if(!includeSpeakerNames)
                        includeSpeakerNames = false;
                    if(!singleParagraph)
                        singleParagraph = false;

                    const apiUrl = `https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/fileexport/dispatcher?app=transkriptor&export_type=${exportFormat}&order_id=${orderId}&includeTimestamps=${includeTimestamps}&includeSpeakerNames=${includeSpeakerNames}&isSingleParagraph=${singleParagraph}&mergeSameSpeakerSegments=${mergeSegments}&paragraphSize=${paragraphSize}`;

                    try {
                        const fileResponse = await fetch(apiUrl);
                        if (!fileResponse.ok) {
                            console.error('API responded with an error:', fileResponse.status, fileResponse.statusText);
                            dismissToast(toastId);

                            if (paragraphSize > 1 && exportFormat != "srt") {
                                mergeSpeakers(1, paragraphSize)
                            }
                            if (mergeSegments && exportFormat !== "srt") {
                                mergeSpeakers(0, 4);
                            }
                            createText(exportFormat, includeSpeakerNames, includeTimestamps, singleParagraph)
                            return;
                        }
                        const contentType = fileResponse.headers.get("Content-Type");
                        if (contentType.includes("application/json")) {
                            const data = await fileResponse.json();
                            if (data.url) {
                                const link = document.createElement('a');
                                link.href = data.url;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                dismissToast(toastId);
                                showToast(t("File is downloaded successfully!"), 'success');

                            } else {
                                dismissToast(toastId);
                                showToast("Error retrieving the file", 'error');
                            }
                        } else {
                            const downloadUrl = await fileResponse.text();
                            const link = document.createElement('a');
                            link.href = downloadUrl;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            dismissToast(toastId);
                            showToast(t("File is downloaded successfully!"), 'success');

                        }
                    } catch (error) {
                        console.error('Failed to download the file: ', error);
                        showToast("Failed to initiate download", 'error');
                    }
                }
            }else{
                if (paragraphSize > 1 && exportFormat != "srt") {
                    mergeSpeakers(1, paragraphSize)
                }
                if (mergeSegments && exportFormat !== "srt") {
                    mergeSpeakers(0, 4);
                }
                createText(exportFormat, includeSpeakerNames, includeTimestamps, singleParagraph)
            }
        };
        if (!isFiles)
            setChangeDetect(true);

    };
    var type = "\n"
    const download = () => {
        const texts = items || getText();
        const combinedText = texts.map(textArea => textArea.text.replaceAll("#", "")).join(type);
        return combinedText;
    }

    const downloadmono = () => {
        const texts = items || getText();

        const combinedText = texts.map(textArea => textArea.text.replaceAll("#", "")).join(' ');
        return combinedText;
    }

    const downloadss = () => {
        const texts = items || getText();

        let combinedText = texts.map(item => {
            let text = item.Speaker ? item.Speaker + type : ''; // Append speaker if it exists
            text += item.text.replaceAll("#", "") + type + type; // Append the actual text and two newlines
            return text;
        }).join('');
        return combinedText;
    }
    const downloadts = () => {
        const texts = items || getText();

        let combinedText = texts.map(item => {
            let text = item.VoiceStart || item.VoiceStart === 0 ? msToTime(item.VoiceStart) + type : ''; // Append speaker if it exists
            text += item.text.replaceAll("#", "") + type + type; // Append the actual text and two newlines
            return text;
        }).join('');
        return combinedText;
    }
    const downloadtsss = () => {
        const texts = items || getText();

        let combinedText = texts.map(item => {
            let text = (item.VoiceStart || item.VoiceStart === 0) && item.Speaker ? msToTime(item.VoiceStart) + " " + item.Speaker + type : ''; // Append speaker if it exists
            text += item.text.replaceAll("#", "") + type + type; // Append the actual text and two newlines
            return text;
        }).join('');
        return combinedText;
    }

    const downloadsrt = () => {
        const items = getText();
        console.log(items)
        console.log("items")
        const srtText = items.map((item, index) => {
            let text = (index + 1).toString() + type;
            let voiceEnd = item.VoiceEnd;
            let voiceStart = item.VoiceStart;
            // Convert to formatted time for comparison
            let formattedVoiceStart = submsToTime(voiceStart);
            let formattedVoiceEnd = submsToTime(voiceEnd);

            // Check if formattedVoiceEnd is smaller than formattedVoiceStart
            if (formattedVoiceEnd < formattedVoiceStart && items[index + 1]) {
                voiceEnd = items[index + 1].VoiceStart; // Update the formattedVoiceEnd
                while (submsToTime(voiceEnd) < submsToTime(voiceStart)) {
                    voiceEnd = voiceEnd + 100 // Add 100ms to the VoiceStart if VoiceEnd is still smaller;
                    items[index + 1].VoiceStart = voiceEnd; // Update the VoiceStart of the next item
                }

                formattedVoiceEnd = submsToTime(voiceEnd); // Update the formattedVoiceEnd
            }

            text += (item.VoiceStart || item.VoiceStart === 0) && voiceEnd ? formattedVoiceStart + " --> " + formattedVoiceEnd + type : '';
            text += item.text.replaceAll("#", "") + type + type;
            return text;
        }).join('');

        return srtText;
    }

    const createText = async (exportFormat, includeSpeakerNames, includeTimestamps, singleParagraph) => {
        var copiedText = "";
        if (exportFormat === "word")
            type = "<br>"
        if (exportFormat === "srt") {
            copiedText = downloadsrt();
        } 
        else {
            if (singleParagraph) {
                copiedText = downloadmono();
            } else if (includeSpeakerNames && includeTimestamps) {
                copiedText = downloadtsss();
            } else if (includeSpeakerNames) {
                copiedText = downloadss();
            } else if (includeTimestamps) {
                copiedText = downloadts();
            } else {
                copiedText = download();
            }
        }
        if (exportFormat === "pdf") {
            const doc = new jsPDF();
        
            // Add Times New Roman font (assuming you've already converted it to Base64 and added to the VFS)
            const timesNewRomanBase64 = font
            doc.addFileToVFS("TimesNewRoman.ttf", timesNewRomanBase64.TimesNewRoman);
            doc.addFont("TimesNewRoman.ttf", "TimesNewRoman", "normal");
            doc.setFont("TimesNewRoman");
        
            const pageWidth = doc.internal.pageSize.getWidth() - 20;
            let startY = 10;
            const margin = 10;
        
            // Ensure text splits and handles custom fonts
            const lines = doc.splitTextToSize(copiedText, pageWidth - margin * 2);
        
            lines.forEach((line) => {
                if (startY > doc.internal.pageSize.getHeight() - margin) {
                    doc.addPage();
                    startY = margin;
                }
                doc.text(line, margin, startY);
                startY += 8; // Adjust line spacing as needed
            });
            doc.save(`${filename}.pdf`);
        }

        else if (exportFormat === "txt") {
            const link = document.createElement('a');
            link.setAttribute('download', filename + '.txt');
            link.setAttribute('href', 'data:' + 'text/plain' + ';charset=utf-8,' + encodeURIComponent(copiedText) + '\n');
            link.click();
        } else if (exportFormat === "word") {
            var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
            var postHtml = "</body></html>";
            var html = preHtml + copiedText + postHtml;
            var blob = new Blob(['\ufeff', html], { type: 'application/msword' });
            // Specify link url
            var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
            // Specify file name
            filename = filename + '.doc'
            // Create download link element
            var downloadLink = document.createElement("a");
            document.body.appendChild(downloadLink);
            if (navigator.msSaveOrOpenBlob) {
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                // Create a link to the file
                downloadLink.href = url;
                // Setting the file name
                downloadLink.download = filename;
                //triggering the function
                downloadLink.click();
            }
            document.body.removeChild(downloadLink);
        } else if (exportFormat === "srt") {
            var link = document.createElement('a');
            link.setAttribute('download', filename + '.srt');
            link.setAttribute('href', 'data:' + 'text/plain' + ';charset=utf-8,' + encodeURIComponent(copiedText) + '\n');
            link.click();
        }
        else if (exportFormat === "clipboard") {
            try {
                await navigator.clipboard.writeText(copiedText);
                showToast(t("The text copied to clipboard!"), 'success');
                return copiedText;
            } catch (err) {
                console.error('Failed to copy text: ', err);
                return false;
            }
        }

    };

    const mergeSpeakers = (current, limit) => {
        const mergedItems = [];

        let i = 0;
        let temp_current = current;
        while (i < items.length) {
            let mergedItem = { ...items[i] };

            // Merge adjacent items with the same speaker
            while (i < items.length - 1 && items[i].Speaker === items[i + 1].Speaker && limit > temp_current) {
                temp_current++;
                mergedItem.text += " " + items[i + 1].text;
                mergedItem.VoiceEnd = items[i + 1].VoiceEnd;
                i++;
            }
            temp_current = current;
            mergedItems.push(mergedItem);
            i++;
        }

        // Update state with mergedItems
        // Assuming you have a function or state to update the modified items, 
        // if not you will need to create one.
        items = mergedItems;
    };



    function msToTime(duration) {
        console.log(duration)
        console.log("duration")
        if (duration === 0)
            return "00:00:00"
        var milliseconds = (duration % 1000);
        var seconds = Math.floor(duration / 1000) % 60;
        var minutes = Math.floor(duration / (1000 * 60)) % 60;
        var hours = Math.floor(duration / (1000 * 60 * 60)) % 24;
        if (hours < 10) {
            hours = "0" + hours.toString()
        }
        if (minutes < 10) {
            minutes = "0" + minutes.toString()
        }
        if (seconds < 10) {
            seconds = "0" + seconds.toString()
        }
        return hours.toString() + ":" + minutes.toString() + ":" + seconds.toString()
    }

    function submsToTime(duration) {
        if (duration === 0)
            return "00:00:00"
        var milliseconds = Math.floor(duration % 1000)
        var seconds = Math.floor(duration / 1000) % 60
        var minutes = Math.floor(duration / (1000 * 60)) % 60
        var hours = Math.floor(duration / (1000 * 60 * 60)) % 24
        if (hours < 10) {
            hours = "0" + hours.toString()
        }
        if (minutes < 10) {
            minutes = "0" + minutes.toString()
        }
        if (seconds < 10) {
            seconds = "0" + seconds.toString()
        }
        var strmilliseconds = milliseconds.toString()
        if (strmilliseconds.length == 1) {
            strmilliseconds = strmilliseconds + "00"
        }
        else if (strmilliseconds.length == 2) {
            strmilliseconds = strmilliseconds + "0"
        }
        return hours.toString() + ":" + minutes.toString() + ":" + seconds.toString() + "," + strmilliseconds

    }
    function timeToMs(timeStr) {
        const parts = timeStr.split(':');
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        const secondsMilliseconds = parts[2].split(',');
        const seconds = parseInt(secondsMilliseconds[0], 10);
        const milliseconds = parseInt(secondsMilliseconds[1], 10);

        return ((hours * 60 * 60) + (minutes * 60) + seconds) * 1000 + milliseconds;
    }

    const checkUser = async () => {
        const response = await fetchData("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId);
        if (response.SStatus === "Trial") {
            showModal("UPGRADE-DOWNLOAD");
        } else {
            items = getText();
            showModal("DOWNLOAD-TRANSCRIPT", { onDownloadTranscript: handleDownloadTranscript })
        }
    }
    const initiateDownload = async (e) => {
        if (isDisabled) {
            e.preventDefault();
            return;
        }
        items = getText();
        showModal("DOWNLOAD-TRANSCRIPT", { onDownloadTranscript: handleDownloadTranscript })
    }
    return (
        <>
            <Link
                onClick={(e) => {
                    e.preventDefault();
                    initiateDownload()
                }}
                className={!isFiles ? "hover-effect" : ""}
                style={isDisabled ? { pointerEvents: 'none', opacity: 0 } : {}}
            >
                {!isFiles ? (

                    <span
                        class="file-action-list theme-color"

                    >
                        <span className="hover-span">
                            {t("Download")}
                        </span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="19.34"
                            viewBox="0 0 21 19.34"
                        >
                            <g
                                id="Group_46"
                                data-name="Group 46"
                                transform="translate(-0.16)"
                            >
                                <rect
                                    id="Rectangle_36"
                                    data-name="Rectangle 36"
                                    width="21"
                                    height="15"
                                    rx="3"
                                    transform="translate(21.16 15.34) rotate(180)"
                                    fill="#fff"
                                />
                                <rect
                                    id="Rectangle_37"
                                    data-name="Rectangle 37"
                                    width="21"
                                    height="5"
                                    rx="2.5"
                                    transform="translate(21.16 17.34) rotate(180)"
                                    fill="currentColor"
                                />
                                <rect
                                    id="Rectangle_38"
                                    data-name="Rectangle 38"
                                    width="21"
                                    height="5"
                                    rx="2.5"
                                    transform="translate(21.16 19.34) rotate(180)"
                                    fill="#fff"
                                />
                                <g
                                    id="arrow-small-down"
                                    transform="translate(5.402)"
                                >
                                    <path
                                        id="Path_163"
                                        data-name="Path 163"
                                        d="M21.767,18.641l-4.612,4.613H15.862L11.25,18.641l1.292-1.31,3.044,3.026V15H17.43v5.357l3.045-3.045Z"
                                        transform="translate(-11.25 -15)"
                                        fill="currentColor"
                                    />
                                </g>
                            </g>
                        </svg>
                    </span>
                ) : (
                    <span className="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Download")}>
                        < img src={downloadSvg} className="img-fluid" alt="img" />
                    </span>
                )}
            </Link>
        </>
    );

}

export default Download;