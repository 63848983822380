import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { auth } from './config';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, sendEmailVerification, signInWithCustomToken, GoogleAuthProvider, signInWithPopup, updateProfile, signInWithCredential } from "firebase/auth";
import { Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../components/common/toast';
import Gleap from "gleap";
import countryLanguage from 'country-language';
import { useTranslation } from '../components/common/LanguageProvider';
import { fetchData } from '../hooks/apiUtils';
import { sendAmplitudeData } from './amplitude';
import { popularLanguages, allLanguages } from '../components/common/transcribeLanguageDropdown';
import {jwtDecode} from 'jwt-decode';
import {getToken, messaging, onMessage} from './config';
import { toast } from 'react-toastify';
import Message from '../components/Notification/Message';
import saveNotificationToken from '../components/Notification/saveNotification';
const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const { t } = useTranslation();
    const [currentUser, setCurrentUser] = useState(null);
    const [userData, setUserData] = useState(null); // [name, email, uid
    const [hashedId, setHashedId] = useState(null);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const signupRef = useRef(); 
    var checkTime;
    const auth = getAuth();
    auth.useDeviceLanguage();
    const navigate = useNavigate();
    const path = useLocation().pathname;

    useEffect(() => {
      getStatus();
    }, [location]);
  
    const getStatus = async()=>{
        if(hashedId){
            const response = await fetchData("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId);
            setUserData(response);
        }
    }
    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    async function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
        
    }

    function logout() {
        localStorage.setItem("loggedIn", false);

        return auth.signOut();
    }

    function sendResetPasswordMail(email){
        return sendPasswordResetEmail(auth, email);
    }

    function sendVerification(user){
        return sendEmailVerification(user);
    }
    function signInWithToken(token){
        return signInWithCustomToken(auth, token);
    }

    function googleLogin(){
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider);
    }
    function updateProfileName(name){
        return updateProfile(auth.currentUser, {displayName: name})
    }

    const automateLogin = async(uid, redirect) => {
        const response = await fetch("https://hg0761tbmf.execute-api.eu-central-1.amazonaws.com/default/automateLogin?hashedId=" + uid);
        
        if (response.status === 200) {
            const data = await response.json();
            const token = data["customToken"];
            await signInWithToken(token);
            if (redirect) {
                
                navigate("/" + redirect);
                
            } else {
                navigate('/dashboard');
            }
            
        } else {
            console.log('Error:', response.status, response.statusText);
        }
        
    };
    const setLoginToken = async(uid) => {
        const response = await fetch("https://hg0761tbmf.execute-api.eu-central-1.amazonaws.com/default/automateLogin?hashedId=" + uid);
        if (response.status === 200) {
            const data = await response.json();
            const token = data["customToken"];
            setToken(token);
           
        } else {
            console.log('Error:', response.status, response.statusText);
        }   
    };
    const directLogin = async (token) => {
        return signInWithToken(token);
    };
 
    const findLanguage = async () => {
        try {
          const browserLanguage = navigator.language || navigator.userLanguage || 'en-US';
          const [iso639_1, countryCode] = browserLanguage.split("-");
      
          localStorage.setItem("dashboard-language", iso639_1);
          localStorage.setItem("dashboard-country", countryCode || 'US');
          let temp_lang = browserLanguage;
        
          localStorage.setItem("transcript-language", temp_lang);
          window.location.reload();
      
        } catch (error) {
          console.error('Error detecting browser language:', error);
        }
      };
      const getUserData = async () => {
        return userData;
      };

      const fetchLanguages = async (temp_hashedId, segment) => {
        if (!temp_hashedId) return;
        try {
            const queryParams = new URLSearchParams({
              user_id: temp_hashedId,
              dashboard_language: localStorage.getItem("dashboard-language") || "en",
              app_name: 'transkriptor'
            }).toString();
            const response = await fetch(`https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/appoptions/getlanguages?${queryParams}`);
        
            if (response.ok) {
              const data = await response.json();
              localStorage.setItem("user_locales", JSON.stringify(data.user_specific_stt_locales));
              localStorage.setItem("all_locales", JSON.stringify(data.stt_locales));        
            } else {
              console.error('Failed to fetch languages:', response.statusText);
              localStorage.setItem("user_locales", JSON.stringify(popularLanguages));
              localStorage.setItem("all_locales", JSON.stringify(allLanguages));
  
            }
          } catch (error) {
            console.error('Error fetching languages:', error);
            localStorage.setItem("user_locales", JSON.stringify(popularLanguages));
            localStorage.setItem("all_locales", JSON.stringify(allLanguages));
          }
      };
      useEffect(() => {
        const channel = new BroadcastChannel('payment_channel');
    
        const handleMessage = (event) => {
          if (event.data === 'payment_success') {
            console.log('Payment successful! Updating data...');
            //fetchDataAndUpdate();
            
          }
        };
      
        channel.addEventListener('message', handleMessage);
      
        return () => {
          channel.removeEventListener('message', handleMessage);
          channel.close();
        };
      }, []);
      const initializeOneTap = () => {
        localStorage.removeItem('onetap-signup')

        window.google.accounts.id.initialize({
          client_id: '468947402819-gmv1nf08dgve3bolkmveee4k9ak93oce.apps.googleusercontent.com',
          callback: handleCredentialResponse,
          auto_select: false,
          cancel_on_tap_outside: false
        });
  
        window.google.accounts.id.prompt((notification) => {
            if (notification.isNotDisplayed()) {
              console.log(notification.getNotDisplayedReason());
            } else if (notification.isSkippedMoment()) {
              console.log(notification.getSkippedReason());
            } else if (notification.isDismissedMoment()) {
              console.log(notification.getDismissedReason());
            }
          });
        
        };

        async function requestPermission(hashed_id) {
            //requesting permission using Notification API
            const permission = await Notification.requestPermission();
        
            if (permission === "granted") {
              const token = await getToken(messaging, {
                vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
              });
        
              //We can send token to server
              console.log("Token generated : ", token);
              saveNotificationToken(hashed_id, token);
            } else if (permission === "denied") {
              //notifications are blocked
              alert("You denied for the notification");
            }
          }
const handleCredentialResponse = (response) => {
    var credential = null;
    if(response.credential)
        credential = GoogleAuthProvider.credential(response.credential); 
    else
        credential = GoogleAuthProvider.credential(response);

    setTimeout(() => {
        if(window.location.pathname === "/signIn" || window.location.pathname === "/register" || window.location.pathname === "/"){
            navigate('/dashboard');
        }
    }, 1500);
    signInWithCredential(auth, credential)
      .then((result) => {
        console.log('User signed in with Firebase:', result.user);
        localStorage.setItem('onetap-signup', "true")   
        signupRef.current = true; 
        
      })
      .catch((error) => {
        console.error('Error signing in with Firebase:', error);
      });
  };
  const closeOneTapPrompt = () => {
    try {
      window.google.accounts.id.cancel();
    }catch(e){
        console.log(e);
        
    }
};
    useEffect(() => {

        // This is a listener which Firebase provides to determine 
        // authentication state. It's automatically managed, so if the token 
        // is invalidated or expired, the user will be logged out automatically.
        const unsubscribe = onAuthStateChanged((auth), user => {
            setCurrentUser(user);
            setLoading(false);
            if(user){
                closeOneTapPrompt();
               if(user.emailVerified){
                localStorage.setItem("loggedIn", true);
                const isGoogleSignUp = user.providerData?.some(provider => provider.providerId === "google.com");
                    const currentTimestamp = Date.now();
                    const creationTimestamp = user.metadata.createdAt;
                    fetch('https://v3t1p3ucic.execute-api.eu-central-1.amazonaws.com/default/AA-API-FireGetHashedID?uid='
                    + user.uid + '&app=Transkriptor').then(response => response.json()).then(data => {
                        const temp_hashedId = data.hashedId;
                        const is_new_user = data.is_new_user;
                        setHashedId(data.hashedId)
                        setLoginToken(data.hashedId);
                        //requestPermission(data.hashedId);

                        fetch("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + data.hashedId).then(response => response.json()).then(data => {
                            setUserData(data)
                            let segment = data.UserSegment;
                            if(!localStorage.getItem("user_locales")){
                                fetchLanguages(temp_hashedId, segment);
                            }
                            setTimeout(() => {
                                var temp_ref = signupRef
                                if(isGoogleSignUp && (parseInt(currentTimestamp) - parseInt(creationTimestamp) < 60000) && temp_ref && temp_ref.current && is_new_user){
                                    sendAmplitudeData("sign_up", data, user, "google_sign_up_onetap", "app.transkriptor.com/signIn", "app.transkriptor.com/dashboard");
                                }
    
                            }, 1000);
                            if(isGoogleSignUp && (parseInt(currentTimestamp) - parseInt(creationTimestamp) < 180000) && localStorage.getItem("google-clicked") && is_new_user){
                                sendAmplitudeData("sign_up", data, user, "google_sign_up_button", "app.transkriptor.com/signIn", "app.transkriptor.com/dashboard");
                            }
                            else if(!isGoogleSignUp && localStorage.getItem("signedup-flag") && is_new_user){
                                localStorage.setItem("signedup-flag", false);
                                sendAmplitudeData("sign_up", data, user, "sign_up_button", "app.transkriptor.com/register", "app.transkriptor.com/dashboard");
                            }if(!isGoogleSignUp && localStorage.getItem("signedin-flag")){
                                localStorage.setItem("signedin-flag", false);
                                sendAmplitudeData("sign_in", data, user, "sign_in_button", "app.transkriptor.com/signIn", "app.transkriptor.com/dashboard");
                            }
                            if(isGoogleSignUp && localStorage.getItem("google-clicked")){
                                localStorage.setItem("google-clicked", false);
                                sendAmplitudeData("sign_in", data, user, "google_sign_in_button", "app.transkriptor.com/signIn", "app.transkriptor.com/dashboard");
                            }
                            const queryParams = new URLSearchParams(location.search);
                                // Get the user agent string
                                var userAgentString = navigator.userAgent;
                                if(!localStorage.getItem("transcript-language"))
                                    //findLanguage();
                                // Detect the browser name
                                var browserName = "";
                                if(userAgentString.indexOf("Chrome") != -1){
                                    browserName = "Chrome";
                                }else if(userAgentString.indexOf("Firefox") != -1){
                                    browserName = "Firefox";
                                }else if(userAgentString.indexOf("Safari") != -1){
                                    browserName = "Safari";
                                }else if(userAgentString.indexOf("Opera") != -1 || userAgentString.indexOf("OPR") != -1){
                                    browserName = "Opera";
                                }else if(userAgentString.indexOf("Edge") != -1){
                                    browserName = "Edge";
                                }else if(userAgentString.indexOf("MSIE") != -1 || !!document.documentMode == true){
                                    browserName = "Internet Explorer";
                                }else{
                                    browserName = "Unknown browser";
                                }

                            Gleap.identify("User#" + temp_hashedId, {
                                email: data.Mail,
                                customData: {
                                subscription: data.SStatus,
                                segment: data.UserSegment,
                                language: localStorage.getItem('dashboard-language'),
                                browser: browserName
                                },
                            },
                                temp_hashedId
                            );
                            // To retrieve a specific query parameter:
                            const uid = queryParams.get('uid');
                            const redirect = queryParams.get('redirect');
                            if (redirect) {
                            } else {
                                if(path === "/signIn" || path === "/register" || path === "/" ){
                                    navigate('/dashboard');
                                } 
                            }
                          
                        })
   
                    })
                }else{
                    sendVerification(user);
                    
                    showToast(t("Please check your mailbox to verify your email."), {type: "success", position:"top-center"});

                    logout();

                    if(path !== "/signIn" && path !== "/register" && !path.startsWith("/authentication")){
                        navigate('/signIn', {state: {email_password: "true"}});
                    }
                    
                
                }
            }else{
                localStorage.setItem("loggedIn", false);

                const queryParams = new URLSearchParams(location.search);
                // To retrieve a specific query parameter:
                const uid = queryParams.get('uid');
                const redirect = queryParams.get('redirect');
                const onetap_id = queryParams.get('onetap_id')

                const isEditorPage = location.pathname.startsWith("/editor") || location.pathname.startsWith('/payment')
                const token = queryParams.get('t');
                if (token) {
                   directLogin(token);

                }
                else if(onetap_id){
                    handleCredentialResponse(onetap_id)
                }
                else if(isEditorPage && uid){
                }
                else if(!redirect && path !== "/signIn" && path !== "/register" && !path.startsWith("/sharing") && !path.startsWith("/authentication")){
                    const { pathname, search } = location;
                    // Construct the full path with query parameters
                    const fullPath = `${pathname.replace('/', '')}${search}`;
                    // Navigate to the specified path and pass state
                    navigate('/signIn', { state: { temp_redirect: fullPath } });
                }else if(redirect && !uid){
                    navigate(`/signIn`, {state: {temp_redirect: redirect}});
                }
                else if(uid && redirect){
                    automateLogin(uid, redirect);
                }
            }
        });
     

        // Cleanup subscription on unmount
        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        hashedId,
        userData,
        token,
        setLoginToken,
        signup,
        login,
        logout,
        sendResetPasswordMail,
        sendVerification, 
        signInWithToken,
        googleLogin,
        updateProfileName,
        initializeOneTap,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};