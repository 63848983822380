import React, { useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";

import { useAuth } from "../Auth/AuthContext";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useModal } from "../components/common/Modal/ModalContext";
import filesBlue from "../assets/img/icon/files-blue.svg";
import dropbox from "../assets/img/icon/dropbox.svg";
import googleDrive from "../assets/img/icon/google-drive.svg";
import oneDrive from "../assets/img/icon/onedrive.svg";
import youtube from "../assets/img/icon/youtube.svg";
import uploadBox from "../assets/img/upload/upload-box.png";
import fileUrl from "../assets/img/upload/file-url.png";
import languagePng from "../assets/img/upload/language.png";
import servicePng from "../assets/img/upload/service.png";
import googleDriveFile from "../assets/img/upload/google-drive-file.png";
import { fetchDataPost } from "../hooks/apiUtils";
import { showToast } from "../components/common/toast";
import { useTranslation } from "../components/common/LanguageProvider";
import uploadPng from "../assets/img/icon/upload-btn-icon.svg";
import Converter from "./Converter";
import { sendAmplitudeData } from "../Auth/amplitude";
import { v4 as uuidv4 } from 'uuid';

const multipartUrl =
  "https://u8vqorw2rc.execute-api.eu-central-1.amazonaws.com/default/AA-API-multipartupload?";
const uploaderFinisherUrl =
  "https://7z7j0bn899.execute-api.eu-central-1.amazonaws.com/default/AA-API-TranskriptorUploaderFinisher?";
var upload_arr = [];
var upload_status = [];
function Uploader({ setIsBlocked }) {
  const { t } = useTranslation();
  const { hashedId, userData, token, currentUser } = useAuth();
  const fileInput = useRef();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState(null);
  const [service, setService] = useState(null);
  const [urlUpload, setUrlUpload] = useState(false);
  const [stillProcessing, setStillProcessing] = useState(false);
  const [urlStatus, setUrlStatus] = useState(null);
  const navigate = useNavigate();
  const { showModal } = useModal();
  const [progressFileName, setProgressFileName] = useState("");
  const ffmpegRef = useRef(new FFmpeg());
  const [loaded, setLoaded] = useState(false);
  const videoRef = useRef(null);
  const messageRef = useRef(null);

  useEffect(() => {
    const loadFFmpeg = async () => {
      const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
      const ffmpeg = ffmpegRef.current;
      try {
        await ffmpeg.load({
          coreURL: await toBlobURL(
            `${baseURL}/ffmpeg-core.js`,
            "text/javascript"
          ),
          wasmURL: await toBlobURL(
            `${baseURL}/ffmpeg-core.wasm`,
            "application/wasm"
          ),
        });
        setLoaded(true);
      } catch (error) {
        console.error("Failed to load FFmpeg:", error);
      }
    };

    loadFFmpeg();
  }, []);

  async function convertVideoToMP3(file, ffmpegRef) {
    if (!file) {
      return null;
    }

    const fileNameWithoutExt = file.name
      .replace(/\.[^/.]+$/, "")
      .replace(/\s+/g, "_");
    const outputFileName = `${fileNameWithoutExt}.mp3`;

    const ffmpeg = ffmpegRef.current;
    try {
      const fileData = await fetchFile(file);
      await ffmpeg.writeFile(file.name, fileData);
      await ffmpeg.exec(["-i", file.name, outputFileName]);
      const data = await ffmpeg.readFile(outputFileName);
      const mp3File = new File([data.buffer], outputFileName, {
        type: "audio/mp3",
      });
      return mp3File;
    } catch (err) {
      console.error("Error during the FFmpeg operation:", err);
      return null;
    }
  }

  useEffect(() => {
    if (urlUpload || isLoading) {
      setIsBlocked(true);
    } else if (!urlUpload && !isLoading) {
      setIsBlocked(false);
    }
  }, [isLoading, urlUpload]);

  const [selectedFiles, setSelectedFiles] = useState(null);
  var urlIndex = 0;
  const [attachListener, setAttachListener] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    if (attachListener) {
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [attachListener]);

  const handleUpload = async (file, language, service, flag, files) => {
    if (!loaded) {
      console.error("FFmpeg is not loaded yet.");
      // Don't return here to allow the upload process to continue without FFmpeg conversion.
    }

    setIsLoading(true);

    setProgressFileName(file.name);

    try {
      let originalFile = file; // Keep a reference to the original file in case conversion fails
      // Convert if it's a video file and FFmpeg is loaded
      if (
        file.type.startsWith("video/") &&
        loaded
      ) {
        try {
          const convertedFile = await convertVideoToMP3(file, ffmpegRef);
          file = convertedFile ? convertedFile : file;
        } catch (conversionError) {
          console.error("Error during FFmpeg conversion:", conversionError);
          file = originalFile; // Fallback to original file on conversion error
        }
      }

      setAttachListener(true);

      let file_size = file.size / (1024 * 1024);

      if (file_size > 5) {
        multipartUpload(
          file,
          hashedId,
          navigate,
          setUploadProgress,
          language,
          service,
          flag,
          files
        );
        return;
      } else {
        // flow for files smaller than 5 mb
        const generalUploadUrl = `https://cqhldp14tk.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreatePresignedURLGeneralUpload?cid=${hashedId}&filename=${encodeURIComponent(
          removeSpecialCharsAndTrim(file.name)
        )}`;

        // Step 1: Get the pre-signed URL from your server
        const response = await fetch(generalUploadUrl);
        const data = await response.json();
        const presignedUrl = data["the_url"];

        // Step 2: Upload the file to S3
        const uploadResult = await uploadToS3(
          presignedUrl,
          file,
          (progress) => {
            setUploadProgress(progress);
          }
        );

        // Step 3: Notify your server about the successful upload
        const endpointUrl = `https://7z7j0bn899.execute-api.eu-central-1.amazonaws.com/default/AA-API-TranskriptorUploaderFinisher?cid=${hashedId}&filename=${encodeURIComponent(
          removeSpecialCharsAndTrim(file.name)
        )}&language=${language}&service=${service}`;
        const endpointResponse = await fetch(endpointUrl);
        const endpointData = await endpointResponse.json();
        showToast(t("Uploaded File") + ": " + file.name, { type: "success" });
      }
    } catch (err) {
      console.error("Error during upload or conversion:", err);
      showToast(t("Failed to process file."), { type: "error" });
    } finally {
    }

    // Navigate to the files route
    if (!flag) {
      setAttachListener(false);
      navigate("/files");
    } else {
      const uploadedFileIndex = files.indexOf(file);
      const nextFile =
        uploadedFileIndex !== -1 && uploadedFileIndex < files.length - 1
          ? files[uploadedFileIndex + 1]
          : null;
      if (nextFile) {
        setUploadProgress(0);
        await onTranscribeFile(language, service, nextFile, flag, files);
      } else {
        setAttachListener(false);
        navigate("/files");
      }
    }
  };

  const onUploadFile = async (file) => {
    showModal("TRANSCRIBE-FILE", { file: file }, { onTranscribeFile });
  };
  const onTranscribeURL = async (language, service, url) => {
    if (userData.Minutes < 0) {
      setTimeout(() => {
        showModal("UPGRADE-MINUTES");
      }, 1000);
      return;
    }
    if (!language) {
      showToast(t("Please select a language"), {
        type: "error",
        position: "top-center",
      });
      return;
    }
    setUrlUpload(true);
    setUrlStatus("checking");
    fetchDataPost(
      "https://3j3l2hnktb.execute-api.eu-central-1.amazonaws.com/default/Transcribe-From-Url-Initiation",
      { url: url, user_id: hashedId, service: service, language: language }
    ).then((data) => {
      if (data.status === 200) {
        setUrlStatus("uploaded");
        showToast(
          t(
            "Your file is being transcribed. You will be notified when it is ready.",
            { type: "success" }
          )
        );
        setAttachListener(false);
        setTimeout(navigate("/files"), 3000);
      } else if (data.status === 500) {
        setAttachListener(false);
        setUrlUpload(false);
        setUrlStatus("invalidUrl");
      } else if (data.status === 403) {
        setAttachListener(false);
        setUrlUpload(false);

        setUrlStatus("privateUrl");
      } else if (data.status === 503) {
        checkProcessing(url);
      }
    });
  };

  const checkProcessing = async (url) => {
    setStillProcessing(true);
    fetchDataPost(
      "https://inwv3q0922.execute-api.eu-central-1.amazonaws.com/default/URL-Transcription-Check",
      { url: url }
    ).then((data) => {
      if (data === "processing" && urlIndex < 90) {
        urlIndex++;
        setTimeout(checkProcessing(url), 10000);
      } else if (data === "processing" && urlIndex >= 90) {
        showToast(t("Upload failed, please try again..."), { type: "error" });
        setTimeout(navigate("/dashboard"), 3000);
      } else if (data === "ready") {
        setAttachListener(false);
        setTimeout(function () {
          setUrlStatus("uploaded");
          navigate("/files");
        }, 40000);
      }
    });
  };

  const onTranscribeFile = async (language, service, file, flag, files) => {
    if (userData.Minutes < 0) {
      showModal("UPGRADE-MINUTES");
      return;
    }
    if (!language) {
      showToast(t("Please select a language"), {
        type: "error",
        position: "top-center",
      });
      return;
    }

    setLanguage(language);
    setService(service);
    if (!flag) flag = false;
    handleUpload(file, language, service, flag, files);
  };

  const onUploadMultipleFiles = async (files) => {
    showModal(
      "TRANSCRIBE-MULTIPLE-FILES",
      { files: files },
      { onTranscribeMultipleFiles }
    );
  };

  const onTranscribeMultipleFiles = async (language, service, files) => {
    if (!userData) {
      showToast(t("Unknown Error"), { type: "error" });
      window.location.reload();
    }
    if (userData.Minutes < 0) {
      showModal("UPGRADE-MINUTES");
      return;
    }

    onTranscribeFile(language, service, files[0], true, files);
  };

  const multipartUpload = async (
    file,
    hashedId,
    navigate,
    setUploadProgress,
    language,
    service,
    flag,
    files
  ) => {
    let partSize = 5 * 1024 * 1024; // 5 MB
    let fileSize = file.size;
    let filename = file.name;
    var payload = {
			feature_category: "transcription",
			feature_subcategory: "local_transcription",
			feature_name: "local_transcription",
			feature_input_type: file.type,
			feature_content: file.name,
			feature_language: language,
			feature_service: service,
			feature_id: uuidv4(),
			feature_product: "transkriptor",
			feature_status: "production",
			feature_platform: "web",
			feature_start_timestamp: new Date().getTime(),
			needs_credit: true,
			is_premium: false,
			data_source: "frontend"
			
		  }
		sendAmplitudeData("feature_init", userData, currentUser, "", "", "", payload)
    if (fileSize > 1024 * 1024 * 1024 * 3) partSize = 20 * 1024 * 1024; // 10 MB
    else if (fileSize > 1024 * 1024 * 1024) {
      partSize = 10 * 1024 * 1024; // 20 MB
    }
    const partCount = Math.ceil(fileSize / partSize);
    let multipartInitiateUrl =
      multipartUrl +
      `fileSize=${fileSize}&cid=${hashedId}&filename=${encodeURIComponent(
        removeSpecialCharsAndTrim(filename)
        )}&partCount=${partCount}&partSize=${partSize}&flag=true&feature_id=${payload.feature_id}&feature_start_timestamp=${payload.feature_start_timestamp}`;

    fetch(multipartInitiateUrl)
      .then((response) => response.json())
      .then((data) => {
        const presignedUrls = data.presignedUrls;
        const uploadId = data.uploadId;
        var partList = [];
        for (let i = 0; i < presignedUrls.length; i++) {
          const partNumber = presignedUrls[i].part_number;
          const presignedUrl = presignedUrls[i].presigned_url;
          const start = i * partSize;

          const end = Math.min((i + 1) * partSize, fileSize);
          const filePart = file.slice(start, end);
          upload_arr.push(0);
          let temp_arr = {
            url: presignedUrls[i],
            status: "started",
          };
          upload_status[i + 1] = temp_arr;

          // Assuming 'presignedUrl' and 'fileChunk' are already defined
          partList.push({
            ETag: "",
            PartNumber: i + 1,
          });

          let content_arr = {
            uploadId: uploadId,
            filename: filename,
            partCount: partCount,
            partList: partList,
            upload_arr: upload_arr,
            file_size: fileSize,
            language: language,
            service: service,
          };
          upload_status[0] = content_arr;
          upload_part(
            presignedUrl,
            i,
            uploadId,
            partList,
            filename,
            multipartUrl,
            filePart,
            partCount,
            file,
            navigate,
            hashedId,
            setUploadProgress,
            language,
            service,
            flag,
            files
          );
        }
      });
  };
  const upload_part = async (
    presignedUrl,
    i,
    uploadId,
    partList,
    filename,
    apiUrl,
    filePart,
    partCount,
    file,
    navigate,
    hashedId,
    setUploadProgress,
    language,
    service,
    multiFlag,
    files
  ) => {
    try {
      // Upload a part of the file using the presigned URL
      let uploadCid = hashedId;
      const partResponse = await axios.put(presignedUrl, filePart, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        onUploadProgress: (e) => {
          upload_arr = upload_status[0].upload_arr;
          upload_arr[i] = e.loaded;
          const sum = upload_arr.reduce((acc, val) => acc + val, 0);
          upload_status[0].loaded = upload_arr;
          const percentComplete = (sum / upload_status[0].file_size) * 100;
          setUploadProgress(percentComplete);
        },
      });

      if (partResponse.status >= 200 && partResponse.status < 300) {
        const etag = partResponse.headers.etag;

        if (partList.length > 0) {
          partList[i].ETag = etag;
        }

        upload_status[i + 1].status = "completed";

        let flag = true;
        for (let j = 1; j < upload_status.length; j++) {
          if (upload_status[j].status !== "completed") {
            flag = false;
            break;
          }
        }

        if (flag) {
          // All parts have been uploaded, complete the multipart upload

          try {
            const response = await axios.get(apiUrl, {
              params: {
                uploadId,
                filename: removeSpecialCharsAndTrim(filename),
                cid: uploadCid,
              },
            });
            // Handle the response here if needed.
          } catch (error) {
            console.error("Error during Axios request:", error);

            // Display a toast notification with the error message.
            showToast(
              t(
                "Upload failed error: Your upload failed please try again. Please use Google Chrome, do not have any special characters (+,&,:...) in your file name",
                { type: "error" }
              )
            );
            setAttachListener(false);
          }

          // Step 3: Notify your server about the successful upload
          const endpointUrl = `https://7z7j0bn899.execute-api.eu-central-1.amazonaws.com/default/AA-API-TranskriptorUploaderFinisher?cid=${uploadCid}&filename=${encodeURIComponent(
            removeSpecialCharsAndTrim(file.name)
          )}&language=${language}&service=${service}`;
          const endpointResponse = await fetch(endpointUrl);
          const endpointData = await endpointResponse.json();
          showToast(t("Uploaded File") + ": " + file.name, { type: "success" });

          // Navigate to the files route
          if (!multiFlag) {
            setAttachListener(false);

            navigate("/files");
          } else {
            const uploadedFileIndex = files.indexOf(file);

            // Get the next file if there is one
            const nextFile =
              uploadedFileIndex !== -1 && uploadedFileIndex < files.length - 1
                ? files[uploadedFileIndex + 1]
                : null;

            if (nextFile) {
              setUploadProgress(0);
              onTranscribeFile(language, service, nextFile, multiFlag, files);
            } else {
              setAttachListener(false);

              navigate("/files");
            }
          }

          // More logic here...
        }
      } else {
        console.error(`Failed to upload part ${i + 1}: ${partResponse.status}`);
        // Your retry logic and snackbar call goes here
      }
    } catch (error) {
      console.error("Error during upload:", error);
      // Your error logic goes here
    }
  };

  const removeSpecialCharsAndTrim = (filename) => {
    // Remove special characters (#, &, *) and trim whitespace
    let temp = filename.replace(/[&#*]/g, "").trim();
    return temp;
  };
  return (
    <>
      <div
        className="row justify-content-center"
        
      >
        <div
          className="col-12 card-margin"
          style={{ display: !isLoading ? "none" : "" }}
        >
          <div className="card card-padding card-100 text-center ">
            <div style={{ minHeight: "5rem" }}>
              {" "}
              {/* Set a fixed minimum height */}
              {uploadProgress > 0 && uploadProgress <= 100 ? (
                <div>
                  <div className="progress mb-4">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: `${uploadProgress}%` }}
                      aria-valuenow={uploadProgress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <strong>{uploadProgress.toPrecision(3)}%</strong>
                </div>
              ) : (
                <div className="spinner-border text-primary mb-4" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>

            <p className="ft-18-title mb-1">{t("Please Wait")}</p>
            <p>
              {t(
                "Please wait until your file is uploaded to cloud. Your file will be transcribed..."
              )}
            </p>
            <p>{progressFileName}</p>
          </div>
        </div>
        <div
          className="col-12 card-margin"
          style={{ display: !urlUpload ? "none" : "" }}
        >
          <div className="card card-padding card-100 text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <p className="ft-18-title mb-1">{t("Please Wait")}</p>
            <p style={{ display: stillProcessing ? "" : "none" }}>
              {t(
                "It might take some time. Please wait until your URL is transcribed..."
              )}
            </p>
            <p>
              {t(
                "Please wait until your url is uploaded to cloud. your URL will be transcribed..."
              )}
            </p>
          </div>
        </div>
        <div
          className="col-12 card-margin"
          style={{
            display:
              urlStatus === "invalidUrl" || urlStatus === "privateUrl"
                ? ""
                : "none",
          }}
        >
          
        </div>
        <div
          class="col-lg-4 card-margin"
          style={{ display: isLoading || urlUpload ? "none" : "" }}
        >
          <div
            class="card card-padding card-100  text-center card-hover"
            onClick={() => {
              setUrlStatus("checking");
              showModal("UPLOAD-FILE", { onUploadFile });
            }}
          >
            <div class="img-icon-list">
              <span className="round-color-icon green-bg recorder-span">
                <img
                  src={uploadPng}
                  className="img-fluid recorder-img"
                  alt="img"
                  style={{ width: "150px", height: "150px" }}
                ></img>
              </span>
            </div>
            <p class="ft-18-title">{t("Upload Audio or Video File")}</p>

            <p>
              {t(
                "Convert any audio file (mp3, mp4, wav, aac, m4a, webm,...) or video file to text"
              )}
            </p>
          </div>
        </div>

         {!isLoading && <>
          <Converter userData={userData} segment={userData?.UserSegment}/>
         </>}
        <Link
          className="text-center underline"
          style={{ display: isLoading || urlUpload ? "none" : "" }}
          onClick={() =>
            showModal("MULTIPLE-UPLOAD", { onUploadMultipleFiles })
          }
        >
          {t("Upload Multiple Files")}
        </Link>
        <div class="col-12 sec-pad-top">
          <h3 class="medium-title text-center mb-lg-5 mb-4">
            {t("Frequently Asked Questions")}
          </h3>
          <div class="card card-padding card-margin">
            <div
              class="accordion accordion-flush dark-color"
              id="accordionFlushExample"
            >
              <div class="accordion-item">
                <span class="collapse-icon fa fa-angle-double-right"></span>
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    {t(
                      "Is there a file size limit, my file is too large how can I upload it faster?"
                    )}
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <p>
                      {t(
                        "There is 5 gb file size limit, but uploading long video files may take too much time, we suggest to convert your video file to mp3 or wav to upload your files much faster. You can also upload your files from Youtube or your Cloud Storage (Google Drive, One Drive or Dropbox). To upload from Cloud or Youtube, you only need to share a public link of your file or video, so that you don't have to wait to upload."
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <span class="collapse-icon fa fa-angle-double-right"></span>
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    {t("Is there a recording duration limit?")}
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <p>
                      {t(
                        "Yes, Trial accounts can transcribe the first 5 minutes of their audio files whereas paid accounts can transcribe up to 6 hour length recordings but recordings longer than 3 hours are divided into two parts."
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <span class="collapse-icon fa fa-angle-double-right"></span>
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    {t(
                      "What is the difference between Standard, Subtitle and Speaker Seperated?"
                    )}
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <ul>
                      <li>
                        <p class="medium">{t("Standard:")}</p>
                        <p>
                          {t(
                            "The most popular transcription solution, it has high accuracy and consistent results for any transcription task, great for meetings and self recordings."
                          )}
                        </p>
                      </li>
                      <li>
                        <p class="medium">{t("Subtitle:")}</p>
                        <p>
                          {t(
                            "A custom solution to transcribe subtitles. It provides shorter chunks of text and provides SRT format.(You need to be paid member to download in SRT format)"
                          )}
                        </p>
                      </li>
                      <li>
                        <p class="medium">{t("Speaker Seperated (Beta):")}</p>
                        <p>
                          {t(
                            "Our custom model separates transcription results by people. If you need to know who said what, you could try this solution. Note that results are better with lower number of speakers and speakers with different voice tones e.g. male and female. If the speakers have very similar voices the result may be worse than Standard transcription."
                          )}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <span class="collapse-icon fa fa-angle-double-right"></span>
                <h2 class="accordion-header" id="flush-headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    {t("Which file formats do you support?")}
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <p>
                      {t(
                        "We support several audio and video formats. Supported file formats are MP3, MP4, WAV, AAC, M4A, WEBM, FLAC, OPUS, AVI, M4V, MPEG, MOV, OGV, MPG, WMV, OGM, OGG, AU, WMA, AIFF, OGA."
                      )}
                    </p>
                    <p>
                      {t(
                        "If you have different format please use any media converter tool to convert your file to supported format. Please ensure that you keep high audio quality."
                      )}
                    </p>
                    <p>
                      {t(
                        "Here are several online media conversion tools you may use:"
                      )}
                    </p>
                    <ul>
                      <li>
                        <p class="mb-2">
                          <a
                            target="_blank"
                            href="https://convertio.co"
                            class="theme-color underline"
                          >
                            https://convertio.co
                          </a>
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <a
                            target="_blank"
                            href="https://cloudconvert.com"
                            class="theme-color underline"
                          >
                            https://cloudconvert.com
                          </a>
                        </p>
                      </li>
                      <li>
                        <p class="mb-2">
                          <a
                            target="_blank"
                            href="https://online-audio-converter.com"
                            class="theme-color underline"
                          >
                            https://online-audio-converter.com
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <span class="collapse-icon fa fa-angle-double-right"></span>
                <h2 class="accordion-header" id="flush-headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    {t("How can I convert Youtube videos to text?")}
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <p class="medium">{t("Upload via link:")}</p>
                    <ul>
                      <li>
                        <p>{t("Click 'Convert Audio' button")}</p>
                        <div class="img-shadow-box mb-3">
                          <img src={uploadBox} class="img-fluid" alt="img" />
                        </div>
                      </li>
                      <li>
                        <p>
                          {t("Paste the url of Youtube video")}
                          <br></br>
                          {t(
                            "Please make sure that the link or file is public"
                          )}
                        </p>
                        <div class="img-shadow-box mb-3">
                          <img src={fileUrl} class="img-fluid" alt="img" />
                        </div>
                      </li>
                      <li>
                        <p>
                          {t(
                            "Select the language spoken in the video or audio recording."
                          )}
                        </p>
                        <div class="img-shadow-box mb-3">
                          <img src={languagePng} class="img-fluid" alt="img" />
                        </div>
                      </li>
                      <li>
                        <p>{t("Select service")}</p>
                        <div class="img-shadow-box mb-3">
                          <img src={servicePng} class="img-fluid" alt="img" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <span class="collapse-icon fa fa-angle-double-right"></span>
                <h2 class="accordion-header" id="flush-headingSix">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSix"
                    aria-expanded="false"
                    aria-controls="flush-collapseSix"
                  >
                    {t(
                      "How can I convert my audio or video files in my Google Drive, OneDrive, or Dropbox accounts to text?"
                    )}
                  </button>
                </h2>
                <div
                  id="flush-collapseSix"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingSix"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <p class="medium">{t("Upload via link:")}</p>
                    <ul>
                      <li>
                        <p>{t("Click 'Convert Audio' button")}</p>
                        <div class="img-shadow-box mb-3">
                          <img src={uploadBox} class="img-fluid" alt="img" />
                        </div>
                      </li>
                      <li>
                        <p>
                          {t("Paste the url of Youtube video")}
                          <br></br>P
                          {t("lease make sure that the link or file is publi")}c
                        </p>
                        <div class="img-shadow-box mb-3">
                          <img src={fileUrl} class="img-fluid" alt="img" />
                        </div>
                      </li>
                      <li>
                        <p>
                          {t(
                            "Select the language spoken in the video or audio recording."
                          )}
                        </p>
                        <div class="img-shadow-box mb-3">
                          <img src={languagePng} class="img-fluid" alt="img" />
                        </div>
                      </li>
                      <li>
                        <p>{t("Select service")}</p>
                        <div class="img-shadow-box mb-3">
                          <img src={servicePng} class="img-fluid" alt="img" />
                        </div>
                      </li>
                    </ul>
                    <p class="medium">
                      {t("How to make a public Google Drive file?")}
                    </p>
                    <ul>
                      <li>
                        <p>
                          {t(
                            "Go to Google Drive, open the file, and click the share button in the upper right."
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t(
                            "Click General Access and then choose 'Anyone with link' from the dropdown menu"
                          )}
                        </p>
                      </li>
                    </ul>
                    <div class="img-shadow-box mb-3">
                      <img src={googleDriveFile} class="img-fluid" alt="img" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <span class="collapse-icon fa fa-angle-double-right"></span>
                <h2 class="accordion-header" id="flush-headingSeven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseSeven"
                    aria-expanded="false"
                    aria-controls="flush-collapseSeven"
                  >
                    {t("How can I improve transcription quality?")}
                  </button>
                </h2>
                <div
                  id="flush-collapseSeven"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingSeven"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <p class="medium">
                      {t(
                        "There are different reasons that are affect transcription quality:"
                      )}
                    </p>
                    <p class="medium">{t("Heavy background noise")}</p>
                    <p>
                      {t(
                        "This is by far the biggest enemy of high-quality transcription. As far as possible, try indoor or in-studio recording, as noise editing is quite an arduous task. If you have background noise, firstly eliminate it."
                      )}
                    </p>
                    <p>
                      {t(
                        "Platforms like Audacity, Adobe Audition, and AVID have tools to help, but it's not a sure fix. Audacity is a free open-source audio recording and editing application."
                      )}{" "}
                    </p>
                    <ul>
                      <li>
                        <p>
                          {t(
                            "Open Audacityteam.org/download, click “File” and “Open” and locate the file you want to fix"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t(
                            "Select your “Room Tone” by dragging your mouse over a section where there is little or no audio sound"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t(
                            "Select the “Effect” menu and then select “Noise Reduction”"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t(
                            "Click “Get Noise Profile” and wait for the modal to disappear"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t(
                            "Click back to your timeline of audio to clear your selection"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t(
                            "Select the “Effect” menu again and select “Noise Reduction” again"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t(
                            "Click “Preview” to hear the default noise removal settings. You can select one of these defaults to see if they work"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          {t(
                            "If you still hear noise, then simply adjust the Sliders (see descriptions below) and then click “Preview” again"
                          )}
                        </p>
                      </li>
                    </ul>
                    <p>{t("Keep the microphone close to the speaker")}</p>
                    <p>
                      {t(
                        "The closer the microphone is to the speaker, the better. Enunciating the words well will ensure better sound fidelity."
                      )}
                    </p>
                    <p class="medium">{t("Skype and phone interviews")}</p>
                    <p>
                      {t(
                        "These typically have very low recording quality due to the compression used in these services. You may encounter lower accuracy with these recordings."
                      )}
                    </p>
                    <p class="medium">{t("Heavy accents")}</p>
                    <p>
                      {t(
                        "Our models are trained in a lot of data, but heavy accents are one of the most difficult things to transcribe!"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Uploader;

// s3 upload function for files smaller than 5 mb
export const uploadToS3 = async (presignedUrl, file, onUploadProgress) => {
  try {
    const result = await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onUploadProgress(percentCompleted);
      },
    });
    return result;
  } catch (error) {
    throw error;
  }
};
