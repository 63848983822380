import React, { useEffect, useState } from 'react';
import { Link, redirect, useInRouterContext, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from '../../Auth/AuthContext';
import profilePng from "../../assets/img/profile.png";
import { useModal } from '../common/Modal/ModalContext';
import { useTranslation } from '../common/LanguageProvider';
import { fetchData } from '../../hooks/apiUtils';
import Gleap from 'gleap';
import { subscription_aware } from '../../ampli/index';
import { getFlagVariant } from '../Paddle/ampliExpFlag';
function Account({isCompleted}) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const {currentUser, userData, hashedId} = useAuth();
    const [status, setStatus] = useState('');
    const [name, setName] = useState('');
    const [photoUrl, setPhotoUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingUpgrade, setIsLoadingUpgrade] = useState(false);
    const location = useLocation();
    const {showModal} = useModal();
    const navigate = useNavigate();
    const [subtype, setSubtype] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const isChrome = window.navigator.userAgent.includes("Chrome")
    const [expVariant, setExpVariant] = useState(null);
    useEffect(() => {
        getMinutes();
    }, [location.pathname]);
    const getMinutes = async () => {
        fetch("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId).then(res => res.json()).then(data => {
            setStatus(data.SStatus);
            setName(currentUser.displayName ? currentUser.displayName : currentUser.email.split('@')[0]);
            setPhotoUrl(currentUser.photoURL ? currentUser.photoURL : profilePng);
            setSubtype(data?.Subscription?.Subscription_Type || null)
            setMinutes(data?.Minutes)
        
        })
    };
    const subtypes = {
        "Lite": ("300 minutes/month"),
        "Premium": "2400 minutes/month",
        "100-hours": "100 hours/month",
        "250-hours": "250 hours/month",
        "500-hours": "500 hours/month",
        "1000-hours": "1000 hours/month",
        "Contact Us":"Contact Us",
        "Lite (Annual)": "300 minutes/month",
        "Premium (Annual)": "2400 minutes/month",
        "100-hours (Annual)": "100 hours/month",
        "250-hours (Annual)": "250 hours/month",
        "500-hours (Annual)": "500 hours/month",
        "1000-hours (Annual)": "1000 hours/month",
        

    }
    useEffect(() => {
        if(userData && currentUser){
            setStatus(userData.SStatus);
            setName(currentUser.displayName ? currentUser.displayName : currentUser.email.split('@')[0]);
            setPhotoUrl(currentUser.photoURL ? currentUser.photoURL : profilePng);
            setSubtype(userData?.Subscription?.Subscription_Type || null)
            setMinutes(userData?.Minutes)
            setIsLoading(false);
        }
    }, [userData]);
    const link = "/payment"
    const getFlagVariantfunc = async(hashedId)=>{
        const user = {
            user_id: hashedId,
        }
        const flagKey = 'paddle-integration';
        const variantValue = await getFlagVariant(flagKey, user);
        if (variantValue === 'control') {
            console.log('Flag is control');
          } else {
            console.log('Flag is off', variantValue);
            
          }
          setExpVariant(variantValue);
    }
    const handleClick = () => {
        if(subtype && status === "Active"){
            if(expVariant === "paddle-experiment" && userData && !userData.Mail.includes('.edu')){
                navigate("/payment?type=sidebar_upgrade_button")
            }else{
                redirect(getNextSubtype(subtype))
                subscription_aware(userData, currentUser, "sidebar_upgrade_button")
            }
        }
        else if(location.pathname.startsWith("/editor") || location.pathname.startsWith("/sharing") && isCompleted){
            showModal("CONFIRM-EDITOR", {link}, {onLeaveEditor})
        }else{
            
            if(subtype && status === "Active"){
                navigate("/payment?type=sidebar_upgrade_button")

            }else{
                
                subscription_aware(userData, currentUser, "sidebar_upgrade_button")
                navigate("/payment?type=sidebar_upgrade_button")
            }

        }
    }
    const onLeaveEditor = (link) => {
        if(subtype){
            redirect(getNextSubtype(subtype))
        }else{
            navigate(link)
            
        }
    }
    useEffect(() => {
        if(hashedId)
            getFlagVariantfunc(hashedId);
    }, [hashedId]);
    const redirectCustomerPortal = async () => {
        setIsLoadingUpgrade(true);

        fetch("https://gf5c4w9xq7.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-Purchase-CreateCustomerPortal-v2?cid=" + hashedId).then(res => res.json()).then(data => {
            const url = data["redirectUrl"];
            if(isChrome){
                window.open(url, "_blank");
            }else{
                window.location.href = url;
            }

        })
        setIsLoadingUpgrade(false);

    }
    const redirect = async (subtype) => {
        redirectCustomerPortal();
        return;
        if(subtype === "Contact Us"){
            Gleap.startFeedbackFlow('gvmfu')
        }else{
            setIsLoadingUpgrade(true);
            var redirectUrl = "https://xkb9tcw6qe.execute-api.eu-central-1.amazonaws.com/default/AA-API-RedirectCheckoutPage?cid=" + hashedId;
            redirectUrl = redirectUrl + "&product=" + subtype;
            const response = await fetchData(redirectUrl)
            const url = response["redirectUrl"];
            window.open(url, "_blank");
            setIsLoadingUpgrade(false);
        }

    }
    function getNextSubtype(currentSubtype) {
        
    
        const keys = Object.keys(subtypes);
        const currentIndex = keys.indexOf(currentSubtype);
        
        if (currentIndex === -1) {
            console.error("Invalid subtype provided");
            return null;
        } else if (currentIndex === keys.length - 1) {
            console.error("No subtype after the provided one");
            return null;
        } else {
            return keys[currentIndex + 1];
        }
    }
    
    return (
             
        <div className="mt-2">
        <div className="upgrade-sidebar-box">
            {isLoading ? (
                <div className='row'style={{ display: isLoading ? "block" : "none" }}>
				<div className='d-flex justify-content-center'>
					<div className="spinner-border text-primary" role="status">

					</div>
				</div>


			</div>
            ): (
                <div className="d-flex align-items-center mb-1">
                <span className="me-2">
                    <img src={photoUrl} className="img-fluid upgrade-profile" alt="icon" />
                </span>
                <span>
                    {name}<br />

                    <span className="light-color subtype-span">{t(subtypes[subtype] || status)}</span><br/>
                    <span className="light-color subtype-span">{t("Minutes:")} {minutes}</span>

                </span>
            </div>
            )}
            <div className='row'style={{ display: isLoadingUpgrade ? "block" : "none" }}>
				<div className='d-flex justify-content-center'>
					<div className="spinner-border text-primary" role="status">

					</div>
				</div>


			</div>
            <button onClick={handleClick} className="btn theme-btn w-100">
                <span className="btn-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                    </svg>
                </span>
                {t("Upgrade")}
            </button>
        </div>
    </div>
           
    );
}

export default Account;