// Payment.js
import React, { useEffect, useState } from "react";

import offerSvg from "../assets/img/icon/offer.svg";
import logoTranskriptor from "../assets/img/icon/logo-transkriptor.png";
import logoSpeaktor from "../assets/img/icon/logo-speaktor.png";
import logoEskritor from "../assets/img/icon/logo-eskritor.png";
import { useAuth } from "../Auth/AuthContext";
import { showToast } from "../components/common/toast";

import { fetchData, fetchDataPost } from "../hooks/apiUtils";
import Gleap from "gleap";
import playStorePng from "../assets/img/icon/playstore.png";
import appStorePng from "../assets/img/icon/appstore.png";
import qrCodePng from "../assets/img/icon/qr-code.png";
import promoCodePng from "../assets/img/upgrade/promocode.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "../components/common/LanguageProvider";
import { subscription_evaluate, subscription_checkout } from "../ampli/index";
import { initializePaddle } from "@paddle/paddle-js";
import { useModal } from "../components/common/Modal/ModalContext";
import { fetchUserVariants, getUser } from "../components/Paddle/ampliExpFunc";
import { getFlagVariant } from "../components/Paddle/ampliExpFlag";
import countryList from "react-select-country-list";

function Payment({ handleOpenSidebar }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { t } = useTranslation();
  // To retrieve a specific query parameter:
  const uid = queryParams.get("uid");
  const redirectProduct = queryParams.get("product");
  const event_type = queryParams.get("type");
  const coupon = queryParams.get("coupon");
  const { hashedId, signInWithToken, userData, currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [product, setProduct] = useState(null);
  const [active, setActive] = useState(true);
  const [businessActive, setBusinessActive] = useState(true);
  const [packageValue, setPackageValue] = useState("250");
  const [packagePrice, setPackagePrice] = useState("75");
  const [annualPrice, setAnnualPrice] = useState("900");
  const [businessPrice, setBusinessPrice] = useState(75);
  const [counterValue, setCounterValue] = useState(5);
  const [eduDiscount, setEduDiscount] = useState(false);
  const isChrome = window.navigator.userAgent.includes("Chrome");
  const navigate = useNavigate();
  const [paddle, setPaddle] = useState();
  const [subscriptionSource, setSubscriptionSource] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const { showModal } = useModal();
  const [expVariant, setExpVariant] = useState(null);
  // Download and initialize Paddle instance from CDN
  useEffect(() => {
    initializePaddle({ token: "live_5222b7f97a4e4b00a6139ab18ef" }).then(
      (paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      }
    );
  }, []);
  const getUserSubscription = async () => {
    const response = await fetchData(
      "https://44iypa7tq6.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-Purchase-ReturnPricingPage-v2?cid=" +
        hashedId
    );
    setStatus(response["mycase"]);
  };
  useEffect(() => {
    if (hashedId) {
      getUserSubscription();
      getFlagVariantfunc(hashedId);
    }

    setStatus(null);
    setProduct(null);
  }, [hashedId]);
  const getFlagVariantfunc = async (hashedId) => {
    const user = {
      user_id: hashedId,
    };
    const flagKey = "paddle-integration";
    const variantValue = await getFlagVariant(flagKey, user);
    if (variantValue === "control") {
      console.log("Flag is control");
    } else {
      console.log("Flag is off", variantValue);
    }
    setExpVariant(variantValue);
  };
  function setSubscriptionTypeFunc(userData) {
    let subscriptionType = null;

    if (
      userData.SStatus === "Active" &&
      userData.Subscription &&
      userData.Subscription.Subscription_Type
    ) {
      if (userData.Subscription.Interval === "year") {
        subscriptionType = userData.Subscription.Subscription_Type + "-Annual";
      } else {
        subscriptionType = userData.Subscription.Subscription_Type;
      }
    }

    setSubscriptionType(subscriptionType);
  }
  useEffect(() => {
    if (event_type && userData && currentUser) {
      subscription_evaluate(userData, currentUser, event_type);
    }
    if (userData && userData.Mail) {
      if (userData.Mail.includes(".edu") && userData.SStatus !== "Active") {
        setEduDiscount(true);
      }
      setSubscriptionSource(
        userData.Subscription ? userData.Subscription.Source : null
      );
      setSubscriptionTypeFunc(userData);
    }
    if (coupon) {
      setEduDiscount(true);
    }
  }, [userData, currentUser]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      let toggles = document.querySelectorAll(".btn-toggle");

      if (toggles.length > 0) {
        // if toggles are found
        for (let i = 0; i < toggles.length; i++) {
          toggles[i].style.setProperty(
            "--before-content",
            '"' + t("Monthly") + '"'
          );
          toggles[i].style.setProperty(
            "--after-content",
            '"' + t("Annual") + '"'
          );
        }
        clearInterval(intervalId); // Clear the interval once the effect has been applied
      }
    }, 100); // You can adjust the interval duration

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const redirectCheckout = async (e) => {
    e.stopPropagation();

    if (!userData || !hashedId || !status) {
      await retryRedirectCheckout(e, 10);
      return;
    }

    if (status !== "Portal") {
      //setIsLoading(true);
      console.log(e.currentTarget.getAttribute("data-value"));
      var product = e.currentTarget.getAttribute("data-value");
      var redirectUrl =
        "https://1s8r2nknq4.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-Purchase-RedirectCheckoutPage-v2?cid=" +
        hashedId +
        "&coupon=" +
        coupon;
      if (product.includes("Business")) {
        redirectUrl = redirectUrl + "&quantity=" + counterValue;
      }
      setProduct(product);
      redirectUrl = redirectUrl + "&product=" + product;

      if (
        !product.includes("Business") &&
        expVariant === "paddle-experiment" &&
        userData &&
        !userData.Mail.includes(".edu")
      ) {
        var level = getSubscriptionComparison(product, subscriptionType);
        if (level === "Disabled" || level === "Current Plan") {
          if (level === "Current Plan")
            showToast(t("You already have this subscription!"), "error");
          if (level === "Disabled")
            showToast(t("You can not upgrade to this subscription!"), "error");
          return;
        } else if (level === "Upgrade" || level === "Get started") {
          openCheckout(product);
        }
      } else {
        const response = await fetchData(redirectUrl);
        const url = response["redirectUrl"];
        setIsLoading(false);
        if (isChrome) {
          window.open(url, "_blank");
        } else {
          window.location.href = url;
        }
      }

      let temp_type = product;
      let interval = "Monthly";
      if (product.includes("Business")) {
        temp_type = temp_type + "-" + counterValue;
      }
      if (temp_type.includes("Annual")) {
        temp_type = temp_type.replace("-Annual", "");
        interval = "Annually";
      }
      subscription_checkout(
        userData,
        currentUser,
        temp_type,
        interval,
        "get_started_button"
      );
    } else {
      redirectCustomerPortal();
    }
  };

  const retryRedirectCheckout = async (e, retries) => {
    for (let i = 0; i < retries; i++) {
      if (userData && hashedId && status) {
        redirectCheckout(e);
        return;
      }
      await new Promise((resolve) => setTimeout(resolve, 5000));
    }
    console.log("Failed to retrieve userData or hashedId after 5 retries");
  };

  const redirectCustomerPortal = async () => {
    fetch(
      "https://gf5c4w9xq7.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-Purchase-CreateCustomerPortal-v2?cid=" +
        hashedId
    )
      .then((res) => res.json())
      .then((data) => {
        const url = data["redirectUrl"];
        if (isChrome) {
          window.open(url, "_blank");
        } else {
          window.location.href = url;
        }
      });
  };

  const changeOffers = async (e) => {
    const isActive = e.currentTarget.classList.contains("active");
    if (isActive) {
      console.log("Button is active");
      // Handle active state
      setActive(true);
    } else {
      console.log("Button is not active");
      // Handle inactive state
      setActive(false);
    }
  };
  const changeOffersBusiness = async (e) => {
    const isActive = e.currentTarget.classList.contains("active");
    if (isActive) {
      console.log("Button is active");
      // Handle active state
      setBusinessActive(true);
      setPackagePrice(packagePrice / 2);
    } else {
      console.log("Button is not active");
      // Handle inactive state
      setBusinessActive(false);
      setPackagePrice(packagePrice * 2);
      setAnnualPrice(packagePrice * 12);
    }
  };

  const handlePackageChange = async (e) => {
    var value = e.target.value;
    var priceRate = businessActive ? 0.3 : 0.6;
    var price = value * priceRate;
    setPackageValue(value);

    if (value !== "specialOffer") {
      setPackagePrice(price);
      setAnnualPrice(price * 12);
    } else {
    }
    console.log(value);
  };
  const MIN_VALUE = 1;
  const MAX_VALUE = 20;

  function incrementCounter() {
    if (counterValue < MAX_VALUE) {
      const newValue = counterValue + 1;
      setCounterValue(newValue);
      setBusinessPrice(newValue * 15);
    }
  }

  function decrementCounter() {
    if (counterValue > MIN_VALUE) {
      const newValue = counterValue - 1;
      setCounterValue(newValue);
      setBusinessPrice(newValue * 15);
    }
  }

  function handleInputChange(e) {
    const value = parseInt(e.target.value, 10);

    if (value >= MIN_VALUE && value <= MAX_VALUE) {
      setCounterValue(value);
      setBusinessPrice(value * 15);
    }
  }

  useEffect(() => {
    if (redirectProduct) {
      handleRedirect();
    } else if (!redirectProduct && uid) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [uid]);

  const automateLogin = async (uid) => {
    setLoading(true);
    const response = await fetch(
      "https://hg0761tbmf.execute-api.eu-central-1.amazonaws.com/default/automateLogin?hashedId=" +
        uid
    );

    if (response.status === 200) {
      const data = await response.json();
      console.log(data);
      console.log(data["customToken"]); // Here you should see your token or any other data sent from Lambda
      const token = data["customToken"];
      await signInWithToken(token);
      setLoading(false);
      if (!redirectProduct) {
        var url = window.location.origin + window.location.pathname;
        if (coupon) {
          url = url + "?coupon=" + coupon;
        }
        window.location.href = url;
      }
    } else {
      console.log("Error:", response.status, response.statusText);
    }

    console.log("kek");
  };
  const handleRedirect = async () => {
    //attığımız maillerde hashed id alıyorsak bu şekilde yapalım yoksa mail ile de yapabilirim.
    let url =
      "https://4jjx377n73.execute-api.eu-central-1.amazonaws.com/default/redirectFlow?product=" +
      redirectProduct;
    if (uid) {
      url = url + "&hashedId=" + uid;
    } else if (hashedId) {
      url = url + "&hashedId=" + hashedId;
    }
    fetch(url)
      .then((response) => response.json())
      .then(function (data) {
        console.log(data);
        console.log(data["redirectUrl"]);
        console.log("kek");
        if (uid) automateLogin(uid);

        setTimeout(function () {
          window.location.href = data["redirectUrl"];
        }, 3000);
      });
  };
  const subscriptionLevels = {
    Lite: 1,
    Premium: 2,
    "Lite-Annual": 3,
    "100-Hours": 4,
    "Premium-Annual": 5,
    "250-Hours": 6,
    "500-Hours": 7,
    "1000-Hours": 8,
    "100-Hours-Annual": 9,
    "250-Hours-Annual": 10,
    "500-Hours-Annual": 11,
    "1000-Hours-Annual": 12,
  };

  function getSubscriptionComparison(selectedPackage, userPackage) {
    const userLevel = subscriptionLevels[userPackage] || 0;
    const selectedLevel = subscriptionLevels[selectedPackage] || 0;
    if (expVariant !== "paddle-experiment") {
      return "Get started";
    } else if (!userPackage || subscriptionSource !== "Paddle" || !userLevel) {
      return "Get started";
    } else if (selectedLevel > userLevel) {
      return "Upgrade";
    } else if (selectedLevel === userLevel) {
      return "Current Plan";
    } else {
      return "Disabled";
    }
  }

  const lite = "pro_01j2dp9skpjs2r3ybmsd3xvw9f";
  const lite_annual = "pri_01j2dq9dfp2s463h3tgzs1kb4k";

  // define items
  let itemsList = [
    {
      priceId: lite,
      quantity: 1,
    },
    {
      priceId: lite_annual,
      quantity: 1,
    },
  ];
  const onUpgradePaddlePlan = async (hashed_id, price_id) => {
    const url =
      "https://8b3h5c5qz4.execute-api.eu-central-1.amazonaws.com/v1/paddle/upgrade-subscription";
    const payload = {
      hashed_id: hashed_id,
      price_id: price_id,
    };
    const response = await fetchDataPost(url, payload);
    showToast(t("Subscription upgraded successfully"), "success");
  };
  async function openCheckout(product) {
    const products = {
      Lite: {
        priceId: "pri_01j2dq9ctq3mnc4q4adm5bcxs7",
        quantity: 1,
      },
      Premium: {
        priceId: "pri_01j2dq9e4v44t76d2b19tppsr9",
        quantity: 1,
      },
      "Lite-Annual": {
        priceId: "pri_01j2dq9dfp2s463h3tgzs1kb4k",
        quantity: 1,
      },
      "Premium-Annual": {
        priceId: "pri_01j2dq9etrehvapvvge7cvgfta",
        quantity: 1,
      },
      "100-Hours": {
        priceId: "pri_01j2dq9ftw6shahq0e1322tcy1",
        quantity: 1,
      },
      "100-Hours-Annual": {
        priceId: "pri_01j2dq9gkmtwpacan2hz3fpyzx",
        quantity: 1,
      },
      "250-Hours": {
        priceId: "pri_01j2dq9hdwv1mhhq9ss2gv0hwg",
        quantity: 1,
      },
      "250-Hours-Annual": {
        priceId: "pri_01j2dq9j772zwghatx0sj4hsxv",
        quantity: 1,
      },
      "500-Hours": {
        priceId: "pri_01j2dq9jvj1nv6gbq4eakn08m4",
        quantity: 1,
      },
      "500-Hours-Annual": {
        priceId: "pri_01j2dq9kp0j7dbvfk4ra4xsvag",
        quantity: 1,
      },
      "1000-Hours": {
        priceId: "pri_01j2dq9mc1yw95dkmr317v2ygt",
        quantity: 1,
      },
      "500-Hours-Annual": {
        priceId: "pri_01j2dq9mztr4jq3aazn7nzxggx",
        quantity: 1,
      },
    };

    var country_code = null;
    if (userData.Country !== "Türkiye")
      country_code = countryList().getValue(userData.Country);
    else {
      country_code = "TR";
    }
    // open checkout
    if (userData.SStatus === "Active") {
      showModal(
        "UPGRADE-PADDLE",
        { hashed_id: hashedId, price_id: products[product].priceId },
        { onUpgradePaddlePlan }
      );
      return;
    } else {
      paddle.Checkout.open({
        settings: {
          successUrl: "https://app.transkriptor.com/payment-received",
          allowLogout: false
        },
        items: [products[product]],
        customer: {
          email: userData.Mail,
          address: {
            countryCode: country_code,
          },
        },

      });
    }
  }

  return (
    <>
      {loading ? (
        <>
          <div className="row m-2">
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row justify-content-center ">
            <div className="col-12">
              <div class="card card-padding card-margin text-center">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="web-view"></div>
                  <span
                    class="me-2 mobile-view"
                    onClick={() => handleOpenSidebar()}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      class="text-black"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.75 12H20.25"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M3.75 6H20.25"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M3.75 18H20.25"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </span>
                  <h3 class="mini-title">{t("For Individuals")}</h3>
                  <div></div>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <div class="text-switch text-center my-4">
                    <button
                      type="button"
                      class="btn btn-toggle active"
                      data-bs-toggle="button"
                      aria-pressed="true"
                      autocomplete="off"
                      onClick={(e) => {
                        changeOffers(e);
                      }}
                    >
                      <div class="handle"></div>
                    </button>
                  </div>
                  <span class="offer-div">
                    <span class="me-1">
                      <img src={offerSvg} class="img-fluid" alt="img" />
                    </span>
                    {t("50% off")}
                  </span>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <span class="info-icon theme-color">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.376"
                      height="16.62"
                      viewBox="0 0 16.376 16.62"
                    >
                      <g id="info" transform="translate(-2.239 -2.244)">
                        <path
                          id="Path_124"
                          data-name="Path 124"
                          d="M11.229,2.284a8.073,8.073,0,0,1,4.977,2.4,8.382,8.382,0,0,1,.546,11.148A8.132,8.132,0,0,1,6.566,17.894a8.31,8.31,0,0,1-3.7-4.155,8.453,8.453,0,0,1-.273-5.592,8.31,8.31,0,0,1,3.288-4.5,8.073,8.073,0,0,1,5.352-1.364Zm.56,15.254a6.992,6.992,0,0,0,4.049-2.457,7.206,7.206,0,0,0-.475-9.569,6.909,6.909,0,0,0-8.821-.879A7.194,7.194,0,0,0,7.136,16.85a6.9,6.9,0,0,0,4.654.689ZM9.813,8.183H11.3V7H9.813ZM11.3,9.37v4.749H9.813V9.37Z"
                          transform="translate(0 0)"
                          fill="currentColor"
                          fill-rule="evenodd"
                        />
                      </g>
                    </svg>
                  </span>
                  <p class="text-start mb-0">
                    <i>
                      {t("Use your credits in")}{" "}
                      <span class="medium blue-logo-text">Transkriptor</span>,{" "}
                      <span class="medium pink-logo-text">Speaktor</span>{" "}
                      {t("and")}{" "}
                      <span class="medium yellow-logo-text">Eskritor</span>{" "}
                      {t("with only one payment")}
                    </i>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xxl-8 col-lg-10 col-12"></div>
            <div
              className="row m-2"
              style={{ display: isLoading ? "block" : "none" }}
            >
              <div className="d-flex justify-content-center">
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            </div>
            <div class="w-100"></div>
            {active ? (
              <>
                <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin ">
                  <div
                    class="card text-center card-100 payment-card"
                    data-value="Lite-Annual"
                    onClick={(e) => {
                      redirectCheckout(e);
                    }}
                  >
                    <div
                      class="theme-bg card-padding white-color"
                      style={{ display: eduDiscount ? "none" : "" }}
                    >
                      <div className="d-flex justify-content-center">
                        <h2 class="package-price mt-2 mb-1 ">$ 4.99</h2>
                        <p class="ft-14-title mb-4 align-self-end">
                          {t("/month")}
                        </p>
                      </div>
                      <p class="mb-2 ft-14">
                        {t("Annually Billed Amount: $59.95")}
                      </p>
                    </div>

                    <div
                      class="theme-bg card-padding white-color  edu-price"
                      style={{ display: eduDiscount ? "block" : "" }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <h2 class="package-price mt-2 mb-1">$2.49</h2>
                        &nbsp;
                        <div className="d-flex justify-content-center align-items-center">
                          <h5 class="">$4.99</h5>
                          <p class="ft-14-title align-self-end">
                            {t("/month")}
                          </p>
                        </div>
                      </div>
                      <p class="mb-2 ft-14">
                        {t("Annually Billed Amount: $29.99")}
                      </p>
                    </div>
                    <div class="card-padding">
                      <h3 class="medium-title">{t("Lite")}</h3>
                      <p className="mb-0 ft-18 payment-bold">
                        {t("300 minutes of transcription per month")}
                      </p>
                      <p class="mb-0">
                        {t("Usage reset every month, billed once per year")}
                      </p>
                      <div
                        className="row m-2"
                        style={{
                          display:
                            isLoading && product === "Lite-Annual"
                              ? "block"
                              : "none",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <button
                        class="btn theme-btn my-4 payment-btn"
                        data-value="Lite-Annual"
                        onClick={(e) => {
                          redirectCheckout(e);
                        }}
                        disabled={
                          getSubscriptionComparison(
                            `Lite-Annual`,
                            subscriptionType
                          ) === "Current Plan" ||
                          getSubscriptionComparison(
                            `Lite-Annual`,
                            subscriptionType
                          ) === "Disabled"
                            ? true
                            : false
                        }
                      >
                        {t(
                          getSubscriptionComparison(
                            "Lite-Annual",
                            subscriptionType
                          )
                        )}
                        <span class="ms-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-arrow-right-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                            />
                          </svg>
                        </span>
                      </button>
                      <div class="mt-1">
                        <img
                          src={logoTranskriptor}
                          class="img-fluid mb-2"
                          alt="img"
                        />
                        <p class="mb-3">
                          {t("300 minutes long transcription")}
                        </p>
                        <img
                          src={logoSpeaktor}
                          class="img-fluid mb-2"
                          alt="img"
                        />
                        <p class="mb-3">
                          {t("300 minutes long speech synthesis")}
                        </p>
                        <img
                          src={logoEskritor}
                          class="img-fluid mb-2"
                          alt="img"
                        />
                        <p class="mb-0">{t("300 AI generated content")}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
                  <div
                    class="card text-center card-100  payment-card"
                    data-value="Premium-Annual"
                    onClick={(e) => {
                      redirectCheckout(e);
                    }}
                  >
                    <div
                      class="theme-bg card-padding white-color"
                      style={{ display: eduDiscount ? "none" : "" }}
                    >
                      <div className="d-flex justify-content-center">
                        <h2 class="package-price mt-2 mb-1 ">$ 12.49</h2>
                        <p class="ft-14-title mb-4 align-self-end">
                          {t("/month")}
                        </p>
                      </div>

                      <p class="mb-2 ft-14">
                        {t("Annually Billed Amount: $149.95")}
                      </p>
                    </div>
                    <div
                      class="theme-bg card-padding white-color  edu-price"
                      style={{ display: eduDiscount ? "block" : "" }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <h2 class="package-price mt-2 mb-1">$6.25</h2>
                        &nbsp;
                        <div className="d-flex justify-content-center align-items-center">
                          <h5 class="">$12.49</h5>
                          <p class="ft-14-title align-self-end">
                            {t("/month")}
                          </p>
                        </div>
                      </div>
                      <p class="mb-2 ft-14">
                        {t("Annually Billed Amount: $74.99")}
                      </p>
                    </div>
                    <div class="card-padding">
                      <h3 class="medium-title">Premium</h3>
                      <p className="mb-0 ft-18 payment-bold">
                        {t("2400 minutes of transcription per month")}
                      </p>
                      <p class="mb-0">
                        {t("Usage reset every month, billed once per year")}
                      </p>

                      <div
                        className="row m-2"
                        style={{
                          display:
                            isLoading && product === "Premium-Annual"
                              ? "block"
                              : "none",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <button
                        class="btn theme-btn my-4 payment-btn"
                        data-value="Premium-Annual"
                        onClick={(e) => {
                          redirectCheckout(e);
                        }}
                        disabled={
                          getSubscriptionComparison(
                            `Premium-Annual`,
                            subscriptionType
                          ) === "Current Plan" ||
                          getSubscriptionComparison(
                            `Premium-Annual`,
                            subscriptionType
                          ) === "Disabled"
                            ? true
                            : false
                        }
                      >
                        {t(
                          getSubscriptionComparison(
                            "Premium-Annual",
                            subscriptionType
                          )
                        )}
                        <span class="ms-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-arrow-right-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                            />
                          </svg>
                        </span>
                      </button>
                      <div class="mt-1">
                        <img
                          src={logoTranskriptor}
                          class="img-fluid mb-2"
                          alt="img"
                        />
                        <p class="mb-3">
                          {t("2400 minutes long transcription")}
                        </p>
                        <img
                          src={logoSpeaktor}
                          class="img-fluid mb-2"
                          alt="img"
                        />
                        <p class="mb-3">
                          {t("2400 minutes long speech synthesis")}
                        </p>
                        <img
                          src={logoEskritor}
                          class="img-fluid mb-2"
                          alt="img"
                        />
                        <p class="mb-0">{t("2400 AI generated content")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
                  <div
                    class="card text-center card-100  payment-card"
                    data-value="Lite"
                    onClick={(e) => {
                      redirectCheckout(e);
                    }}
                  >
                    <div
                      class="theme-bg card-padding white-color"
                      style={{ display: eduDiscount ? "none" : "" }}
                    >
                      <h2 class="package-price mt-2 mb-3">$9.99</h2>
                    </div>
                    <div
                      class="theme-bg card-padding white-color edu-price"
                      style={{ display: eduDiscount ? "block" : "" }}
                    >
                      <div className=" d-flex justify-content-center align-items-center">
                        <h2 class="package-price mt-2 mb-3">$4.99</h2>
                        &nbsp;
                        <h5 class="mt-2 mb-3">$9.99</h5>
                      </div>
                    </div>
                    <div class="card-padding">
                      <h3 class="medium-title">Lite</h3>
                      <p className="mb-0 ft-18 payment-bold">
                        {t("300 minutes of transcription")}
                      </p>
                      <p class="mb-0">{t("Usage reset every month")}</p>
                      <div
                        className="row m-2"
                        style={{
                          display:
                            isLoading && product === "Lite" ? "block" : "none",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <button
                        class="btn theme-btn my-4 payment-btn"
                        data-value="Lite"
                        onClick={(e) => {
                          redirectCheckout(e);
                        }}
                        disabled={
                          getSubscriptionComparison(
                            `Lite`,
                            subscriptionType
                          ) === "Current Plan" ||
                          getSubscriptionComparison(
                            `Lite`,
                            subscriptionType
                          ) === "Disabled"
                            ? true
                            : false
                        }
                      >
                        {t(getSubscriptionComparison("Lite", subscriptionType))}
                        <span class="ms-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-arrow-right-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                            />
                          </svg>
                        </span>
                      </button>
                      <div class="mt-1">
                        <img
                          src={logoTranskriptor}
                          class="img-fluid mb-2"
                          alt="img"
                        />
                        <p class="mb-3">
                          {t("300 minutes long transcription")}
                        </p>
                        <img
                          src={logoSpeaktor}
                          class="img-fluid mb-2"
                          alt="img"
                        />
                        <p class="mb-3">
                          {t("300 minutes long speech synthesis")}
                        </p>
                        <img
                          src={logoEskritor}
                          class="img-fluid mb-2"
                          alt="img"
                        />
                        <p class="mb-0">{t("300 AI generated content")}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
                  <div
                    class="card text-center card-100  payment-card"
                    data-value="Premium"
                    onClick={(e) => {
                      redirectCheckout(e);
                    }}
                  >
                    <div
                      class="theme-bg card-padding white-color"
                      style={{ display: eduDiscount ? "none" : "" }}
                    >
                      <h2 class="package-price mt-2 mb-3">$ 24.99</h2>
                    </div>
                    <div
                      class="theme-bg card-padding white-color edu-price"
                      style={{ display: eduDiscount ? "block" : "" }}
                    >
                      <div className=" d-flex justify-content-center align-items-center">
                        <h2 class="package-price mt-2 mb-3">$12.49</h2>
                        &nbsp;
                        <h5 class="mt-2 mb-3">$24.99</h5>
                      </div>
                    </div>
                    <div class="card-padding">
                      <h3 class="medium-title">Premium</h3>
                      <p className="mb-0 ft-18 payment-bold">
                        {t("2400 minutes of transcription")}
                      </p>
                      <p class="mb-0">{t("Usage reset every month")}</p>
                      <div
                        className="row m-2"
                        style={{
                          display:
                            isLoading && product === "Premium"
                              ? "block"
                              : "none",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <button
                        class="btn theme-btn my-4 payment-btn"
                        data-value="Premium"
                        onClick={(e) => {
                          redirectCheckout(e);
                        }}
                        disabled={
                          getSubscriptionComparison(
                            `Premium`,
                            subscriptionType
                          ) === "Current Plan" ||
                          getSubscriptionComparison(
                            `Premium`,
                            subscriptionType
                          ) === "Disabled"
                            ? true
                            : false
                        }
                      >
                        {t(
                          getSubscriptionComparison("Premium", subscriptionType)
                        )}
                        <span class="ms-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-arrow-right-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                            />
                          </svg>
                        </span>
                      </button>
                      <div class="mt-1">
                        <img
                          src={logoTranskriptor}
                          class="img-fluid mb-2"
                          alt="img"
                        />
                        <p class="mb-3">
                          {t("2400 minutes long transcription")}
                        </p>
                        <img
                          src={logoSpeaktor}
                          class="img-fluid mb-2"
                          alt="img"
                        />
                        <p class="mb-3">
                          {t("2400 minutes long speech synthesis")}
                        </p>
                        <img
                          src={logoEskritor}
                          class="img-fluid mb-2"
                          alt="img"
                        />
                        <p class="mb-0">{t("2400 AI generated content")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div class="w-100"></div>
            <div class="col-xxl-12 col-lg-10 col-12 sec-pad-top">
              <div class="card card-padding card-margin text-center ">
                <h3 class="mini-title">{t("For Teams")}</h3>
                <div class="d-flex align-items-center justify-content-center">
                  <div class="text-switch text-center my-4">
                    <button
                      type="button"
                      class="btn btn-toggle active"
                      data-bs-toggle="button"
                      aria-pressed="true"
                      autocomplete="off"
                      onClick={(e) => {
                        changeOffersBusiness(e);
                      }}
                    >
                      <div class="handle"></div>
                    </button>
                  </div>
                  <span class="offer-div">
                    <span class="me-1">
                      <img src={offerSvg} class="img-fluid" alt="img" />
                    </span>
                    {t("50% off")}
                  </span>
                </div>
                <div class="d-flex align-items-center justify-content-center">
                  <span class="info-icon theme-color">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.376"
                      height="16.62"
                      viewBox="0 0 16.376 16.62"
                    >
                      <g id="info" transform="translate(-2.239 -2.244)">
                        <path
                          id="Path_124"
                          data-name="Path 124"
                          d="M11.229,2.284a8.073,8.073,0,0,1,4.977,2.4,8.382,8.382,0,0,1,.546,11.148A8.132,8.132,0,0,1,6.566,17.894a8.31,8.31,0,0,1-3.7-4.155,8.453,8.453,0,0,1-.273-5.592,8.31,8.31,0,0,1,3.288-4.5,8.073,8.073,0,0,1,5.352-1.364Zm.56,15.254a6.992,6.992,0,0,0,4.049-2.457,7.206,7.206,0,0,0-.475-9.569,6.909,6.909,0,0,0-8.821-.879A7.194,7.194,0,0,0,7.136,16.85a6.9,6.9,0,0,0,4.654.689ZM9.813,8.183H11.3V7H9.813ZM11.3,9.37v4.749H9.813V9.37Z"
                          transform="translate(0 0)"
                          fill="currentColor"
                          fill-rule="evenodd"
                        />
                      </g>
                    </svg>
                  </span>
                  <p class="text-start mb-0">
                    <i>
                      {t("Use your credits in")}{" "}
                      <span class="medium blue-logo-text">Transkriptor</span>,{" "}
                      <span class="medium pink-logo-text">Speaktor</span>{" "}
                      {t("and")}{" "}
                      <span class="medium yellow-logo-text">Eskritor</span>{" "}
                      {t("with only one payment")}
                    </i>
                  </p>
                </div>
              </div>
            </div>
            <div class="w-100"></div>

            {businessActive ? (
              <>
                <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
                  <div class="card text-center card-100 payment-card">
                    <div
                      class="theme-bg card-padding white-color"
                      style={{
                        display: packageValue === "specialOffer" ? "none" : "",
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        <h2 class="package-price mt-2 mb-1 ">
                          $ {packagePrice}
                        </h2>
                        <p class="ft-14-title mb-3 align-self-end">
                          {t("/month")}
                        </p>
                      </div>
                      <p class="mb-2">
                        {t("Annually Billed Amount:")} ${annualPrice}
                      </p>
                    </div>
                    <div
                      class="theme-bg card-padding white-color"
                      style={{
                        display: packageValue === "specialOffer" ? "" : "none",
                      }}
                    >
                      <h2 class="package-price mt-2 mb-1">{t("Contact us")}</h2>

                      <p class="mb-2">{t("Starts at $600 / month")}</p>
                    </div>
                    <div class="card-padding">
                      <h3 class="medium-title mb-4">Premium+</h3>
                      <p class="regular mb-2 dark-color">{t("Choose hours")}</p>
                      <div class="form-group package-input mb-3">
                        <select
                          class="form-select form-control round-input border-input"
                          value={packageValue}
                          onChange={handlePackageChange}
                        >
                          <option value="100">100 {t("hours/month")}</option>
                          <option value="250">250 {t("hours/month")}</option>
                          <option value="500">500 {t("hours/month")}</option>
                          <option value="1000">1000 {t("hours/month")}</option>
                          <option value="specialOffer">
                            +1000 {t("hours/month")}
                          </option>
                        </select>
                      </div>
                      <p class="theme-color regular mb-4">
                        {packageValue !== "specialOffer"
                          ? packageValue
                          : "+1000"}{" "}
                        {t("hours/month")}
                      </p>

                      <p class="theme-color regular mb-4">
                        {t("Usage reset every month")}
                      </p>
                      <div
                        className="row m-2"
                        style={{
                          display:
                            isLoading &&
                            product &&
                            product.includes("Hours-Annual")
                              ? "block"
                              : "none",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <button
                        class="btn theme-btn payment-btn"
                        data-value={`${packageValue}-Hours-Annual`}
                        style={{
                          display:
                            packageValue === "specialOffer" ? "none" : "",
                        }}
                        onClick={(e) => redirectCheckout(e)}
                        disabled={
                          getSubscriptionComparison(
                            `${packageValue}-Hours-Annual`,
                            subscriptionType
                          ) === "Current Plan" ||
                          getSubscriptionComparison(
                            `${packageValue}-Hours-Annual`,
                            subscriptionType
                          ) === "Disabled"
                            ? true
                            : false
                        }
                      >
                        {t(
                          getSubscriptionComparison(
                            `${packageValue}-Hours-Annual`,
                            subscriptionType
                          )
                        )}
                        <span class="ms-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-arrow-right-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                            />
                          </svg>
                        </span>
                      </button>
                      <button
                        class="btn theme-btn"
                        style={{
                          display:
                            packageValue === "specialOffer" ? "" : "none",
                        }}
                        onClick={() => Gleap.startFeedbackFlow("gvmfu")}
                      >
                        {t("Contact us").toUpperCase()}
                        <span class="ms-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-arrow-right-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
                  <div class="card text-center card-100 payment-card">
                    <div class="theme-bg card-padding white-color">
                      <div className="d-flex justify-content-center">
                        <h2 class="package-price mt-2 mb-1 ">$ 15</h2>
                        <p class="ft-14-title mb-3 align-self-end">
                          {t("/month/member")}
                        </p>
                      </div>

                      <p class="mb-2">
                        {t("Annually Billed Amount:")} ${businessPrice * 12}
                      </p>
                    </div>
                    <div class="card-padding">
                      <h3 class="medium-title mb-4">{t("Business")}</h3>
                      <p class="regular mb-2 dark-color">
                        {t("Number of Members")}
                      </p>
                      <div className="form-group counter-input mb-3">
                        <span
                          className="count c-minus"
                          onClick={decrementCounter}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          className="form-control round-input border-input"
                          name=""
                          placeholder=""
                          value={counterValue}
                          onChange={handleInputChange}
                        />
                        <span
                          className="count c-plus"
                          onClick={incrementCounter}
                        >
                          +
                        </span>
                      </div>

                      <p class="theme-color regular mb-4">
                        {t("3000 minutes/month/member")}
                      </p>
                      <p class="theme-color regular mb-4">
                        {t("Usage reset every month")}
                      </p>

                      <div
                        className="row m-2"
                        style={{
                          display:
                            isLoading && product === "Business-Annual"
                              ? "block"
                              : "none",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <button
                        class="btn theme-btn payment-btn"
                        data-value="Business-Annual"
                        onClick={(e) => redirectCheckout(e)}
                      >
                        {t("Get started").toUpperCase()}
                        <span class="ms-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-arrow-right-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
                  <div class="card text-center card-100 payment-card">
                    <div
                      class="theme-bg card-padding white-color"
                      style={{
                        display: packageValue === "specialOffer" ? "none" : "",
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        <h2 class="package-price mt-2 mb-1 ">
                          $ {packagePrice}
                        </h2>
                      </div>
                    </div>
                    <div
                      class="theme-bg card-padding white-color"
                      style={{
                        display: packageValue === "specialOffer" ? "" : "none",
                      }}
                    >
                      <h2 class="package-price mt-2 mb-1">{t("Contact us")}</h2>

                      <p class="mb-2">{t("Starts at $600 / month")}</p>
                    </div>
                    <div class="card-padding">
                      <h3 class="medium-title mb-4">Premium+</h3>
                      <p class="regular mb-2 dark-color">{t("Choose hours")}</p>
                      <div class="form-group package-input mb-3">
                        <select
                          class="form-select form-control round-input border-input"
                          value={packageValue}
                          onChange={handlePackageChange}
                        >
                          <option value="100">100 {t("hours/month")}</option>
                          <option value="250">250 {t("hours/month")}</option>
                          <option value="500">500 {t("hours/month")}</option>
                          <option value="1000">1000 {t("hours/month")}</option>
                          <option value="specialOffer">
                            +1000 {t("hours/month")}
                          </option>
                        </select>
                      </div>
                      <p class="theme-color regular mb-4">
                        {packageValue !== "specialOffer"
                          ? packageValue
                          : "+1000"}{" "}
                        {t("hours/month")}
                      </p>

                      <p class="theme-color regular mb-4">
                        {t("Usage reset every month")}
                      </p>

                      <div
                        className="row m-2"
                        style={{
                          display:
                            isLoading && product && product.includes("Hours")
                              ? "block"
                              : "none",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <button
                        class="btn theme-btn payment-btn"
                        data-value={`${packageValue}-Hours`}
                        style={{
                          display:
                            packageValue === "specialOffer" ? "none" : "",
                        }}
                        disabled={
                          getSubscriptionComparison(
                            `${packageValue}-Hours`,
                            subscriptionType
                          ) === "Current Plan" ||
                          getSubscriptionComparison(
                            `${packageValue}-Hours`,
                            subscriptionType
                          ) === "Disabled"
                            ? true
                            : false
                        }
                        onClick={(e) => redirectCheckout(e)}
                      >
                        {t(
                          getSubscriptionComparison(
                            `${packageValue}-Hours`,
                            subscriptionType
                          )
                        )}
                        <span class="ms-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-arrow-right-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                            />
                          </svg>
                        </span>
                      </button>
                      <button
                        class="btn theme-btn"
                        style={{
                          display:
                            packageValue === "specialOffer" ? "" : "none",
                        }}
                        onClick={() => Gleap.startFeedbackFlow("gvmfu")}
                      >
                        {t("Contact us").toUpperCase()}
                        <span class="ms-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-arrow-right-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xxl-6 col-lg-5 col-md-6 col-12 card-margin">
                  <div class="card text-center card-100 payment-card">
                    <div class="theme-bg card-padding white-color">
                      <div className="d-flex justify-content-center">
                        <h2 class="package-price mt-2 mb-1 ">$ 30</h2>
                        <p class="ft-14-title mb-3 align-self-end">
                          {t("/member")}
                        </p>
                      </div>
                    </div>
                    <div class="card-padding">
                      <h3 class="medium-title mb-4">Business</h3>
                      <p class="regular mb-2 dark-color">
                        {t("Number of Members")}
                      </p>
                      <div className="form-group counter-input mb-3">
                        <span
                          className="count c-minus"
                          onClick={decrementCounter}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          className="form-control round-input border-input"
                          name=""
                          placeholder=""
                          value={counterValue}
                          onChange={handleInputChange}
                        />
                        <span
                          className="count c-plus"
                          onClick={incrementCounter}
                        >
                          +
                        </span>
                      </div>

                      <p class="theme-color regular mb-4">
                        {t("3000 minutes/month/member")}
                      </p>
                      <p class="theme-color regular mb-4">
                        {t("Usage reset every month")}
                      </p>

                      <div
                        className="row m-2"
                        style={{
                          display:
                            isLoading && product === "Business"
                              ? "block"
                              : "none",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          ></div>
                        </div>
                      </div>
                      <button
                        class="btn theme-btn payment-btn"
                        data-value="Business"
                        onClick={(e) => redirectCheckout(e)}
                      >
                        {t("Get started").toUpperCase()}
                        <span class="ms-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            class="bi bi-arrow-right-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div class="w-100"></div>
            <div class="col-12 sec-pad-top">
              <h3 class="medium-title text-center mb-lg-5 mb-4">
                {t("Frequently Asked Questions")}
              </h3>
              <div class="card card-padding card-margin">
                <div
                  class="accordion accordion-flush dark-color"
                  id="accordionFlushExample"
                >
                  <div class="accordion-item">
                    <span class="collapse-icon fa fa-angle-double-right"></span>
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        {t("How can I solve payment error?")}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>
                          {t("There are multiple reasons for payment error.")}
                        </p>
                        <ul>
                          <li>
                            <p>
                              {t(
                                "Firstly, please use VISA or Mastercard credit card."
                              )}
                            </p>
                            <ul class="ps-4">
                              <li>
                                <p>{t("Debit cards or AMEX cause errors.")}</p>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <p>
                              {t(
                                "Secondly, make sure you have sufficient balance and your credit card is open for international online payments."
                              )}
                            </p>
                          </li>
                          <li>
                            <p>
                              {t(
                                "If you still receive errors please download our mobile app and try your payment via Appstore or Playstore. Make sure to login with same mail address. Please download our mobile app to subscribe via Playstore or Appstore"
                              )}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <span class="collapse-icon fa fa-angle-double-right"></span>
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        {t(
                          "What payment methods do you accept? Is my payment secure?"
                        )}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>
                          {t(
                            "We accept credit card from our website, but we also have mobile payment via Playstore (Android) or Appstore (IOS). Please download our mobile app to subscribe via Playstore or Appstore Also, your credit card details are sent directly to the bank and cannot be read or accessed by any parties other than your bank."
                          )}
                        </p>
                        <div class="d-flex align-items-center">
                          <img
                            src={playStorePng}
                            class="img-fluid mt-2 me-2"
                            width="230px"
                            alt="img"
                          />
                          <img
                            src={appStorePng}
                            class="img-fluid mt-2 me-2"
                            width="230px"
                            alt="img"
                          />
                          <img
                            src={qrCodePng}
                            class="img-fluid mt-2 me-2"
                            width="230px"
                            alt="img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <span class="collapse-icon fa fa-angle-double-right"></span>
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        {t(
                          "Will my subscription automatically renews? Can I cancel my subscription anytime?"
                        )}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingThree"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>
                          {t(
                            "Yes, your subscription will renew automatically if you do not cancel your subscription."
                          )}
                        </p>
                        <p>{t("You can cancel your subscription anytime.")}</p>
                        <p>{t("To cancel your subscription:")}</p>
                        <ul>
                          <li>
                            <p>
                              {t(
                                "Go to My Account section and click on Subscriptions, there you can cancel your subscription."
                              )}
                            </p>
                          </li>
                          <li>
                            <p>
                              {t(
                                "If you subscribed via Playstore or Appstore, please manage your subscription via your Playstore or Appstore account."
                              )}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <span class="collapse-icon fa fa-angle-double-right"></span>
                    <h2 class="accordion-header" id="flush-headingFour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        {t(
                          "Can I add more minutes if I use all my minutes before my subscription renews?"
                        )}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingFour"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>
                          {t(
                            "Yes, to add more minutes you can purchase a subscription again. Please cancel your previous subscription by going to My subscriptions page to avoid double billing. Each customer is expected to have at most one active subscription at any time."
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <span class="collapse-icon fa fa-angle-double-right"></span>
                    <h2 class="accordion-header" id="flush-headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFive"
                        aria-expanded="false"
                        aria-controls="flush-collapseFive"
                      >
                        {t("When will my minutes expire?")}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingFive"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>
                          {t(
                            "Unused minutes are not carried over to the next month. Unused minutes will expire after 1 month. Your subscription will be automatically renewed after your minutes expire."
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <span class="collapse-icon fa fa-angle-double-right"></span>
                    <h2 class="accordion-header" id="flush-headingSix">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSix"
                        aria-expanded="false"
                        aria-controls="flush-collapseSix"
                      >
                        {t("How can I redeem my coupon code?")}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSix"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingSix"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>{t("To use the discount code:")}</p>
                        <ul>
                          <li>
                            <p>
                              {t(
                                "Choose your membership model and click Get Started."
                              )}
                            </p>
                          </li>
                          <li>
                            <p>{t("Write discount code.")}</p>
                            <img
                              src={promoCodePng}
                              class="img-fluid mb-3"
                              alt="img"
                            />
                          </li>
                          <li>
                            <p>{t("Enter your card information and pay.")}</p>
                          </li>
                        </ul>
                        <p>
                          {t("How to use discount codes in the mobile app?")}
                        </p>
                        <ul>
                          <li>
                            <p>
                              {t(
                                "You cannot use the discount code on the mobile for now"
                              )}
                            </p>
                          </li>
                          <li>
                            <p>
                              {t(
                                "You can buy with a discount from the website and use Transkriptor from the mobile app."
                              )}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <span class="collapse-icon fa fa-angle-double-right"></span>
                    <h2 class="accordion-header" id="flush-headingSeven">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseSeven"
                        aria-expanded="false"
                        aria-controls="flush-collapseSeven"
                      >
                        {t("Does Transkriptor provide an API?")}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseSeven"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingSeven"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <p>
                          {t(
                            "In case you need an API to integrate Transkriptor into your applications, you can use Transkriptor's public APIs! Please visit our API documentation: "
                          )}{" "}
                          <a
                            href="https://developer.transkriptor.com."
                            target="_blank"
                            class="theme-color"
                          >
                            https://developer.transkriptor.com.
                          </a>{" "}
                          <a
                            href="mailto:mhancerkiran@transkriptor.com"
                            class="jsontranslate"
                          >
                            {t(
                              "If you need any support please reach out us at mhancerkiran@transkriptor.com"
                            )}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Payment;
