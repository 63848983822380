import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "../../components/common/LanguageProvider";
import processingVid from "../../assets/videos/processing.mp4";
import { ProgressBar } from "react-bootstrap";
function Processing({ onRefresh, userData}) {
  const { t } = useTranslation();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshPage = () => {
    setIsRefreshing(true);
    onRefresh();
    setTimeout(() => {
      setIsRefreshing(false);
    }, 1000);
  };
  useEffect(() => {
    const intervalId = setInterval(onRefresh, 10 * 1000); // 10 seconds

    // Clear the interval if the component is unmounted
    return () => clearInterval(intervalId);
  }, [onRefresh]);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (userData) {
        let duration = 0.5; // Default to 30 seconds
        if (userData.SStatus !== "Trial") {
            duration = 1.75; // 1.75 minutes = 1 minute 45 seconds
        }

        const phase1Duration = duration * 60 * 1000; // Convert duration to milliseconds
        const phase1Increment = 80 / (phase1Duration / 1000); // Increment for phase 1

        var phase2Increment = 1; // 1% per 2 seconds for phase 2
        var phase2Interval = 2000; // 2 seconds
        if(userData.SStatus !== "Trial") {
          phase2Interval = 5000; // 5 seconds
        }

        let currentPhase = 1;
        let interval;

        const startPhase2 = () => {
            interval = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return Math.min(oldProgress + phase2Increment, 100);
                });
            }, phase2Interval);
        };

        interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress >= 80) {
                    clearInterval(interval);
                    currentPhase = 2;
                    startPhase2();
                    return oldProgress;
                }
                return Math.min(oldProgress + phase1Increment, 80);
            });
        }, 1000); // Update every second for phase 1

        return () => {
            clearInterval(interval);
        };
    }
}, [userData]);

  return (
    <>
      <div className="card card-padding overflow-visible">
        <div class="card">
          <div class="card-header theme-bg py-3 px-4">
            <h2 class="mini-title white-color semibold text-center mb-0">
              TRANSKRIPTOR
            </h2>
          </div>
          <div class="card-padding">
            <div class="d-md-flex d-block justify-content-center align-items-center text-center text-md-start">
              <div className="d-flex justify-content-center align-items-center">
              <div className="col-8 m-2 text-center">
                {userData.SStatus !== "Trial" && <p className="text-center" style={{fontSize: "1.15rem"}}>
                  {t(
                    "Your file is currently being processed and will be opened automatically when ready. If you wish, you can close this page, you will receive a notification via e-mail when your file is ready."
                  )}
                </p>}
                {userData.SStatus === "Trial" && <div>
                  <p className="text-center">{t("Your file is currently being processed and will be ready in just 30-40 seconds!")}</p>
                  <p className="text-center">{t("No need to close this page, your transcription will be available shortly. Stay with us, and you'll have your results in no time. For a smoother experience, we recommend keeping this tab open.")}</p>
                  <p className="text-center">{t("If you have any questions or need assistance, feel free to reach out. We're here to help!")}</p>
                  
                  </div>}
                <div className="mt-3">
                  <ProgressBar
                    now={progress}
                    label={`${Math.round(progress)}%`}
                    animated
                  />
                </div>
                <div
                  className="m-3"
                  style={progress < 80 ? { visibility: "hidden" } : null}
                >
                  <p className="text-center lead">
                    {t("Completing...")}
                  </p>
                </div>
              </div>

              </div>
            </div>
            <div className="text-center m-3 position-relative">
              <Link
                onClick={() => refreshPage()}
                className="btn theme-btn"
                style={{
                  opacity: isRefreshing ? 0 : 1,
                  pointerEvents: isRefreshing ? "none" : "auto",
                  zIndex: 1,
                }}
              >
                {t("Refresh")}
                <span className="ms-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                </span>
              </Link>

              {isRefreshing && (
                <span className="ms-2">
                  <div
                    className="d-flex justify-content-center text-center position-absolute top-0 start-50 translate-middle-x w-100"
                    style={{ zIndex: 2 }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </span>
              )}
            </div>

            <video
              src={processingVid}
              autoPlay
              loop
              style={{
                width: "100%",
                height: "500px",
                border: "none",
                margin: "0",
                padding: "0",
              }}
            ></video>
          </div>
        </div>
        <hr></hr>
        <div class="col-xxl-8 mx-auto"></div>

        <div class="col-xxl-8 mx-auto" style={{ display: "none" }}>
          <div
            id="carouselExampleIndicators"
            class="carousel slide file-slider"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row gx-0 text-center">
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/blue-export.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Export as TXT, SRT, Word or Plain Text")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Download TXT, SRT, Word or Plain Text versions of your transcriptions in the editor page"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon slider-theme-icon-small">
                          <img
                            src="assets/img/files/blue-youtube.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                        <div class="slider-theme-icon slider-theme-icon-small">
                          <img
                            src="assets/img/files/blue-google-drive.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                        <div class="slider-theme-icon slider-theme-icon-small">
                          <img
                            src="assets/img/files/blue-dropbox.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                        <div class="slider-theme-icon slider-theme-icon-small">
                          <img
                            src="assets/img/files/blue-onedrive.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Transcribe from Youtube or Cloud Storage link")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Paste a Youtube or Cloud link in Uploader page and convert it to text in any language"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/blue-edit.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Edit with slow motion and change speakers")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Paste a Youtube or cloud link in uploader page and convert it to text in any language"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/blue-caption.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Create Caption Files with a Button")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Create subtitles as SRT while uploading your audio"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/blue-relationship.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Colloborate on your transcripts")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Share transcripts with your teammates, create workspace to work on same transcripts and folders and invite your teammates"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/blue-translate.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Transcribe and translate in 100+ languages")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Transcribe the audio for any language and then, translate it to another within seconds"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row gx-0 text-center yellow-theme-slider">
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/yellow-time.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Write and edit articles within seconds")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Use writing use cases to improve your essays or to make them better"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/yellow-case.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("30+ available use cases")}</p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Find the best use case for your needs and finish your works faster"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/yellow-ai.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Edit outputs manually and AI help you to improve")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Edit outputs in the text editor, and shorten, expand or rephrase text again"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/yellow-openbox.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Find ideas for your business")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Give some keywords and get AI generated marketing ideas"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/yellow-relationship.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Colloborate on your results")}</p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Share results with your teammates, create workspace to work on same files, and folders and invite your teammates"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/yellow-translate.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Find your answers in over 100 languages")}
                      </p>
                      <div class="slider-hover-box">
                        <p>{t("Find best results for your target language")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row gx-0 text-center pink-theme-slider">
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/pink-download.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Download Mp3 version of texts")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Download Mp3 version of converted text files, images or links"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/pink-link.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Convert text to audio from any web link")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Paste a web link and convert it to audio within seconds"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/pink-speaker.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Edit with slow motion and change speakers")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Change play speed and edit the text in detail, or change the speakers, then save it to download it"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/pink-convert.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Type anything and convert it to audio")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Type anything and convert it to audio with over 150+ voices"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/pink-relationship.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Colloborate on your audio files")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Share audio files with your teammates, create workspace to work on same files and folders and invite your teammates"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/pink-translate.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Create audio and translate in 100+ languages")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t(
                            "Convert text to audio for any language and then, translate it to another within seconds"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Processing;
