import React, { useRef, useEffect, useState } from 'react';
import { useAuth } from '../../Auth/AuthContext';
import { fetchData, fetchDataPost } from '../../hooks/apiUtils';
import FileItem from './FileItem';
import { useApiData } from '../../hooks/useApiData';
import { Link, useParams, useNavigate } from 'react-router-dom';
import shareWhite from '../../assets/img/icon/share-white.svg';
import sharePeopleSvg from '../../assets/img/icon/share-people.svg';

import closeSvg from '../../assets/img/icon/close.svg';
import { showToast } from '../../components/common/toast';
import uploadPng from "../../assets/img/icon/upload-btn-icon.svg"
import renameSvg from "../../assets/img/icon/rename.svg"
import deleteSvg from "../../assets/img/icon/delete.svg"
import recordPng from "../../assets/img/icon/record-btn-icon.svg"
import botPng from "../../assets/img/icon/minute-btn-icon.svg"
import { useModal } from '../../components/common/Modal/ModalContext';
import { useTranslation } from '../../components/common/LanguageProvider';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { popularLanguages, allLanguages } from '../../components/common/transcribeLanguageDropdown';
import { useUploader } from '../Uploader/UploaderContext';
import { useRecorder } from '../Recorditor/RecorderContext';

import moveWhite from '../../assets/img/icon/move-white.svg';
import moveGrey from '../../assets/img/icon/move-grey.svg';

import MenuItem from '@mui/material/MenuItem';
import sharePeopleSvg2 from '../../assets/img/icon/share-people2.svg';
import sharePeopleSvgGrey from '../../assets/img/icon/share-people2-grey.svg';

import deleteWhite from '../../assets/img/icon/delete-white.svg';
import deleteGrey from '../../assets/img/icon/delete-grey.svg';

import checkAllWhite from '../../assets/img/icon/check-all-white.svg';

import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

import CustomizedMenus from './CustomizedMenus';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import shareSvg from '../../assets/img/icon/share.svg'

import { keyframes } from '@mui/styled-engine';

import LinearProgress from '@mui/material/LinearProgress';


const blinkCaret = keyframes`
  50% { caret-color: transparent; }
`;


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '49px', // Rounded borders as per your design
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'transparent',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(0),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#505050', // This attempts to set the color for the icon.
    zIndex: 2, // Ensures it's above other elements
    paddingLeft: theme.spacing(2),
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: '#505050',
    '& .MuiInputBase-input': {
        borderRadius: '49px', // Rounded borders
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`, // Padding left includes space for the search icon
        transition: 'width 300ms ease-in-out, background-color 300ms ease-in-out',
        backgroundColor: 'white', // Set background color to theme color
        width: '34ch', // Initial width enough to show placeholder text
        border: '1px solid #505050', // Set border color
        '&:focus': {
            width: '48ch', // Expand width on focus
            backgroundColor: 'white', // Keep theme color on focus
            caretColor: '#505050',
            animation: `${blinkCaret} 1s step-end infinite`,
        },
        '&::placeholder': { // Styles for the placeholder text
            color: '#505050', // Adjust color and opacity
            fontFamily: 'var(--regular-font)', // Use the CSS variable for the font-family
            fontSize: '16px', // Set font size
            fontWeight: 'normal' // Set font weight
        }
    },
}));

const SearchContainer = styled('div')(({ theme }) => ({
    width: '600px', // Example width that fits the expanded search bar
    position: 'relative',  // Ensures the search bar can overlap content properly
    overflow: 'visible',   // Allows the search bar to overflow visually when focused
}));

function Files(props) {
    const { fid, uid, tid } = useParams();
    const { hashedId, userData, token } = useAuth();
    const { showModal } = useModal();
    const navigate = useNavigate();
    const [allFiles, setAllFiles] = useState([]);
    const [filesToShow, setFilesToShow] = useState([]);
    const [folders, setFolders] = useState([]);
    const [teams, setTeams] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filesLength, setFilesLength] = useState(0);
    const [checkedItems, setCheckedItems] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredFiles, setFilteredFiles] = useState([]);
    const [folderName, setFolderName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const mainCheckboxRef = useRef(null);
    const [mainCheckboxStatus, setMainCheckboxStatus] = useState('unchecked');

    const isFile = props.isFile || false;
    const isFolder = props.isFolder || false;
    const { filesRouteLoading } = useUploader();
    const filesPerPage = isFile ? 10 : 5;

    const { t } = useTranslation();
    const { showUploadModal } = useUploader();
    const { showRecordModal } = useRecorder();
    var startIndex = 0;
    var endIndex = 0;

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const isAnyChecked = Object.values(checkedItems).some(isChecked => isChecked);

    const checkWindowSize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    // Effect to add window resize listener
    useEffect(() => {
        window.addEventListener('resize', checkWindowSize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', checkWindowSize);
        };
    }, []);

    const fetchFiles = async () => {
        setIsLoading(true); // Set loading to true at the beginning of data fetching
        var url = "https://v0p485qrg1.execute-api.eu-central-1.amazonaws.com/default/AA-API-Gettranscriptions?cid=" + hashedId;
        if (fid !== undefined) {
            url = "https://40k63fbp7d.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFolderTranscriptions?fid=" + fid + "&cid=" + hashedId;
        }
        if (tid !== undefined) {
            var url = "https://v0p485qrg1.execute-api.eu-central-1.amazonaws.com/default/AA-API-Gettranscriptions?cid=" + tid;
        }
        const data = await fetchData(url);
        setAllFiles(data);
        setFilesLength(data.length);
        setIsLoading(false); // Set loading to false once data fetching is complete


    }
    const fetchFolders = async () => {
        let tempId = tid ? tid : hashedId;
        const data = await fetchData("https://i3w4h1tgb5.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFolders?cid=" + tempId);
        if (fid !== undefined) {
            data.forEach(element => {
                if (element.SK.S.replace("User#", "") === fid) {
                    setFolderName(element.Fname.S)
                }
            });
        }
        setFolders(data);
    };

    const fetchTeams = async () => {
        const data = await fetchData("https://5eozpazgv4.execute-api.eu-central-1.amazonaws.com/default/Get-Teams?HashedId=" + hashedId)
        var tempArray = data;
        if (tid !== undefined) {
            data.forEach(element => {
                if (element.TeamId === tid) {
                    tempArray = data.filter(function (obj) {
                        return obj.TeamId !== tid;
                    });
                }
            });
        }
        setTeams(tempArray)
    };
    const fetchLanguages = async () => {
        if (!hashedId) return;
        try {
            const queryParams = new URLSearchParams({
                user_id: hashedId,
                dashboard_language: localStorage.getItem("dashboard-language") || "en",
                app_name: 'transkriptor'
            }).toString();
            const response = await fetch(`https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/appoptions/getlanguages?${queryParams}`);

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem("user_locales", JSON.stringify(data.user_specific_stt_locales));
                localStorage.setItem("all_locales", JSON.stringify(data.stt_locales));
            } else {
                console.error('Failed to fetch languages:', response.statusText);
                localStorage.setItem("user_locales", JSON.stringify(popularLanguages));
                localStorage.setItem("all_locales", JSON.stringify(allLanguages));
            }
        } catch (error) {
            console.error('Error fetching languages:', error);
            localStorage.setItem("user_locales", JSON.stringify(popularLanguages));
            localStorage.setItem("all_locales", JSON.stringify(allLanguages));
        }
    };
    useEffect(() => {
        if (hashedId || tid || fid) {
            fetchFiles();
            fetchFolders();
            fetchTeams();
            fetchLanguages();
        }

    }, [hashedId]);

    useEffect(() => {
        // Calculate the indexes of files to be displayed on the current page
        startIndex = (currentPage - 1) * filesPerPage;
        endIndex = startIndex + filesPerPage;

        // Update the files to show based on the new indexes
        setFilesToShow(allFiles.slice(startIndex, endIndex));

    }, [allFiles, currentPage]);

    useEffect(() => {
        if (filesToShow.length > 0) {
            for (let file of filesToShow) {
                let date_flag = false;
                let myDate = new Date(parseInt(file?.Date?.N)) || Date.now(); // assuming file.Date.N is a Unix timestamp in milliseconds
                let currentDate = Date.now();
                let diffInMs = currentDate - myDate.getTime();

                if (diffInMs < (1000 * 60 * 60 * 24)) {
                    date_flag = true;
                }

                let status = file?.TStatus?.S || "Completed";
                const delayInMilliseconds = 30000;

                if (status === "Processing" && date_flag) {
                    setTimeout(fetchFiles, delayInMilliseconds);
                    break; // This will exit the loop if the conditions are met
                }
            }
        }
    }, [filesToShow]);

    const handleDelete = (tid, uid, multiple) => {
        // Implement delete functionality here
        // After deletion from the server, remove it from state
        fetchDataPost("https://2dwvlsoy9a.execute-api.eu-central-1.amazonaws.com/default/AA-API-DeleteFile", { tid: "#Transkription#" + tid, cid: uid })

        setAllFiles((prevFiles) => prevFiles.filter(file => file.SK.S !== "#Transkription#" + tid));
        setFilesLength((prevLength) => prevLength - 1);
        if (!multiple) {
            showToast(t("File deleted"), { type: "success" });
        }

    };

    const handleRename = (tid, uid, name) => {
        allFiles.forEach(file => {
            if (file.SK.S === "#Transkription#" + tid) {
                file.TempName.S = name;
            }
        });
        fetchDataPost("https://v91m7qwz82.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFile?", { tid: "#Transkription#" + tid, cid: "User#" + uid, Tname: name })
            .then(data => {
                // Deep clone the array to ensure immutability

                showToast(t("File renamed"), { type: "success" });
            });
    };



    const handleMove = (tid, uid, fid, isMultiple) => {
        // Implement move functionality here
        // Update state accordingly
        fetchDataPost("https://319zoan27h.execute-api.eu-central-1.amazonaws.com/default/AA-API-MoveFile", { pk: "User#" + uid, tid: "#Transkription#" + tid, fid: fid })
        console.log(tid);
        console.log(uid);
        setAllFiles((prevFiles) => prevFiles.filter(file => file.SK.S !== "#Transkription#" + tid));
        setFilesLength((prevLength) => prevLength - 1);
        if (!isMultiple) {
            showToast(t("File moved to folder"), { type: "success" });
        }
    }


    const handleShareTeam = (tid, uid, teamId, multiple) => {
        console.log(tid);
        console.log(uid);
        console.log(teamId);
        fetchDataPost(
            "https://po0s8vut8h.execute-api.eu-central-1.amazonaws.com/default/AA-API-MoveFileToTeam?",
            { pk: "User#" + uid, sk: "#Transkription#" + tid, TeamId: teamId })
        setAllFiles((prevFiles) => prevFiles.filter(file => file.SK.S !== "#Transkription#" + tid));
        setFilesLength((prevLength) => prevLength - 1);
        if (!multiple) {
            showToast(t("File moved to team"), { type: "success" });
        }
    }

    const handleCheckboxChange = (fileId, isChecked) => {
        setCheckedItems(prevState => {
            const newState = { ...prevState, [fileId]: isChecked };
            return newState;
        });
    };


    const handleShare = async (oid) => {
        console.log(oid);
        try {
            let link = "app.transkriptor.com/sharing?oid=" + oid;
            await navigator.clipboard.writeText(link);

            showToast(t("File link copied to clipboard"));

            return true;

        } catch (err) {
            console.error('Failed to copy text: ', err);
            return false;
        }

    };

    const handleInputChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        filterFiles(query);
    }

    const filterFiles = (query) => {
        if (query.length === 0) {
            // Reset to show initial set of files when no search query
            const initialFiles = allFiles.slice(0, filesPerPage);
            setFilesToShow(initialFiles);
            setFilesLength(allFiles.length);
            setCurrentPage(1);
        } else {
            // Filter files based on the search query
            const filtered = allFiles.filter(file =>
                file?.TempName?.S.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredFiles(filtered); // Maintain a separate state to keep track of filtered files
            if (filtered.length > 0) {
                // There are matching files
                setFilesToShow(filtered.slice(0, filesPerPage));
                setFilesLength(filtered.length);
                setCurrentPage(1);
            } else {
                // No matches found, set filesToShow to empty to show placeholders
                setFilesToShow([]);
                setFilesLength(0);
                setCurrentPage(1);
            }
        }
    }


    useEffect(() => {
        // Apply filtering whenever the searchQuery changes
        filterFiles(searchQuery);
    }, [searchQuery]);


    useEffect(() => {
        // This ensures the displayed files are updated when pagination changes
        // or when the filtered files list is updated.
        const newFilesToShow = searchQuery.length > 0 ?
            filteredFiles.slice((currentPage - 1) * filesPerPage, currentPage * filesPerPage) :
            allFiles.slice((currentPage - 1) * filesPerPage, currentPage * filesPerPage);

        setFilesToShow(newFilesToShow);
    }, [currentPage, filteredFiles, allFiles, searchQuery]);


    const onDelete = async (fid, uid) => {
        console.log(fid, uid);

        const response = await fetchDataPost("https://5ex4m1gaw1.execute-api.eu-central-1.amazonaws.com/default/AA-API-DeleteFolder?cid=" + uid + "&tid=" + fid);
        showToast(t("Folder deleted"));
        navigate("/folders")
    };
    const onRename = async (fid, uid, name) => {
        console.log(name, fid, uid);

        const response = await fetchDataPost("https://xc8v5q5vc2.execute-api.eu-central-1.amazonaws.com/default/AA-API-RenameFolder",
            { "tid": fid, "Tname": name, "cid": uid });
        setFolderName(name);
        showToast(t("Folder renamed"));
    };

    const onMoveMultiple = async (uid, fid) => {
        console.log(fid, uid);
        console.log("kek");
        let count = 0;
        // Loop through the checkedItems object
        for (let fileId in checkedItems) {
            // If the fileId is checked (i.e., its value is true), perform some action
            if (checkedItems[fileId]) {
                count++;
                console.log("Moving:", fileId);
                // ... Your logic for moving or any other action

                handleMove(fileId.replace("#Transkription#", ""), uid, fid, true);

            }
        }
        setCheckedItems({});
        if (count > 1) {
            showToast(t("Files moved to folder"), { type: "success" });
        } else {
            showToast(t("File moved to folder"), { type: "success" });
        }

    };


    const onDeleteMultiple = async (uid) => {
        console.log("kek");
        let count = 0;
        // Loop through the checkedItems object
        for (let fileId in checkedItems) {
            // If the fileId is checked (i.e., its value is true), perform some action
            if (checkedItems[fileId]) {
                count++;

                console.log("Moving:", fileId);
                // ... Your logic for moving or any other action

                handleDelete(fileId.replace("#Transkription#", ""), uid, true);
            }
        }
        setCheckedItems({});
        if (count > 1) {
            showToast(t("Files deleted"), { type: "success" });
        } else {
            showToast(t("File deleted"), { type: "success" });
        }
    };


    const selectAll = () => {
        const allChecked = {};

        filesToShow.forEach(file => {
            allChecked[file?.SK?.S] = true;
        });

        setCheckedItems(allChecked);
    };

    const unselectAll = () => {
        const allChecked = {};



        setCheckedItems(allChecked);
    };

    const onShareMultiple = async (uid, tid) => {
        console.log("kek");
        let count = 0;
        // Loop through the checkedItems object
        for (let fileId in checkedItems) {
            // If the fileId is checked (i.e., its value is true), perform some action
            if (checkedItems[fileId]) {
                count++;
                console.log("Moving:", fileId);
                // ... Your logic for moving or any other action

                handleShareTeam(fileId.replace("#Transkription#", ""), uid, tid, true);
            }
        }
        setCheckedItems({});
        if (count > 1) {
            showToast(t("Files moved to team"), { type: "success" });
        } else {
            showToast(t("File moved to team"), { type: "success" });
        }
    };


    const handleCreateFolder = async (uid, folderName, tid) => {
        console.log(`handleCreateFolder called with uid: ${uid}, folderName: ${folderName}, tid: ${tid}`);

        const response = await fetchDataPost("https://lq3fr34z20.execute-api.eu-central-1.amazonaws.com/default/AA-API-CreateFolder",
            { Fname: folderName, cid: uid });
        const data = await response.json();

        fetchFolders();

        showToast(t("Folder created"));

        if (tid) {
            handleMove(tid, uid, data.SK.S);

        } else {
            onMoveMultiple(uid, data.SK.S);

        }

    };

    const onCreateFolder = async (uid, folderName, tid) => {
        console.log('onCreateFolder function before showModal:', handleCreateFolder);
        console.log('Triggering onCreateFolder. handleCreateFolder:', handleCreateFolder);
        console.log(`Creating folder with uid: ${uid}, folderName: ${folderName}, tid: ${tid}`);


        showModal('CREATE-FOLDER', { uid: hashedId }, { onCreateFolder: handleCreateFolder });
    }

    const onCreateWorkspace = async () => {
        console.log('Triggering onCreateWorkspace. handleCreateWorkspace:', handleCreateWorkspace);

        showModal("CREATE-WORKSPACE", { uid: hashedId }, { onCreateWorkspace: handleCreateWorkspace })
    };

    const handleCreateWorkspace = async (uid, workspaceName, tid) => {
        console.log(`handleCreateWorkspace called with uid: ${uid}, workspaceName: ${workspaceName}, tid: ${tid}`);

        const response = await fetchDataPost("https://0n4xaw0876.execute-api.eu-central-1.amazonaws.com/default/Create-Team",
            { UserHashedId: uid, TeamName: workspaceName });
        const data = await response.json();
        fetchTeams();
        showToast(t("Workspace created"));
        if (tid) {
            handleShareTeam(tid, uid, data.TeamId, false);
        } else {
            onShareMultiple(uid, data.TeamId);
        }
    };
    const initiateFunction = (type) => {
        if (userData.Minutes > 0) {
            if (type === "upload") {
                showUploadModal();
            } else if (type === "record") {
                showRecordModal();
            } else if (type === "bot") {
                window.open(`https://app.meetingtor.com/dashboard?t=${token}`, "_self");
            }
        } else {
            showModal("UPGRADE-MINUTES")
        }
    };

    const fetchFileContent = async (fileId) => {
        try {
            // Adjust the URL and parameters according to your actual API that serves file content
            const response = await fetch(`https://api.yourdomain.com/get-file-content?fileId=${fileId}`, {
                method: 'GET', // or 'POST', depending on your API
                headers: {
                    'Authorization': `Bearer ${token}`, // if authentication is required
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data; // Adjust according to the actual data structure returned by your API
        } catch (error) {
            console.error('Error fetching file content:', error);
            throw error; // Rethrowing the error so you can handle it where `fetchFileContent` is called
        }
    };

    useEffect(() => {
        const totalItems = filesToShow.length;
        const checkedCount = Object.values(checkedItems).filter(Boolean).length;

        if (checkedCount === 0) {
            setMainCheckboxStatus('unchecked');
            if (mainCheckboxRef.current) {
                mainCheckboxRef.current.indeterminate = false;
            }
        } else if (checkedCount === totalItems) {
            setMainCheckboxStatus('checked');
            if (mainCheckboxRef.current) {
                mainCheckboxRef.current.indeterminate = false;
            }
        } else {
            setMainCheckboxStatus('indeterminate');
            if (mainCheckboxRef.current) {
                mainCheckboxRef.current.indeterminate = true;
            }
        }
    }, [checkedItems, filesToShow]);



    const handleMainCheckboxChange = () => {
        // Check the current state of the main checkbox
        const shouldBeChecked = mainCheckboxStatus !== 'checked';

        // Update all individual checkboxes based on the state of the main checkbox
        const newCheckedItems = {};
        filesToShow.forEach(file => {
            newCheckedItems[file?.SK?.S] = shouldBeChecked;
        });

        // Update the state of individual checkboxes and the main checkbox
        setCheckedItems(newCheckedItems);
        setMainCheckboxStatus(shouldBeChecked ? 'checked' : 'unchecked');

    };

    const checkedCount = Object.values(checkedItems).filter(Boolean).length;  // Calculate the number of checked checkboxes

    return (
        <>
            <div className='row'>
                {filesRouteLoading && (
                    <div className='d-flex justify-content-center text-center'>
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>)}
                <div className="col-12" style={{ display: !isFile ? "none" : "" }}>
                    <div className='d-lg-flex d-block justify-content-between align-items-center'>
                        {!isFolder ? (
                            <h2 className="card-title">{t("Files")}</h2>
                        ) : (<>
                            <h2 class="card-title w-100"><Link to="/folders" class="hover-underline">{t("Folders")}</Link> / {folderName}
                                <div class="action-icon-list d-sm-inline-flex d-flex justify-content-end">
                                    <Link onClick={() => showModal('RENAME', { tid: fid, uid: hashedId, name: folderName }, { onRename })}>
                                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Rename")}>
                                            <img src={renameSvg} class="img-fluid" alt="img" />
                                        </span>
                                    </Link>
                                    <Link onClick={() => showModal('DELETE-FOLDER', { fid: fid, uid: hashedId }, { onDeleteFolder: onDelete })}>
                                        <span class="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={("Delete")}>
                                            <img src={deleteSvg} class="img-fluid" alt="img" />
                                        </span>
                                    </Link>
                                </div>
                            </h2>
                        </>)}

                    </div>
                </div>
            </div>

            <div className='row'>
                <div className="col-12">
                    <div className={`${isFile ? "card card-padding" : ""}`}>
                        <div className='table-responsive'>
                            {isLoading ? (
                                <>
                                    <table className='table' style={{ padding: '0rem' }}>
                                        <tbody>
                                            <tr className={`loader-row ${isLoading ? 'visible' : ''}`}>
                                                <Box sx={{ width: '100%', marginTop: "21.07px" }}>
                                                    <LinearProgress />
                                                </Box>
                                            </tr>
                                            {Array.from({ length: isFile ? 9 : 4 }).map((_, index) => (
                                                <tr key={index} style={{ height: "51.07px" }}>
                                                    <Skeleton height={30} style={{ borderRadius: '10px', marginTop: "21.07px" }} />

                                                </tr>
                                            ))}
                                            <tr style={{ height: "51.07px", display: isFile ? 'block' : 'none' }}>
                                                <Skeleton height={30} style={{ borderRadius: '10px', marginTop: "21.07px" }} />
                                            </tr>
                                            <tr style={{ height: "51.07px", display: isFile ? 'block' : 'none' }}>
                                                <Skeleton height={30} style={{ borderRadius: '10px', marginTop: "21.07px" }} />
                                            </tr>
                                        </tbody>
                                    </table>
                                </>
                            ) : (
                                <>
                                    <table className='table table-fixed-height' style={{ padding: '0rem' }}>
                                        <tbody>

                                            <tr className='fixed-height-row' style={{ display: !isFile || isFolder ? "none" : "" }} >
                                                <th style={{ width: '80px', border: filesToShow.length === 0 && searchQuery.length === 0 ? 'none' : '' }} className="web-view">
                                                    <div className='checkbox custom d-inline-block'>
                                                        <label
                                                            style={{
                                                                pointerEvents: filesToShow.length === 0 && searchQuery.length === 0 ? 'none' : 'auto',
                                                                opacity: filesToShow.length === 0 && searchQuery.length === 0 ? 0 : 1,
                                                                pointer: filesToShow.length === 0 && searchQuery.length === 0 ? 'none' : 'cursor'
                                                            }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={mainCheckboxStatus === 'checked'}
                                                                ref={mainCheckboxRef}
                                                                onChange={handleMainCheckboxChange}
                                                                style={{
                                                                    pointerEvents: (filesToShow.length === 0 && searchQuery.length === 0) || (searchQuery.length > 0 && filesToShow.length === 0) ? 'none' : 'auto',
                                                                    opacity: (filesToShow.length === 0 && searchQuery.length === 0) || (searchQuery.length > 0 && filesToShow.length === 0) ? 0 : 1,
                                                                    pointer: (filesToShow.length === 0 && searchQuery.length === 0) || (searchQuery.length > 0 && filesToShow.length === 0) ? 'none' : 'cursor'
                                                                }}
                                                            />
                                                            {mainCheckboxStatus === 'indeterminate' && <span style={{ position: 'absolute', bottom: '1px', left: '6px', color: 'var(--theme-color)', fontWeight: '40', fontSize: '13px' }}>—</span>}

                                                        </label>
                                                    </div>
                                                </th>

                                                <td style={{ display: !isFile ? "none" : "", width: isMobile ? '100px' : '100px', pointer: 'default', border: filesToShow.length === 0 && searchQuery.length === 0 ? 'none' : '' }}>
                                                    <Box sx={{ marginLeft: '-30px' }}>
                                                        <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: 'none', marginBottom: '10px' }}>
                                                            <Toolbar>
                                                                <SearchContainer>
                                                                    <Search>
                                                                        <SearchIconWrapper>
                                                                            <SearchIcon
                                                                                style={{
                                                                                    pointerEvents: filesToShow.length === 0 && searchQuery.length === 0 ? 'none' : 'auto',
                                                                                    opacity: filesToShow.length === 0 && searchQuery.length === 0 ? 0 : 1,
                                                                                    pointer: filesToShow.length === 0 && searchQuery.length === 0 ? 'none' : 'cursor',
                                                                                }}
                                                                            />
                                                                        </SearchIconWrapper>
                                                                        <StyledInputBase
                                                                            placeholder="Search…"
                                                                            inputProps={{ 'aria-label': 'search' }}
                                                                            value={searchQuery}
                                                                            onChange={handleInputChange}
                                                                            style={{
                                                                                pointerEvents: filesToShow.length === 0 && searchQuery.length === 0 ? 'none' : 'auto',
                                                                                opacity: filesToShow.length === 0 && searchQuery.length === 0 ? 0 : 1,
                                                                                pointer: filesToShow.length === 0 && searchQuery.length === 0 ? 'none' : 'cursor',
                                                                            }}
                                                                        />
                                                                    </Search>
                                                                </SearchContainer>
                                                            </Toolbar>
                                                        </AppBar>
                                                    </Box>


                                                </td>

                                                <td className="res-hide-td text-end w-10" style={{ width: isMobile ? '0px' : '200px', maxWidth: '100px', cursor: 'default', border: filesToShow.length === 0 && searchQuery.length === 0 ? 'none' : '' }}>
                                                </td>

                                                <td className="res-hide-td text-end w-10" style={{ width: isMobile ? '0px' : 'z00px', maxWidth: '100px', border: filesToShow.length === 0 && searchQuery.length === 0 ? 'none' : '' }}>
                                                </td>

                                                <td style={{ paddingLeft: "0px", width: '120px', border: filesToShow.length === 0 && searchQuery.length === 0 ? 'none' : '' }} className='file-all-icons'>
                                                    {isAnyChecked && (
                                                        <div style={{
                                                            opacity: isAnyChecked ? '1' : '0',
                                                        }}>
                                                            <span className='icons-hover' data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Move")}>
                                                                <img src={moveGrey} alt="Move" style={{ width: "24px", cursor: "pointer" }} onClick={() => {
                                                                    console.log('Trigger MOVE');
                                                                    showModal('MOVE', { folders: folders, uid: hashedId }, {
                                                                        onMove: onMoveMultiple,
                                                                        onCreateFolder: onCreateFolder
                                                                    });
                                                                }} />
                                                            </span>
                                                            <span className='icons-hover' data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Workspace")}>
                                                                <img src={sharePeopleSvgGrey} alt="Share Team" style={{ width: "24px", cursor: "pointer" }} onClick={() => {
                                                                    console.log('Trigger SHARE-TEAM');
                                                                    showModal('SHARE-TEAM', { teams: teams, uid: hashedId }, {
                                                                        onShareTeam: onShareMultiple,
                                                                        onCreateWorkspace: onCreateWorkspace
                                                                    });
                                                                }} />
                                                            </span>
                                                            <span className='icons-hover files-top-icons-right' data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Delete")}>
                                                                <img src={deleteGrey} alt="Delete" style={{ width: "24px", cursor: "pointer" }} onClick={() => {
                                                                    console.log('Trigger DELETE');
                                                                    showModal('DELETE', { uid: hashedId }, { onDelete: onDeleteMultiple });
                                                                }} />
                                                            </span>
                                                        </div>
                                                    )}
                                                </td>


                                                <td style={{ width: '0px', border: filesToShow.length === 0 && searchQuery.length === 0 ? 'none' : '' }}>
                                                </td>
                                            </tr>
                                            {filesToShow.length > 0 ? (
                                                filesToShow.map((file, index) => (
                                                    <FileItem
                                                        key={file?.SK?.S}
                                                        file={file}
                                                        index={index + ((currentPage - 1) * filesPerPage)}
                                                        onDelete={handleDelete}
                                                        onRename={handleRename}
                                                        onMove={handleMove}
                                                        onShare={handleShare}
                                                        onShareTeam={handleShareTeam}
                                                        folders={folders}
                                                        teams={teams}
                                                        isChecked={checkedItems[file?.SK?.S] || false}
                                                        onCheckboxChange={handleCheckboxChange}
                                                        isFile={isFile}
                                                        onProcessing={fetchFiles}
                                                        isFolder={isFolder}
                                                        onCreateFolder={onCreateFolder}
                                                        onCreateWorkspace={onCreateWorkspace}
                                                        searchQuery={searchQuery}
                                                        fileIndex={index}
                                                        status={file?.TStatus?.S}
                                                    />
                                                ))
                                            ) : (
                                                null
                                            )}
                                            {searchQuery.length > 0 && filesToShow.length === 0 ? (
                                                <>
                                                    {Array.from({ length: 10 }, (_, index) => (
                                                        <FileItem
                                                            key={`placeholder-${index}`}
                                                            file={null}
                                                            onDelete={handleDelete}
                                                            onRename={handleRename}
                                                            onMove={handleMove}
                                                            onShare={handleShare}
                                                            onShareTeam={handleShareTeam}
                                                            folders={folders}
                                                            teams={teams}
                                                            onCheckboxChange={handleCheckboxChange}
                                                            isFile={isFile}
                                                            onProcessing={fetchFiles}
                                                            isFolder={isFolder}
                                                            onCreateFolder={onCreateFolder}
                                                            onCreateWorkspace={onCreateWorkspace}
                                                            emptyFlag={true}
                                                            style={{ visibility: 'hidden', cursor: 'default', border: 'white' }}
                                                        />
                                                    ))}
                                                    <div className="action-buttons-overlay" style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        zIndex: 2
                                                    }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                            {isFile ? <h2>{t("No files available with this search.")}</h2> : <h4>{t("No files available with this search.")}</h4>}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : null}

                                            {filesToShow.length > 0 && filesToShow.length < filesPerPage ? (
                                                Array.from({ length: filesPerPage - filesToShow.length }).map((_, index) => (
                                                    <FileItem
                                                        key={`placeholder-${index}`}
                                                        file={null}
                                                        onDelete={handleDelete}
                                                        onRename={handleRename}
                                                        onMove={handleMove}
                                                        onShare={handleShare}
                                                        onShareTeam={handleShareTeam}
                                                        folders={folders}
                                                        teams={teams}
                                                        onCheckboxChange={handleCheckboxChange}
                                                        isFile={isFile}
                                                        onProcessing={fetchFiles}
                                                        isFolder={isFolder}
                                                        onCreateFolder={onCreateFolder}
                                                        onCreateWorkspace={onCreateWorkspace}
                                                        emptyFlag={true}
                                                        style={{ visibility: 'hidden', cursor: 'default' }}
                                                    />
                                                ))
                                            ) : null}
                                            {(filesToShow.length === 0 && searchQuery.length === 0 && !isMobile) ? (
                                                <>
                                                    {Array.from({ length: isFile ? 10 : 5 }).map((_, index) => (
                                                        <FileItem
                                                            key={`placeholder-${index}`}
                                                            file={null}
                                                            onDelete={handleDelete}
                                                            onRename={handleRename}
                                                            onMove={handleMove}
                                                            onShare={handleShare}
                                                            onShareTeam={handleShareTeam}
                                                            folders={folders}
                                                            teams={teams}
                                                            onCheckboxChange={handleCheckboxChange}
                                                            isFile={isFile}
                                                            onProcessing={fetchFiles}
                                                            isFolder={isFolder}
                                                            onCreateFolder={onCreateFolder}
                                                            onCreateWorkspace={onCreateWorkspace}
                                                            emptyFlag={true}
                                                            style={{ visibility: 'hidden', cursor: 'default', border: "white" }}

                                                        />
                                                    ))}
                                                    <div className="action-buttons-overlay" style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        zIndex: 2
                                                    }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div className="action-buttons-container" style={{ paddingTop: '20px' }}>
                                                                <button onClick={() => initiateFunction("upload")} className='btn header-btn rounded-btn'>
                                                                    <span className="round-color-icon green-bg d-flex justify-content-center align-items-center" style={{ marginRight: '10px' }}>
                                                                        <img src={uploadPng} className='img-fluid' alt='Upload'></img>
                                                                    </span>
                                                                    {t("Upload")}
                                                                </button>
                                                                <button onClick={() => initiateFunction("record")} className='btn header-btn rounded-btn'>
                                                                    <span className="round-color-icon red-bg d-flex justify-content-center align-items-center" style={{ marginRight: '10px' }}>
                                                                        <img src={recordPng} className='img-fluid' alt='Record'></img>
                                                                    </span>
                                                                    {t("Screen Recorder")}
                                                                </button>
                                                                <button onClick={() => initiateFunction("bot")} className='btn header-btn rounded-btn'>
                                                                    <span className="round-color-icon purple-bg d-flex justify-content-center align-items-center" style={{ marginRight: '10px' }}>
                                                                        <img src={botPng} className='img-fluid' alt='Assistant'></img>
                                                                    </span>
                                                                    {t("Meeting Assistant")}
                                                                </button>
                                                            </div>
                                                            {isFile ? <div>{t("No file available. Please use one of the options above.")}</div> : <div>{t("No file available. Please use one of the options above.")}</div>}

                                                      

                                                        </div>

                                                    </div>

                                                </>
                                            ) : null}
                                            {(filesToShow.length === 0 && searchQuery.length === 0 && isMobile) ? (
                                                <>
                                                    {Array.from({ length: isFile ? 2 : 2 }).map((_, index) => (
                                                        <FileItem
                                                            key={`placeholder-${index}`}
                                                            file={null}
                                                            onDelete={handleDelete}
                                                            onRename={handleRename}
                                                            onMove={handleMove}
                                                            onShare={handleShare}
                                                            onShareTeam={handleShareTeam}
                                                            folders={folders}
                                                            teams={teams}
                                                            onCheckboxChange={handleCheckboxChange}
                                                            isFile={isFile}
                                                            onProcessing={fetchFiles}
                                                            isFolder={isFolder}
                                                            onCreateFolder={onCreateFolder}
                                                            onCreateWorkspace={onCreateWorkspace}
                                                            emptyFlag={true}
                                                            style={{ visibility: 'hidden', cursor: 'default', border: "white" }}

                                                        />
                                                    ))}
                                                    <div className="action-buttons-overlay" style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        right: '0%',
                                                        left: isFile ? '30%' : '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        zIndex: 2
                                                    }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div className="action-buttons-container" style={{ paddingTop: '20px' }}>
                                                                <button onClick={() => initiateFunction("upload")} className='btn header-btn rounded-btn'>
                                                                    <span className="round-color-icon green-bg d-flex justify-content-center align-items-center" style={{ marginRight: '10px' }}>
                                                                        <img src={uploadPng} className='img-fluid' alt='Upload'></img>
                                                                    </span>
                                                                    {t("Upload")}
                                                                </button>
                                                                <button onClick={() => initiateFunction("record")} className='btn header-btn rounded-btn'>
                                                                    <span className="round-color-icon red-bg d-flex justify-content-center align-items-center" style={{ marginRight: '10px' }}>
                                                                        <img src={recordPng} className='img-fluid' alt='Record'></img>
                                                                    </span>
                                                                    {t("Screen Recorder")}
                                                                </button>
                                                                <button onClick={() => initiateFunction("bot")} className='btn header-btn rounded-btn'>
                                                                    <span className="round-color-icon purple-bg d-flex justify-content-center align-items-center" style={{ marginRight: '10px' }}>
                                                                        <img src={botPng} className='img-fluid' alt='Assistant'></img>
                                                                    </span>
                                                                    {t("Meeting Assistant")}
                                                                </button>
                                                            </div>

                                                            
                                                            {isFile ? <div>{t("No file available. Please use one of the options above.")}</div> : <div>{t("No file available. Please use one of the options above.")}</div>}

                                                     
                                                        </div>

                                                    </div>

                                                </>
                                            ) : null}

                                        </tbody>
                                    </table>
                                </>

                            )}

                        </div>

                        <nav aria-label="..." style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ padding: '10px 20px', color: '#666', fontSize: '0.9rem', opacity: checkedCount > 0 ? "1" : "0" }}>
                               {t("Checked Items:")} {checkedCount}
                            </div>
                            <ul className='pagination pagination-sm justify-content-end'>
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <Link className="page-link" to="#" tabIndex="-1" aria-disabled="true" onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}>
                                        <span className="fa fa-angle-left"></span>
                                    </Link>
                                </li>

                                {/* Show previous page if it's not the first page */}
                                {currentPage > 1 && (
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage - 1)}>
                                            {currentPage - 1}
                                        </Link>
                                    </li>
                                )}

                                {/* Current Page */}
                                <li className="page-item active">
                                    <Link className="page-link" to="#" onClick={(e) => e.preventDefault()}>
                                        {currentPage}
                                    </Link>
                                </li>

                                {/* Show next page if it's not the last page */}
                                {currentPage < Math.ceil(filesLength / filesPerPage) && (
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage + 1)}>
                                            {currentPage + 1}
                                        </Link>
                                    </li>
                                )}

                                <li className={`page-item ${currentPage === Math.ceil(filesLength / filesPerPage) ? 'disabled' : ''}`}>
                                    <Link className="page-link" to="#" onClick={() => setCurrentPage(Math.min(currentPage + 1, Math.ceil(filesLength / filesPerPage)))}>
                                        <span className="fa fa-angle-right"></span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div >
        </>


    );
}

export default Files;
