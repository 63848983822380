import React, { useRef, useState, useEffect, useContext, } from 'react';
import { useAuth } from '../../Auth/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import recordPng from "../../assets/img/icon/record-btn-icon.svg"
import { showToast } from '../../components/common/toast';
import { Modal } from 'react-bootstrap';
import { Decoder, Reader, tools } from '../../assets/js/EBML';
import { useRecorder } from './RecorderContext';
import { useModal } from '../../components/common/Modal/ModalContext';
import UploaderContext from '../Uploader/UploaderContext';
import { useTranslation } from '../../components/common/LanguageProvider';
import { sendAmplitudeData } from '../../Auth/amplitude';
import { v4 as uuidv4 } from 'uuid';

export const useUploader = () => useContext(UploaderContext);


const Recorder = () => {
  const { hashedId, userData, token, currentUser } = useAuth();
  const { showModal, hideModal } = useModal();

  const { t } = useTranslation();
  const { showRecordingModal, setshowRecordingModal, selectedVideoDevice, setSelectedVideoDevice, selectedAudioDevice, setSelectedAudioDevice, videoDevices, audioDevices, setBlockRecording } = useRecorder();
  const [recording, setRecording] = useState(false);
  const recordingRef = useRef(recording);
  const [showCamera, setShowCamera] = useState(false);
  const [readyToRecord, setReadyToRecord] = useState(false);
  const [paused, setPaused] = useState(false);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  let temp_platform = navigator.platform.toLowerCase();
  const [platform, setPlatform] = useState(null);
  const [recordingMode, setRecordingMode] = useState("screenOnly");

  const [videoInput, setVideoInput] = useState('');
  const [audioInput, setAudioInput] = useState('');
  const webcamPreviewRef = useRef(null);
  const mediaRecorder = useRef(null);
  const orderId = useRef(null);
  const recordedChunks = useRef([]);
  const chunkCounter = useRef(0);
  const recordingStartTime = useRef(null);
  const pausedAt = useRef(0);
  const recordingTimer = useRef(null);
  const screenStream = useRef(null);
  const webcamStream = useRef(null);
  const [recordingDuration, setRecordingDuration] = useState('00:00');
  const stoppedRef = useRef(false);
  const [segmentTimer, setSegmentTimer] = useState(null);
  const segmentDuration = 10000;
  const [videoDisabled, setVideDisabled] = useState(false);

  const location = useLocation();

  var attempt = 0;

  useEffect(() => {
    if (recordingMode === 'screenOnly' || recordingMode === 'micOnly' || recordingMode === 'micSpeaker') {
      setVideDisabled(true);
    } else {
      setVideDisabled(false);
    }
  }, [recordingMode]);

  useEffect(() => {
    recordingRef.current = recording;
  }, [recording]);

  useEffect(() => {
    setBlockRecording(recording);
  }, [recording]);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (recordingRef.current) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const updateRecordingDuration = () => {
    if (recordingStartTime.current) {
      const elapsed = Date.now() - recordingStartTime.current;
      const seconds = Math.floor((elapsed / 1000) % 60);
      const minutes = Math.floor((elapsed / (1000 * 60)) % 60);
      const hours = Math.floor((elapsed / (1000 * 60 * 60)) % 24);

      let formattedTime;
      if (hours > 0) {
        // Include hours in the formatted time if hours > 0
        formattedTime = [hours, minutes, seconds]
          .map(unit => String(unit).padStart(2, '0'))
          .join(':');
      } else {
        // Do not include hours in the formatted time if hours <= 0
        formattedTime = [minutes, seconds]
          .map(unit => String(unit).padStart(2, '0'))
          .join(':');
      }
      setRecordingDuration(formattedTime);
    }
  };


  useEffect(() => {
    return () => {
      // Clear the segment timer when the component unmounts
      if (segmentTimer) {
        clearInterval(segmentTimer);
      }
    };
  }, [segmentTimer]);

  const setupRecorder = (stream, audio) => {
    setPaused(false);
    setRecordingDuration('00:00');
    setRecording(true);

    let options = {
      mimeType: audio ? 'audio/webm'
        : 'video/webm; codecs=vp9,opus'
    };
    mediaRecorder.current = new MediaRecorder(stream, options);

    mediaRecorder.current.ondataavailable = handleDataAvailable;
    mediaRecorder.current.start();

    const newSegmentTimer = setInterval(() => {
      if (mediaRecorder.current.state === "recording") {
        mediaRecorder.current.stop();
        mediaRecorder.current.start();
      }
    }, segmentDuration);
    setSegmentTimer(newSegmentTimer);

    mediaRecorder.current.onstart = () => {
      recordedChunks.current = [];
      if (!recordingStartTime.current) {

        recordingStartTime.current = Date.now()
        recordingTimer.current = setInterval(updateRecordingDuration, 1000);
      }
    };


    mediaRecorder.current.onstop = () => {

    };

    // Any other event handlers or logic needed for your recorder
  };



  const deleteRecording = () => {
    if (window.confirm('Are you sure you want to delete this recording?')) {
      // Stop the media recorder if it's recording
      if (mediaRecorder.current && mediaRecorder.current.state !== "inactive") {
        mediaRecorder.current.stop();
      }

      // Stop the webcam and screen streams if they exist
      if (webcamStream.current) {
        webcamStream.current.getTracks().forEach(track => track.stop());
        webcamStream.current = null;
      }
      if (screenStream.current) {
        screenStream.current.getTracks().forEach(track => track.stop());
        screenStream.current = null;
      }

      clearInterval(segmentTimer);
      setSegmentTimer(null);

      // Reset the recorded chunks
      recordedChunks.current = [];
      // Reset the state related to recording
      setRecording(false);
      setShowCamera(false);
      setPaused(false);
      stoppedRef.current = false;
      setRecordingDuration('00:00');
      recordingStartTime.current = null;


      // Clear any intervals
      clearInterval(recordingTimer.current);

      // Reset the video element source
      if (webcamPreviewRef.current) {
        webcamPreviewRef.current.srcObject = null;
      }
    }
  };

  useEffect(() => {
    if (temp_platform.indexOf('win') !== -1) {
      setPlatform("win")
    } else if (temp_platform.indexOf('mac') !== -1) {
      setPlatform("mac")
    } else {
      setPlatform("other")
    }
  }, []);

  const hideRecordingModal = () => {
    setshowRecordingModal(false);
  };

  const checkPermissionsBeforeRecording = async () => {
    setshowRecordingModal(false);
    if (( recordingMode === "screenCam") && videoDevices.length === 0) {
      showModal("RECORD-NO-VIDEO-DEVICES", {startRecording});
    }else{
      if (audioDevices.length === 0) {
        showModal("RECORD-NO-DEVICES", { startRecording });
      } else {
        startRecording();
      }
    }
    
  };

  const startRecording = async () => {
    switch (recordingMode) {
      case 'screenCam':
        await startScreenCamRecording();
        break;
      case 'screenOnly':
        await startScreenOnlyRecording();
        break;
      case 'camOnly':
        await startCamOnlyRecording();
        break;
      case 'micOnly':
        await startMicOnlyRecording();
        break;

      case 'micSpeaker':
        await startMicSpeakerRecording();
        break;
      default:
        console.error('Invalid recording mode selected');
        break;
    }
  };

  const startScreenCamRecording = async () => {

    if (videoDevices.length === 0) {
      startScreenOnlyRecording();
      return;
    }

    await getOrderId();
    try {
      const screenStreamTemp = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
      const webcamStreamTemp = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: videoInput ? { exact: videoInput } : undefined },
        audio: { deviceId: audioInput ? { exact: audioInput } : undefined }
      });

      screenStream.current = screenStreamTemp;
      webcamStream.current = webcamStreamTemp;

      const videoTrack = screenStream.current.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.onended = handleStopSharing;
      }

      let tracks = [...screenStreamTemp.getVideoTracks(),
      ...mergeAudioStreams(screenStreamTemp, webcamStreamTemp)
      ];

      let combinedStream = new MediaStream(tracks);
      setupRecorder(combinedStream, false);
      setShowCamera(true);

      if (webcamPreviewRef.current) {
        webcamPreviewRef.current.srcObject = webcamStreamTemp;
      }
    } catch (error) {
      console.error('Error starting screen + cam recording:', error);
    }
  };

  const mergeAudioStreams = (desktopStream, voiceStream) => {
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasDesktop = false;
    let hasVoice = false;
    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      // If you don't want to share Audio from the desktop it should still work with just the voice.
      const source1 = context.createMediaStreamSource(desktopStream);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain).connect(destination);
      hasDesktop = true;
    }

    if (voiceStream && voiceStream.getAudioTracks().length > 0) {
      const source2 = context.createMediaStreamSource(voiceStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source2.connect(voiceGain).connect(destination);
      hasVoice = true;
    }

    return hasDesktop || hasVoice ? destination.stream.getAudioTracks() : [];
  };


  const startScreenOnlyRecording = async () => {
    await getOrderId();
    try {
      const screenStreamTemp = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
      let webcamStreamTemp = null

      if (audioDevices.length > 0) {
        webcamStreamTemp = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: audioInput ? { exact: audioInput } : undefined }
        });
      }


      screenStream.current = screenStreamTemp;
      webcamStream.current = webcamStreamTemp;

      const videoTrack = screenStream.current.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.onended = handleStopSharing;
      }

      let tracks = [...screenStreamTemp.getVideoTracks(),
      ...mergeAudioStreams(screenStreamTemp, webcamStreamTemp)];
      let combinedStream = new MediaStream(tracks);
      setupRecorder(combinedStream, false);


    } catch (error) {
      console.error('Error starting screen-only recording:', error);
    }
  };
  const startMicOnlyRecording = async () => {
    await getOrderId();
    try {
      const webcamStreamTemp = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: { deviceId: audioInput ? { exact: audioInput } : undefined }
      });

      webcamStream.current = webcamStreamTemp;

      const audioTrack = webcamStream.current.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.onended = handleStopSharing;
      }

      setupRecorder(webcamStreamTemp, true);


    } catch (error) {
      console.error('Error starting screen-only recording:', error);
    }
  };

  const startMicSpeakerRecording = async () => {
    await getOrderId();
    try {
      const screenStreamTemp = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
      let webcamStreamTemp = null
      if (audioDevices.length > 0) {
         webcamStreamTemp = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: audioInput ? { exact: audioInput } : undefined }
        });
      }

      screenStream.current = screenStreamTemp;
      webcamStream.current = webcamStreamTemp;

      const videoTrack = screenStream.current.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.onended = handleStopSharing;
      }

      let tracks = [
        ...mergeAudioStreams(screenStreamTemp, webcamStreamTemp)
        ,];
      let combinedStream = new MediaStream(tracks);
      setupRecorder(combinedStream, true);


    } catch (error) {
      console.error('Error starting screen-only recording:', error);
    }
  };

  const getOrderId = async () => {
    var payload = {
      feature_category: "transcription",
      feature_subcategory: "integration_transcription",
      feature_name: "recorditor_transcription",
      feature_input_type: "recorditor_order",
      feature_content: "recorditor content",
      feature_id: uuidv4(),
      feature_product: "transkriptor",
      feature_status: "production",
      feature_platform: "web",
      feature_start_timestamp: new Date().getTime(),
      needs_credit: true,
      is_premium: false,
      data_source: "frontend"
      
      }
      sendAmplitudeData("feature_init", userData, currentUser, "", "", "", payload)
    try {

      const response = await fetch(`https://u5ahw8dg5d.execute-api.eu-central-1.amazonaws.com/default/Recorditor-CreateOrder?uid=${hashedId}`);
      const data = await response.json();
      orderId.current = data.orderid;
      return data.orderid;
    } catch (error) {
      console.error('Error getting order ID:', error);
    }
  };

  const startCamOnlyRecording = async () => {

    await getOrderId();
    try {
      const webcamStreamTemp = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: videoInput ? { exact: videoInput } : undefined },
        audio: { deviceId: audioInput ? { exact: audioInput } : undefined }
      });
      webcamStream.current = webcamStreamTemp;
      setupRecorder(webcamStreamTemp, false);
      setShowCamera(true);

      if (webcamPreviewRef.current) {
        webcamPreviewRef.current.srcObject = webcamStreamTemp;
      }
    } catch (error) {
      console.error('Error starting cam-only recording:', error);
    }
  };

  const handleStopSharing = () => {
    stopRecording();
  };
  const startTranscriptionAttempt = async (selectedService, selectedLanguage, orderData) => {
		let sk = null;
		let pk = null;
		try {
      console.log("orderData", orderData)
      var payload = {
        feature_category: "transcription",
        feature_subcategory: "integration_transcription",
        feature_name: "recorditor_transcription",
        feature_input_type: "recorditor_order",
        feature_content: "recorditor content",
        feature_language: selectedLanguage,
        feature_service: selectedService,
        feature_id: uuidv4(),
        feature_product: "transkriptor",
        feature_status: "production",
        feature_platform: "web",
        feature_start_timestamp: new Date().getTime(),
        needs_credit: true,
        is_premium: false,
        data_source: "frontend"
        
        }
        var orderid = orderData?.OrderID;
        sendAmplitudeData("feature_init2", userData, currentUser, "", "", "", payload)
        var temp_folder_id = localStorage.getItem("destinationId");
				if(localStorage.getItem("destinationId") === hashedId){
					temp_folder_id = "";
				}
        var speaker_count = localStorage.getItem("speakerCount");

        var temp_url = `https://s4uj79ppvk.execute-api.eu-central-1.amazonaws.com/default/Recorditor-AutoTranscribe?order_id=${orderid}&feature_id=${payload.feature_id}&feature_start_timestamp=${payload.feature_start_timestamp}`
        if(temp_folder_id && temp_folder_id.length > 0){
          temp_url += `&folder_id=${temp_folder_id}`;
        }
        if(speaker_count && speaker_count.length > 0){
					temp_url += `&speaker_count=${speaker_count}`;
				}

        const response = await fetch(temp_url);
      console.log("response", response)
			if (!response.ok) {
				if (response.status === 406) {
					showModal("UPGRADE-TRANSCRIBE");
				} else {
					const data = await response.json();
					showToast(data.message, { type: "error" });
				}
			} else {
				const data = await response.json();
        console.log("data", data)
				sk = data.sk.replace("#Transkription#", "");
          
        setTimeout(() => {
          let segment = userData.UserSegment;
            if(!window.location.href.includes("editor"))
              navigate(`/editor/${sk}/${hashedId}`)
         
          
          hideModal();
        }, 2000);
			}

		} catch (error) {
		}
		return { 'sk': sk, 'pk': pk };
	}
  const completeOrder = async () => {
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const onlyAudio = recordingMode === 'micOnly' || recordingMode === 'micSpeaker';
      const response = await fetch(`https://xafry4z8tf.execute-api.eu-central-1.amazonaws.com/default/Recorditor-CompleteRecording?uid=${hashedId}&orderid=${orderId.current}&timezone=${timezone}&only_audio=${onlyAudio}`);
      const data = await response.json();
      showToast(t("Your recording has been successfully saved.", { type: "success" }))
      stoppedRef.current = false;
      navigateToRecording(orderId.current);
    } catch (error) {
      console.error('Error completing order:', error);
    }
  };

  const checkStatus = (selectedService, selectedLanguage, data) => {
    if(userData && userData.Minutes > 0){
      showModal("TRANSCRIBE-PROCESSING");

      startTranscriptionAttempt(selectedService, selectedLanguage,  data)
    }else if(userData.Minutes <= 0){
        showModal("UPGRADE-MINUTES", {type: "record_transcription"})
    }else{
        showToast(t("Please Wait", {type:"info"}))
    }

    }


  const onTranscribeRecord = async (selectedService, selectedLanguage, data) => {
    checkStatus(selectedService, selectedLanguage, data)
 
  }
  const navigateToRecording = async (orderId) => {

    const res = await fetch(`https://p8464z3n1m.execute-api.eu-central-1.amazonaws.com/default/Recorditor-GetOrderContent?oid=${orderId}`);
    if (res.ok) {
      const data = await res.json();
      console.log("data", data)
      if (data.recording.TStatus == "Completed" || data.recording.TStatus == "Processing") {
        const url = `https://app.recorditor.com/recording/${orderId}?t=${token}`;
        showModal("TRANSCRIBE-RECORD", {videoData: data.recording, videoLink: url }, {onTranscribeRecord})

        //window.open(url, '_self');
        attempt = 0;
      }
      else {
        if (attempt < 100) {
          attempt++;
          if(attempt === 5){
            showToast(t("Your recording is being processed. This may take a few minutes.", { type: "info" }))
          }
          setTimeout(() => navigateToRecording(orderId), 7000);
        } else {
          attempt = 0;
        }
      }

    }
  }

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      recordedChunks.current.push(event.data);
      chunkCounter.current++;
      uploadCurrentChunk();
    }
  };

  const uploadCurrentChunk = async () => {
    if (recordedChunks.current.length === 0) return;


    if (recordingStartTime.current) {
      const elapsed = Date.now() - recordingStartTime.current;
      const hours = Math.floor((elapsed / (1000 * 60 * 60)) % 24);

      if (hours >= 3) {
        stopRecording();
        completeOrder();
        return;
      }
    }
    const currentBlob = new Blob(recordedChunks.current, { type: 'video/webm' });
    recordedChunks.current = [];
    const seekableBlob = await makeWebMSeekable(currentBlob);
    const presignedUrl = await generatePresignedUrl();
    await uploadPart(presignedUrl, seekableBlob, chunkCounter.current);
    if (stoppedRef.current) {
      completeOrder();
    }
  };

  const generatePresignedUrl = async () => {
    try {
      const response = await fetch(`https://359u9810wl.execute-api.eu-central-1.amazonaws.com/default/Recorditor-CreatePresignedUrl?uid=${hashedId}&orderid=${orderId.current}&chunk=${chunkCounter.current}`);
      const data = await response.json();
      return data.url;
    } catch (error) {
      console.error('Error generating presigned URL:', error);
      throw error;
    }
  };


  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
      pausedAt.current = Date.now();
      setPaused(true);
      clearInterval(recordingTimer.current);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
      setPaused(false);
      const pausedTime = Date.now() - pausedAt.current;
      recordingStartTime.current = recordingStartTime.current + pausedTime;
      recordingTimer.current = setInterval(updateRecordingDuration, 1000);
    }
  };

  const stopRecording = () => {

    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setRecording(false);
      clearInterval(segmentTimer);
      setSegmentTimer(null);
      setShowCamera(false);
      stoppedRef.current = true;
      recordingStartTime.current = null;
      showModal("RECORD-UPLOADING");
      setRecordingDuration('00:00');
      if (screenStream.current) {
        screenStream.current.getTracks().forEach(track => track.stop());
      }
      if (webcamStream.current) {
        webcamStream.current.getTracks().forEach(track => track.stop());
      }
      clearInterval(recordingTimer.current);
      // Handle any other cleanup and state resetting
    }
  };

  const uploadPart = async (url, blob, partNumber) => {
    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: blob
      });

      if (!response.ok) {
        throw new Error(`Failed to upload part: ${partNumber}`);
      }


    } catch (error) {
      console.error('Upload error:', error);
    }
  };
  const makeWebMSeekable = async (inputBlob) => {
    return new Promise((resolve, reject) => {

      var reader = new Reader();
      var decoder = new Decoder();

      const fileReader = new FileReader();
      fileReader.onload = function (e) {
        const ebmlElms = decoder.decode(this.result);
        ebmlElms.forEach((element) => {
          if (element.type !== 'unknown') {
            reader.read(element);
          }
        });
        reader.stop();
        const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
        const body = this.result.slice(reader.metadataSize);
        const newBlob = new Blob([refinedMetadataBuf, body], { type: "video/webm" });
        resolve(newBlob);
      };
      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(inputBlob);
    });
  };

  return (
    <>

      {/* Modal for Recording Settings */}
      <Modal
        show={showRecordingModal}
        onHide={hideRecordingModal}
        className="top-right-modal"
      >
        <Modal.Body >
          {/* Video Settings */}
          <div className="mb-3">
            <label className="form-label">{t("Recording settings")}</label>
            <select className="form-select" value={recordingMode} onChange={(e) => setRecordingMode(e.target.value)}
            >
              <option value="screenCam">{t("Screen + Camera")}</option>
              <option value="screenOnly">{t("Screen Only")}</option>
              <option disabled={videoDevices.length === 0} value="camOnly">{t("Camera Only")}</option>
              <option disabled={audioDevices.length === 0} value="micOnly">{t("Only audio (Microphone)")}</option>
              <option value="micSpeaker">{t("Only audio (Microphone + Speaker)")}</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="form-label">{t("Video settings")}</label>
            {videoDevices.length === 0 ?
              <div className="form-select d-flex " disabled>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video-off" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M10.961 12.365a2 2 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l.714 1H9.5a1 1 0 0 1 1 1v6a1 1 0 0 1-.144.518zM1.428 4.18A1 1 0 0 0 1 5v6a1 1 0 0 0 1 1h5.014l.714 1H2a2 2 0 0 1-2-2V5c0-.675.334-1.272.847-1.634zM15 11.73l-3.5-1.555v-4.35L15 4.269zm-4.407 3.56-10-14 .814-.58 10 14z" />
                  </svg>
                </span>
                <div className="no-device-text">{t("No cameras available")}</div>
              </div>
              :
              <select className="form-select" value={selectedVideoDevice} onChange={(e) => setSelectedVideoDevice(e.target.value)}
                disabled={videoDisabled}
                style={videoDisabled ? { cursor: 'not-allowed', opacity: 0.5 } : {}}>
                {videoDevices.map(device => (
                  <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
                ))}
              </select>
            }
          </div>
          <div className="mb-3">
            <label className="form-label">{t("Audio settings")}</label>
            {audioDevices.length === 0 ?
              <div className="form-select d-flex" disabled>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mic-mute" viewBox="0 0 16 16">
                    <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4 4 0 0 0 12 8V7a.5.5 0 0 1 1 0zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a5 5 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4m3-9v4.879l-1-1V3a2 2 0 0 0-3.997-.118l-.845-.845A3.001 3.001 0 0 1 11 3" />
                    <path d="m9.486 10.607-.748-.748A2 2 0 0 1 6 8v-.878l-1-1V8a3 3 0 0 0 4.486 2.607m-7.84-9.253 12 12 .708-.708-12-12z" />
                  </svg>
                </span>
                <div className="no-device-text">{t("No microphones available")}</div>
              </div>
              :
              <select className="form-select" value={selectedAudioDevice} onChange={(e) => setSelectedAudioDevice(e.target.value)}>
                {audioDevices.map(device => (
                  <option key={device.deviceId} value={device.deviceId}>{device.label}</option>
                ))}
              </select>
            }
          </div>
          <div class="text-center">
            <button class="btn theme-btn mt-3" onClick={checkPermissionsBeforeRecording}>
              {t("Record")}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <div className='webcam-circle' style={{
        display: showCamera ? 'block' : 'none',
        overflow: 'hidden'

      }}>
        <video ref={webcamPreviewRef} className='webcam-video' autoPlay muted style={{
          display: showCamera ? 'block' : 'none',

        }}></video>
      </div>
      <div className="video-controller" style={{
        display: recording ? 'flex' : 'none',
      }}>
        <button id="stopRecord" class="btn " onClick={stopRecording}>
          <i class="fa fa-stop text-danger" style={{ fontSize: '24px', backgroundColor: 'transparent', margin: '10px 0 10px 0', color: 'white' }}></i>
        </button>
        <button id="pauseButton" class="btn " style={{ display: paused ? 'none' : null }} onClick={pauseRecording} disabled={paused}>
          <i class="fa fa-pause" style={{ fontSize: '24px', margin: '10px 0 10px 0', backgroundColor: 'transparent', color: 'white' }}></i>
        </button>
        <button onClick={resumeRecording} style={{ display: !paused ? 'none' : null, backgroundColor: 'transparent', color: 'white' }} disabled={!paused} id="resumeButton" class="btn ">
          <i class="fa fa-play" style={{ fontSize: '24px', margin: '10px 0 10px 0' }}></i>
        </button>
        <span style={{ color: 'white', fontSize: '24px', margin: '6px 6px 0 6px' }}>
          {recordingDuration}
        </span>

        {/* Divider */}
        <div style={{
          height: '32px',
          width: '2px',
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
          margin: '10px 0 10px 10px',
        }}></div>

        <button id="deleteButton" onClick={deleteRecording} class="btn ">
          <i class="fa fa-trash-o" style={{ fontSize: '24px', backgroundColor: 'transparent', margin: '10px 0 10px 0', color: 'white' }}></i>
        </button>

      </div>

    </>
  );
};

export default Recorder;

