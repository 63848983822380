import { sendAmplitudeData } from "../Auth/amplitude";
export const subscription_aware = async(userData, currentUser, type) => {   
    const url = "/payment";
    const current_url = window.location.href;
    const website = "transkriptor"
    var cta_event = "subscription_evaluate"
    var content = "Upgrade to get more minutes and transcribe more files."
    var reason = "insufficient_credit"
    var cta_url = `app.${website}.com${url}`
    if(type === "trial_file_limit"){
        content = "You can only transcribe one file at a time."
        reason = type;
        cta_url = "checkout.stripe.com"
        cta_event = "subscription_checkout"

    }else if(type === "premium_feature"){
        reason = type;
        content = "Full transcript download is available only in paid accounts"
    }else if(type === "header_upgrade_button" || type === "sidebar_upgrade_button" || type === "upgrade_box"){
        reason = "check_premium";
        content = "Upgrade"
    console.log("Subscription Aware", userData, currentUser, type)
    } 
    if(type === "sidebar_upgrade_button" && userData.SStatus === "Active"){
        cta_url = "checkout.stripe.com"
        cta_event = "subscription_checkout"
    }
    const payload = {
        data_source: "frontend",
        subscription_platform: "web",
        subscription_cta_url: cta_url,
        subscription_cta_event: 'subscription_evaluate',
        subscription_language: localStorage.getItem("dashboard-language"),
        subscription_cta_content: content !== "Upgrade" ? `${reason}_modal` : type, 
        subscription_feature: content !== "Upgrade" ? `${type}` : "none",
        subscription_content: content,
        subscription_reason: reason,
        subscription_product: website,
        subscription_feature_status: "production"
    }
    if(type === "trial_file_limit"){
        payload.subscription_feature = "local_transcription"
    }
    sendAmplitudeData("subscription_aware", userData, currentUser, payload.subscription_cta_content, current_url, payload.subscription_cta_url, payload)
    
}
export const subscription_evaluate = async(userData, currentUser, type) => {
    const url = "/payment";
    const current_url = window.location.href;
    const website = "transkriptor"
    var content = "Upgrade to get more minutes and transcribe more files."
    var reason = "insufficient_credit"
    var cta_url = `app.${website}.com${url}`
    if(type === "premium_feature"){
        reason = type;
        content = "Full transcript download is available only in paid accounts"
    }else if(type === "header_upgrade_button" || type === "sidebar_upgrade_button" || type === "upgrade_box"){
        reason = "check_premium";
        content = "Upgrade"
    } 

    const payload = {
        data_source: "frontend",
        subscription_platform: "web",
        subscription_cta_media: "web_app",
        subscription_cta_url: cta_url,
        subscription_language: localStorage.getItem("dashboard-language"),
        subscription_cta_content: content !== "Upgrade" ? `${reason}_modal` : type, 
        subscription_feature: content !== "Upgrade" ? `${type}` : "none",
        subscription_content: content,
        subscription_reason: reason,
        subscription_product: website,
        subscription_feature_status: "production"
    }

    sendAmplitudeData("subscription_evaluate", userData, currentUser, payload.subscription_cta_content, current_url, payload.subscription_cta_url, payload)
    
}

export const subscription_checkout = async(userData, currentUser, type, interval, cta_content) => {
    
    const checkout_product = "transkriptor"
    const current_url = window.location.href;
    let temp_price = 0;
    if(type.includes('Hours')){
        temp_price = parseInt(type.split("-")[0], 10) * 60
    }
    const packages = {
        "Lite": 300,
        "Premium": 2400,
        "Business": 3000,
    }
    let subscription_credit_limit = 0;

    if(type.includes('Hours')){
        subscription_credit_limit = temp_price
    }else if(packages[type]){
        subscription_credit_limit = packages[type]
    }else if(type.includes("Business")){
        subscription_credit_limit = 3000
    }
    const payload = {
        data_source: "frontend",
        subscription_platform: "web",
        subscription_cta_content: cta_content, 
        subscription_cta_url: "checkout.stripe.com",
        subscription_content: "main_pricing_table",
        subscription_reason: userData.SStatus == "Active" ? "renewal_subscription": "new_subscription",
        subscription_product: checkout_product,    
        subscription_package: type,
        subscription_interval: interval,
        subscription_credit_limit: subscription_credit_limit,
    }
    sendAmplitudeData("subscription_checkout", userData, currentUser, payload.subscription_cta_content, current_url, payload.subscription_cta_url, payload)
    
}
export const feature_review = async(userData, currentUser, rating, feedback, transcription_type, transcription_duration, transcription_order_id, transcription_language) => {
    const current_url = window.location.href;
    const website = "transkriptor"
    const payload = {
        data_source: "frontend",
        feature_platform: "web",
        feature_cta_content: transcription_type,
        feature_product: website,
        feature_status: "production",
        feature_rating: rating,
        feature_feedback: feedback,
        file_duration: transcription_duration,
        order_id: transcription_order_id,
        order_language: transcription_language,
        feature_name: transcription_type
    }
    sendAmplitudeData("feature_review", userData, currentUser, payload.feature_review_cta_content, current_url, null, payload)
}
export const feature_view = async(userData, currentUser, rating, feedback, transcription_type, transcription_duration, transcription_order_id, transcription_language) => {
    const current_url = window.location.href;
    const website = "transkriptor"
    const payload = {
        data_source: "frontend",
        feature_platform: "web",
        feature_cta_content: transcription_type,
        feature_product: website,
        feature_status: "production",
        file_duration: transcription_duration,
        order_id: transcription_order_id,
        order_language: transcription_language,
        feature_name: transcription_type
    }
    sendAmplitudeData("feature_view", userData, currentUser, payload.feature_review_cta_content, current_url, null, payload)
}

