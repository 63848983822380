import { Modal } from "react-bootstrap";
import uploadBox from "../../../assets/img/upload/upload-box.png";
import fileUrl from "../../../assets/img/upload/file-url.png";
import languagePng from "../../../assets/img/upload/language.png";
import servicePng from "../../../assets/img/upload/service.png";
import googleDriveFile from "../../../assets/img/upload/google-drive-file.png";

import { useTranslation } from "../LanguageProvider";

function FAQModal({ isOpen, onClose, faqData = [] }) {
    const { t } = useTranslation();

    const renderAnswer = (answer) => {
        return typeof answer === 'string' ? <p>{t(answer)}</p> : answer;
    };

    return (
        <Modal centered show={isOpen} onHide={onClose} size='xl'>
            <Modal.Body>
                <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                <div className="col-12 sec-pad-top">
                    <h3 className="medium-title text-center mb-lg-5 mb-4">{t("Frequently Asked Questions")}</h3>
                    <div className="card card-padding card-margin faq-modal-card">
                        <div className="accordion accordion-flush dark-color" id="accordionFlushExample">
                            {faqData.map((item, index) => {
                                const targetId = `flush-collapse${index}`;
                                const headerId = `flush-heading${index}`;

                                return (
                                    <div className="accordion-item" key={index}>
                                        <span className="collapse-icon fa fa-angle-double-right"></span>
                                        <h2 className="accordion-header" id={headerId}>
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#${targetId}`}
                                                aria-expanded="false"
                                                aria-controls={targetId}
                                            >
                                                {t(item.question)}
                                            </button>
                                        </h2>
                                        <div
                                            id={targetId}
                                            className="accordion-collapse collapse"
                                            aria-labelledby={headerId}
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">
                                                {renderAnswer(item.answer)}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default FAQModal;