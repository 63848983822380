import React, { useRef, useState, useEffect, useCallback } from "react";

function Player({ audio, focusedTime, onDurationChange, applyFill, setFocus, setSlideFocus, playBlock }) {

    const audioRef = useRef(null);
    const [status, setStatus] = useState("paused");
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [audioSpeed, setAudioSpeed] = useState(1.0);
    const sliderRef = useRef(null);
    const isMac = () => {
        return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    }
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const dropdownRef = useRef(null);

    // Click outside to close the dropdown
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Cleanup on component unmount
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);
    const handleKeyDown = (event) => {
        if (event && ((event.altKey) || (!isMac() && event.ctrlKey))) {
            // Handle combo space
            const action = shortcuts[event.code];
            if (action) {
                event.preventDefault();
                action();
            }
        }
        const audio = audioRef.current;

        if (!audio) return;

        // Detect the key combination for speeding up (ctrl + 9)
        if (event.ctrlKey && event.key === '9') {
            event.preventDefault();
            if (audio.playbackRate < 2.0) { audio.playbackRate += 0.25; }
        } // Detect the key combination for slowing down (ctrl + 0)
        else if (event.ctrlKey && event.key === '0') {
            event.preventDefault(); if (audio.playbackRate > 0.25) {
                audio.playbackRate -= 0.25;
            }
        }
        setAudioSpeed(audio.playbackRate);

    };

    const changeSpeed = (speed) => {
        const audio = audioRef.current;
        audio.playbackRate = speed;
        setAudioSpeed(speed);
    }

    useEffect(() => {
        const audioElement = audioRef.current;

        const handleTimeUpdate = () => {
            setCurrentTime(audioElement.currentTime);
            focusedTime = audioElement.currentTime * 1000;
            applyFill(audioElement.previousElementSibling)
            setFocus(focusedTime)

        };

        const handleLoadedMetadata = () => {
            setDuration(audioElement.duration);
            applyFill(audioElement.previousElementSibling)

        };

        const handlePlay = () => {
            setStatus("playing");
        };

        const handlePause = () => {
            setStatus("paused");
        };

        const handleEnded = () => {
            setStatus("paused");
        };

        // Add event listeners
        audioElement.addEventListener("timeupdate", handleTimeUpdate);
        audioElement.addEventListener("loadedmetadata", handleLoadedMetadata);
        audioElement.addEventListener("play", handlePlay);
        audioElement.addEventListener("pause", handlePause);
        audioElement.addEventListener("ended", handleEnded);
        document.addEventListener("keydown", handleKeyDown);

        // Cleanup
        return () => {
            audioElement.removeEventListener("timeupdate", handleTimeUpdate);
            audioElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
            audioElement.removeEventListener("play", handlePlay);
            audioElement.removeEventListener("pause", handlePause);
            audioElement.removeEventListener("ended", handleEnded);
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        onDurationChange((duration));
    }, [duration]);
    useEffect(() => {
        const audioElement = audioRef.current;
        audioElement.currentTime = focusedTime / 1000;
    }, [focusedTime]);
    const playAudio = () => {
        if (audioRef.current) {
            if (audioRef.current.paused) {
                audioRef.current.play();
                setStatus("playing");
            } else {
                audioRef.current.pause();
                setStatus("paused");
            }
        }
    };
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        const min = Math.floor(seconds / 60);
        const sec = Math.floor(seconds % 60);

        if (hours > 0) {
            return `${hours}:${min.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
        } else {
            return `${min}:${sec.toString().padStart(2, "0")}`;
        }
    };

    const handleSliderChange = (e) => {
        const audioElement = audioRef.current;
        audioElement.currentTime = e.target.value;
        applyFill(e.target);
        setSlideFocus(e.target.value * 1000);
        if (audioRef.current && audioRef.current.paused) {
            audioRef.current.play();

        }
    }

    const handleCurrentTime = (time) => {
        const audioElement = audioRef.current;
        audioElement.currentTime += time;
    };
    const shortcuts = {
        "Space": playAudio, // spacebar
        "ArrowRight": () => handleCurrentTime(5), // placeholder, replace with your function
        "ArrowLeft": () => handleCurrentTime(-5) // placeholder, replace with your function
        // add more key mappings here
    };

    const speedList = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

    return (
        <>

            <div class="audio-play-div-sharing notranslate">
                <div class="water-slider">
                    <div className="range-slider">
                        <span className="range-slider__value">{formatTime(currentTime)}</span>
                        <input id="range-slider-val" className="range-slider__range browser-default" type="range"
                            value={currentTime} min="0" max={duration} ref={sliderRef} onChange={handleSliderChange} />
                        <audio ref={audioRef} src={audio} preload="metadata" id="audio-src"></audio>
                    </div>

                </div>

                <div class="audio-details-div">
                    <span class="play-time pointer">{formatTime(currentTime)}</span>
                    <div class="audio-play-icons">
                        <div class="pointer theme-color hover-effect" onClick={() => handleCurrentTime(-5)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="35.998" height="27.004"
                                viewBox="0 0 35.998 27.004">
                                <path id="forward"
                                    d="M.807,16.27l13.5-11.25A2.253,2.253,0,0,1,18,6.75v22.5a2.251,2.251,0,0,1-3.691,1.73L.807,19.73a2.257,2.257,0,0,1,0-3.459Zm18,0,13.5-11.25A2.253,2.253,0,0,1,36,6.75v22.5a2.251,2.251,0,0,1-3.691,1.73l-13.5-11.25a2.257,2.257,0,0,1,0-3.459Z"
                                    transform="translate(0 -4.499)" fill="currentColor" />
                            </svg>
                            <span className="hover-span">
                                Rewind 5 seconds
                            </span>
                        </div>

                        <div className="hover-effect">
                            <div class=" play-theme-round pointer" style={{ display: status === "paused" ? "" : "none" }}
                                onClick={playAudio}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24.768" height="28.308"
                                    viewBox="0 0 24.768 28.308">
                                    <path id="play"
                                        d="M23.464,11.87,4,.365A2.639,2.639,0,0,0,0,2.649v23a2.652,2.652,0,0,0,4,2.283l19.461-11.5a2.651,2.651,0,0,0,0-4.567Z"
                                        transform="translate(0 -0.002)" fill="#fff" />
                                </svg>

                            </div>
                        </div>
                        <div className="hover-effect">
                            <div className="play-theme-round pointer "
                                style={{ display: status === "playing" ? "" : "none" }} onClick={playAudio}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24.768" height="28.308"
                                    viewBox="0 0 24.768 28.308">
                                    <rect id="pause_left" x="3" y="0" width="7" height="28.308" fill="#fff" />
                                    <rect id="pause_right" x="14.768" y="0" width="7" height="28.308" fill="#fff" />
                                </svg>

                            </div>

                        </div>

                        <div class="pointer theme-color hover-effect " onClick={() => handleCurrentTime(5)} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="35.998" height="27.004"
                                viewBox="0 0 35.998 27.004">
                                <path id="forward"
                                    d="M35.191,16.27,21.691,5.02A2.253,2.253,0,0,0,18,6.75v22.5a2.251,2.251,0,0,0,3.691,1.73l13.5-11.25a2.257,2.257,0,0,0,0-3.459Zm-18,0L3.691,5.02A2.253,2.253,0,0,0,0,6.75v22.5a2.251,2.251,0,0,0,3.691,1.73l13.5-11.25a2.257,2.257,0,0,0,0-3.459Z"
                                    transform="translate(0 -4.499)" fill="currentColor" />
                            </svg>
                            <span className="hover-span">
                                Skip 5 seconds
                            </span>
                        </div>
                    </div>
                    <div className="dropdown sharing-speed" ref={dropdownRef} onMouseLeave={toggleDropdown}>
                        {isOpen && (
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink"
                                style={{ display: "block", bottom: "100%" }}>
                                {
                                    speedList.map((item, i) => {
                                        const activeClass = audioSpeed === item ? 'selected-speaker' : ''; // replace 'active-class' with your desired class name
                                        return (
                                            <a className={`dropdown-item d-flex justify-content-between ${activeClass}`} onClick={() => {
                                                changeSpeed(item)
                                                toggleDropdown();
                                            }

                                            }>
                                                <span>{item}</span>

                                            </a>
                                        );

                                    })
                                }

                            </ul>
                        )}

                        <a className="play-speed pointer" role="button" onMouseEnter={toggleDropdown}>
                            {(audioSpeed % 1 === 0) ? audioSpeed.toFixed(1) : audioSpeed}X

                        </a>

                    </div>

                </div>
            </div>
        </>
    );
}

export default Player;