import React, { useState, useEffect, useRef } from "react";

import { useModal } from "../../components/common/Modal/ModalContext";
import { useTranslation } from '../../components/common/LanguageProvider'; 
function SpeakerDropdown({
  index,
  speaker,
  onAddSpeaker,
  speakerList,
  changeSpeaker,
  onRenameSpeaker,
  fontSize,
}) {
  const { t } = useTranslation();
  // State for dropdown visibility
  const { showModal } = useModal();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(); // Ref for the dropdown container

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close dropdown when clicked outside
      }
    };

    // Adding click event listener when component is mounted
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup: Removing event listener when component is unmounted
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);
  return (
    <>
      <div
        className="transcript-dropdown dropdown notranslate"
        ref={dropdownRef}
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title={speaker}
      >
        <a
          className="btn dropdown-toggle"
          aria-labelledby="dropdownMenuLink"
          role="button"
          style={{ fontSize: fontSize + "px" }}
          aria-expanded="false"
          onClick={toggleDropdown} // Toggle dropdown when this is clicked
        >
          <span className="speaker-span">{t(speaker)}</span>
        </a>

        {isDropdownOpen && speakerList && (
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuLink"
            style={{ display: "block" }}
          >
            <li>
              <a className="dropdown-item d-flex justify-content-between">
                <span>{t("Change Speaker")}</span>
                <span
                  class="pointer"
                  onClick={() => showModal("ADD-SPEAKER", { onAddSpeaker })}
                >
                  <span
                    class="theme-color"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title=""
                    data-bs-original-title="Add New"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                    </svg>
                  </span>
                </span>
              </a>
            </li>
            <li>
              {speakerList.map((item, i) => {
                const activeClass = speaker === item ? "selected-speaker" : ""; // replace 'active-class' with your desired class name
                return (
                  <a
                    className={`dropdown-item d-flex justify-content-between ${activeClass}`}
                    onClick={() => {
                      changeSpeaker(item, index);
                      toggleDropdown();
                    }}
                  >
                    <span>{t(item)}</span>
                    <span
                      className="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        showModal(
                          "RENAME-SPEAKER",
                          { speaker: item },
                          { onRenameSpeaker }
                        );
                      }}
                    >
                      <span
                        className="theme-color"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title={t("Rename")}
                        data-bs-original-title="Rename"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pencil"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                      </span>
                    </span>
                  </a>
                );
              })}
            </li>
          </ul>
        )}
      </div>
    </>
  );
}

export default SpeakerDropdown;
