// FileItem.js
import React, { useRef, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useModal } from '../../components/common/Modal/ModalContext'
import renameSvg from '../../assets/img/icon/rename.svg'
import moveSvg from '../../assets/img/icon/move.svg'
import sharePeopleSvg from '../../assets/img/icon/share-people.svg'
import deleteSvg from '../../assets/img/icon/delete.svg'
import { useTranslation } from '../../components/common/LanguageProvider';
import Download from '../Editor/Download';
import renameGreySvg from '../../assets/img/icon/rename-grey.svg'
import shareSvg from '../../assets/img/icon/share.svg'
import shareGreySvg from '../../assets/img/icon/share-grey.svg'

import CustomizedMenus from './CustomizedMenus';

function highlightText(text, query) {
    const maxLength = 20; // Define the max length before text gets truncated
    const textToShow = text.length > maxLength ? text.substring(0, maxLength) : text;
    const textHidden = text.substring(maxLength);
    const isQueryInTruncated = textHidden.toLowerCase().includes(query.toLowerCase());

    // Check if query is in both visible and truncated text
    const regex = new RegExp(`(${query})`, 'gi');
    const highlightedEllipsis = isQueryInTruncated ? <span style={{ backgroundColor: 'yellow' }}>...</span> : '...';

    const parts = textToShow.split(regex);
    return (
        <span>
            {parts.map((part, i) =>
                regex.test(part) ? <span key={i} style={{ backgroundColor: 'yellow' }}>{part}</span> : part
            )}
            {text.length > maxLength && highlightedEllipsis}
        </span>
    );
}

function FileItem({ file, index, onDelete, onRename, onMove, onShareTeam, onShare, folders, teams, isChecked, onCheckboxChange, isFile, isFolder, onCreateFolder, onCreateWorkspace, style, searchQuery, status }) {
    const { t } = useTranslation();
    const uid = file?.PK?.S.replace('User#', "");
    const tid = file?.SK?.S.replace('#Transkription#', '');
    const name = file?.TempName?.S;
    const orderid = file?.OrderID?.S;
    var date_flag = false;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [audio, setAudio] = useState(null);

    const isProcessing = status === "Processing";
    const isFailed = status === "Failed";

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const placeholder = "—";
    const placeholderStyle = { padding: '20px 0', textAlign: 'center' };

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1300);

    try {
        var myDate;
        if (file?.Date?.N) {
            myDate = new Date(parseInt(file.Date.N)); // assuming file.Date.N is a Unix timestamp in milliseconds
        } else {
            myDate = new Date(); // using current date
        }
        var currentDate = Date.now();
        var diffInMs = currentDate - myDate.getTime();
        if (diffInMs < (1000 * 60 * 60 * 24)) {
            date_flag = true;
        }
    } catch (error) {
        console.error("Error while parsing and comparing dates:", error);
    }
    var status = file?.TStatus?.S;
    if (status == "Processing" && !date_flag) {
        status = "";
    }


    useEffect(() => {
        console.log('FileItem props:', { onCreateFolder, onMove });
    }, [onCreateFolder, onMove]);


    var myDate = new Date(parseInt(file?.Date?.N)); // assuming r.Date.N is a Unix timestamp in milliseconds

    let timer = myDate.toLocaleString();
    let timer2 = myDate.toLocaleDateString();
    const { showModal } = useModal();

    // Action handlers
    const handleActionDelete = () => showModal('DELETE', { tid: tid, uid: uid }, { onDelete });
    const handleActionRename = () => showModal('RENAME', { name: name, tid: tid, uid: uid }, { onRename });
    const handleActionMove = () => showModal('MOVE', { folders: folders, uid: uid, tid: tid }, { onMove, onCreateFolder: onCreateFolder });
    const handleActionShare = () => onShare(orderid);
    const handleActionShareTeam = () => showModal('SHARE-TEAM', { teams: teams, tid: tid, uid: uid }, { onShareTeam, onCreateWorkspace });

    return (
        <tr onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ backgroundColor: isHovered ? '#f5f5f5' : 'transparent', cursor: 'pointer', ...style }}>
            <th style={{ display: !isFile || isFolder ? "none" : "", width: '80px' }} className="web-view">
                <div className='checkbox custom d-inline-block'>
                    <label>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={(e) => onCheckboxChange(file?.SK?.S, e.target.checked)}
                            style={{ visibility: file ? 'visible' : 'hidden' }}
                        />
                        {file ? index + 1 : <span style={placeholderStyle}>{placeholder}</span>}
                    </label>
                </div>
            </th>

            <td class="pointer" style={{ width: isSmallScreen ? '100px' : '200px', opacity: !name ? 0 : 1 }}>
                <Link to={`/editor/${tid}/${uid}`}
                    style={{
                        display: 'block',
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <span className="dark-color regular filename">
                        {searchQuery ? highlightText(name || "Unnamed", searchQuery) : (name || "Unnamed")}
                    </span>
                </Link>

            </td>

            <td class="res-hide-td text-end" style={{ width: (isSmallScreen) ? '200px' : '250px' }}>
                <Link to={`/editor/${tid}/${uid}`} >

                    {(isFile || isFolder) ? timer : timer2}
                </Link>

            </td>

            {isFile && (
                <td className="res-hide-td text-end w-10" style={{ width: (isSmallScreen) ? '0px' : '100px' }}>
                    <Link to={`/editor/${tid}/${uid}`} className="fileitem-linkto">
                        <span className="status processing" style={{ display: file?.TStatus?.S === "Processing" ? "" : "none" }}>
                            <div className="spinner"></div>
                            {t("Processing")}
                        </span>
                        <span className="status failed" style={{ display: file?.TStatus?.S === "Failed" ? "" : "none" }}>
                            {t("Failed")}
                        </span>
                    </Link>
                </td>
            )}

            {isFile && (
                <td className='text-end' style={{ opacity: (isFailed || isProcessing) ? 0 : 1, pointerEvents: (isFailed || isProcessing) ? 'none' : 'cursor' }}>
                    <div style={{ opacity: isHovered ? 1 : 0, transition: 'opacity 0.3s' }}>
                        <span onClick={isProcessing ? null : handleActionRename} style={{ opacity: isProcessing || isFailed ? 0 : 1, pointerEvents: isProcessing || isFailed ? 'none' : 'auto' }}>
                            <span className="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Rename")}>
                                <img src={renameGreySvg} alt="Rename" />
                            </span>
                        </span>
                        <span onClick={handleActionShare} style={{ opacity: isProcessing || isFailed ? 0 : 1, pointerEvents: isProcessing || isFailed ? 'none' : 'auto' }}>
                            <span className="icons-hover" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Share")}>
                                <img src={shareGreySvg} className="img-fluid" alt="Share" />
                            </span>
                        </span>
                        <Download
                            content={[]}
                            filename={"filename"}
                            audio={audio}
                            contentRefs={"contentRefs"}
                            orderId={orderid}
                            setChangeDetect={"setChangeDetect"}
                            isFiles={true}
                            isDisabled={isProcessing || isFailed}
                            isTranslated={false}
                        />
                    </div>
                </td>
            )}
            <td className='text-start'>
                <Link to={`/editor/${tid}/${uid}`} className="fileitem-linkto">
                    <CustomizedMenus
                        onDelete={handleActionDelete}
                        onRename={handleActionRename}
                        onMove={handleActionMove}
                        onShare={handleActionShare}
                        onShareTeam={handleActionShareTeam}
                        style={{ zIndex: 1000 }}
                        onClick={(e) => e.stopPropagation()}
                        isProcessing={isProcessing}
                        isFile={isFile}
                        status={status}
                    />
                </Link>
            </td>
        </tr >
    );
}

export default FileItem;