import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { fetchData } from "../../hooks/apiUtils";
import { useAuth } from "../../Auth/AuthContext";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../common/toast";
import { useTranslation } from "../common/LanguageProvider";
import {subscription_checkout } from '../../ampli/index'
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_live_51Nk7u2FuPyxctGpd9JFAbLArotQsafcIfpmppPPg3wbC7QuIQu6CS8Kq1pjrHbHqkKoWU1Vdsb2LYbMqdDK1R4YK00zyGnhAM2"
);

export default function StripePayment(props) {
  const [clientSecret, setClientSecret] = useState(null);
  const location = useLocation();
  const { product, quantity, hashedId} = location.state || {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!product || !hashedId) {
      navigate("/payment");
      return;
    }
    const fetchClientSecret = async () => {
      if (hashedId) {
       
        try {
          const response = await fetchData(
            "https://7hpyg0at2d.execute-api.eu-central-1.amazonaws.com/default/Subscription-RedirectCheckoutPageEmbedded?product=" + product + "&cid=" + hashedId + "&quantity=" + quantity);
          setClientSecret(response.clientSecret);
        } catch (error) {
          console.error("Error fetcxhing client secret:", error);
          navigate("/payment")
          showToast(t("Error while fetching the data. Please try again."), { type: "error" })
          
        }
     
      }
    };

    fetchClientSecret();
  }, [hashedId]);

 
  const options = clientSecret ? { clientSecret } : {};

  return (
    <div id="checkout" className="checkout-modal-view">
      {clientSecret ? (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : (
        <Box sx={{ width: "100%", marginTop: "21.07px" }}>
          <LinearProgress />
        </Box>
      )}
    </div>
  );
}
