import React,  {useState, useRef} from "react";
import { Container } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import ReactPlayer from 'react-player';
import VideoControls from './VideoControls';
import screenfull from 'screenfull';
import "./MeetingView.css";
export const VideoPlayer = ({ video }) => {
	const [playing, setPlaying] = useState(false);
	const [volume, setVolume] = useState(0.5);
	const [muted, setMuted] = useState(false);
	const [playbackRate, setPlaybackRate] = useState(1.0);
	const [played, setPlayed] = useState(0);
	const [loaded, setLoaded] = useState(0);
	const [seeking, setSeeking] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const videoContainerRef = useRef(null);
	const [isAudio, setIsAudio] = useState(false);

	const playerRef = useRef(null);
	const controlRef = useRef(null);
	const containerClass = isFullscreen ? 'fullscreen-mode' : '';

	const handleMute = () => {
		if (!muted) {
			setPrevVolume(volume);
			setVolume(0);
		} else {
			setVolume(prevVolume);
		}
		setMuted(!muted);
	};

	const [duration, setDuration] = useState(0);
	const videoPlayerRef = useRef(null);
	const videoControlRef = useRef(null);
	const [videoLoading, setVideoLoading] = useState(true);
	const [isBuffering, setIsBuffering] = useState(false);

	const [currentTime, setCurrentTime] = useState(0);



	const undoRef = useRef(null);
	const redoRef = useRef(null);
	const videoRef = useRef(null);
	const playerWrapperRef = useRef(null);


	const [prevVolume, setPrevVolume] = useState(0.5);

	const [videoState, setVideoState] = useState({
		playing: true,
		muted: false,
		volume: 0.5,
		played: 0,
		seeking: false,
		Buffer: true,
		playbackRate: 1,
	});

	const playPauseHandler = () => {
		setVideoState({ ...videoState, playing: !videoState.playing });
	};

    
	const progressHandler = (state) => {
		console.log('Progress event:', state);

		if (!seeking) {
			setVideoState({ ...videoState, ...state });
		}
	};

	const seekHandler = (e, value) => {
		console.log('Seeking to:', parseFloat(value) / 100);
		//setVideoState({ ...videoState, played: parseFloat(value) / 100 });
		videoPlayerRef.current.seekTo(value / 100);

	};

	const seekMouseUpHandler = (e, value) => {
		console.log('Seek released at:', value / 100);
		setVideoState({ ...videoState, seeking: false });
		videoPlayerRef.current.seekTo(value / 100);
	};

	const handleReplay = () => {
		videoPlayerRef.current.seekTo(0);
		setPlaying(true);
	};

	const volumeChangeHandler = (event, value) => {
		event && event.stopPropagation();
		const newVolume = parseFloat(value) / 100;
		console.log('Volume changed:', newVolume);
		setVideoState({
			...videoState,
			volume: newVolume,
			muted: Number(newVolume) === 0 ? true : false,
		})

	};

	const volumeSeekUpHandler = (event, value) => {

		event.stopPropagation();
		const newVolume = parseFloat(value) / 100;
		console.log('Volume released at:', newVolume);
		setVideoState({
			...videoState,
			volume: newVolume,
			muted: newVolume === 0 ? true : false,
		})
	};

    const handleLoadedMetadata = () => {
		const player = videoPlayerRef.current;
		if (!player) return;
		const extension = video?.video_url.split('.').pop();
		setIsAudio(video?.only_audio === "true" || extension === "mp3" || extension === "wav" || extension === "m4a");
		const duration = player.getDuration();

		setVideoLoading(false);
		setDuration(duration);
		setIsBuffering(false);
	};
	const muteHandler = () => {
		setVideoState({ ...videoState, muted: !videoState.muted });
	};
    const rewindHandler = () => {
		//Rewinds the video player reducing 5

		videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 10 < 0 ? 0 : videoPlayerRef.current.getCurrentTime() - 10);
	};

	const fastFowardHandler = () => {
		console.log("forward"
			, videoPlayerRef.current)
		//FastFowards the video player by adding 10
		videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10 > videoPlayerRef.current.getDuration() ? videoPlayerRef.current.getDuration() : videoPlayerRef.current.getCurrentTime() + 10);
	};

    const isIOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      };
     
      const toggleFullScreen = () => {
        if (isIOS()) {
          let videoElement = videoPlayerRef.current.getInternalPlayer();
          if (videoElement && videoElement.webkitEnterFullscreen) {
            videoElement.webkitEnterFullscreen();
          }
        } else {
          // For non-iOS devices
          if (screenfull.isEnabled) {
            screenfull.toggle(playerWrapperRef.current);
          }
        }
    }
    return (
        <>
        <div class="row video-outside" >
										<div className="text-center ">
											<div className={`video-container ${isFullscreen ? 'fullscreen-mode' : ''}`} style={{ margin: '0' }}>
												<Container justify="center">
													<div ref={playerWrapperRef} className={`player__wrapper`}>
													{isBuffering && (
													  <div className="loading-indicator">
														<CircularProgress />
													  </div>
													)}
													<div style={{
																			height: '200px', display: isAudio ? '' : 'none',
																			
																			borderRadius: '25px',
																		}}>

																			
																		</div>
														<ReactPlayer
															ref={videoPlayerRef}
															url={video?.video_url}
															playing={videoState.playing}
															seeking={videoState.seeking}
															volume={videoState.volume}
															muted={videoState.muted}
															playbackRate={playbackRate}
															progressInterval={100}
															onProgress={progressHandler}
															playsinline={true}
															width={"100%"}
															height={isAudio ? '0px' : "100%"}
															controls={false}
															className='react-player' id='video-src' style={{ display: videoLoading ? 'none' : null, borderRadius: '25px' }}
															onReady={handleLoadedMetadata}
															onContextMenu={(e) => e.preventDefault()}
															config={{
																attributes: {
																	disablePictureInPicture: true,
																	preload: "auto"
																}
															}}
															onBuffer={() => setIsBuffering(true)}
														/>
														{
															!videoLoading &&
															<VideoControls
																playing={videoState.playing}
																onPlayPause={playPauseHandler}
																volume={videoState.volume}
																onVolumeSeekUp={volumeSeekUpHandler}
																onVolumeChange={volumeChangeHandler}
																muted={videoState.muted}
																onRewind={rewindHandler}
																onForward={fastFowardHandler}
																currentDuration={duration}
																loaded={duration * loaded}
																played={videoState.played}
																currentTime={duration * videoState.played}
																onSeek={seekHandler}
																onSeekMouseUp={seekMouseUpHandler}
																onReplay={handleReplay}
																mute={muted}
																onMute={muteHandler}
																controlRef={controlRef}
																onToggleFullscreen={toggleFullScreen}
																isFullscreen={isFullscreen}
																playbackRate={playbackRate}
																setPlaybackRate={setPlaybackRate}
																playerbackRate={videoState.playerbackRate}
																ref={videoControlRef}
																videoPlayerRef={videoPlayerRef}
																mp3view={isAudio}

															/>
														}
													</div>
												</Container>
											</div>
										</div>
									</div>
        </>
    );
};
