import React, { useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';
import minutesPng from "../../assets/img/icon/minute-btn-icon.svg"
import uploadPng from "../../assets/img/icon/upload-btn-icon.svg"

import recordPng from "../../assets/img/icon/record-btn-icon.svg"
import botPng from "../../assets/img/icon/bot-btn-icon.svg"
import meetingtorLogo from "../../assets/img/meetingtor-fulllogo.jpg"
import recorditorlogo from "../../assets/img/recorditor-fulllogo.png"
import brandsPng from "../../assets/img/services.png"
import languages from "../../assets/img/icon/language.png"
import logoTranskriptor from '../../assets/img/logo_nobuffer.png';
import logoSpeaktor from '../../assets/img/logo-speaktor-nobuffer.png';
import logoMeetingtor from '../../assets/img/logo-meetingtor-nobuffer.webp';
import logoEskritor from '../../assets/img/logo-eskritor-nobuffer.png';
import logoAdmin from '../../assets/img/logo-admin.png';
import { useApiData } from "../../hooks/useApiData";
import { useModal } from '../common/Modal/ModalContext';
import { loadLanguageTranslations } from '../common/translation';
import { useTranslation } from '../common/LanguageProvider';
import { showToast } from '../common/toast';
import { useRecorder } from '../../pages/Recorditor/RecorderContext';
import { useUploader } from '../../pages/Uploader/UploaderContext';
import { subscription_aware } from '../../ampli/index';

function Header({ handleOpenSidebar }) {
    const { t } = useTranslation();
    const location = useLocation();
    const { hashedId, userData, token, setLoginToken, currentUser, token:firebaseToken } = useAuth();
    const [minutes, setMinutes] = useState(null);
    const { showModal } = useModal();
    const { showRecordModal } = useRecorder();
    const { showUploadModal, uploadQueue } = useUploader();
    const isChrome = !!window.navigator.userAgent.match(/Chrome/i) && !window.navigator.userAgent.match(/Edge/i);
    const navigate = useNavigate();
    const [planInfo, setPlanInfo] = useState(null);
    const [credits, setCredits] = useState(0);

    useEffect(() => {
        if (userData) {
            setMinutes(userData.Minutes);
        }
    }, [userData]);
    useEffect(() => {

        getMinutes();
    }, [location.pathname]);

    useEffect(() => {
        if (token) {
            if (isTokenExpired(token)) {
                setLoginToken(hashedId);
                // logout();
            }
        }
    }, [token]);




    const getMinutes = async () => {
        fetch("https://1hcr8zhgo3.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetCustomerDetails?cid=" + hashedId).then(res => res.json()).then(data => {
            setMinutes(data.Minutes);
        })
    };
    let text;
    switch (location.pathname) {
        case '/uploader':
            text = 'Upload File';
            break;
        case '/recorder':
            text = 'Record Audio';
            break;
        case '/files':
            text = 'Files';
            break;
        case '/folders':
            text = 'Folders';
            break;
        // Add more cases as needed
        default:
            text = 'Home'; // default text if no path matches
            break;
    }
    const openLangDialog = (event) => {
        if (event) event.preventDefault();

        showModal("LANGUAGE-MODAL");

        const modalContent = document.body.querySelector(".modal-content");
        if (modalContent) {
            modalContent.classList.add("overflow-visible");
        } else {
            console.error("No element with class .modal-content found!");
        }
    }


    const handleNavigate = (brand) => {
        // Extract the path segments from the current URL
        const pathSegments = window.location.pathname.split('/');

        // Get the first string after the first "/" (which will be at index 1)
        const firstPathSegment = pathSegments[1] || ''; // Ensure it defaults to an empty string if not found
        var path = firstPathSegment;
        if (path == "meeting-assistant" || path == "uploader" || path == "recorder" || path == "editor") {
            path = "dashboard";
        }
        var newURL = `https://app.${brand}.com/signIn?uid=${hashedId}`;
        if (brand == "meetingtor") {
            newURL = `https://app.${brand}.com/${path}?t=${token}`;
        }
        // Construct new URL using the first path segment
        // Note: You need to decide how to incorporate this segment into your URL. This example directly appends it.

        // Redirect to the new URL
        if (isChrome) {
            window.open(newURL, "_blank");
        } else {
            window.location.href = newURL;
        }
    };
    const generalRedirect = (app) => {

        if (token) {
            const pathSegments = window.location.pathname.split('/');

            // Get the first string after the first "/" (which will be at index 1)
            const firstPathSegment = pathSegments[1] || ''; // Ensure it defaults to an empty string if not found
            var path = firstPathSegment;
            var url = `https://app.${app}.com/${path}?t=${token}&lang=${localStorage.getItem("dashboard-language")}&option=transkriptor`;

            window.location.href = url;


        } else {
            showToast("Please refresh the page and try again.", { type: "error" });
        }

    };
    const generalRedirectOpen = (app) => {

        if (token) {
            const pathSegments = window.location.pathname.split('/');

            // Get the first string after the first "/" (which will be at index 1)
            const firstPathSegment = pathSegments[1] || ''; // Ensure it defaults to an empty string if not found
            var path = firstPathSegment;
            var url = `https://app.${app}.com/${path}?t=${token}&lang=${localStorage.getItem("dashboard-language")}&option=transkriptor&start=true`;
            if (app === "recorditor" && userData.UserSegment % 4 !== 0) {
                navigate("/recorder")
            } else {
                window.location.href = url;
            }

        } else {
            showToast("Please refresh the page and try again.", { type: "error" });
        }

    };


    useEffect(() => {
        const languageCode = localStorage.getItem("dashboard-language") || 'en';
        loadLanguageTranslations(languageCode); // Load translations once when the app starts

    }, []);
    function parseJwt(token) {
        try {
            // Split the token into parts and decode the payload
            const base64Url = token.split('.')[1]; // Get the payload part
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        } catch (e) {
            console.error("Error decoding JWT", e);
            return null;
        }
    }

    function isTokenExpired(token) {
        const payload = parseJwt(token);
        if (!payload) {
            return true; // Assume expired or invalid if we cannot parse
        }
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        return currentTime > payload.exp;
    }

    function older_version() {
        const pathSegments = window.location.pathname.split('/');

        // Get the first string after the first "/" (which will be at index 1)
        const firstPathSegment = pathSegments[1] || '';
        window.location.href = "https://app.transkriptor.com/" + firstPathSegment + "&betaredirect=true";
    }
    useEffect(() => {
        if(hashedId){
          getPlanInfo();
        }
      }, [hashedId]);
    const getPlanInfo = async () => {
        if (hashedId) {
            fetch("https://bfpu1bhqsa.execute-api.eu-central-1.amazonaws.com/default/Recorditor-GetPlanInfo?uid=" + hashedId).then(res => res.json()).then(data => {
                setPlanInfo(data);
            })
        }
    };
    
    useEffect(() => {
      if (planInfo) {
        setCredits(planInfo.allowed - planInfo.total_orders);
      }
    }, [planInfo]);
    const checkUpload = ()=>{
        if(uploadQueue.length <= 0){
            if(userData && userData.Minutes > 0){
                showUploadModal();
            }else if(userData.Minutes <= 0){
                showModal("UPGRADE-MINUTES", {type: "local_transcription"})
            }else{
                showToast(t("Please Wait", {type:"info"}))
            }
    
        }else{
            showToast(t("Please wait until uploading all your files.", {type:"info"}))
        }
        
    }
    const checkRecorder = async()=>{
        if(isChrome){
            try{
                await navigator.mediaDevices.getUserMedia({audio: true });
                if(userData && userData.Minutes > 0){
                  if(userData && (credits> 0 || userData.SStatus !== "Trial")){
                      showRecordModal();
                  }else{
                    showModal("LIMIT-EXCEEDED")
                  }
                }else if(userData.Minutes <= 0){
                  showModal("UPGRADE-MINUTES", {type: "record_transcription"})
                }
              }catch(error){
                showModal("RECORD-NO-AUDIO-DEVICES")
                console.error('Error requesting permissions or fetching devices:', error);
              }
        }else{
            showToast(t("Please use Google Chrome to record audio/video!", {type:"info", position: "top-center"}))
        }
           
    }
    const redirectToNew = () => {
            if(userData  && firebaseToken){
                window.location.href = "https://beta.transkriptor.com/dashboard?t=" + firebaseToken + "&lang=" + localStorage.getItem('dashboard-language');
            }
    };
    return (
        <>
            <div className="header-div notranslate">
                <div className="header-div-flex">
                    <div className="d-flex align-items-center">
                        <div className='d-xl-none d-flex align-items-center me-3 pointer' id='sidebar-menu-open' onClick={handleOpenSidebar}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="text-black" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 12H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 6H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.75 18H20.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                            <Link >
                                <img src={logoAdmin} class="img-fluid header-logo" alt="img" />
                            </Link>
                        </div>
                        <div className='d-xl-flex d-none'>
                            <li onClick={()=> {
                                subscription_aware(userData, currentUser, "header_upgrade_button")
                                navigate(`/payment?type=header_upgrade_button`)

                            }
                            } className='header-minute-text semibold'>
                                <span className="round-color-icon theme-bg">
                                    <img src={minutesPng} className='img-fluid' alt='img'></img>
                                </span> {minutes} {t("minutes")}
                            </li>
                            <div onClick={() => checkUpload()} className='btn header-btn rounded-btn' >
                                <span className="round-color-icon green-bg ">
                                    <img src={uploadPng} className='img-fluid' alt='img'></img>
                                </span> {t("Upload")}
                            </div>
                            <div onClick={() => checkRecorder()} className='btn header-btn rounded-btn'>
                                <span className="round-color-icon red-bg">
                                    <img src={recordPng} className='img-fluid' alt='img'></img>
                                </span> {t("Screen Recorder")}
                            </div>
                            <button onClick={()=>generalRedirect("meetingtor")} className='btn header-btn rounded-btn'>
                            <span className="round-color-icon meetingtor-bg">
                                <img src={meetingtorLogo} className='img-fluid' alt='img'></img>
                            </span> {t("Meeting Recorder")}
                        </button>
                            {/*<Converter header={true} />*/}


                        </div>

                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='pointer' onClick={() => redirectToNew()} >
                           <button className='btn theme-btn m-2'>
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-sparkles"><path d="M9.937 15.5A2 2 0 0 0 8.5 14.063l-6.135-1.582a.5.5 0 0 1 0-.962L8.5 9.936A2 2 0 0 0 9.937 8.5l1.582-6.135a.5.5 0 0 1 .963 0L14.063 8.5A2 2 0 0 0 15.5 9.937l6.135 1.581a.5.5 0 0 1 0 .964L15.5 14.063a2 2 0 0 0-1.437 1.437l-1.582 6.135a.5.5 0 0 1-.963 0z"/><path d="M20 3v4"/><path d="M22 5h-4"/><path d="M4 17v2"/><path d="M5 18H3"/></svg>
                            {t("Use New Version")}
                           </button>
                        </div>
                        <div className='pointer' onClick={() => openLangDialog()} >
                            <img src={languages} width="45px" class="img-fluid res-header-side-icon me-3" alt="img">
                            </img>
                        </div>
                        <div className='dropdown'>
                            <Link className='btn dropdown-toggle no-toggle-icon' role='button'>
                                <img src={brandsPng} width="45px" class="img-fluid res-header-side-icon" alt="img" />

                            </Link>
                            <ul className='dropdown-menu dropdown-menu-end'>
                                <li><Link className='dropdown-item active' to="">
                                    <img src={logoTranskriptor} class="img-fluid brand-drop-logo" alt="img">
                                    </img>

                                </Link>
                                </li>
                                <li>
                                    <Link class="dropdown-item" onClick={() => handleNavigate('speaktor')}>
                                        <img src={logoSpeaktor} class="img-fluid brand-drop-logo" alt="img" />
                                    </Link>
                                </li>
                                <li>
                                    <Link class="dropdown-item" onClick={() => handleNavigate('eskritor')}>
                                        <img src={logoEskritor} class="img-fluid brand-drop-logo" alt="img" />
                                    </Link>
                                </li>
                                <li>
                                    <Link class="dropdown-item" onClick={() => generalRedirect("meetingtor")}>
                                        <img src={logoMeetingtor} class="img-fluid brand-drop-logo" alt="img" />
                                    </Link>
                                </li>
                                <li>
                                    <Link class="dropdown-item" onClick={() => generalRedirect("recorditor")}>
                                        <img src={recorditorlogo} class="img-fluid brand-drop-logo" alt="img" />
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

            </div>
            <div className='res-header-dash-sticky d-xl-none d-flex'>
                <li onClick={()=> {
                    subscription_aware(userData, currentUser, "header_upgrade_button")
                    navigate(`/payment?type=header_upgrade_button`)

                }} className='header-minute-text semibold'>
                    <span className="round-color-icon theme-bg">
                        <img src={minutesPng} className='img-fluid' alt='img'></img>
                    </span> {minutes} {t("minutes")}
                </li>


                <div onClick={() => checkUpload()} className='btn header-btn rounded-btn' >
                    <span className="round-color-icon green-bg ">
                        <img src={uploadPng} className='img-fluid' alt='img'></img>
                    </span>
                </div>
                <div onClick={() => checkRecorder()} className='btn header-btn rounded-btn'>
                    <span className="round-color-icon red-bg">
                        <img src={recordPng} className='img-fluid' alt='img'></img>
                    </span>
                </div>
                <button onClick={()=>generalRedirect("meetingtor")} className='btn header-btn rounded-btn'>
                    <span className="round-color-icon purple-bg">
                        <img src={botPng} className='img-fluid' alt='img'></img>
                    </span> 
                </button>
                {/*<Converter header={true} hideText={true} />*/}
            </div>
        </>
    );
}

export default Header;
