import React, { useState, useEffect, useRef } from "react";

function SpeakerDropdown({
  index,
  speaker,
  onAddSpeaker,
  speakerList,
  changeSpeaker,
  onRenameSpeaker,
  fontSize,
}) {
  // State for dropdown visibility
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(); // Ref for the dropdown container

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close dropdown when clicked outside
      }
    };

    // Adding click event listener when component is mounted
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup: Removing event listener when component is unmounted
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);
  return (
    <>
      <div
        className="transcript-dropdown dropdown notranslate"
        ref={dropdownRef}
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title={speaker}
      >
        <a
          className="btn dropdown-toggle speaker-toggle"
          aria-labelledby="dropdownMenuLink"
          role="button"
          style={{ fontSize: fontSize + "px" }}
          aria-expanded="false"
          onClick={toggleDropdown} // Toggle dropdown when this is clicked
        >
          <span className="speaker-span speaker-span-sharing">{speaker}</span>
        </a>


      </div>
    </>
  );
}

export default SpeakerDropdown;
