import React, { useEffect, useState } from "react";
import { useAuth } from "../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../hooks/apiUtils";
import addMemberSvg from "../assets/img/icon/add-member.svg";
import upgradeSvg from "../assets/img/icon/upgrade.svg";
import { useModal } from "../components/common/Modal/ModalContext";
import { showToast } from "../components/common/toast";
import { Link } from "react-router-dom";
import { useTranslation } from '../components/common/LanguageProvider'; 
import { subscription_aware } from "../ampli/index";
function Enterprise() {
    const { t } = useTranslation();
    const {userData, currentUser} = useAuth();
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [emptySeats, setEmptySeats] = useState(0);
    const [enterpriseId, setEnterpriseId] = useState(null);
    const { showModal, hideModal } = useModal();
    const getEnterprise = async (enterpriseId) => {
        const response = await fetchData("https://fkofgp6eoi.execute-api.eu-central-1.amazonaws.com/default/Enterprise-Get-Enterprise-Details?EnterpriseId=" + enterpriseId);
        setData(response);
        console.log(response);
        console.log("keke")
        setIsLoading(false);
        const count = response.filter(item => item.OwnerMail === "-").length;
        setEmptySeats(count);
        setTimeout(() => {
            getEnterprise(enterpriseId);
        }, 3000);

    };
    useEffect(() => {
        if(userData){
            if(userData.OwnedEnterprise && userData.OwnedEnterprise !== 'no' ){
                getEnterprise(userData.OwnedEnterprise.replace('Enterprise#', ""));
                setEnterpriseId(userData.OwnedEnterprise.replace('Enterprise#', ""));
      
            }else{
                navigate("/dashboard")
            }
        }
    }, [userData]);
    const onEnterpriseInvite = async(enterpriseId, mail) => {
        showToast("Invitation will be sent soon.", {type: "info"})
        fetch("https://n8t4ufwvma.execute-api.eu-central-1.amazonaws.com/default/Enterprise-Invite-User-?EnterpriseId=" + enterpriseId + "&InvitedEmail=" + mail).then(res => res.json()).then(data => {
            if(data.includes('SEAT')){
                showToast(t("There is no empty seat in your enterprise. Please upgrade your enterprise to add new member."), {type: "error"});
            }else{
                showToast(t("Invitation mail has been sent"), {type: "success"});
            }
            hideModal();
        });
    };

    const onEnterpriseRemove = async(mail)=>{
        setIsLoading(true);
        fetch("https://8f3cd6wvfg.execute-api.eu-central-1.amazonaws.com/default/Enterprise-Delete-Member-?EnterpriseId=" + enterpriseId + "&DeletedEmail=" + mail).then(function(){
            showToast(t("Member removed"), {type: "success"});
            getEnterprise(enterpriseId);
        })
    }
    return (
        <>
            <div class="row">
				<div class="col-lg-8 col-12">
                <div className='d-lg-flex d-block justify-content-between align-items-center'>
						<h2 class="card-title mb-lg-4 mb-4">{t("Enterprise")}</h2>
                        <div class="mb-4 table-serach input-icon-div d-lg-block d-none ms-auto" style={{visibility:"hidden"}}>
                        <input type="text" class="form-control round-input" name="" placeholder="Search folders"/>
                        <span class="input-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg>
                        </span>
                    </div>
    					</div>
				</div>
			</div>
            <div class="row">
				<div class="col-lg-8 card-margin order-lg-1 order-2">
					<div class="d-lg-none d-block text-end mb-2">
						<Link to="/payment" class="btn theme-btn mb-1">
							<span class="btn-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-up-circle" viewBox="0 0 16 16">
								  	<path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
								</svg>
							</span>{t("Upgrade")}
						</Link>
						<button class="btn theme-btn mb-1" onClick={()=> showModal("ADD-ENTERPRISE-MEMBER", {enterpriseId}, {onEnterpriseInvite})}>
							<span class="btn-icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
								  	<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
								  	<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
								</svg>
							</span>{t("Add").toUpperCase()}
						</button>
					</div>
					<div class="card card-padding h-100">
					{isLoading ? (
                        <>
                        <div className='d-flex justify-content-center text-center'>
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        </>
                    ):(
                        <>
                        	<div class="table-responsive">
                            <table className="table">
                                <tbody>
                               {data && data.map((item, index) => (
                                        item.OwnerMail !== "-" &&  <tr>
                                        <td>
                                            <span class="table-page-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                                              </svg>
                                            </span>
                                        </td>
                                        <td class="dark-color regular">{item.OwnerMail}</td>
                                        <td class="res-hide-td"><span class="dark-color">{t("Credits:")}</span> &nbsp; {item.RemainingMinutes}</td>
                                        {item.OwnerMail === currentUser.email && <td class="text-end"><span class="dark-color">{t("Admin")}</span></td>}
                                        <td class="text-end" style={{display: item.OwnerMail !== currentUser.email ? "": "none"}}>
                                            <div class="action-icon-list res-action-list">
                                                <Link  onClick={()=> showModal("REMOVE-ENTERPRISE-MEMBER", {mail: item.OwnerMail}, {onEnterpriseRemove})}>
                                                    <span class="icons-hover text-danger" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Remove")}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                      </svg>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div class="dropdown dropstart res-action-dropdown">
                                                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                  </svg>
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li> <Link  onClick={()=> showModal("REMOVE-ENTERPRISE-MEMBER", {mail: item.OwnerMail}, {onEnterpriseRemove})}>
                                                        <span class="action-drop-icon text-danger">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                          </svg>
                                                        </span>{t("Remove")}
                                                    </Link></li>
                                                </ul>
                                          </div>
                                        </td>
                                  </tr>

                                         
                               ))}
                                </tbody>
                            </table>
						</div>
                        {emptySeats > 0 && 	<div class="card card-padding">
								<div class="text-center">
									<img src={addMemberSvg} class="img-fluid mb-3" alt="icon"/><br></br>
									<p class="ft-18-title mb-2">{t("Add New Member")}</p>
									<p>{t("Add team member to collaborate with your friends")}</p>
                                    <p>{t("Empty Seats:")} {emptySeats}</p>
								</div>
								<div class="text-center">
									<button class="btn theme-btn"  onClick={()=> showModal("ADD-ENTERPRISE-MEMBER", {enterpriseId}, {onEnterpriseInvite})}>
										<span class="btn-icon">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
											  	<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
											  	<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
											</svg>
										</span>{t("Add").toUpperCase()}
									</button>
								</div>
							</div>}
                        </>
                    )}
					</div>
				</div>
				<div class="col-lg-4 order-lg-2 order-1 d-lg-block d-none">
					<div class="row h-100">
						<div class="col-12 card-margin">
							<div class="card card-padding h-100 flex-center card-hover"onClick={()=> showModal("ADD-ENTERPRISE-MEMBER", {enterpriseId}, {onEnterpriseInvite})}>
								<div class="text-center">
									<img src={addMemberSvg} class="img-fluid mb-3" alt="icon"/><br></br>
									<p class="ft-18-title mb-2">{t("Add New Member")}</p>
									<p>{t("Add team member to collaborate with your friends")}</p>
								</div>
								
							</div>
						</div>
						<div class="col-12 card-margin">
							<div class="card card-padding h-100 flex-center card-hover" onClick={()=>{
                                subscription_aware(userData, currentUser, "upgrade_box")
                                navigate("/payment?type=upgrade_box")
                                }}>
								<div class="text-center">
									<img src={upgradeSvg} class="img-fluid mb-3" alt="icon"/><br></br>
									<p class="ft-18-title mb-2">{t("Upgrade")} 
										<span class="ms-1">
									
										</span>
									</p>
									<p>{t("Upgrade your account to transcribe long audio and video files")}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </>
    );
}

export default Enterprise;