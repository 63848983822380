import React, { useState, useEffect, Suspense, useRef } from 'react';
import logo from './logo.svg';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import CheckSharingComponent from './components/common/CheckSharingComponent';
import { BrowserRouter, Routes, Route, Link, useNavigate, useLocation, Navigate, redirect } from 'react-router-dom';
import Files from './pages/Files/Files';
import Payment from './pages/Payment';
import Folders from './pages/Folders/Folders';
import Workspaces from './pages/Workspaces/Workspaces';
import Workspace from './pages/Workspace';
import Rewards from './pages/Rewards';
import Editor from './pages/Editor/Editor';
import Folder from './pages/Folder';
import Dashboard from './pages/Dashboard';
import LoginComponent from './pages/SignIn';
import SignUpComponent from './pages/SignUp';
import SharingComponent from './pages/Sharing';
import Account from './pages/Account';
import Enterprise from './pages/Enterprise';
import MeetingAssistant from './pages/MeetingAssistant';

import ReceivedPayment from './pages/ReceivedPayment';
import Authentication from './pages/Authentication';
import ConnectionStatus from './components/common/ConnectionStatus';
import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/font-awesome.css'
import '../src/assets/css/custome.css';
import '../src/assets/css/responsive.css';
import '../src/assets/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import RecorderPage from './pages/Recorder/Recorder'
import Recorder from './pages/Recorditor/Recorder';
import Gleap from "gleap";
import { LanguageProvider } from './components/common/LanguageProvider';

import { AuthProvider, useAuth } from './Auth/AuthContext';
import { ModalProvider } from './components/common/Modal/ModalContext';
import BootstrapModal from './components/common/Modal/BootstrapModal';
import minutesPng from "./assets/img/icon/minute-btn-icon.svg"
import uploadPng from "./assets/img/icon/upload-btn-icon.svg"

import recordPng from "./assets/img/icon/record-btn-icon.svg"
import botPng from "./assets/img/icon/minute-btn-icon.svg"
import brandsPng from "./assets/img/icon/brands.png"
import languages from "./assets/img/icon/language.png"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Uploader from './pages/Uploader';
import Billing from './pages/Billing';
import ErrorBoundary from './components/common/errorBoundary';
import { loadLanguageTranslations } from './components/common/translation';
import { DirectionProvider, useDirection } from './components/common/DirectionContext';
import amplitude from 'amplitude-js';
import { RecorderProvider } from './pages/Recorditor/RecorderContext';
import { UploadProvider } from './pages/Uploader/UploaderContext';
import UploadBox from './pages/Uploader/UploadBox';
import ConversionBanner from './components/common/ConversionBanner';
import StripePayment from './components/Stripe/CheckoutForm';
import NotificationComponent from './components/Notification/Notification';
// ... other imports ...



function AppContent() {
  const [isOpen, setIsOpen] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const location = useLocation();

  const handleOpenSidebar = () => setIsOpen(true);
  const handleCloseSidebar = () => setIsOpen(false);
  const mainRef = useRef(null);
  const { currentUser, logout, hashedId, signInWithToken, userData, token: firebaseToken } = useAuth();
  const { direction } = useDirection();
  const [isCompleted, setIsCompleted] = useState(false);
  /*const [shouldCheck, setShouldCheck] = useState(() => {
    // Get the shouldCheck state from sessionStorage
    const storedValue = sessionStorage.getItem('shouldCheck');
    return storedValue === null ? true : JSON.parse(storedValue);
  });
  


  const handleSlowConnection = () => {
    if (shouldCheck) {
      showToast("Your internet connection is slow. This might affect your experience.", "warning")

      // Set the shouldCheck state to false in sessionStorage to avoid rechecking after reload
      sessionStorage.setItem('shouldCheck', JSON.stringify(false));
      window.location.reload(); // refresh the page
    }else{
      showToast("Your internet connection is slow. This might affect your experience.", "warning")
    }
  };*/

  useEffect(() => {
    document.documentElement.setAttribute('dir', direction);
  }, [direction]);
  const navigate = useNavigate();
  const checkRemember = async () => {
    const rememberSession = sessionStorage.getItem('remember');
    const rememberLocal = localStorage.getItem('remember');
    if (!rememberSession && rememberLocal === 'false') {
      await logout();
    }
  };
  const queryParams = new URLSearchParams(location.search);
  // To retrieve a specific query parameter:
  const redirect = queryParams.get('redirect');
  const user_id = queryParams.get('uid')
  const onetap_id = queryParams.get('onetap_id')
  const old_version = queryParams.get('old_version')
  amplitude.getInstance().init("a4563a2706e1e084439f19815d3b36f8", null,
    {
      includeGclid: true,
      includeReferrer: true,
      includeUtm: true,
    }
  );
  if(old_version){
    localStorage.setItem('old_version', "true");
  }

  const isMobileDevice = () => {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	};

  const isEditorPage = location.pathname.startsWith("/editor") || location.pathname === "/payment" || location.pathname.startsWith("/sharing");
  const editorClass = !location.pathname.startsWith("/rewards") && !location.pathname.startsWith("/payment") && !location.pathname.startsWith('/checkout') && !isMobileDevice();
  const isDashboardPage = location.pathname.startsWith("/dashboard");
  const token = queryParams.get('t');


  useEffect(() => {
    checkRemember();
    if (!token && !onetap_id) {
      if (user_id && (location.pathname.startsWith("/editor") || location.pathname.startsWith('/payment'))) {
        const automateLogin = async (uid) => {
          const response = await fetch("https://hg0761tbmf.execute-api.eu-central-1.amazonaws.com/default/automateLogin?hashedId=" + uid);
          if (response.status === 200) {
            const data = await response.json();

            const token = data["customToken"];

            await signInWithToken(token);
            // Remove query string and refresh page
            var baseUrl = window.location.href.split('?')[0];
            const queryParams = new URLSearchParams(location.search);
            
            const coupon = queryParams.get('coupon');
            if(coupon)
              baseUrl += `?coupon=${coupon}`;
            window.history.pushState({}, document.title, baseUrl);

          } else {
            console.log('Error:', response.status, response.statusText);
          }

        };
        if (!currentUser && user_id !== hashedId) {
          automateLogin(user_id);
        }


      }
      else if (!redirect && !currentUser && !location.pathname.startsWith("/sharing")) {
        const { pathname, search } = location;
        // Construct the full path with query parameters
        const fullPath = `${pathname.replace('/', '')}${search}`;
        // Navigate to the specified path and pass state
        navigate('/signIn', { state: { temp_redirect: fullPath } });
      } else {
      }
    }

  }, [currentUser, navigate]);
 
  if (!currentUser && !redirect && !location.pathname.startsWith("/sharing")) {
    return null;
  }
  Gleap.initialize("cj7rzrc0mklHXp7Ux1hISbI6hcVIIPJp");
  Gleap.setLanguage(localStorage.getItem('dashboard-language') || "en")


  let style = {};

  // Conditionally adjust the height if it's the dashboard page
  if (isDashboardPage) {
    style = { ...style, height: '160vh' };
  }
  let className = 'main-div';
  if (isOpen) {
    className += ' disable-layer';
  }
  if (!localStorage.getItem('loggedIn') || localStorage.getItem('loggedIn') === "false") {
    className += ' main-padding-left';
  }
  if(editorClass){
    className += ' editor-page';
  }

  return (
    <>

      <RecorderProvider>
        <UploadProvider>
          <Recorder />
          <UploadBox />

          {localStorage.getItem('loggedIn') === "true" && <Sidebar isOpen={isOpen} handleCloseSidebar={handleCloseSidebar} isBlocked={isBlocked} isCompleted={isCompleted} />}

          <div className={className} style={style} >
            {userData && userData.SStatus !== "Active" && userData.Mail.includes('.edu') && 
            <ConversionBanner />
          
            }
            {isEditorPage ? "" : <Header handleOpenSidebar={handleOpenSidebar} />}


            <div className={isEditorPage ? 'content-area pb-0 ' : 'content-area notranslate'} >
              <div className={isEditorPage ? 'min-100vh-relative-div' : 'container-fluid p-0 notranslate'}>
                <Routes>
                  <Route path="/files" element={<Files isFile={true} />} />
                  <Route path="/payment" element={<Payment handleOpenSidebar={handleOpenSidebar} />} />
                  <Route path="/folders" element={<Folders />} />
                  <Route path="/workspaces" element={<Workspaces />} />
                  <Route path="/rewards" element={<Rewards />} />
                  <Route path="/folder/:fid/:uid" element={<Folder />} />
                  <Route path="/workspace/:tid" element={<Workspace />} />
                  <Route path="/editor/:tid?/:uid?" element={<Editor handleOpenSidebar={handleOpenSidebar} setIsCompleted={setIsCompleted}/>} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/uploader" element={<Uploader setIsBlocked={setIsBlocked} />} />
                  <Route path="/account" element={<Account />} />
                  <Route path="/billing" element={<Billing />} />
                  <Route path="/enterprise" element={<Enterprise />} />
                  <Route path="/meeting-assistant" element={<MeetingAssistant />} />
                  <Route path="/payment-received" element={<ReceivedPayment />} />
                  <Route path="/sharing/:oid?" element={<CheckSharingComponent handleOpenSidebar={handleOpenSidebar} setIsCompleted={setIsCompleted}/>} />
                  <Route path="/recorder" element={<RecorderPage setIsBlocked={setIsBlocked} />} />
                  <Route path="/checkout" element={<StripePayment/>} />
                  <Route path="*" element={<Navigate to="/dashboard" replace />} />
                </Routes>
              </div>
            </div>

          </div>



        </UploadProvider>
      </RecorderProvider>

    </>
  );
}

function App() {
  loadLanguageTranslations(localStorage.getItem("dashboard-language") || 'en');


  return (
    <ErrorBoundary>
      <DirectionProvider>
        <LanguageProvider>
          <ModalProvider>

            <BrowserRouter>

              <AuthProvider>

                <div className="App scale75">
                  <BootstrapModal />

                  <ToastContainer />

                  <Routes>
                    <Route path='/authentication' element={<Authentication />} />
                    <Route path="/signIn" element={<LoginComponent />} />
                    <Route path="/register" element={<SignUpComponent />} />
                    <Route path="*" element={<AppContent />} />
                  </Routes>
                </div>

              </AuthProvider>

            </BrowserRouter>
          </ModalProvider>
        </LanguageProvider>
      </DirectionProvider>

    </ErrorBoundary>

  );
}

export default App;


