import React, {useEffect, useState} from 'react';
import { useAuth } from '../Auth/AuthContext';
import audioIcon from '../assets/img/icon/audio-icon.svg';
import googleDriveIcon from '../assets/img/icon/google-drive.svg';
import youtubeIcon from '../assets/img/icon/youtube.svg';
import zoom from '../assets/img/icon/zoom.svg';
import transkriptorLogo from "../assets/img/logo_nobuffer.png"
import eskritorLogo from "../assets/img/logo-eskritor-nobuffer.png"
import speaktorLogo from "../assets/img/logo-speaktor-nobuffer.png"

import recordPng from "../assets/img/icon/record-btn-icon.svg"

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from '../components/common/LanguageProvider'; 

function ReceivedPayment() {

  const { t } = useTranslation();
  const {userData, hashedId} = useAuth();
  const [price, setPrice] = useState(0);
  const navigate = useNavigate();
  const triggerGTMEvent = (event) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  };
  
  const paddle_customer_info_url = "https://8b3h5c5qz4.execute-api.eu-central-1.amazonaws.com/v1/paddle/get-customerinfo"
  const stripe_customer_info_url = "https://ojlaf55ibl.execute-api.eu-central-1.amazonaws.com/default/Stripe-GetCustomerInfo"
  useEffect(() => {
    if (userData) {
      const fetchData = (url, attempt = 1) => {
        fetch(url + '?user_id=' + hashedId)
          .then(response => {
            if (!response.ok) { // Checks if the response status code is not in the range 200-299
              if (response.status === 404) {
                if (attempt < 3) {
                  console.log(`Attempt ${attempt}: retrying in 2 seconds`);
                  setTimeout(() => fetchData(attempt + 1), 2000);
                } else {
                };
              } else {
                throw new Error('Other Error');
              }
            }
            return response.json(); // Parse JSON only if response is OK
          })
          .then(data => {
            setPrice(data.last_charge);
            var dataLayer = window.dataLayer || [];
            
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
      
            // Send a custom dimension value
            gtag('config', 'G-Y8NSVCH60L', { 'allow_enhanced_conversions': true });
      
            gtag('set', 'user_data', {
              "email": userData.Mail,
              "price": data.last_charge
            });
             // Trigger GTM event after API call success
            triggerGTMEvent({
              event: 'enhanced_purchased',
              user_mail: userData.Mail,
              package_price: data.last_charge
            });
          })
          .catch(error => {
            // Handle 404 or other errors specifically
            console.error(`Error: ${error.message}`);
            if (attempt < 3) {
              console.log(`Attempt ${attempt}: retrying in 2 seconds`);
              setTimeout(() => fetchData(attempt + 1), 2000);
            } else {
              console.log("Failed after 3 attempts", error);
            }
          });
      };
      
      var url = stripe_customer_info_url;
      if(userData.Subscription && userData.Subscription.Source === "Paddle")
        url = paddle_customer_info_url;
      fetchData(url); // Initial call to fetchData
    }
  }, [userData]);
  
  
    setTimeout(()=>{
      navigate("/dashboard")
    }, 10000)

    useEffect(() => {
      const channel = new BroadcastChannel('payment_channel');
      channel.postMessage('payment_success');
      channel.close();
    }, []);
  
    return (
       <>

              <div className='row justify-content-center'>
                <div className='col-lg-10 card-margin'>
                  <div className='card card-padding card-100 text-center card-hover' >
                    <h2>{t("Thank you for your purchase!")}</h2>
                    {price > 0 ? <p>{t("You have successfully paid")} {price}$</p> : <p>&nbsp;</p>}
                    <p >{t("Please allow 10-20 seconds to see your minutes in your account.")}</p>
                    <div className='img-icon-list' style={{display: "none"}}>
                        <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Audio")}><img src={audioIcon} class="img-fluid" alt="img"/></span>
                        <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Youtube"><img src={youtubeIcon} class="img-fluid" alt="img"/></span>
                        <span class="pointer red-bg recorder-span2 d-flex align-items-center justify-content-center" style={{borderRadius: "10px"}} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Youtube"><img src={recordPng} class="img-fluid" alt="img"/></span>

                        <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Zoom"><img src={zoom} class="img-fluid" alt="img"/></span>

                    </div>
                    <img src={transkriptorLogo} width="20%"></img>
					<p>{t("Convert Any Audio Or Video File To Text From Your Computer, Cloud Storage, Youtube, Record Screen or Record Meetings (Zoom, Google Meet, Teams)")}</p>
					<img src={speaktorLogo} width="18%"></img>
					<p>{t("Convert Any Text File To Audio From Your Computer (docs, word, txt), website URL, or just copy from clipboard")}</p>
					<img src={eskritorLogo} width="16%"></img>
					<p>{t("Automatically generate different formats of text with Eskritor’s artificial intelligence (AI) content writer")}</p>
					
                  </div>
                </div>

           
              </div>
       </>
    );
}

export default ReceivedPayment;



