import React, { useState, useEffect, useRef } from "react";
import { TextField, Box, Typography, Paper } from "@mui/material";
import { useTranslation } from "../LanguageProvider";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import ToolTip10 from "../../Tooltip/ToolTip1.1";
import axios from "axios";
import { showToast } from "../toast";
import { useAuth } from "../../../Auth/AuthContext";
import { fetchData, fetchDataPost } from "../../../hooks/apiUtils";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useModal } from "./ModalContext";
const AdvancedOptions = (props) => {
  const { t } = useTranslation();
  const [customInput, setCustomInput] = useState("");
  const { hashedId, userData } = useAuth();

  const [isLimitReached, setIsLimitReached] = useState(false);

  const { user, data, textFieldRef, paperStyle } = props;
  var user_name = user.displayName ? user.displayName : "";
  const initialPlaceholderText =
    user_name +
    "\nElon Musk \nMeta\nGoogle\nOpenAI\nTranskriptor\nMeetingtor\n...\n ";

  const saveRef = useRef(null);
  const setCustomPhraseURL =
    "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/appoptions/settranskriptorcustomphrases";
  const getCustomPhraseURL =
    "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/appoptions/gettranskriptorcustomphrases";
  const handleCustomInputChange = (event) => {
    setCustomInput(event.target.value);
    saveRef.current.disabled = false;
  };

  useEffect(() => {
    getCustomPhrase();
    if (textFieldRef && textFieldRef.current) {
      textFieldRef.current.focus();
    }
    if (saveRef && saveRef.current) saveRef.current.disabled = true;
  }, [textFieldRef]);
  // s3 upload function for files smaller than 5 mb
  const uploadToS3 = async (presignedUrl, file) => {
    try {
      const result = await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      return result;
    } catch (error) {
      throw error;
    }
  };

  async function uploadToPresignedUrl(data, presignedUrl) {
    try {
      const response = await fetch(presignedUrl);

      const res = await response.json();
      const presigned_url = res["url"];
      uploadToS3(presigned_url, data);
    } catch (error) {
      console.error("Error uploading the file", error);
    }
  }

  const saveCustomPhrase = () => {
    // Assuming customInput is a string that might contain new lines
    var temp_input = textFieldRef.current.value;
    const customPhrases = temp_input.replace(/\n/g, ",");
    // Creating a Blob from the comma-separated data
    const dataBlob = new Blob([customPhrases], { type: "text/plain" });
    const presignedUrl =
      setCustomPhraseURL +
      "?hashed_id=" +
      data.HashedId +
      "&language=" +
      localStorage.getItem("transcript-language");
    uploadToPresignedUrl(dataBlob, presignedUrl);
    showToast(t("Custom dictionary saved successfully"), { type: "success" });
    saveRef.current.disabled = true;
  };

  const getCustomPhrase = async () => {
    try {
      const response = await fetch(
        getCustomPhraseURL +
          "?hashed_id=" +
          data.HashedId +
          "&language=" +
          localStorage.getItem("transcript-language").substring(0, 2)
      );
      const res = await response.json();
      const presigned_url = res["url"];
      const result = await axios.get(presigned_url);
      // Assuming result.data is a string like "phrase1,phrase2,phrase3"
      const customPhrasesArray = result.data.split(","); // Split the string into an array
      if (customPhrasesArray.length > 0) {
        const customPhrasesString = customPhrasesArray.join("\n"); // Join the array into a string with new lines
        setCustomInput(customPhrasesString); // Set the transformed string as the new state
        textFieldRef.current.scrollTop = textFieldRef.current.scrollHeight;
      }
    } catch (error) {
      console.error("Error getting custom phrases", error);
    }
  };
  const [isDictionaryOpen, setIsDictionaryOpen] = useState(false);
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const [isAddNewAudio, setIsAddNewAudio] = useState(false);
  const [activeTab, setActiveTab] = useState('destination');
  const [isDestinationOptionsOpen, setIsDestinationOptionsOpen] =
    useState(false);

  const [userProfiles, setUserProfiles] = useState([]); // [{name: 'John Doe', id: 1}, {name: 'Jane Doe', id: 2}
  const [currentAudio, setCurrentAudio] = useState(null);
  const [playingIndex, setPlayingIndex] = useState(null);

  const handlePlayPause = (audioUrl, index) => {
    if (currentAudio && playingIndex === index) {
      currentAudio.pause();
      setCurrentAudio(null);
      setPlayingIndex(null);
    } else {
      if (currentAudio) {
        currentAudio.pause();
      }
      const newAudio = new Audio(audioUrl);
      newAudio.play();
      setCurrentAudio(newAudio);
      setPlayingIndex(index);
    }
  };
  const [audioURL, setAudioURL] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [newSpeakerName, setNewSpeakerName] = useState("");
  const [isFoldersOpen, setIsFoldersOpen] = useState(false);
  const [isWorkspacesOpen, setIsWorkspacesOpen] = useState(false);
  const [folders, setFolders] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const {showModal} = useModal();
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [recordingTime, setRecordingTime] = useState(0);
  const [speakerCount, setSpeakerCount] = useState(null);
  const handleFileUpload = (event) => {
    if(userData && userData.SStatus !== "Trial"){
      const file = event.target.files[0];
      if (file) {
        const fileURL = URL.createObjectURL(file);
        setAudioURL(fileURL);
        setAudioFile(file);
  
      }
    }else if(userData && userData.SStatus === "Trial"){
      showModal("UPGRADE-PREMIUM");
    }
  };

  const startRecording = async () => {
    if(userData && userData.SStatus !== "Trial"){
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current);
          const fileURL = URL.createObjectURL(audioBlob);
          setAudioURL(fileURL);
          setAudioFile(audioBlob);
          audioChunksRef.current = [];
        };
        mediaRecorderRef.current.start();
        
        setIsRecording(true);
      } catch (err) {
        console.error("Error accessing microphone:", err);
      }
    }else if(userData && userData.SStatus === "Trial"){
      showModal("UPGRADE-PREMIUM");
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleSave = async () => {
    try {
      if(isLimitReached) {
        // Reset state after saving
        setNewSpeakerName("");
        setAudioURL(null);
        setAudioFile(null);
        setIsAddNewAudio(false);
        showToast(t("Speaker label feature is limited to ten speakers per account."), { type: "error" });
        return;
      }
      if (!audioFile) {
        console.error("No audio file or pre-signed URL provided");
        return;
      }
      showToast(t("Saving sample speaker label..."), { type: "info" });
      const url =
        "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/transkriptorannotation/createurlforprofile";
      const payload = { hashed_id: hashedId, speaker_name: newSpeakerName };
      const data = await (await fetchDataPost(url, payload)).json();
      const presignedUrl = data.upload_url;
      // Upload the audio file to the pre-signed URL
      const response = await fetch(presignedUrl, {
        method: "PUT",
        body: audioFile,
        headers: {
          "Content-Type": audioFile.type,
        },
      });

      if (response.ok) {
        const url = "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/transkriptorannotation/uploaderfinisher";
        const payload = {
          hashed_id: hashedId,
          speaker_name: newSpeakerName,

        }
        const response = await fetchDataPost(url, payload);

        // Reset state after saving
        setNewSpeakerName("");
        setAudioURL(null);
        setAudioFile(null);
        setIsAddNewAudio(false);
        showToast(t("New speaker added successfully"), { type: "success" });
        getUserProfiles();

      } else {
        console.error("Failed to upload file", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const getUserProfiles = async () => {
    const url =
      "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/transkriptorannotation/getuserprofiles?hashed_id=" +
      data.HashedId;

    try {
      const response = await fetch(url);
      const res = await response.json();
      setUserProfiles(res);
      console.log("User profiles:", res);
    } catch (error) {
      console.error("Error getting user profiles", error);
    }
  };
  const getFolders = async () => {
    const url = "https://i3w4h1tgb5.execute-api.eu-central-1.amazonaws.com/default/AA-API-GetFolders?cid=" + hashedId;
    const response = await fetchData(url);
    setFolders(response);
  }
  const getWorkspaces = async () => {
    const url = "https://5eozpazgv4.execute-api.eu-central-1.amazonaws.com/default/Get-Teams?HashedId=" + hashedId;
    const response = await fetchData(url);
    setWorkspaces(response);
  }
  const [dictionaryStyle, setDictionaryStyle] = useState(null);

  useEffect(() => {
    getUserProfiles();
    getFolders();
    getWorkspaces();
    if(localStorage.getItem('transcript-language').toLowerCase().startsWith("en")) {
      setDictionaryStyle(null);
    } else {
      setDictionaryStyle({ display: "none" });
    }
    checkSpeakerCount();
  }, []);
  const [selectedDestination, setSelectedDestination] = useState(hashedId);
  localStorage.setItem('destinationId', hashedId);
  useEffect(() => {
    localStorage.setItem('destinationId', selectedDestination);
  }, [selectedDestination]);

  const handleDestinationChange = (value) => {
    if(userData && userData.SStatus === "Trial"){
      showModal("UPGRADE-PREMIUM");
      return;
    }
    setSelectedDestination(value);
    localStorage.setItem('destinationId', value);
  };
  const deleteProfile = async (speakerName) => {
    const url =
      "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/transkriptorannotation/deleteuserprofile";
    const payload = { hashed_id: hashedId, speaker_name: speakerName };
    const response = await fetchDataPost(url, payload);
    showToast(t("Labeled speaker is being deleted"), { type: "success" });
    getUserProfiles();
  }
  const checkSpeakerCount = async () => {
    const url = "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/transkriptorannotation/checkspeakercount?hashed_id=" + hashedId;
    const response = await fetchData(url);
    if(response["limit_reached"]) {
      setIsLimitReached(true);
    }else{
      setIsLimitReached(false);
    }
  };

  const handleSpeakerCount = (event) => {
    const value = event.target.value;
    if(value > 10){
      showToast(t("Speaker label feature is limited to ten speakers per account."), { type: "error" });
      return;
    }else if(value > 0 && value <= 10){
      setSpeakerCount(value);
      localStorage.setItem('speakerCount', value);
      showToast(t("Speaker count updated successfully for this transcription."), { type: "success" });
    }else{
      setSpeakerCount(null);
      
    }
  }
  const tooltipContent = (
  
    <>
      <Typography sx={{fontSize: 20, fontWeight: "700", fontFamily: "var(--medium-font)"}} color="#333333">{t("Follow formatting guidelines for custom vocabulary to ensure the best results:")}</Typography>
      <ul>
        <li style={{fontSize: 18, fontFamily: "var(--regular-font)", color:"#333333"}}>{t("Remove all punctuation except apostrophes.")}</li>
        <li style={{fontSize: 18, fontFamily: "var(--regular-font)", color:"#333333"}}>{t("Make sure each word is in its spoken form. For example, use 'iphone seven' instead of 'iphone 7'.")}</li>
        <li style={{fontSize: 18, fontFamily: "var(--regular-font)", color:"#333333"}}>{t("Remove spaces between letters in acronyms.")}</li>
        <li style={{fontSize: 18, fontFamily: "var(--regular-font)", color:"#333333"}}>{t("Additionally, the model still accepts words with unique characters such as é, but converts them to their ASCII equivalent.")}</li>
        <li style={{fontSize: 18, fontFamily: "var(--regular-font)", color:"#333333"}}>{t("You can boost a maximum of 1,000 unique keywords and phrases, where each of them can contain up to 6 words.")}</li>
      </ul>
    </>
  );

  return ( 
    <div className="mt-2 advanced-settings-container">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-center align-items-center">
          <a className="advanced-settings-button"
            onClick={()=>{
              props.setShowAdvancedOptions(false);
            
            }}
          >
            <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"/>
            </svg>
            </span>
            {t("Back")}
          </a>
        </div>
        <h2 className="mini-title">{t("Advanced Settings")}</h2>
        <div></div>
      </div>
    <ul className="nav nav-tabs" role="tablist">
      <li className="nav-item" style={dictionaryStyle}>
        <a className={`nav-link ${activeTab === 'dictionary' ? 'active' : ''}`} onClick={() => setActiveTab('dictionary')}>{t("Custom Dictionary")}</a>
      </li>
      <li className="nav-item">
      <a
        className={`nav-link ${activeTab === 'destination' ? 'active' : ''}`}
        onClick={() => setActiveTab('destination')}
        data-tooltip-id="destination-tooltip"

      >
        {t("Transcription Destination")}
 
        <ReactTooltip place="top-end" type="dark" effect="solid" className="tooltip-container" id="destination-tooltip" >
          <div className="tooltip-content">
          <span >{t("You can select any destination to upload your file. If you select a folder or workspace, your file will be uploaded to that folder or workspace. If you select 'Files', your file will be uploaded to your files.")}</span>
          </div>
        </ReactTooltip>
      </a>
    </li>
      <li className="nav-item">
        <a className={`nav-link ${activeTab === 'speakers' ? 'active' : ''}`} onClick={() => setActiveTab('speakers')}         data-tooltip-id="speakers-tooltip"
>{t("Labeled Speakers")}
        <ReactTooltip place="top-end" type="dark" effect="solid" className="tooltip-container" id="speakers-tooltip" >
          <div className="tooltip-content">
          <span >{t("You can upload a speaker sample or record one. Also, you can see your speaker labels and play or delete them.")}</span>
          </div>
        </ReactTooltip>
        </a>
      </li>
      <li className="nav-item">
        <a className={`nav-link ${activeTab === 'speaker-count' ? 'active' : ''}`} onClick={() => setActiveTab('speaker-count')}         data-tooltip-id="speaker-count-tooltip"
>{t("Speaker Count")}
        <ReactTooltip place="top-end" type="dark" effect="solid" className="tooltip-container" id="speaker-count-tooltip" >
          <div className="tooltip-content">
          <span >{t("You can predefine the number of speakers in the transcription to get a precise speaker number.")}</span>
          </div>
        </ReactTooltip>
        </a>
      </li>
    </ul>
    <div className="tab-content">
      <div className={`tab-pane fade dictionary-tab ${activeTab === 'dictionary' ? 'show active' : ''}`}>
        <div className="p-3">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="d-flex justify-content-center">

            </div>
            <div className="form-group text-left">
              <label>{t("Enter special names line by line or paste them.")}</label>
            </div>
            <Paper
              elevation={7}
              sx={{
                maxWidth: "1000px",
                width: "100%",
                height: "100%",
                borderRadius: "28px",
                overflow: "hidden",
                textAlign: "right !important",
                "& .MuiTextField-root": {
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    backgroundColor: "black",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "black",
                    borderRadius: "3px",
                  },
                },
                ...paperStyle,
              }}
            >
                <div className="d-flex justify-content-end">
                <a data-tooltip-id="dictionary-tooltip">
                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
</svg></span>
                  <ReactTooltip place="top-end" type="dark" effect="solid" className="tooltip-container" id="dictionary-tooltip" >
                  <div className="tooltip-content">
                    {tooltipContent}
                   </div>
                </ReactTooltip>
                </a>
                </div>

              <TextField
                multiline
                minRows={8}
                maxRows={9}
                value={customInput}
                onChange={handleCustomInputChange}
                placeholder={initialPlaceholderText}
                variant="outlined"
                fullWidth
                InputProps={{
                  sx: {
                    border: "none",
                    overflow: "auto",
                    fontFamily: "var(--regular-font)",
                    padding: "0px 4px 0px 4px",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: "0px",
                    },
                    "&:hover fieldset": {
                      borderWidth: "0px",
                    },
                    "&.Mui-focused fieldset": {
                      borderWidth: "0px",
                    },
                  },
                }}
                inputRef={textFieldRef}
              />
            </Paper>
            <div className="d-flex justify-content-center mt-3">
              <button className="btn theme-btn m-2" onClick={saveCustomPhrase}>
                {t("Save")}
              </button>
            </div>
          </Box>
        </div>
      </div>
      <div className={`tab-pane fade ${activeTab === 'speakers' ? 'show active' : ''}`}>
      
        <div className="p-3 list-container">
        {!audioURL && <input type="file" accept="audio/*" onChange={handleFileUpload} className="form-control mb-2" />}
          {audioURL && <audio controls src={audioURL} className="w-100 mb-2" />}
          {!audioURL && (
             <button type="button" className={`btn ${isRecording ? "btn-danger" : "btn-primary"} btn-sm m-2`} onClick={isRecording ? stopRecording : startRecording}>
             {isRecording ? t("Stop Recording") : t("Start Recording")}
           </button>
          )}
          {(audioURL) && (
            <div className="mt-4">
              <input type="text" className="form-control mb-2" placeholder={t("Enter speaker name")} value={newSpeakerName} onChange={(e) => setNewSpeakerName(e.target.value)} />
              <button type="button" className="btn theme-btn btn-sm m-2" disabled={newSpeakerName.length === 0 ? true: false} onClick={handleSave}>
                {t("Save")}
              </button>
            </div>
          )}
          {!audioURL && !isRecording && (  <h5 className="m-2 mt-2 mb-0">{t("Labeled Speakers")}</h5>)}
          {!isRecording && !audioURL && userProfiles && userProfiles.map((profile, index) => (
            <div key={index} className="row align-items-center p-2 mb-2">
              <div className="col-3">
                <span className="font-weight-bold">{profile.speaker_name}</span>
              </div>
              <div className="col-8 text-right">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm m-2"
                  onClick={() => handlePlayPause(profile.download_url, index)}
                >
                  {playingIndex === index ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pause"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 3.5A.5.5 0 0 1 6 4v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0A.5.5 0 0 1 10 4v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-play"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z" />
                    </svg>
                  )}
                  {t(playingIndex === index ? "Pause" : "Play")}
                </button>
                <button type="button" className="btn btn-outline-danger btn-sm m-2"
                  onClick={() => {
                    deleteProfile(profile.speaker_name);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                  </svg>
                  {t("Delete")}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={`tab-pane fade ${activeTab === 'destination' ? 'show active' : ''}`}>

        <div className="p-3 list-container">
        <div className="container mt-4">
              <ul className="list-group">
                  <li className="list-group-item form-check">
                    <input
                      type="radio"
                      id="files"
                      name="destination"
                      value="files"
                      checked={selectedDestination === hashedId}
                      onChange={() => handleDestinationChange(hashedId)}
                      className="form-check-input"
                    />
                    <label htmlFor="files" className="form-check-label">{t("Files")}</label>
                  </li>
                  <li className="list-group-item form-check">
                    <div className="d-flex align-items-center " onClick={() => setIsFoldersOpen(!isFoldersOpen)} style={{ cursor: 'pointer'}}>
                                        {isFoldersOpen ? <span style={{marginLeft: '-1.5em' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg></span>: <span style={{marginLeft: '-1.5em' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                  </svg></span>}
                      <label className="form-check-label" style={{marginLeft: '0.5em' }} >{t("Folders")}</label>
                    </div>
                    {isFoldersOpen && (
                      <ul className="list-group list-group-flush">
                        {folders.map((folder, index) => (
                          <li key={index} className="list-group-item">
                            <input
                              type="radio"
                              id={`folder-${index}`}
                              name="destination"
                              value={folder?.SK?.S.replace('User#', '')}
                              checked={selectedDestination === folder?.SK?.S.replace('User#', '')}
                              onChange={() => handleDestinationChange(folder?.SK?.S.replace('User#', ''))}
                              className="form-check-input"
                            />
                            <label htmlFor={`folder-${index}`} className="ml-2">{folder.Fname.S}</label>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                  <li className="list-group-item  form-check">
                    <div className="d-flex align-items-center" onClick={() => setIsWorkspacesOpen(!isWorkspacesOpen)} style={{ cursor: 'pointer' }}>
                    {isWorkspacesOpen ? <span style={{marginLeft: '-1.5em' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
                    </svg></span>: <span style={{marginLeft: '-1.5em' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                  </svg></span>}
                  <label className="form-check-label" style={{marginLeft: '0.5em' }} >{t("Workspaces")}</label>
                    </div>
                    {isWorkspacesOpen && (
                      <ul className="list-group list-group-flush">
                        {workspaces.map((workspace, index) => (
                          <li key={index} className="list-group-item">
                            <input
                              type="radio"
                              id={`workspace-${index}`}
                              name="destination"
                              value={workspace.TeamId}
                              checked={selectedDestination === workspace.TeamId}
                              onChange={() => handleDestinationChange(workspace.TeamId)}
                              className="form-check-input"
                            />
                            <label htmlFor={`workspace-${index}`} className="ml-2">{workspace.TeamName}</label>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                </ul>
              </div>
        </div>
      </div>
      <div className={`tab-pane fade ${activeTab === 'speaker-count' ? 'show active' : ''}`}>
  <div className="p-3 list-container">
    <div className="container mt-4">
      <label htmlFor="speakerCount">{t("Select or type a speaker number (1-10):")}</label>
      <input
        type="text"
        id="speakerCount"
        name="speakerCount"
        min="1"
        max="10"
        value={speakerCount}
        className="form-control"
        onChange={handleSpeakerCount}
        placeholder="Auto"
      />
    </div>
  </div>
</div>

    </div>
    
    </div>);
};

export default AdvancedOptions;
