import React, { useEffect } from 'react';
import { useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../../Auth/AuthContext';
import { useState } from 'react';
import { useNavigate, Link, } from 'react-router-dom';
import { useModal } from '../../components/common/Modal/ModalContext';
import { fetchDataPost } from '../../hooks/apiUtils';
import { showToast } from '../../components/common/toast';
import uploadPng from "../../assets/img/icon/upload-btn-icon.svg"
import { useUploader } from './UploaderContext';
import { useTranslation } from '../../components/common/LanguageProvider';

function UploadBox() {
    const { hashedId } = useAuth();
    const navigate = useNavigate();
    const { showModal } = useModal();
    const { uploadProgress, setUploadQueue, showUploadBox, uploadQueue, closeUploader, showUploadedState, setShowUploadedState, conversionFlag, checkUploader } = useUploader();
    const { t } = useTranslation();
    const [maximized, setMaximized] = useState(true);

    const filesNotUploadedCount = uploadQueue.reduce((count, file) => {
        return file.status !== 'completed' ? count + 1 : count;
    }, 0);

    const cancelUpload = async (file) => {
        setUploadQueue(uploadQueue.filter(f => f !== file));
    }


    return (
        <>
            <div className="progress-indicator-layer" style={{ display: !showUploadBox ? "none" : "" }}>

                <div className="card card-padding card-100 text-center ">
                    {/* Header */}
                    <div className="upload-header">

                        <span className="upload-header-title" style={{ display: !showUploadedState ? "" : "none" }}>
                             {filesNotUploadedCount === 1 ? t("File is being uploaded") : t("Files are being uploaded")}

                        </span>
                        <span className="upload-header-title" style={{ display: !showUploadedState ? "none" : "" }}>
                             {(filesNotUploadedCount === 1 || filesNotUploadedCount === 0) ? t("File is uploaded") : t("Files are uploaded")}

                        </span>
                        <div className="header-controls">

                            {maximized && (
                                <span onClick={() => setMaximized(false)} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
                                </svg></span>
                            )}
                            {!maximized && (
                                <span onClick={() => setMaximized(true)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M1.646 11.646a.5.5 0 0 1 .708 0L8 5.293l5.646 5.647a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1-.708 0l-6 6a.5.5 0 0 1 0-.708" />
                                </svg></span>
                            )}
                            <span onClick={checkUploader}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                            </svg></span>
                        </div>
                    </div>
                    {maximized && (
                        <div className="upload-indicator-box">

                            <div className="upload-list">
                                {uploadQueue.map((file, index) => (

                                    <div key={index} className="upload-item align-items-center" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', marginTop: '10px' }}>

                                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '70%' }}>
                                            {file.status === 'waiting' &&
                                                <span
                                                    onClick={() => cancelUpload(file)}
                                                    className='pointer me-2'
                                                ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                              </svg></span>}
                                            <span className="file-name" >{file.name}</span>
                                        </div>
                                        {file.status === "uploading" && conversionFlag && (
                                        <div style={{ width: '32px', height: '32px', marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {/* Circular Progress Bar */}
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        )}            
                                        {file.status === 'uploading' && !conversionFlag && (
                                            <div style={{ width: '32px', height: '32px', marginRight: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {/* Circular Progress Bar */}
                                                <svg viewBox="0 0 36 36" className="circular-chart" style={{ width: '32x', height: '32px' }}>
                                                    <path className="circle-bg"
                                                        d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                                                        fill="none" stroke="#eee" strokeWidth="4" />
                                                    <path className="circle"
                                                        strokeDasharray={`${uploadProgress}, 100`}
                                                        d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                                                        fill="none" stroke="#4caf50" strokeWidth="4" />
                                                </svg>
                                            </div>
                                        )}
                                        {file.status === 'waiting' && <span>{t("In queue")}</span>}
                                        {file.status === 'completed' && (
                                            <span style={{ marginRight: '10px' }}>

                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                </svg>
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>

                        </div>
                    )}
                </div>
            </div>

        </>
    );
}

export default UploadBox;