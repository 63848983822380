import React, { useEffect } from 'react';
import Editor from '../../pages/Editor/Editor';
import SharingComponent from '../../pages/Sharing';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../Auth/AuthContext';

const CheckSharingComponent = (props) => {
    const { oid } = useParams();
    console.log("sadsd");    
    return (
        <>
            {localStorage.getItem('loggedIn') === "true" ? <Editor oid={oid} handleOpenSidebar={props?.handleOpenSidebar} setIsCompleted={props?.setIsCompleted}/> : <SharingComponent setIsCompleted={props?.setIsCompleted}  />}
        </>
    );
};

export default CheckSharingComponent;
