import React, { useEffect, useState } from "react";

import {Link} from 'react-router-dom';
import {t} from "../../../components/common/translation"
import processingVid from "../../../assets/videos/processing.mp4";

function Processing({onRefresh}){

    const [isRefreshing, setIsRefreshing] = useState(false);
    const refreshPage = ()=>{
      setIsRefreshing(true);  
      onRefresh();
      setTimeout(() => {
        setIsRefreshing(false);
        
      }, 1000);
    };
    useEffect(() => {
        const timeoutId = setTimeout(onRefresh, 2 * 60 * 1000); // 2 minutes = 2 * 60 seconds = 2 * 60 * 1000 milliseconds

        // Clear the timeout if the component is unmounted before the 2 minutes are up
        return () => clearTimeout(timeoutId);
    }, []);
    return(
        <>
        <div className="card card-padding overflow-visible">
        <div class="card">
                            <div class="card-header theme-bg py-3 px-4">
                              <h2 class="mini-title white-color semibold text-center mb-0">
                                TRANSKRIPTOR
                              </h2>
                            </div>
                            <div class="card-padding">
                              <div class="d-md-flex d-block align-items-center text-center text-md-start">
                              
                             
                                <div>
                                 
                                  <p>
                                  {t("Your file is currently being processed and the process can take as long as the length of the file you uploaded. You can close this page if you wish, and you will receive a notification by email when your file is ready, or you can stay on this page to see the result.")}
                                  </p>
                                </div>
                              </div>
                              {isRefreshing && <>
                                <div className='d-flex justify-content-center text-center'>
                                  <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                              </>}
                              <div class="text-center mt-3">
                                <Link onClick={()=>refreshPage()} class="btn theme-btn">
                                  {t("Refresh")}
                                  <span class="ms-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                              </svg> 
                                  </span>
                                </Link>
                              </div>
                              <video src={processingVid} autoPlay loop style={{ width: '100%', height: '500px',  border: "none", margin: "0",padding: "0" }}></video>

                            </div>
                          </div>
        <hr></hr>
        <div class="col-xxl-8 mx-auto">
</div>

        <div class="col-xxl-8 mx-auto" style={{display: "none"}}>
          <div
            id="carouselExampleIndicators"
            class="carousel slide file-slider"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row gx-0 text-center">
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/blue-export.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Export as TXT, SRT, Word or Plain Text")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t("Download TXT, SRT, Word or Plain Text versions of your transcriptions in the editor page")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon slider-theme-icon-small">
                          <img
                            src="assets/img/files/blue-youtube.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                        <div class="slider-theme-icon slider-theme-icon-small">
                          <img
                            src="assets/img/files/blue-google-drive.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                        <div class="slider-theme-icon slider-theme-icon-small">
                          <img
                            src="assets/img/files/blue-dropbox.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                        <div class="slider-theme-icon slider-theme-icon-small">
                          <img
                            src="assets/img/files/blue-onedrive.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Transcribe from Youtube or Cloud Storage link")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Paste a Youtube or Cloud link in Uploader page and convert it to text in any language")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/blue-edit.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                       {t("Edit with slow motion and change speakers")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Paste a Youtube or cloud link in uploader page and convert it to text in any language")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/blue-caption.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Create Caption Files with a Button")}</p>
                      <div class="slider-hover-box">
                        <p>
                          {t("Create subtitles as SRT while uploading your audio")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/blue-relationship.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Colloborate on your transcripts")}</p>
                      <div class="slider-hover-box">
                        <p>
                          {t("Share transcripts with your teammates, create workspace to work on same transcripts and folders and invite your teammates")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/blue-translate.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Transcribe and translate in 100+ languages")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t("Transcribe the audio for any language and then, translate it to another within seconds")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row gx-0 text-center yellow-theme-slider">
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/yellow-time.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                       {t("Write and edit articles within seconds")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Use writing use cases to improve your essays or to make them better")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/yellow-case.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("30+ available use cases")}</p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Find the best use case for your needs and finish your works faster")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/yellow-ai.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Edit outputs manually and AI help you to improve")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Edit outputs in the text editor, and shorten, expand or rephrase text again")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/yellow-openbox.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Find ideas for your business")}</p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Give some keywords and get AI generated marketing ideas")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/yellow-relationship.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Colloborate on your results")}</p>
                      <div class="slider-hover-box">
                        <p>
                        {t("Share results with your teammates, create workspace to work on same files, and folders and invite your teammates")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/yellow-translate.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Find your answers in over 100 languages")}
                      </p>
                      <div class="slider-hover-box">
                        <p>{t("Find best results for your target language")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row gx-0 text-center pink-theme-slider">
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/pink-download.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Download Mp3 version of texts")}</p>
                      <div class="slider-hover-box">
                        <p>
                          {t("Download Mp3 version of converted text files, images or links")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/pink-link.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                       {t("Convert text to audio from any web link")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                        {t("Paste a web link and convert it to audio within seconds")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/pink-speaker.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Edit with slow motion and change speakers")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Change play speed and edit the text in detail, or change the speakers, then save it to download it")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/pink-convert.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Type anything and convert it to audio")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                          {t("Type anything and convert it to audio with over 150+ voices")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/pink-relationship.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">{t("Colloborate on your audio files")}</p>
                      <div class="slider-hover-box">
                        <p>
                        {t("Share audio files with your teammates, create workspace to work on same files and folders and invite your teammates")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 card-margin">
                    <div class="card card-padding card-100 slider-hover-card">
                      <div class="img-icon-list">
                        <div class="slider-theme-icon">
                          <img
                            src="assets/img/files/pink-translate.svg"
                            class="img-fluid"
                            alt="img"
                          />
                        </div>
                      </div>
                      <p class="slider-p">
                        {t("Create audio and translate in 100+ languages")}
                      </p>
                      <div class="slider-hover-box">
                        <p>
                         {t("Convert text to audio for any language and then, translate it to another within seconds")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
          </div>
        </div>
      </div>
        </>
    );
}

export default Processing;