import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from './LanguageProvider';
function ServiceDropdown(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(localStorage.getItem("transcript-service") || "Standard");
    const { t } = useTranslation();
    useEffect(() => {
        props.onServiceChange(selectedService);
    }, [selectedService, props]);

    const dropdownRef = useRef(null);

    const handleServiceClick = (event) => {
        const serviceValue = event.target.getAttribute('value');
        const serviceName = event.target.textContent; // Using textContent to ensure readability

        if (serviceValue) {
            setSelectedService(serviceValue); // This will now store the value, such as "8" for "Speaker Separated"
            setIsOpen(false);
            props.onServiceChange(serviceValue); // Notify parent component of the change
            localStorage.setItem("transcript-service", serviceValue); // Save the selection in localStorage
        }
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getDisplayName = (value) => {
        switch (value) {
            case "Standard":
                return t("Standard");
            case "Subtitle":
                return t("Subtitle");
            case "8":
                return t("Speaker Separated");
            default:
                return t("Standard"); // Default to "Standard" if no match is found
        }
    };

    
    return (
        <>
                <div ref={dropdownRef} className={`selectBox ${isOpen ? 'show' : ''}`} onClick={() => setIsOpen(!isOpen)}>
            <div className="form-control round-input selectBox__value">
            {getDisplayName(selectedService)}
            </div>
            {isOpen && (
                      <div class="dropdown-menu" onClick={handleServiceClick}>
                      <a href="#" className={`dropdown-item ${selectedService === "Standard" ? 'active' : ''}`} value="Standard">{t("Standard")}</a>
                      <a href="#" className={`dropdown-item ${selectedService === "Subtitle" ? 'active' : ''}`} value="Subtitle">{t("Subtitle")}</a>
                      <a href="#" className={`dropdown-item ${selectedService === "Speaker Separated" ? 'active' : ''}`} value="8">{t("Speaker Separated")}</a>
                  </div>
            )}
        </div>
          
        </>
    );
}

export default ServiceDropdown;