import React, { useState, useRef, useEffect } from "react";

//import shareSvg from "../../assets/img/files/share.svg";

import Player from "./Player";
import Failed from './Failed';
import Processing from './Processing';
import Download from '../../Editor/Download';

import Transcription from "./Transcription";
//import {t} from "@/components/common/translation"
import { useModal } from "../../../components/common/Modal/ModalContext";

import ConversionBanner from "../conversion-banner";
import DownloadApp from "../download-app";
import { useLocation } from "react-router-dom";
function Editor({ language }) {

  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("Completed");
  const fontSizeRef = useRef();
  const [filename, setFilename] = useState("");
  const [date, setDate] = useState("");
  const [duration, setDuration] = useState(0);
  const [fontSize, setFontSize] = useState(20);
  const [audio, setAudio] = useState(null);
  const [focusedTime, setFocusedTime] = useState(0);
  const [focus, setFocus] = useState(0);
  const [slideFocus, setSlideFocus] = useState(0);
  const [playBlock, setPlayBlock] = useState(false);
  const [speakerList, setSpeakerList] = useState([]);
  const [userTrial, setUserTrial] = useState(false);
  const contentRefs = useRef([]);
  const { showModal } = useModal();
  const [editing, setEditing] = useState(false);
  const [userStatus, setUserStatus] = useState(null);
  const [failCode, setFailCode] = useState(null);
  var oid = null;

  const location = useLocation();

  const fetchData = async (url) => {
    const response = await fetch(url);
    return await response.json();
  };

  if (!oid) {
    const params = new URLSearchParams(location.search);

    oid = oid || params.get("oid");
  }

  function convertMsToFormattedDate(ms) {
    ms = parseInt(ms);
    const date = new Date(ms);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns a 0-based value
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const goToBlock = (voiceStart) => {
    console.log(voiceStart);
    setFocusedTime(voiceStart);
    if (document.getElementById("audio-src")) {
      let audio = document.getElementById("audio-src");
      audio.play()
    }
  };
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };
  const sanitizeContent = (content) => {
    return content.replace(/&nbsp;/g, ' ').trim();
  };

  const handleTranscriptContent = (event, index) => {
    console.log("kekxce")
    let sanitizedContent = sanitizeContent(event.target.innerText);
    event.target.innerText = sanitizedContent;
    var text = event.target.innerText;
    // Create a new array with the updated content
    const updatedContent = [...content];
    updatedContent[index].text = text;

    setContent(updatedContent);
  };
  const getCaretPosition = (element) => {
    let position = 0;
    const selection = window.getSelection();
    if (selection.rangeCount !== 0) {
      const range = window.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      position = preCaretRange.toString().length;
    }
    return position;
  };

  function getCaretCharacterOffsetWithin(element) {
    let caretOffset = 0;
    const doc = element.ownerDocument || element.document;
    const win = doc.defaultView || doc.parentWindow;
    const sel = win.getSelection();
    if (sel.rangeCount > 0) {
      const range = win.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
    return caretOffset;
  }

  const handleKeyDown = (e, contentRef, index) => {
    setEditing(true);
    var kc = e.which || e.keyCode;

    const position = getCaretPosition(e.target);

    // Handle the Tab key to go to the next line
    // Handle the Tab key to go to the next ref
    if (e.key === "Tab") {
      e.preventDefault(); // Prevent default behavior (like changing focus to the next focusable element)

      // Check if there's a next ref in the array and if it exists
      if (contentRefs.current[index + 1]) {
        contentRefs.current[index + 1].focus();
      }
      return;
    } else if (e.altKey && e.key === "ArrowUp") {
      e.preventDefault();
      if (contentRefs.current[index - 1]) {
        contentRefs.current[index - 1].focus();
      }
      return;
    }
    if (e.key === "ArrowRight" && position === e.target.textContent.length) {

      let nextElem = contentRefs.current[index + 1]
      if (!nextElem) return;

      nextElem.focus();
    } else if (e.key === "ArrowLeft" && position === 0) {
      let prevElem = contentRefs.current[index - 1]
      if (!prevElem) return;

      prevElem.focus();

    } else if (e.key === "Enter") {
      e.preventDefault();

      // Get current text and split it at caret position
      const currentText = contentRefs.current[index].innerText.trim();
      const beforeCaret = currentText.slice(0, position);
      const afterCaret = currentText.slice(position);
      console.log(content)
      console.log("bababa")
      // If caret is at the end of the string, there is nothing to split.
      if (position === currentText.length) return;

      const currentItem = content[index];
      const { StartTime, EndTime, VoiceStart, VoiceEnd } = currentItem;

      // Calculate ratio to split the time and voice properties
      const ratio = beforeCaret.length / currentText.length;

      // Create the first and second items with updated properties
      const firstItem = {
        ...currentItem,
        text: beforeCaret,
        VoiceEnd: VoiceStart + Math.round((VoiceEnd - VoiceStart) * ratio),
        EndTime: StartTime + Math.round((EndTime - StartTime) * ratio),
      };

      const secondItem = {
        ...currentItem,
        text: afterCaret,
        VoiceStart: firstItem.VoiceEnd,
        StartTime: firstItem.EndTime,
      };

      // Update content array and set new content
      const updatedContent = [...content.slice(0, index), firstItem, secondItem, ...content.slice(index + 1)];
      setContent(updatedContent);

      // Focus on the newly created item
      setTimeout(() => {
        if (contentRefs.current[index + 1]) contentRefs.current[index + 1].focus();
      }, 0);
    }

    if (e.key === "Backspace" && position === 0) {
      e.preventDefault();

      // Check if there's a previous index
      if (content[index - 1]) {
        // Merge the content of the current line with the previous line
        const prevText = content[index - 1].text;
        const currentText = contentRefs.current[index].innerText.trim();
        const updatedItem = {
          ...content[index - 1],
          text: prevText + " " + currentText,
          VoiceEnd: content[index].VoiceEnd,
          EndTime: content[index].EndTime
        }

        // Update the content array
        let updatedContent = [...content];
        updatedContent[index - 1] = updatedItem;
        updatedContent.splice(index, 1);

        // Update the state
        setContent(updatedContent);

        setTimeout(() => {
          const elem = contentRefs.current[index - 1];
          if (elem) {
            elem.focus();
            const textLength = prevText.length + 1; // +1 for the space between merged texts

            const range = document.createRange();
            range.setStart(elem.childNodes[0] || elem, textLength);
            range.collapse(true);

            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }, 0);
      }
    }



    if (e.key === "Delete" && position === e.target.textContent.length) {
      e.preventDefault();

      // Check if there's a next index
      if (content[index + 1]) {
        const nextText = content[index + 1].text;
        const currentText = contentRefs.current[index].innerText.trim();

        const updatedItem = {
          ...content[index],
          text: currentText + " " + nextText,
          VoiceEnd: content[index + 1].VoiceEnd,
          EndTime: content[index + 1].EndTime
        }

        let updatedContent = [...content];
        updatedContent[index] = updatedItem;
        updatedContent.splice(index + 1, 1);

        // Update the state
        setContent(updatedContent);

        setTimeout(() => {
          const elem = contentRefs.current[index];
          if (elem) {
            elem.focus();

            const range = document.createRange();
            range.setStart(elem.childNodes[0] || elem, position);
            range.collapse(true);

            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
          }
        }, 0);
      }
    }


    if ((e.ctrlKey || e.metaKey)) {
      switch (e.key.toLowerCase()) {

        case 'a':
          e.preventDefault();
          if (userStatus && userStatus === "Trial") {
            showModal("UPGRADE-COPY");
            return;
          }
          if (contentRefs.current[index]) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(contentRefs.current[index]);
            selection.removeAllRanges();
            selection.addRange(range);
          }
          break;
        case 'c':
          e.preventDefault();
          if (userStatus && userStatus === "Trial") {
            showModal("UPGRADE-COPY");
            return;
          }
          navigator.clipboard.writeText(window.getSelection().toString())
            .then(() => {
              console.log('Text successfully copied to clipboard!');
            })
            .catch(err => {
              console.error('Could not copy text: ', err);
            });
          break;
        case 'v':
          e.preventDefault();
          if (userStatus && userStatus === "Trial") {
            showModal("UPGRADE-COPY");
            return;
          }
          navigator.clipboard.readText()
            .then(clipText => {
              const currentItem = contentRefs.current[index];
              const currentText = currentItem.innerText;
              const caretPos = getCaretCharacterOffsetWithin(currentItem);
              const newText = currentText.slice(0, caretPos) + clipText + currentText.slice(caretPos);
              currentItem.innerText = newText;
            })
            .catch(err => {
              console.error('Could not read clipboard: ', err);
            });
          break;
        case "1":
        case "2":
        case "3":
        case "4":
          e.preventDefault();

          const selectedSpeakerIndex = parseInt(e.key, 10) - 1;
          // Modulo operation to cycle through available speakers if the selected speaker index is out of range
          const speakerIndex = selectedSpeakerIndex % speakerList.length;
          handleChangeSpeaker(speakerList[speakerIndex], index);

          break;
        case "5":
          e.preventDefault();

          let currentSpeakerIndex = speakerList.findIndex(s => s === content[index].Speaker);

          const nextSpeakerIndex = (currentSpeakerIndex + 1) % speakerList.length;
          handleChangeSpeaker(speakerList[nextSpeakerIndex], index);
          break;
      }

    }


  };
  const handleAddSpeaker = (speaker) => {
    console.log("asd:" + speaker);
    const temp_speakerList = [...speakerList];
    temp_speakerList.push(speaker);
    setSpeakerList(temp_speakerList);
  };

  const handleChangeSpeaker = (speaker, index) => {
    const updatedContent = [...content];
    updatedContent[index].Speaker = speaker;
    setContent(updatedContent);
  };

  const handleRenameSpeaker = (speaker, newSpeaker) => {
    const temp_speakerList = [...speakerList];
    const index = temp_speakerList.indexOf(speaker);
    temp_speakerList[index] = newSpeaker;
    setSpeakerList(temp_speakerList);
    const updatedContent = [...content];
    updatedContent.forEach((item) => {
      if (item.Speaker === speaker) {
        item.Speaker = newSpeaker;
      }
    });
    setContent(updatedContent);
  };
  // First let's set the colors of our sliders
  const settings = {
    fill: "var(--theme-color)",
    background: "#E0E8F1",
  };

  const shareLink = async () => {
    try {
      // Get the current origin (e.g., "http://localhost:3000" or "https://app.transkriptor.com")
      let baseUrl = window.location.origin;



      await navigator.clipboard.writeText(baseUrl);

      //showToast(t("File link copied to clipboard"));

      return true;

    } catch (err) {
      console.error('Failed to copy text: ', err);
      return false;
    }
  };
  function applyFill(slider) {
    // Let's turn our value into a percentage to figure out how far it is in between the min and max of our input
    const percentage =
      (100 * (slider.value - slider.min)) / (slider.max - slider.min);
    // now we'll create a linear gradient that separates at the above point
    // Our background color will change here
    const bg = `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${settings.background
      } ${percentage + 0.1}%)`;
    slider.style.background = bg;
  }

  // Now you can use tid and uid in this component
  const getTranscript = async () => {
    var temp_url = "https://sqxnupivk2.execute-api.eu-central-1.amazonaws.com/default/AA-API-Gettranscription-content-v2?OrderId=" + oid;

    const response = await fetchData(temp_url);

    let temp_content = []
    response["content"].forEach((item) => {
      // Check if contentItem has a text property that is defined, not null and not an empty string
      if (item.text && item.text.trim() !== "") {

        temp_content.push(item);
        // Or if you are supposed to call setContent for each non-empty text
        // setContent(contentItem.text);
      }
    });
    setContent(temp_content);

    setAudio(response["sound"]);
    setDate(convertMsToFormattedDate(response["date"]));
    setFilename(response["filename"]);


    setUserTrial(response["userTrial"] === "yes" ? true : false);
    applyFill(fontSizeRef.current);
    setIsLoading(false);

  };

  useEffect(() => {
    getTranscript("en");
    const sliders = document.querySelectorAll(".range-slider");
    Array.prototype.forEach.call(sliders, (slider) => {
      slider.querySelector("input").addEventListener("input", (event) => {
        slider.querySelector("span").innerHTML = event.target.value;
        applyFill(event.target);
      });
      applyFill(slider.querySelector("input"));
    });
  }, []);

  const setChangeDetect = () => {
    return true;
  };
  return (
    <>
      <div>

        <div className="row justify-content-center">
          <div>
            {isLoading && !content ? (
              <>
                <div className='d-flex justify-content-center text-center'>
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </>
            ) : (
              status === "Failed" ? (
                <>
                  <Failed
                    failCode={failCode}
                  />
                </>
              ) : status === "Processing" ? (
                <>
                  <Processing
                    onRefresh={getTranscript}
                  />
                </>
              ) : status === "Completed" ? (
                <>


                  <div className="card card-padding ">
                    <div className="editor-header">
                      <div className={`file-row-sharing ${isMobile ? 'mobile' : 'd-none'}`}>
                        <div className="filename-container" style={{ zIndex: '98' }}>
                          <div className="filename-hover">
                            <div className="filename-container">
                              <span className="filename ">{filename}</span>
                            </div>
                            <span className="filename-span">{filename}</span>
                          </div>
                        </div>
                        <Download content={content} filename={filename} audio={audio} contentRefs={contentRefs} orderId={oid} setChangeDetect={setChangeDetect} />
                      </div>
                    </div>
                    <div className="row align-items-center" >
                      <div className={`col-xxl-4 col-lg-4 col-md-4 col-12 mb-2 order-xxl-1 order-lg-1 order-md-1 order-1 text-md-start text-center notranslate ${isMobile ? 'd-none' : ''}`}>
                        <h2 class="small-title regular mb-2">

                          <div className="file-row-sharing">

                            <div className="filename-hover">
                              <div className="filename-container">
                                <span className="filename ">{filename}</span>
                              </div>
                              <span className="filename-span">{filename}</span>
                            </div>
                          </div>
                        </h2>

                        <p class="ms-2 d-sm-inline-block d-block ft-14 mb-1 me-sm-4 me-0 web-view ">
                          <span class="me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="16"
                              viewBox="0 0 19.2 18"
                            >
                              <g id="date-line" transform="translate(-2 -2)">
                                <path
                                  id="Path_135"
                                  data-name="Path 135"
                                  d="M20.15,6H18.2V7.2H20V20.4H3.2V7.2H5V6H3.05A1.068,1.068,0,0,0,2,7.086V20.514A1.068,1.068,0,0,0,3.05,21.6h17.1a1.068,1.068,0,0,0,1.05-1.086V7.086A1.068,1.068,0,0,0,20.15,6Z"
                                  transform="translate(0 -1.6)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_136"
                                  data-name="Path 136"
                                  d="M8,14H9.2v1.2H8Z"
                                  transform="translate(-2.4 -4.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_137"
                                  data-name="Path 137"
                                  d="M14,14h1.2v1.2H14Z"
                                  transform="translate(-4.8 -4.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_138"
                                  data-name="Path 138"
                                  d="M20,14h1.2v1.2H20Z"
                                  transform="translate(-7.2 -4.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_139"
                                  data-name="Path 139"
                                  d="M26,14h1.2v1.2H26Z"
                                  transform="translate(-9.6 -4.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_140"
                                  data-name="Path 140"
                                  d="M8,19H9.2v1.2H8Z"
                                  transform="translate(-2.4 -6.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_141"
                                  data-name="Path 141"
                                  d="M14,19h1.2v1.2H14Z"
                                  transform="translate(-4.8 -6.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_142"
                                  data-name="Path 142"
                                  d="M20,19h1.2v1.2H20Z"
                                  transform="translate(-7.2 -6.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_143"
                                  data-name="Path 143"
                                  d="M26,19h1.2v1.2H26Z"
                                  transform="translate(-9.6 -6.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_144"
                                  data-name="Path 144"
                                  d="M8,24H9.2v1.2H8Z"
                                  transform="translate(-2.4 -8.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_145"
                                  data-name="Path 145"
                                  d="M14,24h1.2v1.2H14Z"
                                  transform="translate(-4.8 -8.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_146"
                                  data-name="Path 146"
                                  d="M20,24h1.2v1.2H20Z"
                                  transform="translate(-7.2 -8.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_147"
                                  data-name="Path 147"
                                  d="M26,24h1.2v1.2H26Z"
                                  transform="translate(-9.6 -8.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_148"
                                  data-name="Path 148"
                                  d="M9.6,6.8a.6.6,0,0,0,.6-.6V2.6A.6.6,0,0,0,9,2.6V6.2A.6.6,0,0,0,9.6,6.8Z"
                                  transform="translate(-2.8)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_149"
                                  data-name="Path 149"
                                  d="M25.6,6.8a.6.6,0,0,0,.6-.6V2.6a.6.6,0,0,0-1.2,0V6.2A.6.6,0,0,0,25.6,6.8Z"
                                  transform="translate(-9.2)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_150"
                                  data-name="Path 150"
                                  d="M13,6h6V7.2H13Z"
                                  transform="translate(-4.4 -1.6)"
                                  fill="#6b6c6d"
                                />
                              </g>
                            </svg>
                          </span>
                          {date}
                        </p>
                        <p class="d-sm-inline-block d-block ft-14 mb-1 web-view">
                          <span class="me-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 16.875 16.875"
                            >
                              <g id="clock" transform="translate(-1.125 -1.125)">
                                <path
                                  id="Path_151"
                                  data-name="Path 151"
                                  d="M17.966,6.715H16.841v6.261l4.064,1.932.483-1.016-3.423-1.627Z"
                                  transform="translate(-7.858 -2.795)"
                                  fill="#6b6c6d"
                                />
                                <path
                                  id="Path_152"
                                  data-name="Path 152"
                                  d="M9.563,1.125A8.438,8.438,0,1,0,18,9.563,8.437,8.437,0,0,0,9.563,1.125Zm0,15.75a7.313,7.313,0,1,1,7.313-7.312A7.313,7.313,0,0,1,9.563,16.875Z"
                                  fill="#6b6c6d"
                                />
                              </g>
                            </svg>
                          </span>
                          {formatTime(duration)}
                        </p>
                      </div>
                      <div className={`col-xxl-5 col-lg-4 col-md-4 col-12 order-xxl-2 order-lg-2 order-md-3 order-3 ${isMobile ? 'd-none' : ''}`}>
                        <div class="row align-items-center">
                          <div class="col-md-6" style={{ overflow: "hidden" }}>
                            <div id="google_translate_element" className="google-div"></div>


                          </div>
                          <div class="col-md-6 web-view notranslate d-none" >
                            <div class="d-flex align-items-center justify-content-between mb-3">
                              <p class="mb-0">Aa</p>
                              <div class="water-slider text-slider w-100 mx-3">
                                <div class="range-slider">
                                  <span class="range-slider__value"></span>
                                  <input
                                    id="range-slider-val"
                                    class="range-slider__range browser-default"
                                    type="range"
                                    ref={fontSizeRef}
                                    min="16" max="28" value={fontSize}

                                  />
                                </div>
                              </div>
                              <p class="small-title mb-0">Aa</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`col-xxl-3 col-md-4 col-12 order-xxl-3 order-lg-3 order-x order-300 text-md-end text-end notranslate ${isMobile ? 'd-none' : ''}`}>
                        <div class="file-action-icon-flex mb-3">

                          <Download content={content} filename={filename} audio={audio} contentRefs={contentRefs} orderId={oid} setChangeDetect={setChangeDetect} />





                        </div>
                      </div>
                    </div>
                    <hr class="mt-0" />
                    <div class="files-tab-div-height">
                      <ul
                        class="nav nav-pills btn-pills mb-3 notranslate"
                        id="pills-tab"
                        role="tablist"
                      >



                      </ul>
                      <div class="tab-content" id="pills-tabContent">
                        {isLoading && content ? (
                          <>
                            <div className='d-flex justify-content-center text-center'>
                              <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                          </>) : null}
                        <div
                          class="tab-pane fade show active"
                          id="pills-traskript"
                          role="tabpanel"
                          aria-labelledby="pills-traskript-tab"
                        >
                          <div class="transkript-list-res-height">
                            <div class="row gx-0 w-100 m-0">
                              <div className="col-12">
                                {content && content.length > 0 ? (
                                  content.every(item => !item.text.trim()) ? (
                                    <p className="text-center">Make sure that the audio/video file has sound!</p>
                                  ) : (
                                    <>
                                      {content.map((item, index) => (
                                        <React.Fragment key={index}>
                                          <Transcription
                                            tabIndex={0}
                                            item={item}
                                            index={index}
                                            goToBlock={goToBlock}
                                            handleTranscriptContent={handleTranscriptContent}
                                            handleKeyDown={handleKeyDown}
                                            onAddSpeaker={handleAddSpeaker}
                                            speakerList={speakerList}
                                            changeSpeaker={handleChangeSpeaker}
                                            onRenameSpeaker={handleRenameSpeaker}
                                            fontSize={fontSize}
                                            ref={(el) => (contentRefs.current[index] = el)}
                                            focus={focus}
                                            slideFocus={slideFocus}
                                          />
                                          {index === content.length - 1 && <div style={{ height: '400px' }} />}
                                        </React.Fragment>
                                      ))}
                                    </>

                                  )
                                ) : null}


                              </div>


                            </div>
                          </div>
                          <div class="m-2">

                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="pills-chat"
                          role="tabpanel"
                          aria-labelledby="pills-chat-tab"
                        >
                        </div>
                      </div>
                    </div>


                  </div>
                </>
              ) : (<></>)

            )}
          </div>
        </div>
      </div >
      {status === "Completed" ? (
        <>
          {isMobile ? <DownloadApp /> : <ConversionBanner />}
          <Player
            audio={audio}
            focusedTime={focusedTime}
            onDurationChange={setDuration}
            applyFill={applyFill}
            setFocus={setFocus}
            setSlideFocus={setSlideFocus}
            playBlock={playBlock}
          // This function updates the totalDuration state in the parent
          />
        </>
      ) : (
        <></>
      )
      }
    </>
  );
}

export default Editor;
