import React, { useContext, useState, useEffect } from 'react';

const LanguageContext = React.createContext(null);
let cachedTranslations = {};
let toTranslate= {};
export async function loadLanguageTranslations(languageCode) {
    if(languageCode === "en"){
        return {};
    }
    if (cachedTranslations[languageCode]) {
        return cachedTranslations[languageCode];
    }

    try {
        const translations = await import(`../../assets/translations/${languageCode}.json`);
        cachedTranslations[languageCode] = translations;
        return translations;
    } catch (error) {
        console.error("Error loading language file:", error);
        return {};
    }
}
export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(localStorage.getItem('dashboard-language') || navigator.language.split('-')[0] || 'en');
    const [translations, setTranslations] = useState({});

    useEffect(() => {
        loadLanguageTranslations(language).then(setTranslations);
    }, [language]);

    const t = (word) => translations[word] || word;

    return (
        <LanguageContext.Provider value={{ t, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useTranslation = () => useContext(LanguageContext);