import React from "react";
import downloadapple from '../../assets/svg/sidebar/downloadapple.svg';
import downloadgoogleplay from '../../assets/svg/sidebar/downloadgoogleplay.svg';

function DownloadApp() {

    const isClient = typeof window !== 'undefined';

    const isAndroid = () => isClient && /Android/i.test(navigator.userAgent);
    // URLs for different platforms
    const appStoreURL = 'https://apps.apple.com/us/app/transkriptor-speech-to-text/id1610386353';
    const googlePlayURL = 'https://play.google.com/store/apps/details?id=com.transkriptor.app';

    return (
        <div className="download-img-sharing">
            <a href={isAndroid() ? googlePlayURL : appStoreURL} target="_blank" rel="noopener noreferrer">
                <img src={isAndroid() ? downloadgoogleplay : downloadapple} alt="Download App" />
            </a>
        </div>
    );
}

export default DownloadApp;