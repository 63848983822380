import React, { useRef, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useModal } from "./ModalContext";
import { useDropzone } from "react-dropzone";
import LanguageDropdown from "../transcribeLanguageDropdown";
import DashboardLanguageDropdown from "../dashboardLanguageDropdown";
import audioIcon from "../../../assets/img/icon/audio-icon.svg";
import videoIcon from "../../../assets/img/icon/video-icon.svg";
import googleDriveSvg from "../../../assets/img/icon/google-drive.svg";
import dropboxSvg from "../../../assets/img/icon/dropbox.svg";
import oneDriveSvg from "../../../assets/img/icon/onedrive.svg";
import youtubeSvg from "../../../assets/img/icon/youtube.svg";
import deleteSvg from "../../../assets/img/icon/delete.svg";
import { showToast } from "../toast.js";
import ServiceDropdown from "../transcribeServiceDropdown";
import googleIcon from "../../../assets/img/icon/google-icon.png";
import subscriptionBilling from "../../../assets/img/billing/subscription.png";
import billingGift from "../../../assets/img/billing/gift.png";
import { useAuth } from "../../../Auth/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@mui/material";
import { Typography } from "@mui/material";
import AdvancedOptions from "./AdvancedOptions.js";
import { useTranslation } from "../LanguageProvider";
import Skeleton from "react-loading-skeleton";
import { set } from "react-hook-form";
import { VideoPlayer } from "../../video/videoPlayer.js";
import recordPermission from "../../../assets/img/record/record_permission.png";
import TextField from "@mui/material/TextField";
import { fetchData } from "../../../hooks/apiUtils.js";
import {
  subscription_aware,
  subscription_checkout,
} from "../../../ampli/index.js";
import StripePayment from "../../Stripe/CheckoutForm.js";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getFlagVariant } from "../../Paddle/ampliExpFlag.js";
function BootstrapModal() {
  const { t } = useTranslation();
  const { modalType, modalData, hideModal, specialHideModal } = useModal();
  const { currentUser, login, hashedId, googleLogin, userData } = useAuth();
  const onRename = modalData?.onRename;
  const onDelete = modalData?.onDelete;
  const onMove = modalData?.onMove;
  const onShareTeam = modalData?.onShareTeam;
  const onUploadFile = modalData?.onUploadFile;
  const onTranscribeFile = modalData?.onTranscribeFile;
  const onTranscribeURL = modalData?.onTranscribeURL;
  const onTranscribeRecord = modalData?.onTranscribeRecord;
  const onRedirectRecorder = modalData?.onRedirectRecorder;
  const onLogout = modalData?.onLogout;
  const startRecording = modalData?.startRecording;
  const onResetPassword = modalData?.onResetPassword;
  const onDeleteAccount = modalData?.onDeleteAccount;
  const onDeleteAccountComment = modalData?.onDeleteAccountComment;
  const onDeleteCompletely = modalData?.onDeleteCompletely;
  const onCancelSubscription = modalData?.onCancelSubscription;
  const onCancelSubscriptionComment = modalData?.onCancelSubscriptionComment;
  const onCancelSubscriptionComplete = modalData?.onCancelSubscriptionComplete;
  const onDeleteFolder = modalData?.onDeleteFolder;
  const onCreateFolder = modalData?.onCreateFolder;
  const onDeleteWorkspace = modalData?.onDeleteWorkspace;
  const onCreateWorkspace = modalData?.onCreateWorkspace;
  const onAddSpeaker = modalData?.onAddSpeaker;
  const onRenameSpeaker = modalData?.onRenameSpeaker;
  const onDownloadTranscript = modalData?.onDownloadTranscript;
  const onEnterpriseInvite = modalData?.onEnterpriseInvite;
  const onEnterpriseRemove = modalData?.onEnterpriseRemove;
  const onRenameTeam = modalData?.onRenameTeam;
  const onRemoveMember = modalData?.onRemoveMember;
  const onInviteTeam = modalData?.onInviteTeam;
  const onLeaveTeam = modalData?.onLeaveTeam;
  const onDeleteTeam = modalData?.onDeleteTeam;
  const onUploadMultipleFiles = modalData?.onUploadMultipleFiles;
  const onTranscribeMultipleFiles = modalData?.onTranscribeMultipleFiles;
  const onLeaveEditor = modalData?.onLeaveEditor;
  const onChangeUserName = modalData?.onChangeUserName;
  const onSendFeedback = modalData?.onSendFeedback;
  const onTranscribeModal = modalData?.onTranscribeModal;
  const addMoreFiles = modalData?.addMoreFiles;
  const onAddSpeakerProfile = modalData?.onAddSpeakerProfile;
  const [selectedLanguage, setSelectedLanguage] = useState(
    modalData?.languageParam || 
    localStorage.getItem("transcript-language") || "en-US"
  );
  const [selectedService, setSelectedService] = useState("Standard");
  const [selectedFolder, setSelectedFolder] = useState(null); // To track the selected folder.
  const [isMoveButtonEnabled, setIsMoveButtonEnabled] = useState(false); // To track if the "Move" button is enabled.
  const [selectedTeam, setSelectedTeam] = useState(null); // To track the selected folder.
  const [isShareButtonEnabled, setIsShareButtonEnabled] = useState(false); // To track if the "Move" button is enabled.
  const [size, setSize] = useState(null);
  const inputRef = useRef();
  const [newFileName, setNewFileName] = useState("");
  const [folders, setFolders] = useState([]);
  const [teams, setTeams] = useState([]);
  const [url, setUrl] = useState(modalData?.linkParam || "");
  const [mailInput, setMailInput] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [commentsValue, setCommentsValue] = useState("");
  const [additionalValue, setAdditionalValue] = useState("");
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [transcribeUrlInProgress, setTranscribeUrlInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLastStep, setDeleteLastStep] = useState(false);
  const [newSpeaker, setNewSpeaker] = useState("New Speaker");
  const [exportFormat, setExportFormat] = useState("txt");
  const [paragraphSize, setParagraphSize] = useState("1");
  const [includeTimestamps, setIncludeTimestamps] = useState(false);
  const [mergeSegments, setMergeSegments] = useState(false);
  const [includeSpeakerNames, setIncludeSpeakerNames] = useState(false);
  const [singleParagraph, setSingleParagraph] = useState(false);
  const [transcription, setTranscription] = useState(true);
  const [transcribeRecordProgress, setTranscribeRecordProgress] =
    useState(false);
  const [mp3, setMP3] = useState(false);
  const exportDropdown = useRef(null);
  const paragraphSizeDropdown = useRef(null);
  const fileInputRef = useRef(null);
  const textFieldRef = useRef(null);

  const [os, setOs] = useState("Other");
  const [webURL, setWebURL] = useState("");
  const [urlWarning, setUrlWarning] = useState(null);

  const [files, setFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [typedText2, setTypedText2] = useState("");

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const [list, setList] = React.useState([]);
  const [editingSpeaker, setEditingSpeaker] = useState(null);

  const navigate = useNavigate();
  const times = [
    "00:00:00",
    "00:00:02",
    "00:00:05",
    "00:00:09",
    "00:00:11",
    "00:00:15",
  ];
  const isChrome = window.navigator.userAgent.includes("Chrome");

  const defaultSettings = {
    exportFormat: "txt",
    paragraphSize: "1",
    includeTimestamps: false,
    mergeSegments: false,
    includeSpeakerNames: false,
    singleParagraph: false,
    transcription: true,
    mp3: false,
  };

  const [settings, setSettings] = useState(() => {
    const localSettings = localStorage.getItem("exportSettings");
    return localSettings && localSettings.length > 0
      ? JSON.parse(localSettings)
      : defaultSettings;
  });
  const [expVariant, setExpVariant] = useState(null);
  useEffect(() => {
    const savedExportFormat = localStorage.getItem("exportFormat") || "txt";
    const savedParagraphSize = localStorage.getItem("paragraphSize") || "1";
    const savedIncludeTimestamps =
      localStorage.getItem("includeTimestamps") === "false";
    const savedMergeSegments =
      localStorage.getItem("mergeSegments") === "false";
    const savedIncludeSpeakerNames =
      localStorage.getItem("includeSpeakerNames") === "false";
    const savedSingleParagraph =
      localStorage.getItem("singleParagraph") === "false";
    const savedTranscription = true;
    const savedMP3 = localStorage.getItem("mp3") === "false";

    setExportFormat(savedExportFormat);
    setParagraphSize(savedParagraphSize);
    setIncludeTimestamps(savedIncludeTimestamps);
    setMergeSegments(savedMergeSegments);
    setIncludeSpeakerNames(savedIncludeSpeakerNames);
    setSingleParagraph(savedSingleParagraph);
    setTranscription(savedTranscription);
    setMP3(savedMP3);
  }, []);

  const [prevConfig, setPrevConfig] = useState({
    includeTimestamps: includeTimestamps,
    includeSpeakerNames: includeSpeakerNames,
  });

  useEffect(() => {
    if (singleParagraph) {
      setPrevConfig({
        includeTimestamps: includeTimestamps,
        includeSpeakerNames: includeSpeakerNames,
      });

      setIncludeTimestamps(false);
      setIncludeSpeakerNames(false);
      setParagraphSize("1");
    } else {
      // Restore previous state
      setIncludeTimestamps(prevConfig.includeTimestamps);
      setIncludeSpeakerNames(prevConfig.includeSpeakerNames);
    }
  }, [singleParagraph]); // Dependency array

  const handleSingleParagraphChange = () => {
    // Toggle singleParagraph state
    setSingleParagraph(!singleParagraph);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputSubmit = (event) => {
    event.preventDefault();
    if (!inputValue.trim()) return;
    setList([...list, inputValue]);
    setInputValue("");
  };

  useEffect(() => {
    setSize(null);
    setMailInput("");

    if (
      (modalType === "RENAME" ||
        modalType === "CREATE-FOLDER" ||
        modalType === "CREATE-WORKSPACE" ||
        modalType === "ADD-SPEAKER" ||
        modalType === "RENAME-SPEAKER" ||
        modalType === "ADD-ENTERPRISE-MEMBER") &&
      inputRef.current
    ) {
      inputRef.current.focus();
      setNewFileName(modalData.name);
      setNewSpeaker("New Speaker");
    } else if (modalType === "MOVE") {
      setFolders(modalData.folders);
    } else if (modalType === "SHARE-TEAM") {
      setTeams(modalData.teams);
    } else if (
      modalType === "UPLOAD-FILE" ||
      modalType === "TRANSCRIBE-FILE" ||
      modalType === "TRANSCRIBE-URL" ||
      modalType === "MULTIPLE-UPLOAD" ||
      modalType === "TRANSCRIBE-MULTIPLE-FILES" ||
      modalType === "TRANSCRIBE-RECORD" ||
      modalType === "RECORD-NO-DEVICES" ||
      modalType === "RECORD-NO-AUDIO-DEVICES" ||
      modalType === "RECORD-NO-VIDEO-DEVICES" ||
      modalType === "DOWNLOAD-TRANSCRIPT" ||
      modalType === "STRIPE-PAYMENT"
    ) {
      setSize("xl");
    } else if (modalType === "RESET-PASSWORD") {
      setMailInput(modalData.email);
      inputRef.current.focus();
    } else if (modalType === "DELETE-ACCOUNT-COMMENT") {
      setSelectedReason(modalData.reason);
      setCommentsValue("");
      setAdditionalValue("");
    } else if (modalType === "DELETE-ACCOUNT-VERIFY") {
      setPassword("");
      setVerifyPassword("");
      setPasswordsMatch(false);
      setIsLoading(false);
      setDeleteLastStep(false);
    }
    if (modalType === "RENAME-SPEAKER") {
      setNewSpeaker(modalData.speaker);
    }
    if (modalType === "TRANSCRIBE-RECORD") {
      setTranscribeRecordProgress(false);
    }
    if (modalType === "DOWNLOAD-TRANSCRIPT") {
      const localSettings = localStorage.getItem("exportSettings");
      console.log("localSettings", localSettings);
      setSettings(localSettings ? JSON.parse(localSettings) : defaultSettings);
    }
    if (modalType === "TRANSCRIBE-URL") {
      if (inputRef) inputRef.current.focus();
      setTranscribeUrlInProgress(false);
      if(modalData?.linkParam) {
        setUrl(modalData.linkParam);
        if(inputRef) inputRef.current.blur()
      }
      else if(modalData?.languageParam){
        handleLanguageChange(modalData.languageParam);
      }

    }
    if (modalType === "TRANSCRIBE-MULTIPLE-FILES") {
      setFiles(modalData?.files);
    }
    if (modalType === "MULTIPLE-UPLOAD") {
      setFiles([]);
      setValidFiles([]);
    }
    if (modalType === "EDIT-ACCOUNT") {
      inputRef.current.focus();
      setNewFileName(modalData.name);
    }
    if (modalType === "TRANSCRIBE-RECORD") {
      const class_name = document.getElementsByClassName(
        "recorditor-text-class"
      )[0];
      const text = class_name.innerText;
      const new_text = text.replace(
        /(Recorditor)/,
        '<span class="red-text">Recorditor</span>'
      );
      class_name.innerHTML = new_text;
    }
    if (modalType === "TRIAL-FILE-LIMIT") {
      setIsLoading(false);
    }
  }, [modalType]);
  const handleFolderClick = (folder) => {
    setSelectedFolder(folder); // Set the clicked folder as the selected one.
    setIsMoveButtonEnabled(true); // Enable the "Move" button.
  };
  const handleTeamClick = (team) => {
    setSelectedTeam(team); // Set the clicked folder as the selected one.
    setIsShareButtonEnabled(true); // Enable the "Move" button.
  };
  const handleModalHide = () => {
    setSelectedFolder(null); // Reset the selected folder.
    setIsMoveButtonEnabled(false); // Disable the "Move" button.
    setSelectedTeam(null); // Reset the selected folder.
    setIsShareButtonEnabled(false); // Disable the "Move" button.
    hideModal(); // Close the modal.
    setShowAdvancedOptions(false); // Close the advanced options.
    if (currentAudio) {
      currentAudio.pause();
      setCurrentAudio(null);
      setPlayingIndex(null);
    }

  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (onRename) {
        onRename(modalData.tid, modalData.uid, newFileName);
      } else if (onCreateFolder) {
        onCreateFolder(modalData.uid, newFileName);
      } else if (onCreateWorkspace) {
        onCreateWorkspace(modalData.uid, newFileName);
      } else if (onEnterpriseInvite) {
        onEnterpriseInvite(modalData.enterpriseId, mailInput);
      } else if (onInviteTeam) {
        onInviteTeam(mailInput);
      } else if (onRenameTeam) {
        onRenameTeam(newFileName);
      }
      if (onChangeUserName) {
        onChangeUserName(newFileName);
      }
      hideModal();
    }
  };
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };
  const hideCheck = (checkFlag) => {
    if (checkFlag && modalType === "LANGUAGE-MODAL") {
      hideModal();
    }
  };
  const handleServiceChange = (service) => {
    setSelectedService(service);
  };
  const [dropzoneKey, setDropzoneKey] = useState(0);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: "audio/*, video/*",
    maxFiles: modalType === "UPLOAD-FILE" ? 1 : null,
    onDrop: (acceptedFiles) => {
      setAcceptedFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    if (modalType === "UPLOAD-FILE") {
      setAcceptedFiles([]);
    }
  }, [modalType]);
  useEffect(() => {
    // Handling rejected files
    if (fileRejections.length > 0) {
      showToast(t("Only one file can be uploaded at a time"), {
        type: "error",
        position: "top-center",
      });
    }

    // Filtering for valid and invalid files
    const newValidFiles = acceptedFiles.filter(
      (file) => file.type.startsWith("audio/") || file.type.startsWith("video/")
    );

    const invalidFiles = acceptedFiles.filter(
      (file) =>
        !file.type.startsWith("audio/") && !file.type.startsWith("video/")
    );

    if (invalidFiles.length > 0) {
      showToast(t("File is not a valid audio or video file."), {
        type: "error",
        position: "top-center",
      });
    }

    // Set the valid files
    setValidFiles(newValidFiles);

    // Handle the upload logic
    if (modalType === "UPLOAD-FILE" && newValidFiles.length > 0) {
      newValidFiles.forEach((file) => {
        hideModal();
        onUploadFile(file);
      });
    } else if (modalType === "MULTIPLE-UPLOAD" && newValidFiles.length > 0) {
      onUploadMultipleFiles(newValidFiles);
    }
  }, [
    fileRejections,
    acceptedFiles,
    modalType,
    onUploadFile,
    onUploadMultipleFiles,
    hideModal,
  ]);

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
    if (e.target.value !== "other") {
      setOtherReason(""); // Clear the otherReason text if another option is selected
    }
  };
  const handleOtherReasonChange = (e) => {
    setOtherReason(e.target.value);
  };
  async function checkPassword() {
    if (password === verifyPassword) {
      // Handle successful password verification
      setPasswordsMatch(true);
      setIsLoading(true);
      try {
        await login(currentUser.email, password);
        setIsLoading(false);
        setDeleteLastStep(true);
      } catch (error) {
        hideModal();
        showToast(t("Passwords not matched. Please check passwords."), {
          type: "error",
        });
      }
    } else {
      // Handle incorrect password
    }
  }

  const [isExport, setIsExport] = useState(false);
  const [isParagraphSize, setIsParagraphSize] = useState(false);
  const handleExportDropdown = () => {
    setIsExport(!isExport);
  };
  const handleParagraphSizeDropdown = () => {
    setIsParagraphSize(!isParagraphSize);
  };
  const formats = {
    txt: "TXT",
    clipboard: "Clipboard",
    word: "Word",
    srt: "SRT",
  };

  const [editedSpeakerName, setEditedSpeakerName] = useState("");
  const handleEditClick = (speaker) => {
    setEditingSpeaker(speaker);
    setEditedSpeakerName(speaker);
  };

  const handleInputBlur = () => {
    modalData?.handleInputChangeSpeaker(editingSpeaker, editedSpeakerName)
    setEditingSpeaker(null);
  };

  const handleInputKeyPress = (e, speaker) => {
    
   
      setEditedSpeakerName(e.target.value);
  
  };
  const handleInputKeyDown = (e, speaker) => {
    if(e.key === 'Enter') {
      modalData?.handleInputChangeSpeaker(editingSpeaker, editedSpeakerName)
      setEditingSpeaker(null);
    }
  }

  // Click outside to close the dropdown
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        exportDropdown.current &&
        !exportDropdown.current.contains(event.target)
      ) {
        setIsExport(false);
      }
      if (
        paragraphSizeDropdown.current &&
        !paragraphSizeDropdown.current.contains(event.target)
      ) {
        setIsParagraphSize(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [exportDropdown, paragraphSizeDropdown]);

  useEffect(() => {
    const platform = window.navigator.platform;

    if (/Mac/i.test(platform)) {
      setOs("macOS");
    } else if (/Win/i.test(platform)) {
      setOs("Windows");
    }

    // ... you could add more conditions for other OSs if needed
  }, []); // Emp

  const handleRemoveFile = (index) => {
    const newFiles = [...files]; // Create a new copy of the files array
    newFiles.splice(index, 1);
    setFiles(newFiles);
    if (newFiles.length === 0) {
      hideModal();
    }
  };
  const handleFileChange = (e) => {
    const newFiles = [...files, ...e.target.files];
    // Filtering for valid and invalid files
    const newValidFiles = newFiles.filter(
      (file) => file.type.startsWith("audio/") || file.type.startsWith("video/")
    );

    const invalidFiles = newFiles.filter(
      (file) =>
        !file.type.startsWith("audio/") && !file.type.startsWith("video/")
    );

    if (invalidFiles.length > 0) {
      showToast(t("File is not a valid audio or video file."), {
        type: "error",
        position: "top-center",
      });
    }
    // Handle the upload logic
    if (modalType === "UPLOAD-FILE" && newValidFiles.length > 0) {
      newValidFiles.forEach((file) => {
        hideModal();
        onUploadFile(file);
      });
    } else if (modalType === "MULTIPLE-UPLOAD" && newValidFiles.length > 0) {
      onUploadMultipleFiles(newValidFiles);
    }
    setFiles(newFiles); // Assuming you have a state called 'files' to store the list of files
  };

  const googleSignIn = async () => {
    const response = await googleLogin();
    setIsLoading(false);
    setDeleteLastStep(true);
  };
  const handleFileDrop = (files) => {
    const event = {
      target: {
        files: files,
      },
    };
    handleFileChange(event);
  };

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const handleURLInput = (event) => {
    const url = event.target.value;
    setUrl(url);
    setWebURL(url);

    if (url.length > 0) {
      if (isValidHttpUrl(url)) {
        setUrlWarning("");
      } else {
        setUrlWarning(
          t("URL is not valid. Please use 'https://website.com' format.")
        );
      }
    } else {
      setUrlWarning(null);
    }
  };

  const handleSettingChange = (settingKey, newValue) => {
    const updatedSettings = { ...settings, [settingKey]: newValue };
    localStorage.setItem("exportSettings", JSON.stringify(updatedSettings));

    setExportSettings(hashedId, updatedSettings);
    setSettings(updatedSettings);
  };
  useEffect(() => {
    if(hashedId)
      getFlagVariantfunc(hashedId);
  }, [hashedId]);
  const setExportSettings = async (hashedId, exportSettings) => {
    const url =
      "https://um2vro8lrb.execute-api.eu-central-1.amazonaws.com/prod/appoptions/setexportsettings";
    console.log("exportSettings", exportSettings);
    const payload = {
      hashed_id: hashedId,
      export_settings: {
        exportFormat: exportSettings.exportFormat,
        paragraphSize: exportSettings.paragraphSize,
        includeTimestamps: exportSettings.includeTimestamps,
        mergeSegments: exportSettings.mergeSegments,
        includeSpeakerNames: exportSettings.includeSpeakerNames,
        singleParagraph: exportSettings.singleParagraph,
        mp3: exportSettings.mp3,
        transcription: true,
      },
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
      });
      console.log("payload", payload);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonResponse = await response.json();
      console.log("Success:", jsonResponse);
    } catch (error) {
      console.error("Error:", error);
    }
    console.log("exportSettings11", exportSettings);
  };

  const handleDownloadClick = async () => {
    console.log("Preparing to update settings and initiate download.");

    try {
      localStorage.setItem("exportSettings", JSON.stringify(settings));
      console.log("Settings saved to localStorage successfully.");
    } catch (storageError) {
      console.error("Error saving settings to localStorage:", storageError);
    }

    try {
      const updateResult = await setExportSettings(hashedId, settings);
      console.log(
        "Export settings updated successfully on the server.",
        updateResult
      );
    } catch (apiError) {
      console.error(
        "Failed to update export settings on the server:",
        apiError
      );
    }

    if (onDownloadTranscript) {
      onDownloadTranscript(
        exportFormat,
        paragraphSize,
        includeTimestamps,
        mergeSegments,
        includeSpeakerNames,
        singleParagraph,
        transcription,
        mp3
      );
    }
    hideModal();
  };

  console.log("settings in modal", settings);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "exportSettings") {
        // Assuming the settings are stored under the 'settings' key
        const newSettings = JSON.parse(event.newValue);
        setSettings(newSettings);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const onUpgradePlan = async () => {
    const response_data = await fetchData(
      "https://44iypa7tq6.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-Purchase-ReturnPricingPage-v2?cid=" +
        hashedId
    );
    if (response_data) {
      var redirectUrl =
        "https://1s8r2nknq4.execute-api.eu-central-1.amazonaws.com/default/Prod-Tran-Purchase-RedirectCheckoutPage-v2?cid=" +
        hashedId;

      redirectUrl = redirectUrl + "&product=Lite";
      const response = await fetchData(redirectUrl);
      const url = response["redirectUrl"];
      if (isChrome) {
        window.open(url, "_blank");
      } else {
        window.location.href = url;
      }
      setIsLoading(false);
      specialHideModal();
      subscription_checkout(
        userData,
        currentUser,
        "Lite",
        "Monthly",
        "trial_file_limit"
      );
    }
  };
  const [currentAudio, setCurrentAudio] = useState(null);
  const [playingIndex, setPlayingIndex] = useState(null);


  const handlePlayPause = (audioUrl, index) => {
    if (currentAudio && playingIndex === index) {
      currentAudio.pause();
      setCurrentAudio(null);
      setPlayingIndex(null);
    } else {
      if (currentAudio) {
        currentAudio.pause();
      }
      const newAudio = new Audio(audioUrl);
      newAudio.play();
      setCurrentAudio(newAudio);
      setPlayingIndex(index);
    }
  };
  const getFlagVariantfunc = async(hashedId)=>{
    const user = {
        user_id: hashedId,
    }
    const flagKey = 'paddle-integration';
    const variantValue = await getFlagVariant(flagKey, user);
    if (variantValue === 'control') {
        console.log('Flag is control');
      } else {
        console.log('Flag is off', variantValue);
        
      }
      setExpVariant(variantValue);
}
  const handlePlayPauseAudio = (start_time, end_time, index) => {
    if (currentAudio && playingIndex === index) {
        currentAudio.pause();
        setCurrentAudio(null);
        setPlayingIndex(null);
    } else {
        if (currentAudio) {
            currentAudio.pause();
        }
        const newAudio = new Audio(modalData?.audio); // Ensure modalData.audio contains the correct URL
        
        console.log("start_time", start_time);
        console.log(modalData?.audio)
        newAudio.currentTime = start_time / 1000;
        
        const handleTimeUpdate = () => {
            if (newAudio.currentTime >= end_time / 1000) {
                newAudio.pause();
                newAudio.removeEventListener('timeupdate', handleTimeUpdate);
                setCurrentAudio(null);
                setPlayingIndex(null);
            }
        };
        
        newAudio.addEventListener('timeupdate', handleTimeUpdate);
        
        newAudio.play();
        setCurrentAudio(newAudio);
        setPlayingIndex(index);
    }
};


  const renderModalContent = () => {
    switch (modalType) {
      case "RENAME":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={() => hideModal()}
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">{t("Rename")}</h2>
              </div>
              <div className="form-group me-3">
                <label>{t("Rename")}</label>
              </div>
              <input
                ref={inputRef}
                value={newFileName}
                type="text"
                onFocus={(e) => {
                  setTimeout(() => {
                    e.target.select();
                  }, 50); // Delay the select to ensure it occurs after the modal and focus have stabilized
                }}
                onChange={(e) => setNewFileName(e.target.value)}
                onKeyDown={handleKeyDown} // <-- add this line
                autoFocus
                className="form-control round-input"
              />
              <div className="text-center">
                <button
                  className="btn theme-btn mt-3"
                  onClick={() => {
                    if (onRename) {
                      onRename(modalData.tid, modalData.uid, newFileName);
                    }
                    if (onRenameTeam) {
                      onRenameTeam(newFileName);
                    }
                    hideModal();
                  }}
                >
                  {t("Rename")}
                </button>
              </div>
            </Modal.Body>
          </>
        );
      case "DELETE":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">{t("Delete")}</h2>
                <p class="ft-18-title mb-4">
                  {t(
                    "Are you sure delete this file? It will be deleted permanently..."
                  )}
                </p>
              </div>
              <div class="text-center">
                <button
                  className="btn theme-btn red-btn-bg mt-3"
                  onClick={() => {
                    if (onDelete) {
                      if (!modalData.tid) {
                        onDelete(modalData.uid);
                      } else {
                        onDelete(modalData.tid, modalData.uid);
                      }
                    }
                    hideModal();
                  }}
                >
                  {t("Delete")}
                </button>
              </div>
            </Modal.Body>
          </>
        );
      case "MOVE":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">
                  {t("Choose Folder")}
                </h2>
              </div>
              {folders &&
                folders.map((folder) => (
                  <div
                    className={`choose-list ${
                      selectedFolder === folder ? "active" : ""
                    }`}
                    onClick={() => handleFolderClick(folder)}
                  >
                    <span class="me-2 theme-color">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-folder2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v7a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 12.5v-9zM2.5 3a.5.5 0 0 0-.5.5V6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5zM14 7H2v5.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V7z" />
                      </svg>
                    </span>
                    {folder.Fname.S}
                  </div>
                ))}
              <div className="d-flex justify-content-center align-items-center">
                <div className="col-5 text-center">
                  <div className="row ">
                    <button
                      className="btn theme-btn mt-3"
                      disabled={!isMoveButtonEnabled}
                      onClick={() => {
                        console.log(
                          "Move button clicked. Selected Folder:",
                          selectedFolder
                        );

                        if (onMove) {
                          if (modalData.tid)
                            onMove(
                              modalData.tid,
                              modalData.uid,
                              selectedFolder?.SK.S
                            );
                          else {
                            onMove(modalData.uid, selectedFolder?.SK.S);
                          }
                        }
                        hideModal();
                      }}
                    >
                      {t("Move")}
                    </button>
                  </div>
                  <div className="row">
                    <button
                      className="btn theme-btn white-border-btn mt-3 me-2"
                      onClick={() => {
                        hideModal();
                        onCreateFolder();
                      }}
                    >
                      {t("Create New Folder")}
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        );
      case "SHARE-TEAM":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">
                  {t("Choose Workspace")}
                </h2>
              </div>
              {teams && teams.length > 0 ? (
                teams.map((team) => (
                  <div
                    className={`choose-list ${
                      selectedTeam === team ? "active" : ""
                    }`}
                    onClick={() => handleTeamClick(team)}
                  >
                    <span class="me-2 theme-color">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-people"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                      </svg>
                    </span>
                    {team.TeamName}
                  </div>
                ))
              ) : (
                <div class="text-center">
                  <p>
                    {t(
                      "There is no team yet, please create a team to colloborate with your friends and co-workers by sharing files"
                    )}
                  </p>
                </div>
              )}
              <div className="d-flex justify-content-center align-items-center">
                <div className="col-5 text-center">
                  <div className="text-center row ">
                    <button
                      className="btn theme-btn mt-3"
                      disabled={!isShareButtonEnabled}
                      onClick={() => {
                        if (onShareTeam) {
                          if (modalData.tid)
                            onShareTeam(
                              modalData.tid,
                              modalData.uid,
                              selectedTeam?.TeamId
                            );
                          else {
                            onShareTeam(modalData.uid, selectedTeam?.TeamId);
                          }
                        }
                        hideModal();
                      }}
                    >
                      {t("Move")}
                    </button>
                  </div>
                  <div className="text-center row">
                    <button
                      className="btn theme-btn white-border-btn mt-3 me-2"
                      onClick={() => {
                        onCreateWorkspace();
                      }}
                    >
                      {t("Create New Workspace")}
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        );
      case "UPLOAD-FILE":
        return (
          <>
            <Modal.Body className="p-0">
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div className="row gx-0">
                <div className="col-lg-7 order-lg-1 order-2">
                  <div className="modal-body-padding">
                    <h2 class="mini-title text-center">{t("Upload File")}</h2>
                    <div
                      className="drag-upload-div my-4"
                      onClick={() => fileInputRef.current.click()}
                      onDragOver={(e) => {
                        e.preventDefault(); // Necessary to allow drop
                        e.stopPropagation();
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // Handle the dropped file(s)
                        var files = [];
                        if (e.dataTransfer.items) {
                          for (
                            let i = 0;
                            i < e.dataTransfer.items.length;
                            i++
                          ) {
                            if (e.dataTransfer.items[i].kind === "file") {
                              const file = e.dataTransfer.items[i].getAsFile();
                              files.push(file);
                            }
                          }
                        } else {
                          for (
                            let i = 0;
                            i < e.dataTransfer.files.length;
                            i++
                          ) {
                            files.push(e.dataTransfer.files[i]);
                          }
                        }
                        handleFileDrop(files);
                      }}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept="audio/*,video/*"
                      />

                      <span class="d-inline-block theme-color mb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          class="bi bi-upload"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                        </svg>
                      </span>
                      <p class="theme-color mb-0">{t("Drop File Here")}</p>
                    </div>
                    <div class="text-center">
                      <button
                        class="btn theme-btn mt-3"
                        onClick={() => {
                          fileInputRef.current.click();
                        }}
                      >
                        <span class="btn-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-search"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                          </svg>
                        </span>
                        {t("Browse")}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 order-lg-2 order-1 light-theme-bg modal-body-padding flex-center text-center d-lg-flex d-none">
                  <h2 class="mini-title">{t("Upload Single File")}</h2>
                  <div class="img-icon-list">
                    <span>
                      <img src={audioIcon} class="img-fluid" alt="img" />
                    </span>
                    <span>
                      <img src={videoIcon} class="img-fluid" alt="img" />
                    </span>
                  </div>
                  <p>
                    {t(
                      "Transcribe your audio or video files from your computer"
                    )}
                  </p>
                </div>
              </div>
            </Modal.Body>
          </>
        );
      case "MULTIPLE-UPLOAD":
        return (
          <Modal.Body className="p-0">
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <div className="row gx-0">
              <div className="col-lg-7 order-lg-1 order-2">
                <div className="modal-body-padding">
                  <h2 class="mini-title text-center">{t("Upload Files")}</h2>
                  <div
                    className="drag-upload-div my-4"
                    onClick={() => fileInputRef.current.click()}
                    onDragOver={(e) => {
                      e.preventDefault(); // Necessary to allow drop
                      e.stopPropagation();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // Handle the dropped file(s)
                      var files = [];
                      if (e.dataTransfer.items) {
                        for (let i = 0; i < e.dataTransfer.items.length; i++) {
                          if (e.dataTransfer.items[i].kind === "file") {
                            const file = e.dataTransfer.items[i].getAsFile();
                            files.push(file);
                          }
                        }
                      } else {
                        for (let i = 0; i < e.dataTransfer.files.length; i++) {
                          files.push(e.dataTransfer.files[i]);
                        }
                      }
                      handleFileDrop(files);
                    }}
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      multiple
                      onChange={handleFileChange}
                      accept="audio/*,video/*, .mp3, .mp4"
                    />

                    <span class="d-inline-block theme-color mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        class="bi bi-upload"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                      </svg>
                    </span>
                    <p class="theme-color mb-0">{t("Drop File Here")}</p>
                  </div>
                  <div class="text-center">
                    <button
                      class="btn theme-btn mt-3"
                      onClick={() => {
                        fileInputRef.current.click();
                      }}
                    >
                      <span class="btn-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </span>
                      {t("Browse")}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 order-lg-2 order-1 light-theme-bg modal-body-padding flex-center text-center d-lg-flex d-none">
                <h2 class="mini-title">{t("Upload Files")}</h2>
                <div class="img-icon-list">
                  <span>
                    <img src={audioIcon} class="img-fluid" alt="img" />
                  </span>
                  <span>
                    <img src={videoIcon} class="img-fluid" alt="img" />
                  </span>
                </div>
                <p>
                  {t("Transcribe your audio or video files from your computer")}
                </p>
              </div>
            </div>
          </Modal.Body>
        );
      case "TRANSCRIBE-FILE":
        return (
          <>
            <Modal.Body className="p-0">
              <div className="row gx-0">
                <div class="col-lg-7 order-lg-1 order-2">
                  <div class="modal-body-padding">
                    <h2 class="mini-title text-center">{t("Upload Files")}</h2>
                    <div class="form-group input-icon-div">
                      <label>{t("Uploaded File")}</label>
                      <input
                        type="text"
                        class="form-control round-input"
                        name=""
                        value={
                          modalData?.file?.name
                            ? modalData.file.name
                            : "unknown"
                        }
                        disabled
                      />
                      <span class="input-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-file-earmark"
                          viewBox="0 0 16 16"
                        >
                          <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                        </svg>
                      </span>
                    </div>
                    <div class="form-group selectBox">
                      <label>{t("Language")}</label>
                      <LanguageDropdown
                        onLanguageChange={handleLanguageChange}
                        hideCheck={hideCheck}
                      />
                    </div>
                    <div class="form-group selectBox">
                      <label>{t("Service")}</label>
                      <ServiceDropdown onServiceChange={handleServiceChange} />
                    </div>
                   
                    <div class="text-center">
                      <button
                        class="btn theme-btn mt-3"
                        onClick={() => {
                          if (onTranscribeFile) {
                            hideModal();

                            onTranscribeFile(
                              selectedLanguage,
                              selectedService,
                              modalData?.file
                            );
                          }
                        }}
                      >
                        {t("Transcribe")}
                      </button>
                    </div>
                  </div>
                </div>
                {!showAdvancedOptions ? (
                  <div className="col-lg-5 order-lg-2 order-1 light-theme-bg modal-content-right-radius modal-body-padding flex-center text-center d-lg-flex d-none">
                    <h2 className="mini-title">{t("Upload Single File")}</h2>
                    <div className="img-icon-list">
                      <span>
                        <img
                          src={audioIcon}
                          className="img-fluid"
                          alt="audio icon"
                        />
                      </span>
                      <span>
                        <img
                          src={videoIcon}
                          className="img-fluid"
                          alt="video icon"
                        />
                      </span>
                    </div>
                    <p>
                      {t(
                        "Transcribe your audio or video files from your computer"
                      )}
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="col-lg-5 order-lg-2 order-1 light-theme-bg modal-content-right-radius modal-body-padding flex-center-2 text-center d-lg-flex d-none">
                      <AdvancedOptions
                        user={currentUser}
                        data={userData}
                        textFieldRef={textFieldRef}
                        paperStyle={{ maxWidth: "800px", p: 3 }}
                      />
                    </div>
                  </>
                )}
              </div>
            </Modal.Body>
          </>
        );
      case "TRANSCRIBE-MULTIPLE-FILES":
        return (
          <>
            <Modal.Body className="p-0">
              <div className="row gx-0">
                <div class="col-lg-7 order-lg-1 order-2">
                  {showAdvancedOptions ? (
                      <div class="modal-body-padding">
                      <AdvancedOptions
                        user={currentUser}
                        data={userData}
                        textFieldRef={textFieldRef}
                        setShowAdvancedOptions={setShowAdvancedOptions}
                        language={selectedLanguage}
                        paperStyle={{ maxWidth: "800px", p: 3 }}
                      />
                    </div>
                  ):(
                    <div class="modal-body-padding">
                    <h2 class="mini-title text-center">{t("Upload Files")}</h2>
                    <div className="d-flex justify-content-end">
                      <a
                        className="theme-color advanced-settings-button"
                        onClick={() => {
                          setShowAdvancedOptions((prev) => !prev);
                        }}
                      >
                        <span className="m-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-gear"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
                            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
                          </svg>
                        </span>
                        {t("Advanced Settings")}
                      </a>
                    </div>
                    <div class="form-group input-icon-div">
                      <label>{t("Uploaded File")}</label>
                      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                        {files.map((file, index) => (
                          <div
                            key={index}
                            className="file-input-wrapper d-flex "
                          >
                            <input
                              type="text"
                              className="form-control round-input m-2"
                              value={file.name || "unknown"}
                              disabled
                            />
                            <button
                              className=" btn delete-file-btn m-2 border-none"
                              onClick={() => handleRemoveFile(index)}
                            >
                              &times;
                            </button>
                          </div>
                        ))}
                      </div>
                      <input
                        type="file"
                        multiple // This allows selecting multiple files
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept="audio/*,video/*" // This ensures only audio or video types are accepted
                      />

                      <div
                        class="text-center"
                        style={{
                          display: userData?.SStatus === "Trial" ? "none" : "",
                        }}
                      >
                        <button
                          class="btn theme-btn mt-3"
                          onClick={() => fileInputRef.current.click()}
                        >
                          {t("Add more")}
                        </button>
                      </div>
                    </div>
                    <div class="form-group selectBox">
                      <label>{t("Language")}</label>
                      <LanguageDropdown
                        onLanguageChange={handleLanguageChange}
                        hideCheck={hideCheck}
                      />
                    </div>
                    <div class="form-group selectBox">
                      <label>{t("Service")}</label>
                      <ServiceDropdown onServiceChange={handleServiceChange} />
                    </div>
                  
                    <div class="text-center">
                      <button
                        class="btn theme-btn mt-3"
                        onClick={() => {
                          if (onTranscribeMultipleFiles) {
                            onTranscribeMultipleFiles(
                              selectedLanguage,
                              selectedService,
                              files
                            );
                          }
                          hideModal();
                        }}
                      >
                        {t("Transcribe")}
                      </button>
                    </div>
                  </div>
                  )}
                 
                </div>
                 <div class="col-lg-5 order-lg-2 order-1 light-theme-bg modal-content-right-radius modal-body-padding flex-center text-center d-lg-flex d-none">
                    <h2 class="mini-title">{t("Upload Multiple Files")}</h2>
                    <div class="img-icon-list">
                      <span>
                        <img src={audioIcon} class="img-fluid" alt="img" />
                      </span>
                      <span>
                        <img src={videoIcon} class="img-fluid" alt="img" />
                      </span>
                    </div>
                    <p>
                      {t(
                        "Transcribe your audio or video files from your computer"
                      )}
                    </p>
                  </div>
              </div>
            </Modal.Body>
          </>
        );
      case "TRANSCRIBE-URL":
        return (
          <>
            <Modal.Body className="p-0">
              <div className="row gx-0">
                <div class="col-lg-7 order-lg-1 order-2">
                  {showAdvancedOptions ? (
                    <div class="modal-body-padding">
                      <AdvancedOptions
                        user={currentUser}
                        data={userData}
                        textFieldRef={textFieldRef}
                        setShowAdvancedOptions={setShowAdvancedOptions}
                        language={selectedLanguage}
                        paperStyle={{ maxWidth: "800px", p: 3 }}
                      />
                    </div>
                  ) : (
                    <div class="modal-body-padding">
                      <h2 class="mini-title text-center">
                        {t("Transcribe your URL")}
                      </h2>
                      <p class="text-center">
                        {t(
                          "Please make sure that the url is valid, share file link from Youtube, Google Drive, Dropbox, OneDrive or raw media files..."
                        )}
                      </p>
                      <div className="d-flex justify-content-end">
                           <a
                             className="theme-color advanced-settings-button"
                             onClick={() => {
                               setShowAdvancedOptions((prev) => !prev);
                             }}
                           >
                             <span className="m-2">
                               <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="16"
                                 height="16"
                                 fill="currentColor"
                                 class="bi bi-gear"
                                 viewBox="0 0 16 16"
                               >
                                 <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
                                 <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
                               </svg>
                             </span>
                             {t("Advanced Settings")}
                           </a>
                         </div>
                      <div class="form-group">
                        <label>{t("File URL")}</label>
                        <input
                          type="text"
                          class="form-control round-input"
                          name=""
                          ref={inputRef}
                          placeholder={t("Enter media file URL here")}
                          onChange={handleURLInput}
                          value={url}
                        />
                        {urlWarning && (
                          <div
                            style={{
                              color: "red",
                              fontSize: "0.875em",
                              marginTop: "5px",
                            }}
                          >
                            {urlWarning}
                          </div>
                        )}
                      </div>
                      <div class="form-group selectBox">
                        <label>{t("Language")}</label>
                        <LanguageDropdown
                          onLanguageChange={handleLanguageChange}
                          hideCheck={hideCheck}
                        />
                      </div>
                      <div class="form-group selectBox">
                        <label>{t("Service")}</label>
                        <ServiceDropdown
                          onServiceChange={handleServiceChange}
                        />
                      </div>

                      <div class="text-center"></div>
                      <div class="text-center">
                        {transcribeUrlInProgress ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          ></div>
                        ) : (
                          <button
                            class="btn theme-btn mt-3"
                            onClick={() => {
                              if (onTranscribeURL) {
                                onTranscribeURL(
                                  selectedLanguage,
                                  selectedService,
                                  url
                                );
                              }
                            }}
                          >
                            {t("Transcribe")}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div class="col-lg-5 order-lg-2 order-1 light-theme-bg modal-content-right-radius modal-body-padding flex-center text-center d-lg-flex d-none">
                  <h2 class="mini-title">
                    {t("Convert Audio from Youtube or Cloud")}
                  </h2>
                  <div class="img-icon-list">
                    <span
                      class="pointer"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Dropbox"
                    >
                      <img src={dropboxSvg} class="img-fluid" alt="img" />
                    </span>
                    <span
                      class="pointer"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Google Drive"
                    >
                      <img src={googleDriveSvg} class="img-fluid" alt="img" />
                    </span>
                    <span
                      class="pointer"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="OneDrive"
                    >
                      <img src={oneDriveSvg} class="img-fluid" alt="img" />
                    </span>
                    <span
                      class="pointer"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Youtube"
                    >
                      <img src={youtubeSvg} class="img-fluid" alt="img" />
                    </span>
                  </div>
                  <p>
                    {t(
                      "Transkriptor will transcribe the audio or video from youtube link or cloud storage (Google Drive, One Drive, Dropbox)."
                    )}
                  </p>
                </div>
              </div>
            </Modal.Body>
          </>
        );
      case "TRANSCRIBE-RECORD":
        return (
          <>
            <Modal.Body className="p-0">
              {transcribeRecordProgress && (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
      
              {showAdvancedOptions ? (
                 <div class="modal-body-padding">
                 <AdvancedOptions
                   user={currentUser}
                   data={userData}
                   textFieldRef={textFieldRef}
                   setShowAdvancedOptions={setShowAdvancedOptions}
                   language={selectedLanguage}
                   paperStyle={{ maxWidth: "800px", p: 3 }}
                 />
               </div>
              ):(
                <>
                                  <div className="d-flex justify-content-end m-2" style={{marginRight: "20px !important"}}>
                          <a
                            className="theme-color advanced-settings-button"
                            onClick={() => {
                              setShowAdvancedOptions((prev) => !prev);
                            }}
                          >
                            <span className="m-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-gear"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
                                <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
                              </svg>
                            </span>
                            {t("Advanced Settings")}
                          </a>
                        </div>
                <div className="d-flex justify-content-center row mx-auto m-2 mt-4">
                <div className="col-5">
                  <div class="form-group selectBox">
                    <LanguageDropdown
                      onLanguageChange={handleLanguageChange}
                      hideCheck={hideCheck}
                    />
                  </div>
                </div>
                <div className="col-3 ">
                  <div class="form-group selectBox">
                    <ServiceDropdown onServiceChange={handleServiceChange} />
                  </div>
                </div>

                <div className="col-3 d-flex justify-content-center">
                  <div class="text-center">
                    <button
                      class="btn theme-btn "
                      style={{ marginRight: "5px" }}
                      onClick={() => {
                        if (onTranscribeRecord) {
                          hideModal();
                          onTranscribeRecord(
                            selectedService,
                            selectedLanguage,
                            modalData?.videoData
                          );
                          setTranscribeRecordProgress(true);
                        }
                      }}
                    >
                      {t("Transcribe")}
                    </button>
                  </div>
                  <div class="text-center">
                    <button
                      class="btn danger-btn"
                      onClick={() => {
                        specialHideModal();
                      }}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="container">
                <VideoPlayer video={modalData?.videoData} />
              </div>
              <div className="container m-4">
                <p className="text-center">
                  <Link
                    class="recorditor-text-class"
                    to={modalData?.videoLink}
                    rel="nofollow"
                    target="_blank"
                  >
                    {t(
                      "Click here and go to Recorditor to watch or share your recording."
                    )}
                  </Link>
                </p>
              </div>
                </>
              )}
            </Modal.Body>
          </>
        );
      case "LOGOUT":
        return (
          <>
            <Modal.Body className="text-center">
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>

              <h2 class="mini-title mb-4">Transkriptor</h2>
              <p>
                {t(
                  "Are you sure you want to sign out from your account? You can sign in anytime..."
                )}
              </p>
              <div class="text-center">
                <button
                  class="btn theme-btn red-bg mt-3"
                  onClick={() => {
                    if (onLogout) {
                      onLogout();
                    }
                    hideModal();
                  }}
                >
                  {t("Sign Out")}
                </button>
              </div>
            </Modal.Body>
          </>
        );
      case "RESET-PASSWORD":
        return (
          <>
            <Modal.Body className="text-center">
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>

              <h2 class="mini-title mb-2">{t("Reset Password")}</h2>
              <p class="mb-4">
                {t("Please type your mail address to reset your password")}
              </p>
              <div class="form-group">
                <label>{t("Email Address")}</label>
                <input
                  type="text"
                  class="form-control"
                  name=""
                  ref={inputRef}
                  value={mailInput}
                  onChange={(e) => {
                    setMailInput(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (onResetPassword) {
                        onResetPassword(mailInput);
                      }
                      hideModal();
                    }
                  }}
                />
              </div>
              <div class="text-center">
                <button
                  class="btn theme-btn mt-3"
                  onClick={() => {
                    if (onResetPassword) {
                      onResetPassword(mailInput);
                    }
                    hideModal();
                  }}
                >
                  {t("Reset Password")}
                </button>
              </div>
            </Modal.Body>
          </>
        );
      case "DELETE-ACCOUNT":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="ft-18-title medium mb-2">
                  {t("We're sorry to see you go")}
                </h2>
                <p class="mb-4">
                  {t(
                    "We'd really appreciate it if you told us why you want to delete your account. Your insights can help us improve Transkriptor."
                  )}
                </p>
              </div>
              <div class="radio custom mb-2">
                <label>
                  <input
                    type="radio"
                    name="r1"
                    value="missing-features"
                    onChange={handleReasonChange}
                  />
                  {t("Missing features")}
                </label>
              </div>
              <div class="radio custom mb-2">
                <label>
                  <input
                    type="radio"
                    name="r1"
                    value="poor-quality"
                    onChange={handleReasonChange}
                  />
                  {t("Poor quality")}
                </label>
              </div>
              <div class="radio custom mb-2">
                <label>
                  <input
                    type="radio"
                    name="r1"
                    value="too-expensive"
                    onChange={handleReasonChange}
                  />
                  {t("Too expensive")}
                </label>
              </div>
              <div class="radio custom mb-2">
                <label>
                  <input
                    type="radio"
                    name="r1"
                    value="no-longer-needed"
                    onChange={handleReasonChange}
                  />
                  {t("I no longer need Transkriptor")}
                </label>
              </div>
              <div class="radio custom mb-2">
                <label>
                  <input
                    type="radio"
                    value="other"
                    name="r1"
                    onChange={handleReasonChange}
                    checked={selectedReason === "other"}
                  />
                  {t("Other")}
                </label>
              </div>
              {selectedReason === "other" && (
                <div class="form-group mt-3">
                  <label>{t("Please specify")}</label>
                  <textarea
                    rows="4"
                    class="form-control round-input"
                    value={otherReason}
                    onChange={handleOtherReasonChange}
                    placeholder=""
                  ></textarea>
                </div>
              )}
              <div class="text-center">
                <button
                  class="btn theme-btn mt-3"
                  disabled={
                    selectedReason === "other"
                      ? otherReason.trim() === ""
                      : selectedReason === ""
                  }
                  onClick={() => {
                    hideModal();
                    if (onDeleteAccount) {
                      onDeleteAccount(selectedReason, otherReason);
                    }
                  }}
                >
                  {t("Continue")}
                </button>
              </div>
            </Modal.Body>
          </>
        );
      case "DELETE-ACCOUNT-COMMENT":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              {selectedReason === "missing-features" ? (
                <>
                  <div class="form-group">
                    <label>
                      {t("Which features would you like to see added?")}*
                    </label>
                    <textarea
                      rows="4"
                      class="form-control"
                      name=""
                      value={commentsValue}
                      onChange={(e) => setCommentsValue(e.target.value)}
                    ></textarea>
                  </div>
                </>
              ) : (
                <>
                  <div class="form-group">
                    <label>{t("What was the problem you encountered?")}*</label>
                    <textarea
                      rows="4"
                      class="form-control"
                      name=""
                      value={commentsValue}
                      onChange={(e) => setCommentsValue(e.target.value)}
                    ></textarea>
                  </div>
                </>
              )}
              <div class="form-group">
                <label>
                  {t(
                    "Would you like to share any additional thoughts with us? (optional)"
                  )}
                </label>
                <textarea
                  rows="4"
                  class="form-control"
                  name=""
                  value={additionalValue}
                  onChange={(e) => setAdditionalValue(e.target.value)}
                ></textarea>
              </div>
              <div class="text-center">
                <button
                  class="btn theme-btn mt-3"
                  disabled={!commentsValue} // disabled if both are empty
                  onClick={() => {
                    hideModal();
                    if (onDeleteAccountComment) {
                      onDeleteAccountComment(
                        modalData.reason,
                        commentsValue,
                        additionalValue
                      );
                    }
                  }}
                >
                  {t("Continue")}
                </button>
              </div>
            </Modal.Body>
          </>
        );
      case "DELETE-ACCOUNT-VERIFY":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              {!deleteLastStep ? (
                <>
                  <div class="text-center">
                    <h2 class="mini-title mb-2">{t("Verification")}</h2>
                    <p class="mb-4">
                      {t(
                        "To continue delete your account, verify you are the owner of this account by your password or logging into your associated Google account."
                      )}
                    </p>
                    <button
                      onClick={googleSignIn}
                      class="btn theme-btn w-100 white-border-btn mb-3"
                    >
                      <span class="btn-icon">
                        <img src={googleIcon} class="img-fluid" alt="img" />
                      </span>
                      {t("Sign In with Google")}
                    </button>
                    <h2 class="ft-18-title medium my-3">{t("OR")}</h2>
                  </div>
                  <div class="form-group">
                    <label>{t("Enter your password")}</label>
                    <div
                      className="form-group"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        placeholder={t("Enter your password")}
                      />
                      {passwordsMatch && verifyPassword.length > 0 ? (
                        <span style={{ color: "green", marginLeft: "10px" }}>
                          ✓
                        </span>
                      ) : verifyPassword.length > 0 ? (
                        <span style={{ color: "red", marginLeft: "10px" }}>
                          ✗
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{t("Verify your password")}</label>
                    <div
                      className="form-group"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <input
                        type="password"
                        className="form-control"
                        style={{ flexGrow: 1 }} // ensures the input takes up most of the space
                        value={verifyPassword}
                        onChange={(e) => {
                          setVerifyPassword(e.target.value);
                          setPasswordsMatch(e.target.value === password);
                        }}
                        placeholder={t("Verify your password")}
                      />
                      {passwordsMatch && verifyPassword.length > 0 ? (
                        <span style={{ color: "green", marginLeft: "10px" }}>
                          ✓
                        </span>
                      ) : verifyPassword.length > 0 ? (
                        <span style={{ color: "red", marginLeft: "10px" }}>
                          ✗
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ display: isLoading ? "block" : "none" }}
                  >
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button
                      class="btn theme-btn mt-3"
                      disabled={!passwordsMatch}
                      onClick={checkPassword}
                    >
                      {t("Continue")}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div class="text-center">
                    <h2 class="mini-title mb-2">{t("Delete Account")}</h2>
                    <h5 class="mb-2">{t("Delete account forever?")}</h5>
                    <p class="mb-4">
                      {t(
                        "That includes your profile, all conversations, imported recordings, and everything you've done since joining."
                      )}
                    </p>
                  </div>
                  <div class="text-center">
                    <button
                      class="btn theme-btn red-btn-bg mt-3"
                      onClick={() => {
                        hideModal();
                        if (onDeleteCompletely) {
                          onDeleteCompletely(
                            modalData?.reason,
                            modalData?.comment,
                            modalData?.additional
                          );
                        }
                      }}
                    >
                      {t("Delete")}
                    </button>
                  </div>
                </>
              )}
            </Modal.Body>
          </>
        );
      // Add more modal types as needed...
      case "CANCEL_SUBSCRIPTION_WEB":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="ft-18-title medium mb-2">
                  {t("We're sorry to see you go")}
                </h2>
                <p class="mb-4">
                  {t(
                    "We'd really appreciate if you told us why you want to cancel your subscription. Your insights can help us improve Transkriptor."
                  )}
                </p>
              </div>
              <div class="radio custom mb-2">
                <label>
                  <input
                    type="radio"
                    name="r1"
                    value="missing-features"
                    onChange={handleReasonChange}
                  />
                  {t("Missing features")}
                </label>
              </div>
              <div class="radio custom mb-2">
                <label>
                  <input
                    type="radio"
                    name="r1"
                    value="poor-quality"
                    onChange={handleReasonChange}
                  />
                  {t("Poor quality")}
                </label>
              </div>
              <div class="radio custom mb-2">
                <label>
                  <input
                    type="radio"
                    name="r1"
                    value="too-expensive"
                    onChange={handleReasonChange}
                  />
                  {t("Too expensive")}
                </label>
              </div>
              <div class="radio custom mb-2">
                <label>
                  <input
                    type="radio"
                    name="r1"
                    value="no-longer-needed"
                    onChange={handleReasonChange}
                  />
                  {t("I no longer need Transkriptor")}
                </label>
              </div>
              <div class="radio custom mb-2">
                <label>
                  <input
                    type="radio"
                    value="other"
                    name="r1"
                    onChange={handleReasonChange}
                    checked={selectedReason === "other"}
                  />
                  {t("Other")}
                </label>
              </div>
              {selectedReason === "other" && (
                <div class="form-group mt-3">
                  <label>{t("Please specify")}</label>
                  <textarea
                    rows="4"
                    class="form-control round-input"
                    value={otherReason}
                    onChange={handleOtherReasonChange}
                    placeholder=""
                  ></textarea>
                </div>
              )}
              <div class="text-center">
                <button
                  class="btn theme-btn mt-3"
                  disabled={
                    selectedReason === "other"
                      ? otherReason.trim() === ""
                      : selectedReason === ""
                  }
                  onClick={() => {
                    hideModal();
                    if (onCancelSubscription) {
                      onCancelSubscription(selectedReason, otherReason);
                    }
                  }}
                >
                  {t("Continue")}
                </button>
              </div>
            </Modal.Body>
          </>
        );
      case "CANCEL-SUBSCRIPTION-COMMENT":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              {selectedReason === "missing-features" ? (
                <>
                  <div class="form-group">
                    <label>
                      {t("Which features would you like to see added?")}*
                    </label>
                    <textarea
                      rows="4"
                      class="form-control"
                      name=""
                      value={commentsValue}
                      onChange={(e) => setCommentsValue(e.target.value)}
                    ></textarea>
                  </div>
                </>
              ) : (
                <>
                  <div class="form-group">
                    <label>{t("What was the problem you encountered?")}*</label>
                    <textarea
                      rows="4"
                      class="form-control"
                      name=""
                      value={commentsValue}
                      onChange={(e) => setCommentsValue(e.target.value)}
                    ></textarea>
                  </div>
                </>
              )}
              <div class="form-group">
                <label>
                  {t(
                    "Would you like to share any additional thoughts with us? (optional)"
                  )}
                </label>
                <textarea
                  rows="4"
                  class="form-control"
                  name=""
                  value={additionalValue}
                  onChange={(e) => setAdditionalValue(e.target.value)}
                ></textarea>
              </div>
              <div class="text-center">
                <button
                  class="btn theme-btn mt-3"
                  disabled={!commentsValue} // disabled if both are empty
                  onClick={() => {
                    hideModal();
                    if (onCancelSubscriptionComment) {
                      onCancelSubscriptionComment(
                        modalData.reason,
                        commentsValue,
                        additionalValue
                      );
                    }
                  }}
                >
                  {t("Continue")}
                </button>
              </div>
            </Modal.Body>
          </>
        );
      case "CANCEL-SUBSCRIPTION-COMPLETE":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              {modalData?.isRewarded ? (
                <>
                  <div className="text-center">
                    <p class="small-title">
                      {t("Are you sure you want to cancel your subscription?")}
                    </p>
                  </div>
                  <div class="text-center">
                    <button
                      class="btn theme-btn red-btn-bg m-3"
                      onClick={() => {
                        hideModal();
                        if (onCancelSubscriptionComplete) {
                          onCancelSubscriptionComplete(
                            modalData?.reason,
                            modalData?.comment,
                            modalData?.additional,
                            modalData?.isRewarded
                          );
                        }
                      }}
                    >
                      {t("Cancel Subscription")}
                    </button>
                    <button
                      class="btn theme-btn m-3"
                      onClick={() => {
                        hideModal();
                      }}
                    >
                      {t("Keep Subscription")}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div class="row align-items-center">
                    <div class="col-lg-5 text-center">
                      <img src={billingGift} class="img-fluid" alt="img" />
                    </div>
                    <div class="col-lg-7">
                      <div class="text-center py-lg-4 py-2">
                        <p class="ft-18-title mb-4">
                          {t(
                            "It is sad to see you go. Thankyou for your feedback, Here is free gift:"
                          )}
                        </p>
                        <p>
                          {t(
                            "Click the Get 1 Month Free button to continue your subscription for 1 month free"
                          )}
                        </p>
                        <div class="mt-lg-5 mt-4">
                          <button
                            class="btn theme-btn transparent-white-btn mt-1 me-1"
                            onClick={() => {
                              hideModal();
                              if (onCancelSubscriptionComplete) {
                                onCancelSubscriptionComplete(
                                  modalData?.reason,
                                  modalData?.comment,
                                  modalData?.additional,
                                  true
                                );
                              }
                            }}
                          >
                            {t("Continue to Cancel")}
                          </button>
                          <button
                            class="btn theme-btn white-border-btn theme-color mt-1"
                            onClick={() => {
                              hideModal();
                              if (onCancelSubscriptionComplete) {
                                onCancelSubscriptionComplete(
                                  modalData?.reason,
                                  modalData?.comment,
                                  modalData?.additional,
                                  modalData?.isRewarded
                                );
                              }
                            }}
                          >
                            {t("Get one month free")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </>
        );
      case "CANCEL-SUBSCRIPTION-MINUTES":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="row align-items-center">
                <div class="col-lg-12 text-center">
                  <img src={subscriptionBilling} class="img-fluid" alt="img" />
                </div>
                <div class="col-lg-12">
                  <div class="text-center pt-3">
                    <p class="small-title">
                      {t(
                        "We have gifted you 120 minutes that you can use anytime in the future!"
                      )}
                    </p>
                    <p>
                      {t(
                        "When you need to convert your next conversation to text. Remember that Transkriptor is here for you and it will be\u2026 Always ;)"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        );
      case "CANCEL-SUBSCRIPTION-GIFT":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="row align-items-center">
                <div class="col-lg-12 text-center">
                  <img src={billingGift} class="img-fluid" alt="img" />
                </div>
                <div class="col-lg-12">
                  <div class="text-center py-3">
                    <p class="small-title">
                      {t(
                        "We have gifted you 1 Month Free Subscription so you can continue transcribing audio to text!"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        );
      case "DELETE-FOLDER":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">{t("Delete")}</h2>
                <p class="ft-18-title mb-4">
                  {t(
                    "Are you sure to delete this folder? All transcriptions inside the folder will be deleted?"
                  )}
                </p>
              </div>
              <div class="text-center">
                <button
                  className="btn theme-btn red-btn-bg mt-3"
                  onClick={() => {
                    if (onDeleteFolder) {
                      onDeleteFolder(modalData.fid, modalData.uid);
                    }
                    hideModal();
                  }}
                >
                  {t("Delete")}
                </button>
              </div>
            </Modal.Body>
          </>
        );
      case "CREATE-FOLDER":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">
                  {" "}
                  {t("Create Folder")}
                </h2>
              </div>
              <div class="form-group me-3">
                <label>{t("Create Folder")}</label>
                <input
                  ref={inputRef}
                  type="text"
                  onChange={(e) => setNewFileName(e.target.value)}
                  onKeyDown={handleKeyDown} // <-- add this line
                  className="form-control round-input"
                  placeholder={t("Enter folder name")}
                />
              </div>
              <div class="text-center">
                <button
                  class="btn theme-btn mt-3"
                  onClick={() => {
                    console.log(
                      "Create Folder button clicked. New Folder Name:",
                      newFileName
                    );

                    if (onCreateFolder) {
                      onCreateFolder(modalData.uid, newFileName);
                    }
                    hideModal();
                  }}
                >
                  {t("Create")}
                </button>
              </div>
            </Modal.Body>
          </>
        );
      case "DELETE-WORKSPACE":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">{t("Delete")}</h2>
                <p class="ft-18-title mb-4">
                  {t(
                    "Are you sure delete this workspace? It will be deleted permanently with all the files and folders inside..."
                  )}
                </p>
              </div>
              <div class="text-center">
                <button
                  className="btn theme-btn red-btn-bg mt-3"
                  onClick={() => {
                    if (onDeleteWorkspace) {
                      onDeleteWorkspace(modalData.tid, modalData.uid);
                    }
                    hideModal();
                  }}
                >
                  {t("Delete")}
                </button>
              </div>
            </Modal.Body>
          </>
        );
      case "CREATE-WORKSPACE":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">
                  {" "}
                  {t("Create Workspace")}
                </h2>
              </div>
              <div class="form-group me-3">
                <label>{t("Create Workspace")}</label>
                <input
                  ref={inputRef}
                  type="text"
                  onChange={(e) => setNewFileName(e.target.value)}
                  onKeyDown={handleKeyDown} // <-- add this line
                  className="form-control round-input"
                  placeholder={t("Enter workspace name")}
                />
              </div>
              <div class="text-center">
                <button
                  class="btn theme-btn mt-3"
                  onClick={() => {
                    console.log(
                      "Create Workspace button clicked. New Workspace Name:",
                      newFileName
                    );

                    if (onCreateWorkspace) {
                      onCreateWorkspace(modalData.uid, newFileName);
                    }
                    hideModal();
                  }}
                >
                  {t("Create")}
                </button>
              </div>
            </Modal.Body>
          </>
        );
      case "SHORTCODES":
        return (
          <Modal.Body>
            <div className="notranslate">
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center dark-color">
                <h2 class="mini-title text-center mb-4">{t("Shortcodes")}</h2>
                {os === "Windows" ? (
                  <div class="row text-start shortcodes-p">
                    <div class="col-5">
                      <p class="nowrap">Ctrl + S</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Save")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">Tab</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Next Paragraph")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">Alt + Up</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Previous Paragraph")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">{t("Delete")}</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Merge with next")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">{t("Backspace")}</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Merge with previous")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">Enter</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Split paragraph")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">Ctrl + Space</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Play/Pause Audio")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">Alt + Left</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Rewind 5 seconds")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">Alt + Right</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Skip 5 seconds")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">CTRL + 9</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Speed up audio")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">CTRL + 0</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Slow down audio")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">CTRL + 1, 2, 3, 4</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Change Person")}</p>
                    </div>

                    <div class="col-5">
                      <p class="nowrap">CTRL + 5</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Select Person")}</p>
                    </div>

                    <div class="col-5">
                      <p class="nowrap">CTRL + M</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Merge Same Speakers")}</p>
                    </div>
                  </div>
                ) : (
                  <div class="row text-start shortcodes-p">
                    <div class="col-5">
                      <p class="nowrap">control + S</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Save")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">Tab</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Next Paragraph")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">option + Up</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Previous Paragraph")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">Delete</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Merge with next")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">Backspace</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Merge with previous")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">Enter</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Split paragraph")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">option + Space</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Play/Pause Audio")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">option + Left</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Rewind 5 seconds")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">option + Right</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Skip 5 seconds")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">control + 9</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Speed up audio")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">control + 0</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Slow down audio")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">control + 1, 2, 3, 4</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Change Person")}</p>
                    </div>
                    <div class="col-5">
                      <p class="nowrap">control + 5</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Select Person")}</p>
                    </div>

                    <div class="col-5">
                      <p class="nowrap">control + M</p>
                    </div>
                    <div class="col-7">
                      <p>{t("Merge Same Speakers")}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        );
      case "ADD-SPEAKER":
        return (
          <Modal.Body>
            <div className="notranslate">
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">{t("Add Speaker")}</h2>
              </div>
              <div class="form-group me-3">
                <label>{t("New Speaker")}</label>
                <input
                  type="text"
                  class="form-control round-input"
                  ref={inputRef}
                  name=""
                  placeholder={t("New Speaker")}
                  value={newSpeaker}
                  onChange={(e) => setNewSpeaker(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (onAddSpeaker) {
                        hideModal();
                        onAddSpeaker(e.target.value);
                      }
                    }
                  }}
                />
              </div>
              <div class="text-center">
                <button
                  class="btn theme-btn mt-3"
                  onClick={(e) => {
                    if (onAddSpeaker) {
                      hideModal();
                      onAddSpeaker(newSpeaker);
                    }
                  }}
                >
                  {t("Add")}
                </button>
              </div>
            </div>
          </Modal.Body>
        );
      case "RENAME-SPEAKER":
        return (
          <Modal.Body>
            <div className="notranslate">
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">
                  {t("Rename Speaker")}
                </h2>
              </div>
              <div class="form-group me-3">
                <label>{t("Rename Speaker")}</label>
                <input
                  type="text"
                  class="form-control round-input"
                  ref={inputRef}
                  name=""
                  placeholder={t("Rename Speaker")}
                  value={t(newSpeaker)}
                  onChange={(e) => setNewSpeaker(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (onRenameSpeaker) {
                        hideModal();
                        if(e.target.value.trim() === ""){
                          showToast("Name cannot be empty", "error");
                          return;
                        }
                        onRenameSpeaker(modalData.speaker, e.target.value);
                      }
                    }
                  }}
                />
              </div>
              <div class="text-center">
                <button
                  class="btn theme-btn mt-3"
                  onClick={(e) => {
                    if (onRenameSpeaker) {
                      hideModal();
                      onRenameSpeaker(modalData.speaker, newSpeaker);
                    }
                  }}
                >
                  {t("Add")}
                </button>
              </div>
            </div>
          </Modal.Body>
        );
      case "DOWNLOAD-TRANSCRIPT":
        return (
          <Modal.Body>
            <div className="notranslate">
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div className="row">
                <div
                  className="col"
                  style={{ backgroundColor: "rgb(246,248,253)" }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      marginTop: "8px",
                    }}
                  >
                    <h2 className="mini-title mb-4">
                      {t("Transcription Preview")}
                    </h2>
                  </div>
                  <div className="content-section">
                    {settings.mergeSegments && !settings.singleParagraph ? (
                      <>
                        <div style={{ paddingTop: "16px" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ marginRight: "10px" }}>
                              {times[0]}
                            </div>
                            <span>{t("Speaker 1")}</span>
                          </div>
                          <Skeleton count={4} enableAnimation={false} />
                        </div>
                        <div style={{ paddingTop: "16px" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ marginRight: "10px" }}>
                              {times[3]}
                            </div>
                            <span>{t("Speaker 2")}</span>
                          </div>
                          <Skeleton count={2} enableAnimation={false} />
                        </div>

                        <div style={{ paddingTop: "16px" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ marginRight: "10px" }}>
                              {times[5]}
                            </div>
                            <span>{t("Speaker 1")}</span>
                          </div>
                          <Skeleton count={3} enableAnimation={false} />
                        </div>
                      </>
                    ) : (
                      (() => {
                        // Calculate the total number of items to render based on paragraphSize
                        const size = parseInt(paragraphSize, 10);
                        let totalItems = 6; // Default total items
                        if (size === 4 || size === 8) {
                          totalItems = 8; // Add two extra items for the last group
                        }
                        // Function to determine the speaker based on index
                        const getSpeakerName = (index) => {
                          return `Speaker ${
                            (Math.floor(index / size) % 2) +
                            ((index % size) % 2) +
                            1
                          }`;
                        };
                        return Array.from({ length: totalItems }).map(
                          (_, index) => {
                            const groupIndex = Math.floor(index / size);
                            const showNameAndTime =
                              index % size === 0 || index === 0;
                            const timestampToShow =
                              times[Math.min(groupIndex, times.length - 1)]; // Safeguard for times array
                            const speakerName = getSpeakerName(index);

                            return (
                              <div
                                key={index}
                                style={{
                                  paddingTop: showNameAndTime ? "16px" : "4px",
                                }}
                              >
                                {showNameAndTime && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {settings.includeTimestamps && (
                                      <div style={{ marginRight: "10px" }}>
                                        {timestampToShow}
                                      </div>
                                    )}
                                    {settings.includeSpeakerNames && (
                                      <span>{t(speakerName)}</span>
                                    )}
                                  </div>
                                )}
                                <Skeleton count={1} enableAnimation={false} />
                              </div>
                            );
                          }
                        );
                      })()
                    )}
                  </div>
                </div>
                <div className="col">
                  <div class="text-center">
                    <h2 class="mini-title text-center mb-4">
                      {t("Download Text")}
                    </h2>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group selectBox" ref={exportDropdown}>
                        <label>{t("Export format")}</label>
                        <select
                          className="form-control round-input"
                          value={settings.exportFormat}
                          onChange={(e) =>
                            handleSettingChange("exportFormat", e.target.value)
                          }
                        >
                          <option value="txt">TXT</option>
                          <option value="clipboard">{t("Clipboard")}</option>
                          <option value="word">Word</option>
                          <option value="srt">SRT</option>
                          <option value="pdf">PDF</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12">
                      <div
                        class="form-group selectBox"
                        ref={paragraphSizeDropdown}
                      >
                        <label>{t("Paragraph Size")}</label>
                        <select
                          className="form-control round-input"
                          value={settings.paragraphSize}
                          onChange={(e) =>
                            handleSettingChange("paragraphSize", e.target.value)
                          }
                        >
                          <option value="1">1 {t("line")}</option>
                          <option value="2">2 {t("lines")}</option>
                          <option value="4">4 {t("lines")}</option>
                          <option value="8">8 {t("lines")}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="checkbox custom mb-2">
                        <label>
                          {" "}
                          <input
                            type="checkbox"
                            checked={settings.includeTimestamps}
                            onChange={() =>
                              handleSettingChange(
                                "includeTimestamps",
                                !settings.includeTimestamps
                              )
                            }
                          />
                          {t("Include timestamps")}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="checkbox custom mb-2">
                        <label>
                          <input
                            type="checkbox"
                            checked={settings.mergeSegments}
                            onChange={() =>
                              handleSettingChange(
                                "mergeSegments",
                                !settings.mergeSegments
                              )
                            }
                          />
                          {t("Merge same-speaker segments")}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="checkbox custom mb-2">
                        <label>
                          <input
                            type="checkbox"
                            checked={settings.includeSpeakerNames}
                            onChange={() =>
                              handleSettingChange(
                                "includeSpeakerNames",
                                !settings.includeSpeakerNames
                              )
                            }
                          />
                          {t("Include speaker names")}
                        </label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="checkbox custom mb-2">
                        <label>
                          <input
                            type="checkbox"
                            checked={settings.singleParagraph}
                            onChange={() =>
                              handleSettingChange(
                                "singleParagraph",
                                !settings.singleParagraph
                              )
                            }
                          />
                          {t("Single paragraph")}
                        </label>
                      </div>
                    </div>
                    <div class="col-12 mb-4"></div>

                    <div class="col-md-6">
                      <div class="checkbox custom mb-2">
                        <label>
                          <input
                            type="checkbox"
                            checked={settings.mp3}
                            onChange={() =>
                              handleSettingChange("mp3", !settings.mp3)
                            }
                          />
                          {t("Include MP3 File")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button
                      class="btn theme-btn mt-3 download-transcript-tag"
                      onClick={() => {
                        if (onDownloadTranscript) {
                          hideModal();
                          onDownloadTranscript(
                            settings.exportFormat,
                            settings.paragraphSize,
                            settings.includeTimestamps,
                            settings.mergeSegments,
                            settings.includeSpeakerNames,
                            settings.singleParagraph,
                            settings.transcription,
                            settings.mp3
                          );
                        }
                      }}
                    >
                      {t("Download")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        );
      case "UPGRADE-DOWNLOAD":
        return (
          <Modal.Body>
            <div className="notranslate">
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">Transkriptor</h2>
                <p>
                  {t(
                    "Full transcript download is available only in paid accounts"
                  )}
                </p>
              </div>
              <div class="text-center">
                <button
                  onClick={() => {
                    subscription_aware(
                      userData,
                      currentUser,
                      "premium_feature"
                    );
                    navigate(`/payment?type=premium_feature`);

                    hideModal();
                  }}
                  class="btn theme-btn mt-3"
                >
                  {t("Get started")}
                </button>
              </div>
            </div>
          </Modal.Body>
        );
      case "UPGRADE-COPY":
        return (
          <Modal.Body>
            <div className="notranslate">
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">Transkriptor</h2>
                <p>
                  {t("Transcription copy is available only in paid accounts")}
                </p>
              </div>
              <div class="text-center">
                <button
                  onClick={() => {
                    subscription_aware(
                      userData,
                      currentUser,
                      "premium_feature"
                    );
                    navigate(`/payment?type=premium_feature`);
                    hideModal();
                  }}
                  class="btn theme-btn mt-3"
                >
                  {t("Get started")}
                </button>
              </div>
            </div>
          </Modal.Body>
        );
      case "UPGRADE-SAVE":
        return (
          <Modal.Body>
            <div className="notranslate">
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <h2 class="mini-title text-center mb-4">Transkriptor</h2>
                <p>
                  {t(
                    "Full transcript saving is available only in paid accounts"
                  )}
                </p>
              </div>
              <div class="text-center">
                <Link to="/payment" target="_blank" class="btn theme-btn mt-3">
                  {t("Get started")}
                </Link>
              </div>
            </div>
          </Modal.Body>
        );
      case "UPGRADE-MINUTES":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <div class="text-center">
              <h2 class="mini-title text-center mb-4">Transkriptor</h2>
              <p>
                {t("Upgrade to get more minutes and transcribe more files!")}
              </p>
            </div>
            <div class="text-center">
              <button
                onClick={() => {
                  subscription_aware(userData, currentUser, modalData?.type);
                  navigate(`/payment?type=${modalData?.type}`);
                  hideModal();
                }}
                target="_blank"
                class="btn theme-btn mt-3"
              >
                {t("Get started")}
              </button>
            </div>
          </Modal.Body>
        );
      case "ADD-ENTERPRISE-MEMBER":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <div class="text-center">
              <h2 class="mini-title text-center mb-2">
                {t("Invite a friend")}
              </h2>
              <p class="mb-4">
                {t(
                  "Invite team member or friend to collaborate with same files and folders, and share files with them."
                )}
              </p>
            </div>
            <div class="form-group me-3">
              <label>{t("Email address")}</label>
              <input
                type="text"
                class="form-control round-input"
                ref={inputRef}
                value={mailInput}
                onChange={(e) => {
                  setMailInput(e.target.value);
                }}
                onKeyDown={handleKeyDown}
                name=""
                placeholder="Email address"
              />
            </div>
            <div class="text-center">
              <button
                class="btn theme-btn mt-3"
                onClick={() => {
                  if (onEnterpriseInvite) {
                    onEnterpriseInvite(modalData.enterpriseId, mailInput);
                  } else if (onInviteTeam) {
                    onInviteTeam(mailInput);
                  }
                }}
              >
                {t("Send invitation email")}
              </button>
            </div>
          </Modal.Body>
        );
      case "REMOVE-ENTERPRISE-MEMBER":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <div class="text-center">
              <h2 class="mini-title text-center mb-4">{t("Remove")}</h2>
              <p class="ft-18-title mb-4">
                {t("Are you sure to remove this team member or friend?")}
              </p>
            </div>
            <div class="text-center">
              <button
                class="btn theme-btn red-btn-bg mt-3"
                onClick={() => {
                  if (onEnterpriseRemove) {
                    hideModal();
                    onEnterpriseRemove(modalData.mail);
                  } else if (onRemoveMember) {
                    hideModal();
                    onRemoveMember(modalData.email, modalData?.tid);
                  }
                }}
              >
                {t("Remove")}
              </button>
            </div>
          </Modal.Body>
        );
      case "SHOW-TEAM-MEMBERS":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <div class="text-center">
              <h2 class="mini-title text-center mb-4">
                {t("Workspace Members")}
              </h2>
            </div>
            <ul className="dark-color">
              {modalData?.members?.map((member, index) => (
                <li key={index}>
                  <p class="d-flex justify-content-between align-items-center mb-1">
                    {modalData?.ownerMail === member ? (
                      <>
                        <span>
                          {member} &nbsp; ({t("Admin")})
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          {member} &nbsp; ({t("Team")})
                        </span>
                        {modalData?.ownerId === hashedId && (
                          <Link
                            onClick={() =>
                              onRemoveMember(member, modalData?.tid)
                            }
                          >
                            <span
                              class="icons-hover"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Delete"
                            >
                              <img
                                src={deleteSvg}
                                class="img-fluid"
                                alt="img"
                              />
                            </span>
                          </Link>
                        )}
                      </>
                    )}
                  </p>
                </li>
              ))}
            </ul>
          </Modal.Body>
        );
      case "DELETE-TEAM":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <div class="text-center">
              <h2 class="mini-title text-center mb-4">{t("Delete")}</h2>
              <p class="ft-18-title mb-4">
                {t(
                  "Are you sure to delete this workspace? All files, folders, workspace members inside the workspace will be deleted..."
                )}
              </p>
            </div>
            <div class="text-center">
              <button
                class="btn theme-btn red-btn-bg mt-3"
                onClick={() => {
                  if (onDeleteTeam) {
                    hideModal();
                    onDeleteTeam();
                  }
                }}
              >
                {t("Delete")}
              </button>
            </div>
          </Modal.Body>
        );
      case "LEAVE-TEAM":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <div class="text-center">
              <h2 class="mini-title text-center mb-4">{t("Leave")}</h2>
              <p class="ft-18-title mb-4">
                {t(
                  "Are you sure to leave from this workspace? All files, folders, workspace members inside the workspace will be unaccesible..."
                )}
              </p>
            </div>
            <div class="text-center">
              <button
                class="btn theme-btn red-btn-bg mt-3"
                onClick={() => {
                  if (onLeaveTeam) {
                    hideModal();
                    onLeaveTeam();
                  }
                }}
              >
                {t("Leave")}
              </button>
            </div>
          </Modal.Body>
        );
      case "LANGUAGE-MODAL":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <h2 class="mini-title text-center mb-4">{t("Choose Language")}</h2>
            <div class="form-group selectBox">
              <label>{t("Transcription Language")}</label>
              <LanguageDropdown
                onLanguageChange={handleLanguageChange}
                hideCheck={hideCheck}
              />
            </div>
            <div class="form-group selectBox">
              <label>{t("Dashboard Language")}</label>
              <DashboardLanguageDropdown
                onLanguageChange={handleLanguageChange}
                hideCheck={hideCheck}
              />
            </div>
          </Modal.Body>
        );
      case "CONFIRM-EDITOR":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <h2 class="mini-title text-center mb-4">{t("Leave")} </h2>
            <div className="text-center">
              <p>
                {t(
                  "Please make sure you saved the changes before leaving the page!"
                )}
              </p>
              <button
                class="btn theme-btn red-btn-bg mt-3"
                onClick={() => {
                  if (onLeaveEditor) {
                    hideModal();
                    onLeaveEditor(modalData.link);
                  }
                }}
              >
                {t("Leave")}
              </button>
            </div>
          </Modal.Body>
        );
      case "CONFIRM-UPLOADER":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <h2 class="mini-title text-center mb-4">{t("Attention")}</h2>
            <div className="text-center">
              <p>{t("Please wait until your file is uploaded to server!")}</p>
              <button
                class="btn theme-btn red-btn-bg mt-3"
                onClick={() => {
                  if (onLeaveEditor) {
                    hideModal();
                    onLeaveEditor(modalData.link);
                  }
                }}
              >
                {t("Leave Anyway")}
              </button>
            </div>
          </Modal.Body>
        );
      case "CONFIRM-RECORDER":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <h2 class="mini-title text-center mb-4">{t("Attention")}</h2>
            <div className="text-center">
              <p>{t("Please wait until your recording is finished!")}</p>
              <button
                class="btn theme-btn red-btn-bg mt-3"
                onClick={() => {
                  if (onLeaveEditor) {
                    hideModal();
                    onLeaveEditor(modalData.link);
                  }
                }}
              >
                {t("Leave Anyway")}
              </button>
            </div>
          </Modal.Body>
        );
      case "CANCEL_SUBSCRIPTION_GOOGLE":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <p class="mb-4" style={{ color: "red" }}>
                  {t(
                    "Subscriptions made from mobile cannot be canceled through the web! To cancel your subscription, please follow the steps below on your mobile device:"
                  )}
                </p>
                <p class="mb-4">
                  {t(
                    "1. Open the Google Play Store app on your Android device."
                  )}
                </p>
                <p class="mb-4">
                  {t(
                    "2. Ensure you're signed in to the correct Google Account."
                  )}
                </p>
                <p class="mb-4">
                  {t("3. Tap Menu then select Subscriptions.")}
                </p>
                <p class="mb-4">{t("4. Select Transkriptor subscription.")}</p>
                <p class="mb-4">{t("5. Tap 'Cancel Subscriptions'.")}</p>
              </div>
            </Modal.Body>
          </>
        );
      case "CONFIRM-RECORDER":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <h2 class="mini-title text-center mb-4">{t("Attention")}</h2>
            <div className="text-center">
              <p>{t("Please wait until your recording is finished!")}</p>
              <button
                class="btn theme-btn red-btn-bg mt-3"
                onClick={() => {
                  if (onLeaveEditor) {
                    hideModal();
                    onLeaveEditor(modalData.link);
                  }
                }}
              >
                {t("Leave Anyway")}
              </button>
            </div>
          </Modal.Body>
        );
      case "CANCEL_SUBSCRIPTION_GOOGLE":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <p class="mb-4" style={{ color: "red" }}>
                  {t(
                    "Subscriptions made from mobile cannot be canceled through the web! To cancel your subscription, please follow the steps below on your mobile device:"
                  )}
                </p>
                <p class="mb-4">
                  {t(
                    "1. Open the Google Play Store app on your Android device."
                  )}
                </p>
                <p class="mb-4">
                  {t(
                    "2. Ensure you're signed in to the correct Google Account."
                  )}
                </p>
                <p class="mb-4">
                  {t("3. Tap Menu then select Subscriptions.")}
                </p>
                <p class="mb-4">{t("4. Select Transkriptor subscription.")}</p>
                <p class="mb-4">{t("5. Tap 'Cancel Subscriptions'.")}</p>
              </div>
            </Modal.Body>
          </>
        );
      case "CANCEL_SUBSCRIPTION_APPSTORE":
        return (
          <>
            <Modal.Body>
              <button
                type="button"
                class="btn-close"
                onClick={hideModal}
                aria-label="Close"
              ></button>
              <div class="text-center">
                <p class="mb-4" style={{ color: "red" }}>
                  {t(
                    "Subscriptions made from mobile cannot be canceled through the web! To cancel your subscription, please follow the steps below on your mobile device:"
                  )}
                </p>
                <p class="mb-4">
                  {t("1. Open the Settings app on your Iphone or Ipad device.")}
                </p>
                <p class="mb-4">
                  {t(
                    "2. Tap your name at the top of the Settings menu to access your Apple ID and iCloud settings."
                  )}
                </p>
                <p class="mb-4">{t("3. Tap Subscriptions.")}</p>
                <p class="mb-4">{t("4. Tap Transkriptor subscription.")}</p>
                <p class="mb-4">
                  {t(
                    "5. Tap 'Cancel Subscription' at the bottom of the screen and confirm your cancellation."
                  )}
                </p>
              </div>
            </Modal.Body>
          </>
        );
      case "GIVE-FEEDBACK":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <div class="text-center">
              <h2 class="mini-title text-center mb-2">
                {t("We'd Love to Hear from You!")}
              </h2>
            </div>
            <div class="form-group me-3">
              <label>{t("Feedback")}</label>
              <textarea
                value={typedText2}
                onChange={(e) => setTypedText2(e.target.value)}
                rows="6"
                class="form-control round-input"
                placeholder={t("Type your feedbacks...")}
              ></textarea>
            </div>
            <div class="text-center">
              <button
                disabled={typedText2 === ""}
                class="btn theme-btn mt-3"
                onClick={() => {
                  if (onSendFeedback) {
                    onSendFeedback(typedText2);
                    hideModal();
                  }
                }}
              >
                {t("Send")}
              </button>
            </div>
          </Modal.Body>
        );
      case "CONVERT-FAQ":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <div class="text-center">
              <h2 class="mini-title text-center mb-4">{t("Remove")}</h2>
              <p class="ft-18-title mb-4">
                {t("Are you sure to remove this team member or friend?")}
              </p>
            </div>
          </Modal.Body>
        );
      case "RECORD-UPLOADING":
        return (
          <Modal.Body className="text-center">
            <Spinner animation="border" variant="primary" />
            <h2>Transkriptor</h2>
            <p>{t("Your record is being uploaded to cloud. Please wait...")}</p>
          </Modal.Body>
        );
      case "TRANSCRIBE-PROCESSING":
        return (
          <Modal.Body className="text-center">
            <Spinner animation="border" variant="primary" />
            <h2>Transkriptor</h2>
            <p>
              {t(
                "Your transcription is being uploaded to cloud. Please wait..."
              )}
            </p>
          </Modal.Body>
        );
      case "EDIT-ACCOUNT":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <h2 class="mini-title text-center mb-4">{t("Edit Account")}</h2>
            <div className="text-center">
              <div class="form-group me-3">
                <label>{t("Name")}</label>
                <input
                  type="text"
                  class="form-control round-input"
                  ref={inputRef}
                  value={newFileName}
                  onChange={(e) => {
                    setNewFileName(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                  name=""
                  placeholder={t("Name")}
                />
              </div>
              <button
                class="btn theme-btn  mt-3"
                onClick={() => {
                  if (onChangeUserName) {
                    hideModal();
                    onChangeUserName(newFileName);
                  }
                }}
              >
                {t("Save")}
              </button>
            </div>
          </Modal.Body>
        );
      case "RECORD-NO-DEVICES":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <h2 class="mini-title text-center mb-2">
              {t("Media Device Access Restricted")}
            </h2>
            <div className="text-center">
              <p style={{ color: "black" }}>
                {" "}
                {t(
                  "To use your camera and microphone, we need access to your camera and microphone. Please enable these permissions."
                )}{" "}
              </p>

              <img
                src={recordPermission}
                style={{ height: "500px" }}
                class="img-fluid mb-1"
                alt="img"
              />

              <p style={{ color: "black" }}>
                {" "}
                {t("Step 1: Click browser permission settings.")}
              </p>
              <p style={{ color: "black" }}>
                {" "}
                {t("Step 2: Give Camera and Microphone permissions.")}
              </p>

              <p style={{ color: "black" }}>{t("Step 3: Refresh the page.")}</p>

              <p style={{ color: "black" }}>
                {t("Do you wish to proceed anyway?")}
              </p>

              <div className="text-center">
                <button
                  className="btn theme-btn white-border-btn  me-2"
                  onClick={() => {
                    hideModal();
                  }}
                >
                  {t("Cancel")}
                </button>
                <button
                  class="btn theme-btn "
                  onClick={() => {
                    if (startRecording) {
                      startRecording();
                    }
                    hideModal();
                  }}
                >
                  {t("Start Recording")}
                </button>
              </div>
            </div>
          </Modal.Body>
        );
      case "LIMIT-EXCEEDED":
        return (
          <Modal.Body className="text-center">
            <h2>Transkriptor</h2>
            <p>
              {t(
                "You have reached the record limit by recording 30 file. Please upgrade your subscription to record."
              )}
            </p>
            <div class="text-center">
              <Link to="/payment" target="_blank" class="btn theme-btn mt-3">
                {t("Get started")}
              </Link>
            </div>
          </Modal.Body>
        );
      case "TRIAL-FILE-LIMIT":
        return (
          <Modal.Body className="text-center">
            <button
              type="button"
              class="btn-close"
              onClick={specialHideModal}
              aria-label="Close"
            ></button>

            <h2>Transkriptor</h2>
            <p>
              {t(
                "You can only transcribe one file at a time. Please upgrade to upload more files at the same time or continue with one file."
              )}
            </p>
            {isLoading && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            <div class="text-center">
              <button
                className="btn secondary-btn m-2"
                onClick={() => {
                  specialHideModal();
                  onTranscribeModal(modalData?.files);
                }}
              >
                {t("Continue with only one file")}
              </button>
              <button
                className="btn theme-btn m-2"
                onClick={() => {
                  setIsLoading(true);
                  subscription_aware(userData, currentUser, "trial_file_limit");
                  if(expVariant === "paddle-experiment" && userData && !userData.Mail.includes('.edu')){
                    navigate("/payment?type=sidebar_upgrade_button")
                    }else{
                      onUpgradePlan();
                    }
                }}
              >
                {t("Upgrade")}
              </button>
            </div>
          </Modal.Body>
        );
      case "CHECK-UPLOADER":
        return (
          <Modal.Body className="text-center">
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <h2>Transkriptor</h2>
            <p>
              {t(
                "You have an ongoing upload process. Please wait until your file is uploaded to server or cancel the upload."
              )}
            </p>
            <div className="text-center">
              <button
                className="btn danger-btn m-2"
                onClick={() => {
                  hideModal();
                  modalData?.closeUploaderBox();
                }}
              >
                {t("Cancel Upload")}
              </button>
              <button
                className="btn secondary-btn m-2"
                onClick={() => {
                  hideModal();
                }}
              >
                {t("Wait...")}
              </button>
            </div>
          </Modal.Body>
        );
      case "RECORD-NO-AUDIO-DEVICES":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <h2 class="mini-title text-center mb-2">
              {t("Media Device Access Restricted")}
            </h2>
            <div className="text-center">
              <p style={{ color: "black" }}>
                {" "}
                {t(
                  "To use your microphone, we need access to your microphone. Please enable microphone permission."
                )}{" "}
              </p>

              <img
                src={recordPermission}
                style={{ height: "500px" }}
                class="img-fluid mb-1"
                alt="img"
              />

              <p style={{ color: "black" }}>
                {" "}
                {t("Step 1: Click browser permission settings.")}
              </p>
              <p style={{ color: "black" }}>
                {" "}
                {t("Step 2: Give Camera and Microphone permissions.")}
              </p>

              <p style={{ color: "black" }}>{t("Step 3: Refresh the page.")}</p>

              <div className="text-center">
                <button
                  className="btn theme-btn white-border-btn  me-2"
                  onClick={() => {
                    hideModal();
                  }}
                >
                  {t("Cancel")}
                </button>
              </div>
            </div>
          </Modal.Body>
        );
      case "RECORD-NO-VIDEO-DEVICES":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <h2 class="mini-title text-center mb-2">
              {t("Media Device Access Restricted")}
            </h2>
            <div className="text-center">
              <p style={{ color: "black" }}>
                {" "}
                {t(
                  "To use your camera and microphone, we need access to your camera and microphone. Please enable these permissions."
                )}{" "}
              </p>

              <img
                src={recordPermission}
                style={{ height: "500px" }}
                class="img-fluid mb-1"
                alt="img"
              />

              <p style={{ color: "black" }}>
                {" "}
                {t("Step 1: Click browser permission settings.")}
              </p>
              <p style={{ color: "black" }}>
                {" "}
                {t("Step 2: Give Camera and Microphone permissions.")}
              </p>

              <p style={{ color: "black" }}>{t("Step 3: Refresh the page.")}</p>

              <p style={{ color: "black" }}>
                {t("Do you wish to proceed anyway?")}
              </p>

              <div className="text-center">
                <button
                  className="btn theme-btn white-border-btn  me-2"
                  onClick={() => {
                    hideModal();
                  }}
                >
                  {t("Cancel")}
                </button>
                <button
                  class="btn theme-btn "
                  onClick={() => {
                    if (startRecording) {
                      startRecording();
                    }
                    hideModal();
                  }}
                >
                  {t("Start Recording")}
                </button>
              </div>
            </div>
          </Modal.Body>
        );
      case "STRIPE-PAYMENT":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <div className="container">
              <StripePayment />
            </div>
          </Modal.Body>
        );
      case "CONFIRM-SPEAKER-PROFILE":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <div className="container">
              <p className=""></p>
              {t(
                "Would you like to remember this speaker name for your future transcriptions?"
              )}
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn secondary-btn m-2"
                onClick={() => {
                  hideModal();
                }}
              >
                {" "}
                {t("No")}
              </button>
              <button
                className="btn theme-btn m-2"
                onClick={() => {
                  if (onAddSpeakerProfile) {
                    onAddSpeakerProfile(
                      modalData?.old_speaker_name,
                      modalData?.new_speaker_name
                    );
                    hideModal();
                  }
                }}
              >
                {t("Yes")}
              </button>
            </div>
          </Modal.Body>
        );
      case "SPEAKER-LABELS":
        return (
          <Modal.Body>
            <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>
            <div class="text-center">
              <h2
                class="mini-title
                            text-center mb-4"
                            data-tooltip-id="speaker-label-tooltip"
              >
                {t("User Profiles")}
              </h2>
              <ReactTooltip place="top-end" type="dark" effect="solid" className="tooltip-container" id="speaker-label-tooltip" >
          <div className="tooltip-content">
          <span >{t("You can see sample speaker labels in this transcription and change their name for this and future transcriptions as well.")}</span>
          </div>
        </ReactTooltip>
            </div>
                {modalData?.speakers &&
              modalData?.longestItems.map((item, index) => (
                <div key={index} className="row align-items-center justify-content-center p-2 mb-2">
                  <div className="col-3">
                  <a className="d-flex justify-content-between align-items-center" key={item.Speaker}>
          {editingSpeaker === item.Speaker ? (
            <input
              type="text"
              value={editedSpeakerName}
              onBlur={handleInputBlur}
              onInput={(e) => handleInputKeyPress(e, item.Speaker)}
              onKeyDown={(e) => handleInputKeyDown(e, item.Speaker)}
              autoFocus
              className="form-control "
              style={{marginRight: "2px"}}
            />
          ) : (
            <span>{item.Speaker}</span>
          )}
          <span
            className="pointer"
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(item.Speaker);
            }}
          >
            <span
              className="theme-color"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title={t("Rename")}
              data-bs-original-title="Rename"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil"
                viewBox="0 0 16 16"
              >
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
              </svg>
            </span>
          </span>
        </a>
        
                  </div>
                  <div className="col-3 text-right">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm m-2"
                      onClick={() =>
                        handlePlayPauseAudio(item.StartTime, item.EndTime, index)
                      }
                    >
                      {playingIndex === index ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pause"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 3.5A.5.5 0 0 1 6 4v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0A.5.5 0 0 1 10 4v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-play"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.804 8 5 4.633v6.734zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696z" />
                        </svg>
                      )}
                      {t(playingIndex === index ? "Pause" : "Play")}
                    </button>
                  </div>
                </div>
              ))}
          </Modal.Body>
        );

      case "UPGRADE-PREMIUM":
        return(
          <Modal.Body>
             <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>

            <div class="text-center">
              <h2 class="mini-title
                            text-center mb-2">
                {t("Transkriptor")}
              </h2>
            <p>{t("Please upgrade your account to access premium features.")}</p>
            <div class="text-center">
                <button
                  onClick={() => {
                    subscription_aware(
                      userData,
                      currentUser,
                      "premium_feature"
                    );
                    navigate(`/payment?type=premium_feature`);
                    hideModal();
                  }}
                  class="btn theme-btn mt-3"
                >
                  {t("Get started")}
                </button>
              </div>
            </div>
        </Modal.Body>
        )
        case "UPGRADE-PADDLE":
        return(
          <Modal.Body>
             <button
              type="button"
              class="btn-close"
              onClick={hideModal}
              aria-label="Close"
            ></button>

            <div class="text-center">
              <h2 class="mini-title
                            text-center mb-2">
                {t("Transkriptor")}
              </h2>
            <p>{t("Would you like to upgrade your plan? The amount you paid for your previous plan will be deducted from the price of the plan you are about to be upgraded. Click on Upgrade to proceed.")}</p>
            <div class="text-center">
            <button
                  onClick={() => {
                    hideModal();
                  }}
                  class="btn danger-btn m-3"
                >
                  {t("Cancel")}
                </button>
                <button
                  onClick={() => {
                    modalData?.onUpgradePaddlePlan(modalData?.hashed_id, modalData?.price_id);
                    hideModal();
                  }}
                  class="btn theme-btn m-3"
                >
                  {t("Upgrade")}
                </button>
              </div>
            </div>
        </Modal.Body>
        )
      default:
        return null;
    }
  };

  return (
    <Modal
      show={!!modalType}
      onHide={handleModalHide}
      centered
      size={size}
      className="notranslate"
    >
      {renderModalContent()}
    </Modal>
  );
}

export default BootstrapModal;
