import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

import renameSvg from '../../assets/img/icon/rename.svg'
import moveSvg from '../../assets/img/icon/move.svg'
import sharePeopleSvg from '../../assets/img/icon/share-people.svg'
import shareSvg from '../../assets/img/icon/share.svg'
import deleteSvg from '../../assets/img/icon/delete.svg'

import { useTranslation } from '../../components/common/LanguageProvider';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';


export default function CustomizedMenus({ onDelete, onRename, onMove, onShareTeam, onShare, onClick, isFile, status }) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [moveMenuAnchorEl, setMoveMenuAnchorEl] = useState(null);
  const moveMenuOpen = Boolean(moveMenuAnchorEl);

  const isFailed = status === "Failed";
  const isProcessing = status === "Processing";

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (anchorEl) {
      handleClose();  // If the menu is open and clicked, close it
    } else {
      setAnchorEl(event.currentTarget);  // Otherwise, open it at the current target
    }
  };

  const handleClose = () => {
    setAnchorEl(null);  // Close the menu
  };


  const handleDelete = () => {
    console.log("Delete action triggered");
    onDelete();
    handleClose();
  };

  const handleRename = () => {
    console.log("Rename action triggered");
    onRename();
    handleClose();
  };

  const handleMove = () => {
    console.log("Move action triggered");
    onMove();
    handleClose();
  };

  const handleShareTeam = () => {
    console.log("Share action triggered");
    onShareTeam();
    handleClose();
  }

  const handleShare = () => {
    console.log("Share action triggered");
    onShare();
    handleClose();
  }

  return (
    <div onClick={onClick} style={{ borderRadius: "6px" }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {status === "Failed" ? <DoNotDisturbOnIcon style={{ pointerEvents: 'none', cursor: 'none' }} /> : <MoreVertIcon />}
      </IconButton>
      {!isFailed && (
        <Menu
          id="customized-menu"
          className={isFile ? "custom-menu-style" : ""}
          classes={{ paper: 'custom-menu-paper' }}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          getContentAnchorEl={null}
        >
          <MenuItem onClick={handleRename} style={{ opacity: isProcessing ? "0.5" : "1", pointerEvents: isProcessing ? "none" : "auto" }}>
            <span class="action-drop-icon regular" >
              <img src={renameSvg} class="img-fluid" alt="img" />
            </span>
            <span class="regular">{t("Rename")}</span>
          </MenuItem>
          <MenuItem onMouseDown={handleShare} >
            <span class="action-drop-icon regular">
              <img src={shareSvg} class="img-fluid" alt="img" />
            </span>
            <span class="regular">{t("Share")}</span>
          </MenuItem>
          <MenuItem onMouseDown={handleMove} style={{ opacity: isProcessing ? "0.5" : "1", pointerEvents: isProcessing ? "none" : "auto" }}>
            <span class="action-drop-icon regular">
              <img src={moveSvg} class="img-fluid" alt="img" />
            </span>
            <span class="regular">{t("Move to folder")}</span>
          </MenuItem>
          <MenuItem onMouseDown={handleShareTeam} style={{ opacity: isProcessing ? "0.5" : "1", pointerEvents: isProcessing ? "none" : "auto" }}>
            <span class="action-drop-icon regular">
              <img src={sharePeopleSvg} class="img-fluid" alt="img" />
            </span>
            <span class="regular"> {t("Move to workspace")}</span>
          </MenuItem>
          <MenuItem onMouseDown={handleDelete} style={{ opacity: isProcessing ? "0.5" : "1", pointerEvents: isProcessing ? "none" : "auto" }}>
            <span class="action-drop-icon">
              <img src={deleteSvg} class="img-fluid" alt="img" />
            </span>
            <span class="regular">{t("Delete")}</span>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}
