import React, { useEffect, useState } from 'react';
import { useAuth } from '../Auth/AuthContext';
import audioIcon from '../assets/img/icon/audio-icon.svg';
import googleDriveIcon from '../assets/img/icon/google-drive.svg';
import youtubeIcon from '../assets/img/icon/youtube.svg';
import zoom from '../assets/img/icon/zoom.svg';
import transkriptorLogo from "../assets/img/logo_nobuffer.png"
import eskritorLogo from "../assets/img/logo-eskritor-nobuffer.png"
import speaktorLogo from "../assets/img/logo-speaktor-nobuffer.png"

import recordPng from "../assets/img/icon/record-btn-icon.svg"

import { useNavigate, useLocation } from "react-router-dom";
import { showToast } from "../components/common/toast";
import { useTranslation } from '../components/common/LanguageProvider';
function Authentication() {
    const queryString = window.location.search;
    const authenticate = new URLSearchParams(queryString);
    const { login, currentUser } = useAuth();
    const mode = authenticate.get('mode');
    const oobCode = authenticate.get('oobCode');
    const api_key = authenticate.get('apiKey');

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const verifyMail = async () => {
        fetch("https://identitytoolkit.googleapis.com/v1/accounts:update?key=" + api_key, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "oobCode": oobCode })
        }).then(response => response.json()).then(response => {
            fetch("https://v3t1p3ucic.execute-api.eu-central-1.amazonaws.com/default/AA-API-FireGetHashedID?uid=" + response.localId).then(response => response.json()).then(user => {
                navigate("/signIn?uid=" + user.hashedId)
            })
        })
    }
    useEffect(() => {
        if (mode === "verifyEmail") {
            verifyMail();
        }


    }, [mode])
    async function verify_password() {

        if (password === verifyPassword) {
            // Handle successful password verification
            console.log("passwords match");
            setPasswordsMatch(true);
            setIsLoading(true);
            try {
                const response = await fetch('https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=' + api_key, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ "oobCode": oobCode, "newPassword": password })
                })
                    .then(response => response.json())
                    .then(response => {
                        setIsLoading(false)
                        showToast(t("Please login now!"))
                        navigate("/signIn");
                    });

            } catch (error) {
                console.log(error);
                showToast(t("Passwords not matched. Please check passwords."), { type: "error" });
            }
        } else {
            // Handle incorrect password
        }
    }
    return (
        <>

            <div className='content-area pb-0'>
                <div className='row justify-content-center'>
                    <div className='col-lg-10 card-margin'>
                        <div className='card card-padding card-100 text-center ' style={{ display: mode === "verifyEmail" ? "" : "none" }} >

                            <div className='img-icon-list' style={{ display: "none" }}>
                                <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title={t("Audio")}><img src={audioIcon} class="img-fluid" alt="img" /></span>
                                <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Youtube"><img src={youtubeIcon} class="img-fluid" alt="img" /></span>
                                <span class="pointer red-bg recorder-span2 d-flex align-items-center justify-content-center" style={{ borderRadius: "10px" }} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Youtube"><img src={recordPng} class="img-fluid" alt="img" /></span>

                                <span class="pointer" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Zoom"><img src={zoom} class="img-fluid" alt="img" /></span>

                            </div>
                            <img src={transkriptorLogo} width="20%"></img>
                            <p>{t("Convert Any Audio Or Video File To Text From Your Computer, Cloud Storage, Youtube, Record Screen or Record Meetings (Zoom, Google Meet, Teams)")}</p>
                            <h2>{t("Please wait...")}</h2>
                            <div className='row m-2'>
                                <div className='d-flex justify-content-center'>
                                    <div className="spinner-border text-primary" role="status">

                                    </div>
                                </div>


                            </div>
                            <p >{t("Your mail has been verified, and you will be redirected to sign in page in seconds...")}</p>
                        </div>
                        <div className='card card-padding card-100 text-center d-flex justify-content-center align-items-center' style={{ display: mode === "resetPassword" ? "" : "none" }}>
                            <div class="form-group col-4 mt-2">
                                <h2 class="mini-title mb-2">{t("Reset Password")}</h2>
                            </div>
                            <div class="form-group col-4 mt-2">
                                <label>{t("Please type your new password")}</label>
                                <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>

                                    <input
                                        type="password"
                                        className="form-control"
                                        value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                        placeholder={t("******")}
                                    />
                                    {(passwordsMatch && verifyPassword.length > 0) ?
                                        <span style={{ color: 'green', marginLeft: '10px' }}>✓</span> :
                                        (verifyPassword.length > 0 ?
                                            <span style={{ color: 'red', marginLeft: '10px' }}>✗</span> : null)
                                    }
                                </div>
                            </div>
                            <div class="form-group col-4">
                                <label>{t("Verify new password")}</label>
                                <div className="form-group " style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="password"
                                        className="form-control"
                                        style={{ flexGrow: 1 }} // ensures the input takes up most of the space
                                        value={verifyPassword}
                                        onChange={(e) => {
                                            setVerifyPassword(e.target.value);
                                            setPasswordsMatch(e.target.value === password);
                                        }}
                                        placeholder={t("******")}
                                    />
                                    {(passwordsMatch && verifyPassword.length > 0) ?
                                        <span style={{ color: 'green', marginLeft: '10px' }}>✓</span> :
                                        (verifyPassword.length > 0 ?
                                            <span style={{ color: 'red', marginLeft: '10px' }}>✗</span> : null)
                                    }
                                </div>

                            </div>
                            {isLoading && (
                                <div class="text-center " >
                                    <div style={{ padding: '5px 25px' }}
                                    >
                                        <div className='row mb-5' style={{ zIndex: 2 }} >
                                            <div className='d-flex justify-content-center'>
                                                <div className="spinner-border text-primary" role="status">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )}

                            <div class="text-center mb-5" style={{ opacity: isLoading ? 0 : 1, pointerEvents: isLoading ? 'none' : 'auto', zIndex: 1, display: isLoading ? "none" : "block" }}>
                                <button class="btn theme-btn "
                                    disabled={!passwordsMatch}
                                    onClick={verify_password}

                                >{t("Confirm")}</button>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}

export default Authentication;


