import React, { memo, useEffect, useRef, useState } from "react";
import ChatBotWhite from "../../assets/img/files/tab-chatbot-white.svg";
import Logo from "../../assets/img/icon/logo-icon.png";
import { useAuth } from "../../Auth/AuthContext";
import { fetchData, fetchDataPost } from "../../hooks/apiUtils";
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from '../../components/common/LanguageProvider';
import { showToast } from "../../components/common/toast";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Markdown from 'markdown-to-jsx';
import {marked} from 'marked';
function AiChat({orderId, tid, session_id}) {

    const { t } = useTranslation();
    const {hashedId, userData} = useAuth();

    const [chatContent, setChatContent] = useState([]);
    const [amigotorOid, setAmigotorOid] = useState(null);
    const [chatStatus, setChatStatus] = useState(false);   
    const [question, setQuestion] = useState("");  
    const [intelligence, setIntelligence] = useState("normal");
    const [mode, setMode] = useState("general");
    const [source, setSource] = useState("my-file");
    const [memory, setMemory] = useState("disabled");
    const [isAnswerLoading, setIsAnswerLoading] = useState(false);
    const [minutes, setMinutes] = useState(null);
    const [isModeDropdownOpen, setIsModeDropdownOpen] = useState(false);
    const [isSourceDropdownOpen, setIsSourceDropdownOpen] = useState(false);
    const [isMemoryDropdownOpen, setIsMemoryDropdownOpen] = useState(false);
    const [isIntelligenceDropdownOpen, setIsIntelligenceDropdownOpen] = useState(false);
    const modeRef = useRef(null);
    const sourceRef = useRef(null);
    const memoryRef = useRef(null);
    const scrollRef = useRef(null);

    const [chatHistory, setChatHistory] = useState(null);
    const [suggestedQuestions, setSuggestedQuestions] = useState(null)
    const [waitingResponse, setWaitingResponse] = useState(false);
    const [internetEnabled, setInternetEnabled] = useState(localStorage.getItem("internetEnabled") === "true" ? true : false);
    const [isLoading, setIsLoading] = useState(false);
    const [customPrompt, setCustomPrompt] = useState("");

    const check_status = async() => {
        try{
            setIsLoading(true);
            const response = await fetch("https://f31fbu79l4.execute-api.eu-central-1.amazonaws.com/default/amigotor-checkstatus?orderid=" + orderId)
            let status = response.status;
            let data = await response.json();
            console.log(response)
            console.log("hello")
            if(status === 404){
                setChatStatus(false);
            }else if(status === 200){
              setChatStatus(true)
              setAmigotorOid(data)
            }
          
        }catch(error){
            setChatStatus(false);

        }
        setIsLoading(false);
        

    };
    const start_chat = async() => {
        setIsLoading(true);
        try {
            const response = await fetch("https://3qa0e471p0.execute-api.eu-central-1.amazonaws.com/default/amigotor-transkriptorintegration?order=" + orderId);
            
            if (!response.ok) {
                throw new Error('HTTP status ' + response.status);
            }
               
            check_status();
        } catch(error) {
            console.error('Error:', error);
        }
        setIsLoading(false);
    };
    const get_chat = async() => {
        if(amigotorOid)
            fetchDataPost("https://ubh7e9mzc5.execute-api.eu-central-1.amazonaws.com/default/amigotor-getchathistory?oid=" + amigotorOid).then((response) => response.json()).then((data) => {
                setChatContent(data.chat)
                scrollToBottom();

            });
    };
   /* useEffect(() => {
        if(orderId)
            check_status();
    }, [orderId]);
    useEffect(() => {
        get_chat();
    }, [amigotorOid]);*/

    const generate_answer = async() => {
      if(question.length > 0){
        setIsAnswerLoading(true);
        var location = ""
        setTimeout(() => {
          scrollToBottom();
        }, 0);
        var temp_content = [...chatContent]
        temp_content.push([question, ""])
        setChatContent(temp_content)
        const data = {
            query: question,
            intelligence: intelligence,
            mode: mode,
            source: source,
            memory: memory,
            transkriptor_sk: tid,
            location: location,
            uid: hashedId
        }
        setQuestion("");
        fetchDataPost("https://180tk84b01.execute-api.eu-central-1.amazonaws.com/default/amigotor-query?oid=" + amigotorOid, 
        data).then((response) => response.json()).then((data) => {

            try{
              console.log(data)
              let status = data.result.statusCode;

              console.log(status)
              if(status === 200){
                  setMinutes(data.minutes);
                  get_chat();
                  
                  setIsAnswerLoading(false);
  
              }
              else{
                  setIsAnswerLoading(false);
                  get_chat();
              }
            }catch{
              setIsAnswerLoading(false);
              get_chat();
              showToast("Unknown error occured, please contact support", {type: "error"})
            }
           
        });

      }
       
    };
    const scrollToBottom = () => {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
    const getRenderedText = (text) => {
      var rawMarkup = marked.parse(text);
      return { __html: rawMarkup };
    }
   
    
    const modes = {
      "general": t("General"),
      "custom": t("Custom"),
    }
  
    const sources = {
      "my-file": "My File",
      "internet": "Internet",
    }
    const handleSelect = (value, type) => {
      if (type === "mode") {
        setMode(value);
        setIsModeDropdownOpen(false);
  
  
      } else if (type === "source") {
          handleToggleChange(value);
        
        setSource(value);
        setIsSourceDropdownOpen(false);
      }
    } 
    const handleToggleChange = (value) => {
      if(value === "internet" ){
        localStorage.setItem("internetEnabled", true);

        setInternetEnabled(true);
      }else{
        localStorage.setItem("internetEnabled", false);
        setInternetEnabled(false);
      }

    };
  
    useEffect(() => {
        if(userData)
          setMinutes(userData.Minutes);
      }, [userData]);
      const generate_answer_stream = async (suggested_question) => {
        if (isAnswerLoading) {
          return;
        }
        const query = suggested_question || question;
        
        if (query.length > 0) {
          setIsAnswerLoading(true);
          setWaitingResponse(true);
          var temp_content = [...chatHistory, { role: "user", content: query }];
          setChatHistory(temp_content);
          setQuestion("");
          setSuggestedQuestions([]);
    
    
    
          const uri = "wss://wddat2ivpe.execute-api.eu-central-1.amazonaws.com/production";
          const websocket = new WebSocket(uri);
          var temp_internet_enabled = false;
          if(internetEnabled === "true"){
            temp_internet_enabled = true;
          }
          const request_data = {
            "action": "sendMessage",
            "oid": session_id,
            "uid": hashedId,
            "query": query,
            "internet": temp_internet_enabled,
            "session_specific": true,
          }
    
          let content = "";
          let questions = [];
    
          websocket.onopen = () => {
            console.log("WebSocket connection established");
            websocket.send(JSON.stringify(request_data));
            console.log("> Request sent:", request_data);
    
    
          };
    
          websocket.onmessage = (event) => {
            const response = JSON.parse(event.data);
            console.log(response);
    
    
    
            if (response.status_code) {
              if (response.status_code !== 200) {
                showToast("Unknown error occured, please contact support", { type: "error" });
                setIsAnswerLoading(false);
                setWaitingResponse(false);
                return;
              }
            }
    
            if (response.response) {
              setWaitingResponse(false)
              content = response.response;
              setChatHistory(prevContent => {
                let updatedContent = [...prevContent];
                if (updatedContent[updatedContent.length - 1].role !== "assistant") {
                  updatedContent.push({ role: "assistant", content: content });
                } else {
                  updatedContent[updatedContent.length - 1].content = content;
                }
                return updatedContent;
              });
    
              scrollToBottom();
    
            }
    
    
            if (response.questions) {
              questions = response.questions;
              setSuggestedQuestions(questions);
            }
    
    
    
    
            if (response.stream_stopped) {
    
              
              setIsAnswerLoading(false);
              setWaitingResponse(false);
              scrollToBottom();
              console.log("Special condition met, closing connection.");
              websocket.close(); // Close the connection gracefully
              console.log("Connection closed.");
            }
          };
    
          websocket.onerror = (event) => {
            setIsAnswerLoading(false);
            setWaitingResponse(false);
            console.error("WebSocket error observed:", event);
          };
    
    
    
          return () => {
            if (websocket.readyState === WebSocket.OPEN) {
              setIsAnswerLoading(false);
              setWaitingResponse(false);
              websocket.close();
            }
          };
        }
      };
      const getLastSuggested = async()=>{
        const response = await fetch(`https://05ij0k1nni.execute-api.eu-central-1.amazonaws.com/default/Amigotor-RetrieveChatAzure?oid=${session_id}`)
        const data = await response.json();
        console.log(data)
        console.log("kek") 
        let temp_old_chat = data.responses;
        let temp_suggested_questions = data.suggested_questions;
        setChatHistory(temp_old_chat);
        setSuggestedQuestions(temp_suggested_questions)
        let temp_custom_prompt = data.session.custom_prompt;
        if(temp_custom_prompt && temp_custom_prompt.length > 0){
          setCustomPrompt(temp_custom_prompt)
          setMode("custom")
        }
      }
      useEffect(()=>{
        if(session_id && session_id.length > 0){
          getLastSuggested();
        }
      }, [session_id])


      const handlePromptSave = async() => {
        const response = await fetchDataPost(`https://43xo7cbkwi.execute-api.eu-central-1.amazonaws.com/default/Amigotor-AddCustomInstructions?soid=${session_id}`, {"prompt": customPrompt})
        showToast(t("Prompt is saved successfully"))
      }
    return (
        <>
            
            {session_id && session_id.length  > 0 ? (
                <>
                    <div class="row gx-lg-4 gx-0 w-100 m-0 notranslate">
                        <div class="col-lg-8 col-12">
                          <div class="ai-chat-div ai-chat-div-height vertical-flex-box">
                            <div class="ai-chat-msg-content" ref={scrollRef}>
                              <div class="ai-chat-msg-list chat-list-box">
                                <span class="info-icon theme-color me-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16.376"
                                    height="16.62"
                                    viewBox="0 0 16.376 16.62"
                                  >
                                    <g
                                      id="info"
                                      transform="translate(-2.239 -2.244)"
                                    >
                                      <path
                                        id="Path_124"
                                        data-name="Path 124"
                                        d="M11.229,2.284a8.073,8.073,0,0,1,4.977,2.4,8.382,8.382,0,0,1,.546,11.148A8.132,8.132,0,0,1,6.566,17.894a8.31,8.31,0,0,1-3.7-4.155,8.453,8.453,0,0,1-.273-5.592,8.31,8.31,0,0,1,3.288-4.5,8.073,8.073,0,0,1,5.352-1.364Zm.56,15.254a6.992,6.992,0,0,0,4.049-2.457,7.206,7.206,0,0,0-.475-9.569,6.909,6.909,0,0,0-8.821-.879A7.194,7.194,0,0,0,7.136,16.85a6.9,6.9,0,0,0,4.654.689ZM9.813,8.183H11.3V7H9.813ZM11.3,9.37v4.749H9.813V9.37Z"
                                        transform="translate(0 0)"
                                        fill="currentColor"
                                        fill-rule="evenodd"
                                      />
                                    </g>
                                  </svg>
                                </span>
                                <div className="chat-assistant-list-box">
                                  <p class="ft-18-title">{t("What is AI Chat?")}</p>
                                  <p >
                                   {t(`Using this chat, you can ask anything you want about this recording. I know all the important concepts of this recording, you can think of me like your personal assistant. Use me to generate new types of content, or as a source for new ideas.`)}
                                  </p>
                                </div>
                               
                              </div>
                              {chatHistory ? (
                                  chatHistory.map((item, index) => (
                                    item.role === "user" ? (
                                      <div className="chat-user-list-box" key={index}>
                                        <p >{item.content}</p>
                                      </div>
                                    ) : (
                                      <div className="chat-assistant-list-box" key={index}>
                                         <p dangerouslySetInnerHTML={getRenderedText(item.content.replace(/【.*?†source】/g, ''))}></p>
                                       
                                      </div>
                                    )
                                  ))
                                ) : null}

                              {suggestedQuestions ? suggestedQuestions.map((item, index) => (
                                    <div className="chat-suggested-list-box" key={index}>
                                        
                                        <Markdown
                                        onClick={()=> generate_answer_stream(item)}
                                          options={{ forceBlock: true }}

                                        >
                                          {item}
                                        </Markdown>
                                    </div>
                                )): null}
                                  {
                                    waitingResponse ? (
                                      <div class={`chat-assistant-list-box text-center`} style={{ 'marginRight': '90%' }}>
                                        <p class="loading-threedots">
                                          <span>.</span>
                                          <span>.</span>
                                          <span>.</span>
                                        </p>
                                      </div>
                                    ) : null
                                  }
                            </div>
                            <div class="ai-chat-msg-box">
                              <input
                                type="text"
                                class="form-control round-input border-input"
                                name=""
                                placeholder={t("Type here")}
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && !isAnswerLoading) {
                                        generate_answer_stream();
                                    }
                                }}
                              />
                              <button class="btn theme-btn d-flex rounded-btn ms-2" onClick={()=> generate_answer_stream()} disabled={isAnswerLoading ? true : false}>
                                <span class="me-2">
                                  <img
                                    src={ChatBotWhite}
                                    class="img-fluid "
                                    alt="img"
                                  />
                                </span>
                                {t("Generate")}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-12">
              <div class="">

                <p class="ft-18-title mb-4">
                  <span class="theme-color me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.022"
                      height="23.645"
                      viewBox="0 0 23.022 23.645"
                    >
                      <g
                        id="settings"
                        transform="translate(-2.715 -2.295)"
                      >
                        <path
                          id="Path_171"
                          data-name="Path 171"
                          d="M23.537,14.761v-1.3l1.626-1.423a1.694,1.694,0,0,0,.321-2.16l-2-3.387a1.707,1.707,0,0,0-2.007-.762l-2.058.694a9.612,9.612,0,0,0-1.11-.635l-.432-2.134a1.694,1.694,0,0,0-1.694-1.363H12.222a1.694,1.694,0,0,0-1.694,1.363L10.1,5.792a9.722,9.722,0,0,0-1.118.635L6.963,5.7a1.694,1.694,0,0,0-.541-.05A1.694,1.694,0,0,0,4.957,6.5l-2,3.387a1.694,1.694,0,0,0,.347,2.126l1.6,1.465v1.3l-1.6,1.423a1.694,1.694,0,0,0-.347,2.16l2,3.387a1.707,1.707,0,0,0,2.007.762l2.058-.694a9.612,9.612,0,0,0,1.11.635l.432,2.134a1.694,1.694,0,0,0,1.694,1.363h4a1.694,1.694,0,0,0,1.694-1.363l.432-2.134a9.722,9.722,0,0,0,1.118-.635l2.05.694a1.707,1.707,0,0,0,2.007-.762l1.931-3.387a1.694,1.694,0,0,0-.347-2.126Zm-1.516,6.131-2.9-.982a7.5,7.5,0,0,1-2.3,1.329l-.6,3.04h-4l-.6-3.007a7.927,7.927,0,0,1-2.287-1.329l-2.912.948-2-3.387,2.3-2.032a7.538,7.538,0,0,1,0-2.651l-2.3-2.092,2-3.387,2.9.982A7.5,7.5,0,0,1,11.622,7l.6-3.04h4l.6,3.007a7.927,7.927,0,0,1,2.287,1.329l2.912-.948,2,3.387-2.3,2.032a7.538,7.538,0,0,1,0,2.651l2.3,2.092Z"
                          transform="translate(0 0)"
                          fill="currentColor"
                        />
                        <path
                          id="Path_172"
                          data-name="Path 172"
                          d="M16.331,21.412a5.031,5.031,0,1,1,3.608-1.473A5.081,5.081,0,0,1,16.331,21.412Zm0-8.469a3.311,3.311,0,1,0,2.418.969,3.311,3.311,0,0,0-2.418-.969Z"
                          transform="translate(-2.11 -2.214)"
                          fill="currentColor"
                        />
                      </g>
                    </svg>
                  </span>
                  {t("Chat Settings")}
                </p>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setIsModeDropdownOpen(false)
                  }}
                >

                  <div class="form-group selectBox" ref={modeRef}>
                    <label>{t("Mode")}</label>
                    <div
                      className="form-control round-input border-input selectBox__value"
                      onClick={() => {
                        setIsModeDropdownOpen(!isModeDropdownOpen)

                      }}
                    >
                      {modes[mode]}
                    </div>
                    {isModeDropdownOpen && (
                      <div className="dropdown-menu">
                        <a
                          href="#"
                          className={`dropdown-item ${mode === 'general' ? 'active' : ''}`}
                          onClick={() => handleSelect('general', "mode")}
                        >
                          {t("General")}
                        </a>

                        <a
                          href="#"
                          className={`dropdown-item ${mode === 'custom' ? 'active' : ''}`}
                          onClick={() => handleSelect('custom', "mode")}
                        >
                          {t("Custom")}
                        </a>
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
                {mode === "custom" &&
                <div class="form-group ">
                  <label>{t("Prompt")}</label>
                  <input
                    type="text"
                    class="form-control round-input border-input"
                    name=""
                    placeholder={t("Give me short answers and make them as easy to understand as possible.")}
                    value={customPrompt}
                    onChange={(e) => setCustomPrompt(e.target.value)}
                    onBlur={handlePromptSave}
                  
                  />
                </div> }
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setIsSourceDropdownOpen(false)
                  }}
                >
                  <div class="form-group selectBox" ref={sourceRef}>
                    <label>{t("Source")}</label>
                    <div class="form-control round-input border-input selectBox__value"
                      onClick={() => {

                        setIsSourceDropdownOpen(!isSourceDropdownOpen)
                      }}
                    >
                      {t(sources[source])}
                    </div>
                    {isSourceDropdownOpen && (
                      <div class="dropdown-menu">
                        <a href="#"
                          className={`dropdown-item ${source === 'my-file' ? 'active' : ''}`}
                          onClick={() => handleSelect('my-file', "source")}
                        >
                          {t("My File")}
                        </a>
                        <a href="#"
                          className={`dropdown-item ${source === 'internet' ? 'active' : ''}`}
                          onClick={() => handleSelect('internet', "source")}
                        >
                          {t("Internet")}
                        </a>

                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
             
                <div class="d-flex align-items-start">
                  <span class="info-icon theme-color">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.376"
                      height="16.62"
                      viewBox="0 0 16.376 16.62"
                    >
                      <g
                        id="info"
                        transform="translate(-2.239 -2.244)"
                      >
                        <path
                          id="Path_124"
                          data-name="Path 124"
                          d="M11.229,2.284a8.073,8.073,0,0,1,4.977,2.4,8.382,8.382,0,0,1,.546,11.148A8.132,8.132,0,0,1,6.566,17.894a8.31,8.31,0,0,1-3.7-4.155,8.453,8.453,0,0,1-.273-5.592,8.31,8.31,0,0,1,3.288-4.5,8.073,8.073,0,0,1,5.352-1.364Zm.56,15.254a6.992,6.992,0,0,0,4.049-2.457,7.206,7.206,0,0,0-.475-9.569,6.909,6.909,0,0,0-8.821-.879A7.194,7.194,0,0,0,7.136,16.85a6.9,6.9,0,0,0,4.654.689ZM9.813,8.183H11.3V7H9.813ZM11.3,9.37v4.749H9.813V9.37Z"
                          transform="translate(0 0)"
                          fill="currentColor"
                          fill-rule="evenodd"
                        />
                      </g>
                    </svg>
                  </span>
                      <p class="mb-0" style={{ display: mode === "general" ? "" : "none" }}>
                        {t("In General mode, you can engage in a direct conversation with the AI")}
                      </p>
                      <p class="mb-0" style={{ display: mode === "custom" ? "" : "none" }}>
                        {t("In Custom mode, you can personalize your interaction by specifying the prompt you wish to use in your chat with the AI")}
                      </p>

                    </div>
                  </div>
                </div>
                      
            </div>
                </>
            ) : <>
            <div class="row gx-lg-4 gx-0 w-100 m-0 notranslate">
                        <div class="col-lg-12 col-12">
                          <div class="ai-chat-div ai-chat-div-height vertical-flex-box">
                          <Skeleton height={30} count={10} style={{ borderRadius: '10px', marginTop: "21.07px" }} />

                          </div>
                        </div>
                      
            </div>
               
            </>}
            {session_id && session_id.length === 0 ? (
              <>
              <div class="row gx-lg-4 gx-0 w-100 m-0 notranslate">
                          <div class="col-lg-12 col-12">
                            <div class="ai-chat-div ai-chat-div-height vertical-flex-box">
                            <Skeleton height={30} count={10} style={{ borderRadius: '10px', marginTop: "21.07px" }} />

                            </div>
                          </div>
                        
              </div>
                 
              </>
            ): null
           }
           
        </>
    );
}

export default AiChat;