import React, { useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import { useTranslation } from './LanguageProvider';
import {showToast} from './toast';
import { feature_review } from '../../ampli';
import { useAuth } from '../../Auth/AuthContext';
function FeedbackForm(props) {
    const {userData, currentUser} = useAuth();
    const [rating, setRating] = useState(0);
    const [isFeedbackSent, setIsFeedbackSent] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [isDisabled, setIsDisabled] = useState(true)
    const {t} = useTranslation();
    const handleRating = (newRating) => {
        setIsDisabled(false);
        setRating(newRating);
    };

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleSubmit = () => {
        feature_review(userData, currentUser, rating, feedback, props?.transcription_feature_name, props?.transcriptionDuration, props?.transcription_order_id, props?.transcriptionLanguage);
        setRating(0);
        setFeedback("");
        setIsDisabled(true);
        showToast(t("Feedback is submitted successfully!"));
        setIsFeedbackSent(true);
    };

    return (
        <div className="card mb-4 feature-review-card" style={{ display: isFeedbackSent ? "none" : "" }}>
                
             <div className="card-body text-center">

                <div className="mb-3 text-center d-flex justify-content-center align-items-center">
                    <ReactStars
                        count={5}
                        onChange={handleRating}
                        size={36}
                        activeColor="#ffd700"
                        value={rating}
                    />
                </div>
                <div className="mb-3 form-group d-flex justify-content-center">
                    <textarea
                        className="form-control w-50"
                        value={feedback}
                        onChange={handleFeedbackChange}
                        rows={3}
                        placeholder={t("Enter your feedback here...")}
                    />
                </div>
                <button
                    type="button"
                    className="btn theme-btn w-20"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                >
                    {t("Submit Feedback")}
                </button>
            </div>
        </div>
    );
}

export default FeedbackForm;
