import React from "react";

import { useTranslation } from "../../components/common/LanguageProvider";
import transkriptor from "../../assets/img/icon/transkriptor.svg"
import amplitude from "amplitude-js";

function ConversionBanner() {

    const { t } = useTranslation();
    const register = () => {
        var deviceId = amplitude.getInstance().getDeviceId();
        window.location.href =
            "https://app.transkriptor.com/register?lang=en&deviceId=" + deviceId;
    };

    return (
        <div class=" row m-0 sec-pad-top justify-content-center conversion-banner">
            <div class="col-xxl-12 card-margin">
                <div class="card conversion-card">
                    <div class="card-padding">
                        <div class="d-md-flex d-block align-items-center justify-content-center text-center">
                            <div>
                                <p class="ft-18 conversion-text medium">{t("Created with Transkriptor")}</p>
                                <p class="conversion-text">{t("Unlock the full version of Transkriptor. Click the button below to get the experience!")}</p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center login-btn-sec mb-2">
                            <button class="btn-tryit btn nav-link theme-btn nav-link-btn d-flex justify-content-center conversion-button"
                                onClick={register}>
                                <span class="conversion-button-text">{t("Get Started for Free")}</span><span class="ms-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                                        class="bi bi-arrow-right-circle conversion-button-text" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z">
                                        </path>
                                    </svg></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default ConversionBanner;